import React from 'react'
import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const RectangleButtonStyle = styled(Button)({
    background:'#fff',
    color: '#9E9E9E',
    fontSize: '14px',
    border: '1px solid #DADCE0',
    borderRadius: '4px !important',
    padding: '2px !important',
    width: '119px',
    boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.070)',
    textTransform: 'none'
});

// const useStyles = makeStyles(theme => ({
//     disabled: {
//         border: '2px solid #DDDDDD',
//         color: '#DDDDDD'
//     },
//     enabled: {
//         border: '2px solid #535353',
//         color: '#535353'
//     }
// }));


export default function RectangleButton({onClickHandler, btnText }) {
    //const classes = useStyles();
    return (
        <RectangleButtonStyle  onClick={onClickHandler}>{btnText}</RectangleButtonStyle>
    )
}
