import React from "react";
import { styled, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import classnames from "classnames";

const BorderButtonStyle = styled(Button)({
  backgroundColor: "#ffffff",
  fontSize: 14,
  color: "#535353",
  textTransform: "capitalize",
  fontFamily: "inherit",
  padding: "7px 24px",
  boxShadow: "none",
  marginRight: 30,
  border: "2px solid #535353"
});

const useStyles = makeStyles(theme => ({
  disabled: {
    border: "2px solid #DDDDDD",
    color: "#DDDDDD"
  },
  enabled: {
    border: "2px solid #535353",
    color: "#535353",
    "&:hover": {
      backgroundColor: "#535353",
      color: "#fff",
      opacity: 0.8
    }
  },
  leftBtn: {
    float: "left"
  },
  mr0: {
    marginRight: "0px"
  }
}));

export default function BorderButton({
  disabled,
  onClickHandler,
  btnText,
  btnStyle
}) {
  const classes = useStyles();
  return (
    <BorderButtonStyle
      classes={{ root: btnStyle ? btnStyle : null }}
      disabled={disabled}
      onClick={onClickHandler}
      className={classnames(
        disabled ? classes.disabled : classes.enabled,
        btnText === "Return to Entity Page" ? classes.mr0 : classes.leftBtn
      )}
    >
      {btnText}
    </BorderButtonStyle>
  );
}
