import React, { Fragment, useState, useEffect } from "react";
import AppointRoleContainer from "./AppointRoleContainer";
import { Box, InputBase, withStyles, createStyles } from "@material-ui/core";
import ButtonStyled from "../../../website/components/ButtonStyled";
import { nodeholderSteps } from "./ManageNodeholder";
// import * as actions from "../../../../redux/actions";
// import { useDispatch } from "react-redux";

const BootstrapInput = withStyles(theme =>
  createStyles({
    root: {
      marginTop: theme.spacing(1) + 2,
      marginBottom: theme.spacing(1) + 2,
      width: "100%"
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: "#FCFCFC",
      border: "2px solid #DAE1E7",
      fontSize: 16,
      width: "100%",
      padding: theme.spacing(2),
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      color: "#9E9E9E",
      '&:focus': {
        border: "2px solid #535353",
      }
    }
  })
)(InputBase);

// const intialFormData = {
//   name: "",
//   email: ""
// };

let emailValid = false;
let nameValid = false;

function AppointRoleNodeForm({ classes, handleNext, formData, setFormData, nodeholderRoles }) {
  // const [formData, setFormData] = useState(intialFormData);

  const [enableSubmit, setEnableSubmit] = useState(false);
  // const dispatch = useDispatch();

  useEffect(() => {
    let enableSubmitBtn = Object.keys(formData).every(value => {
      if (value === 'emailError') {
        return true;
      }
      else{
        return (
          formData[value].trim() &&
          formData[value].trim().length &&
          emailValid &&
          nameValid
        );
      }
    });
    setEnableSubmit(enableSubmitBtn);
  }, [formData]);

  const onFormChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    if (e.target.name === "name") {
      if (/^[a-zA-Z ]+$/i.test(e.target.value)) nameValid = true;
      else nameValid = false;
    }
    if (e.target.name === "email") {
      if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value))
        emailValid = true;
      else emailValid = false;
    }
  };

  const handleSubmit = () => {
    
    if (nodeholderRoles.length>0) {
      const emailMatchFound = nodeholderRoles.find(({ NodeHolderEmail }) => NodeHolderEmail === formData.email);
      if (emailMatchFound) {
        setFormData({...formData, emailError: "nodeholder with this email is already exist !!"})
      } else {        
        handleNext(nodeholderSteps.appointRoleConfirmation);
        setFormData({...formData, emailError: ''})
      }
    }
  };

  return (
    <Fragment>
      <AppointRoleContainer
        classes={classes}
        titleDescription={
          "Enter the full name and email address of the nodeholder you wish to appoint the role to."
        }
      >
        <Box style={{ width: "100%", textAlign: "center" }}>
          <BootstrapInput
            type="text"
            name="name"
            placeholder="Full name"
            value={formData.name}
            onChange={onFormChange}
            required
          />
          <BootstrapInput
            type="email"
            name="email"
            placeholder="Enter your email"
            value={formData.email}
            onChange={onFormChange}
            required
            error={formData.emailError.length>0 ? true : false}
          />
          {formData.emailError.length>0 && <small style={{color: 'red'}}>{formData.emailError}</small> }
          <ButtonStyled
            disabled={!enableSubmit}
            btnPadding={classes}
            onClickHandler={handleSubmit}
            btnText={"Done"}
          />
        </Box>
      </AppointRoleContainer>
    </Fragment>
  );
}

export default AppointRoleNodeForm;
