import React, { useState } from 'react';
import Nominations from '../Nominations';
import TermsAndConditions from './TermsAndConditions';

export const nominationSteps = {
    default: 0,
    nominationTnC: 1
}

function NominationsContainer(props) {
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep(nominationSteps.nominationTnC);
    }

    const getStepContent = () => {
        switch (activeStep) {
            case nominationSteps.default:
                return (
                    <Nominations handleNextTerms={handleNext}/>
                );
            case nominationSteps.nominationTnC:
                return (
                    <TermsAndConditions 
                    setActiveStep={setActiveStep}
                    />
                );
            default:
                return <div />
        }
    }

    return (
        <div>
            {getStepContent()}
        </div>
    );
}

export default NominationsContainer;