import React, { Fragment, useEffect } from 'react';
import { Grid, Typography, Box, Link } from '@material-ui/core';
import classnames from 'classnames';
import EntitiesContainer from '../Entities/EntitiesContainer';
import NoEntities from '../Entities/AssociatedEntities/NoEntities';
import ExecutiveNodeHolderCard from './ExecutiveNodeHolderCard';
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../../redux/actions";

function ExecutiveNodeHolders({ classes }) {

    const selectedEntity = useSelector(
        state => state.entityPlatform.selectedEntity
    );
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.updateRoleLimits());
    }, []);

    const entities = selectedEntity.NodeHolderRoles ? selectedEntity.NodeHolderRoles : [];

    const footerText = (<Fragment><Grid className={classes.Alternatively}>Alternatively</Grid><Grid><Link className={classes.linkTxt} href="/entity-formation">Form your own decentralized entity.</Link></Grid></Fragment>);
    const headerText = (<Fragment>You currently don't have an active role in any decentralized entity.</Fragment>);
    const middleText = (<Grid className={classes.middleTxt}>Wait for others to appoint you as a nodeholder.</Grid>);

    return (
        <div>
            <Grid container spacing={0} className={classes.executiveMainCont}>
                <Box className={classes.borderBtm}>
                    <Grid item xs={12} className={classnames(classes.executiveTitleCont)}>
                        <Typography className={classes.cardTitleText}>Executive Nodeholders</Typography>
                        <Typography className={classnames(classes.cardDescriptionText, classes.executiveCardDescriptionText)}>Manage the company's executive nodeholders and appoint new ones</Typography>
                    </Grid>
                </Box>
                <Box style={{ width: '100%' }}>
                    <Grid item xs={12} className={classes.executiveDescriptionCont}>
                        <EntitiesContainer
                            maxWidth="xl">
                            <Grid container className={classnames(classes.executiveTitleCont, classes.subTitlePadding)} direction="row" >
                                <Grid item xs={10} className={classes.subTitleActive}>
                                    Nodeholder Roles ({entities.length ? entities.length : 0})
                                </Grid>
                                <Grid item xs={2} className={classes.historyText}>History</Grid>
                            </Grid>
                            <Grid item xs={12} className={classes.executiveTitleCont}>
                                {
                                    !entities.length ?
                                        (<NoEntities
                                            noCardPAdding={true}
                                            footerText={footerText}
                                            headerText={headerText}
                                            middleText={middleText}
                                        />) : entities.map(entity => <ExecutiveNodeHolderCard key={entity._id} entity={entity} />)
                                }
                            </Grid>
                        </EntitiesContainer>
                    </Grid>
                </Box>
            </Grid>
        </div>
    );
}

export default ExecutiveNodeHolders;