import * as actions from '../actions';
import { call, put } from 'redux-saga/effects';
import { getLoggedUser } from '../../services/generic';
import fireAjax from '../../services/index';
import { toast } from 'react-toastify';

export function* getAssociatedEntities() {
  try {
    const response = yield call(
      fireAjax,
      'GET',
      'entity/get-associated-entites',
      {},
      { Authorization: getLoggedUser() }
    );
    if (response.data.status === 1) {
      yield put(actions.getAssociatedEntitiesSuccess(response.data));
    } else {
      yield put(
        actions.getAssociatedEntitiesError({
          message:
            response.data.message ||
            'Error Occurs Getting Associated Details !!'
        })
      );
      // toast(
      //   `${response.data.message ||
      //     'Error Occurs Getting Associated Details !!'}`
      // );
    }
  } catch (e) {
    yield put(
      actions.profileRequestError({
        message: 'Error Occurs Getting Associated Details !!'
      })
    );
    // toast('Error Occurs Getting Associated Details !!');
  }
}

export function* getAllNominations() {
  try {
    const response = yield call(
      fireAjax,
      'GET',
      'entity/pending-invites',
      {},
      { Authorization: getLoggedUser() }
    );
    if (response.data.status === 1) {
      yield put(actions.getAllNominationsSuccess(response.data.data));
    } else {
      yield put(
        actions.getAllNominationsError({
          message:
            response.data.message || 'Error when trying to get nominations'
        })
      );
      // toast('Error when trying to get nominations');
    }
  } catch (e) {
    yield put(
      actions.getAllNominationsError({
        message: 'Error when trying to get nominations'
      })
    );
    // toast('Error when trying to get nominations');
  }
}

export function* getNominationHistory() {
  try {
    const response = yield call(
      fireAjax,
      'GET',
      'entity/history',
      {},
      { Authorization: getLoggedUser() }
    );
    if (response.data.status === 1) {
      yield put(actions.getNominationHistorySuccess(response.data.data));
    } else {
      yield put(
        actions.getNominationHistoryError({
          message:
            response.data.message ||
            'Error Occurs Getting Nominations History Details !!'
        })
      );
      // toast('Error Occurs Getting Nominations History Details !!');
    }
  } catch (e) {
    yield put(
      actions.getNominationHistoryError({
        message: 'Error Occurs Getting Nominations History Details !!'
      })
    );
    // toast('Error Occurs Getting Nominations History Details !!');
  }
}

export function* setSuspendedEntity(action) {
  const formData = {
    entity_id: action.payload
  };
  try {
    const response = yield call(
      fireAjax,
      'POST',
      'entity/entity-suspend',
      formData,
      { Authorization: getLoggedUser() }
    );
    if (response.data.status === 1) {
      yield put(actions.setSuspendedEntitySuccess(response.data.data));
    } else {
      yield put(
        actions.setSuspendedEntityError({
          message:
            response.data.message || 'Error Occurs To Suspending Entity !!'
        })
      );
      toast('Error Occurs To Suspending Entity !!');
    }
  } catch (e) {
    yield put(
      actions.profileRequestError({
        message: 'Error Occurs To Suspending Entity !!'
      })
    );
    toast('Error Occurs To Suspending Entity !!');
  }
}

export function* changeEntityName(action) {
  const data = action.payload;
  const formData = {
    entityId: data.selectedEntity.EntityId,
    oldName: data.selectedEntity.EntityName,
    newName: data.EntityName
  };
  try {
    const response = yield call(
      fireAjax,
      'POST',
      'entity/change-name',
      formData,
      { Authorization: getLoggedUser() }
    );
    if (response.data.status === 1) {
      yield put(actions.changeEntityNameSuccess(response.data.data));
    } else {
      yield put(
        actions.changeEntityNameError({
          message:
            response.data.message || 'Error Occurs To Change Entity Name !!'
        })
      );
      toast(
        response.data.message
          ? response.data.message
          : 'Error Occurs To Change Entity Name !!'
      );
    }
  } catch (e) {
    yield put(
      actions.profileRequestError({
        message: 'Error Occurs To Change Entity Name !!'
      })
    );
    toast('Error Occurs To Change Entity Name !!');
  }
}

export function* getEquityNodeHolderHistories(action) {
  const id = action.payload;
  try {
    const response = yield call(
      fireAjax,
      'GET',
      `entity/equity-allocation-history/${id}`,
      {},
      { Authorization: getLoggedUser() }
    );
    if (response.data.status === 1) {
      yield put(
        actions.getEquityNodeHolderHistoriesSuccess(response.data.data)
      );
    } else {
      yield put(
        actions.getEquityNodeHolderHistoriesError({
          message:
            response.data.msg ||
            'Error Occurs Getting Equity NodeHolder History !!'
        })
      );
      toast('Error Occurs Getting Equity NodeHolder History !!');
    }
  } catch (e) {
    yield put(
      actions.profileRequestError({
        message: 'Error Occurs Getting Equity NodeHolder History !!'
      })
    );
    toast('Error Occurs Getting Equity NodeHolder History !!');
  }
}

export function* getNodeHolderStatusRoles() {
  try {
    const response = yield call(
      fireAjax,
      'GET',
      'entity/roles',
      {},
      { Authorization: getLoggedUser() }
    );
    if (response.data.status === 1) {
      yield put(actions.getNodeHolderStatusRolesSuccess(response.data.data));
    } else {
      yield put(actions.getNodeHolderStatusRolesError(response.data.message));
    }
  } catch (e) {
    yield put(
      actions.profileRequestError({
        message: 'Error Occurs Getting Equity NodeHolders !!'
      })
    );
    // toast('Error Occurs Getting Equity NodeHolders !!');
  }
}

export function* resignRole(action) {
  // const data = action.payload;
  const resignData = action.payload;

  try {
    const response = yield call(fireAjax, 'POST', 'entity/resign', resignData, {
      Authorization: getLoggedUser()
    });
    if (response.data.status === 1) {
      // yield put(actions.getNodeHolderStatusRoles);
      yield put(
        actions.resignRoleSuccess({ response: response.data.data, resignData })
      );
      toast('Successfully Resigned !!');
    }
  } catch (e) {
    yield put(
      actions.resignRoleError({
        message: 'Error Occurs While Resign Role !!'
      })
    );
    toast('Error Occurs While Resign Role !!');
  }
}

export function* getNodeHolderStatusHistory() {
  try {
    const response = yield call(
      fireAjax,
      'GET',
      'entity/roles/history',
      {},
      { Authorization: getLoggedUser() }
    );
    if (response.data.status === 1) {
      yield put(actions.getNodeHolderStatusHistorySuccess(response.data.data));
    } else {
      yield put(
        actions.getNodeHolderStatusHistoryError({
          message:
            response.data.message ||
            'Error Occurs Getting Nodeholder Status History Details !!'
        })
      );
      // toast('Error Occurs Getting Nodeholder Status History Details !!');
    }
  } catch (e) {
    yield put(
      actions.getNodeHolderStatusHistoryError({
        message: 'Error Occurs Getting Nodeholder Status History Details !!'
      })
    );
    // toast('Error Occurs Getting Nodeholder Status History Details !!');
  }
}

export function* nominationAction(action) {
  const data = action.payload;
  try {
    const response = yield call(
      fireAjax,
      'GET',
      `entity/accept-invitation?Token=${data.token}&Email=${data.email}&action=${data.action}`,
      {},
      { Authorization: getLoggedUser() }
    );
    if (response.data.status === 1) {
      yield put(actions.nominationActionSuccess(data.token));
    } else {
      yield put(
        actions.nominationActionError({
          message:
            response.data.message ||
            `Error Occurs Getting Accept or Decline Invitation Details !!`
        })
      );
      toast('Error Occurs Getting Accept or Decline Invitation Details !!');
    }
  } catch (e) {
    yield put(
      actions.nominationActionError({
        message: 'Error Occurs Getting Accept or Decline Invitation Details !!'
      })
    );
    toast('Error Occurs Getting Accept or Decline Invitation Details !!');
  }
}

export function* allocateEqt(action) {
  const data = action.payload;
  try {
    const response = yield call(fireAjax, 'POST', 'entity/allocate-eqt', data, {
      Authorization: getLoggedUser()
    });
    if (response.data.status === 1) {
      yield put(actions.allocateEqtSuccess(response.data.data));
    } else {
      yield put(
        actions.allocateEqtError({
          message:
            response.data.message || 'Error Occurs on Allocate Eqt Details !!'
        })
      );
      toast(response.data.message);
    }
  } catch (e) {
    yield put(
      actions.allocateEqtError({
        message: 'Error Occurs on Allocate Eqt !!'
      })
    );
    toast('Error Occurs on Allocate Eqt Details !!');
  }
}

export function* currentNominationCardEntityId(action) {
  const entityId = action.payload;
  if (entityId)
    yield put(actions.currentNominationCardEntityIdSuccess(entityId));
  else yield put(actions.currentNominationCardEntityIdError());
}

export function* verifyPassword(action) {
  const verifyData = action.payload;
  try {
    const response = yield call(
      fireAjax,
      'POST',
      `user/verify-password`,
      verifyData,
      { Authorization: getLoggedUser() }
    );
    if (response.data.status === 1) {
      yield put(actions.verifyPasswordSuccess(response.data.passwordverified));
    } else {
      yield put(
        actions.verifyPasswordError({
          message:
            response.data.message || `Error Occurs On Password Verification !!`
        })
      );
      toast('Error Occurs On Password Verification !!');
    }
  } catch (e) {
    yield put(
      actions.verifyPasswordError({
        message: 'Error Occurs On Password Verification !!'
      })
    );
    toast('Error Occurs On Password Verification !!');
  }
}

export function* setVisibilityStatus(action) {
  const data = action.payload;
  const { EntityId, Email, Role } = data;
  try {
    const response = yield call(
      fireAjax,
      'POST',
      `entity/visibility-status`,
      { EntityId, Email, Role },
      { Authorization: getLoggedUser() }
    );
    if (response.data.status === 1) {
      yield put(actions.setVisibilityStatusSuccess(data));
    } else {
      yield put(
        actions.setVisibilityStatusError({
          message:
            response.data.message || `Error Occurs On Visibility Status !!`
        })
      );
      toast('Error Occurs On Visibility Status !!');
    }
  } catch (e) {
    yield put(
      actions.setVisibilityStatusError({
        message: 'Error Occurs On Visibility Status !!'
      })
    );
    toast('Error Occurs On Visibility Status !!');
  }
}

export function* setAppointNodeHolders(action) {
  const data = action.payload;

  try {
    const response = yield call(
      fireAjax,
      'POST',
      'entity/appoint-nodeholder',
      data,
      { Authorization: getLoggedUser() }
    );
    if (response.data.status === 1) {
      yield put(
        actions.setAppointNodeHolderSuccess(response.data.data, data.EntityId)
      );
      toast('Successfully Appoint As A NodeHolder !!');
    } else {
      yield put(
        actions.setAppointNodeHolderError({
          message:
            response.data.message || 'Error Occurs To Appoint Node Holder !!'
        })
      );
      toast('Error Occurs To Appoint Node Holder !!');
    }
  } catch (e) {
    toast('Error Occurs To Appoint Node Holder !!');
  }
}

export function* updatePasswordVerifiedFlag() {
  yield put(actions.updatePasswordVerifiedFlagSuccess());
}

export function* updateIsAppointNodeholderFlag() {
  yield put(actions.updateIsAppointNodeholderFlagSuccess());
}

export function* updateRoleLimits() {
  yield put(actions.updateRoleLimitsSuccess());
}

export function* setSelectedEntity(action) {
  const entity = action.payload;
  yield put(actions.setSelectedEntitySuccess(entity));
}

export function* updateNodeRolesAsInitial() {
  yield put(actions.updateNodeRolesAsInitialSuccess());
}

export function* updateOldEntityName() {
  yield put(actions.updateOldEntityNameSuccess());
}
