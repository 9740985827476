import React, { useState } from "react";
import ManageEquity from "./ManageEquity";
import AllocateEquity from "./AllocateEquity";
import { withStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
// import * as actions from "../../../../../redux/actions"

export const equityPages = {
  manageEquityPage: 1,
  allocateEquityPage: 2
};

const styles = theme => ({
  companyName: {
    color: "#555555",
    fontSize: 18,
    marginBottom: 5,
    width: '100%',
    lineHeight: '1.5',
    overflow: 'hidden'
  },
  companyValue: {
    color: "#535353",
    fontSize: 20,
    textAlign: "right"
  },
  companyDescName: {
    color: "#9E9E9E",
    fontSize: 14,
    textTransform: 'initial'
  },
  cardContainerPAdding: {
    padding: "16px"
  },
  textRight: {
    textAlign: "right"
  },
  cardContainer: {
    border: "1px solid #DADCE0",
    borderRadius: 4,
    margin: "0 0 12px",
    padding: 16
  },
  mb20: {
    marginBottom: 20
  },
  pendingManageEntityBtnTxt: {
    color: "#555555",
    fontSize: "14px",
    textTransform: "capitalize",
    padding: "0px"
  },
  manageEntityPAdding: {
    padding: "16px 16px",
    borderTop: "1px solid #DADCE0"
  },
  textCenter: {
    textAlign: "center"
  },
  btnPadding: {
    padding: theme.spacing(1, 4),
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(2),
    borderRadius: "2px"
  },
  plusIcon: {
    // fontSize: 18,
    // color: '#a6a6a6',
    // '&:hover': {
    color: "#757575"
    // }
  },
  displayCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  addNodeholderText: {
    fontSize: 16,
    color: "#757575",
    marginRight: theme.spacing(1)
  },
  addEqtP: {
    fontSize: 16,
    color: "#757575",
    lineHeight: "1.5"
  },
  equityNodeList: {
    border: "1px solid #DAE1E7",
    borderRadius: "5px",
    backgroundColor: "#FCFCFC",
    marginBottom: theme.spacing(1),
    "&:last-child": {
      marginBottom: theme.spacing(0)
    }
  },
  equityNodeListLeft: {
    padding: theme.spacing(2)
  },
  equityNodeListRight: {
    padding: theme.spacing(1)
  },
  equityNodeText: {
    color: "#9E9E9E",
    fontSize: 16
  },
  borderRight1: {
    borderRight: "1px solid #DAE1E7"
  },
  activeText: {
    color: "#535353"
    // textDecoration: 'underline'
  },
  equityNodeholdersTab: {
    "&:hover": {
      color: "#000",
      cursor: "pointer"
    }
  }
});


function ManageEquityContainer({ classes, setManageEntityStep }) {

  const selectedEntity = useSelector(
    state => state.entityPlatform.selectedEntity
  );

  const equityNodeHolders = selectedEntity.EquityAllocation;
  const [activeStep, setActiveStep] = useState(1);
  const [showHistory, setShowHistory] = useState(false);

  const handleNext = () => {
    setActiveStep(equityPages.allocateEquityPage);
  };

  const getStepContent = () => {
    switch (activeStep) {
      case equityPages.manageEquityPage:
        return (
          <ManageEquity
            handleNext={handleNext}
            classes={classes}
            setManageEntityStep={setManageEntityStep}
            showHistory={showHistory}
            setShowHistory={setShowHistory}
            equityNodeHolders={equityNodeHolders}
          />
        );
      case equityPages.allocateEquityPage:
        return (
          <AllocateEquity
            classes={classes}
            setManageEntityStep={setManageEntityStep}
            setActiveStep={setActiveStep}
            setShowHistory={setShowHistory}
          />
        );

      default:
        return <ManageEquity
          handleNext={handleNext}
          classes={classes}
          setManageEntityStep={setManageEntityStep}
          showHistory={showHistory}
          setShowHistory={setShowHistory}
          equityNodeHolders={equityNodeHolders}
        />;
    }
  };
  return <div>{getStepContent(activeStep)}</div>;
}

export default withStyles(styles)(ManageEquityContainer);
