import React, { Component } from "react";
import { Grid, Button, TextField, CircularProgress } from "@material-ui/core";
// import { Link } from "react-router-dom";
import * as action from "../../../redux/actions";
import { connect } from "react-redux";
import "../styles.css";

class IdRecovery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      errorText: ""
    };
  }
  
  componentDidMount() {
    document.body.style.backgroundColor = '#fcfcfc';
  }

  componentWillUnmount() {
    document.body.style.backgroundColor = 'unset';
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.auth.isSuccess !== this.props.auth.isSuccess &&
      this.props.auth.isSuccess
    ) {
      this.setState({ email: "", errorText: "" });
      this.props.history.push("/login");
    }
  }

  onSubmit = () => {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email)) {
      this.setState({ errorText: "Invalid email address" });
    } else {
      this.props.recoverId({ email: this.state.email });
    }
  };
  render() {
    return (
      <div>
        <Grid container justify="center" alignItems="center" spacing={0}>
          <Grid item xs={12}>
            <form className="form-container">
              <span className="nodeholder-icon"></span>
              <div className="form-header">Username Reset</div>
              <div className="form-subheader">
                Enter your email and follow the reset link sent in your mailbox.
              </div>
              <div className="text-fields">
                <TextField
                  style={{ width: "100%" }}
                  id="email"
                  label="Email Address"
                  type="email"
                  autoComplete="email"
                  margin="normal"
                  onChange={e => {
                    this.setState({ email: e.target.value, errorText: "" });
                  }}
                  error={this.state.errorText ? true : false}
                />
                <small style={{ color: "red" }}>{this.state.errorText}</small>
              </div>
              <div className="center-button">
                <Button
                  label="Reset Username"
                  variant="outlined"
                  style={{
                    paddingLeft: 40,
                    paddingRight: 40,
                    textTransform: "capitalize"
                  }}
                  disabled={this.props.auth.isLoading}
                  onClick={this.onSubmit}
                  type="submit"
                >
                  {this.props.auth.isLoading ? (
                    <CircularProgress color="primary" size={24} />
                  ) : (
                    "Reset Username"
                  )}
                </Button>
              </div>
            </form>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};
const mapDispatchToProps = dispatch => {
  return { recoverId: data => dispatch(action.idRequest(data)) };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IdRecovery);
