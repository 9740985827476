import React, { Fragment, useState } from "react";
import NodeHoldersContainer from "../../website/components/NodeHoldersContainer";
import NodeHoldersTypography from "../../website/components/NodeHoldersTypography";
import { withStyles, Grid } from "@material-ui/core";
import classnames from "classnames";
import ButtonStyled from "../../website/components/ButtonStyled";
// import { useSelector, useDispatch } from "react-redux";
// import * as actions from "../../../redux/actions";
const styles = theme => ({
  textLeft: {
    textAlign: "left"
  },
  companyName: {
    letterSpacing: 0,
    fontWeight: "500",
    color: "#A7A7A7",
    fontSize: 16,
    textAlign: "left",
    marginTop: 60
  },
  companyNameSmall: {
    letterSpacing: 0.21,
    fontWeight: "500",
    color: "#777777",
    fontSize: 14,
    textAlign: "left",
    marginTop: 8
  },
  mT30: {
    marginTop: 30
  },
  mT60: {
    marginTop: 60
  },
  btnPadding: {
    padding: "9px 56px"
  },
  reverse: {
    textAlign: "center",
    fontSize: 14,
    letterSpacing: 0.42,
    color: "#535353",
    marginTop: "1rem",
    cursor: "pointer"
  },
  textRight: {
    textAlign: "right"
  },
  issuedCount: {
    fontSize: 14,
    fontWeight: "500",
    letterSpacing: 0.21,
    color: "#777777"
  },
  mb5: {
    marginBottom: 5
  }
});

// const nodeHolderList = [
//     {
//         id: 1,
//         designation: 'Founder Node',
//         name: 'Nodeholder Name',
//     },
//     {
//         id: 2,
//         designation: 'CEO Node',
//         name: 'Nodeholder Name',
//     },
//     {
//         id: 3,
//         designation: 'Founder Node',
//         name: 'Nodeholder Name',
//     },
//     {
//         id: 4,
//         designation: 'Chairman Node',
//         name: 'Nodeholder Name',
//     },
//     {
//         id: 5,
//         designation: 'Founder Node',
//         name: 'Nodeholder Name',
//     },

// ];

// const eqtAllocationList = [
//     {
//         id: 1,
//         name: 'Nodeholder Name',
//         allocation: '10%'
//     },
//     {
//         id: 2,
//         name: 'Nodeholder Name',
//         allocation: '25%'
//     },
//     {
//         id: 3,
//         name: 'Nodeholder Name',
//         allocation: '35%'
//     },
//     {
//         id: 4,
//         name: 'Nodeholder Name',
//         allocation: '15%'
//     },
//     {
//         id: 5,
//         name: 'Nodeholder Name',
//         allocation: '15%'
//     },
// ];

function ContractIncorporation({
  classes,
  handleNext,
  entityName,
  entityType,
  nodes,
  nodeHolderName,
  handlerevise,
  isEmailVarifiedOrNot,
  AssociatingNodeEmail
}) {
  // const dispatch = useDispatch();

  let entityTypeUpper = entityType
    .toLowerCase()
    .replace(/_/g, " ")
    .replace(/(?: |\b)(\w)/g, function(key, p1) {
      return key.toUpperCase();
    });
  const cardDescription =
    "Review and confirm the formation details, revise if needed.";

  const [currentStep, setCurrentStep] = useState(1);
  const getStepContent = () => {
    switch (currentStep) {
      case 1:
        return CompanyDetails;
      // break;
      case 2:
        return NodeholderDetails;
      // break;
      case 3:
        return EqtAllocation;
      // break;

      default:
        break;
    }
  };
  const CompanyDetails = (
    <Fragment>
      <div className={classes.companyName}>COMPANY NAME</div>
      <div className={classes.companyNameSmall}>{entityName}</div>
      <div className={classnames(classes.companyName, classes.mT30)}>
        COMPANY TYPE
      </div>
      <div className={classes.companyNameSmall}>{entityTypeUpper}</div>
    </Fragment>
  );

  const NodeholderDetails = (
    <Fragment>
      <div
        className={classnames(classes.companyName, classes.mT60, classes.mb5)}
      >
        Nodeholders{" "}
      </div>

      {nodes.map((nodeHolder, i) => {
        return (
          <Grid
            key={nodeHolder.id}
            className={classes.mb5}
            container
            direction="row"
            justify="flex-start"
          >
            <Grid className={classes.textLeft} item xs={6}>
              <div className={classes.issuedCount}>{nodeHolder.name}</div>
            </Grid>
            <Grid className={classes.textRight} item xs={6}>
              <div className={classes.issuedCount}>
                {nodeHolder.users.length > 0
                  ? nodeHolder.users[0].email
                  : "Not added"}
              </div>
              <div className={classes.issuedCount}>
                {nodeHolder.users.length > 1
                  ? "+ " + (nodeHolder.users.length - 1) + " More"
                  : ""}
              </div>
            </Grid>
          </Grid>
        );
      })}
    </Fragment>
  );

  const EqtAllocation = (
    <Fragment>
      <div
        className={classnames(classes.companyName, classes.mT60, classes.mb5)}
      >
        EQT Allocation{" "}
      </div>
      {nodeHolderName.map((email, i) => {
        return (
          <Grid
            key={i}
            className={classes.mb5}
            container
            direction="row"
            justify="flex-start"
          >
            <Grid className={classes.textLeft} item xs={6}>
              <div className={classes.issuedCount}>
                {email.inviteNodeHolderEmail}
              </div>
            </Grid>
            <Grid className={classes.textRight} item xs={6}>
              <div className={classes.issuedCount}>
                {email.nodeEqtValue + "%"}
              </div>
            </Grid>
          </Grid>
        );
      })}
    </Fragment>
  );

  const goToNext = () => {
    if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
    } else {
      isEmailVarifiedOrNot(AssociatingNodeEmail);
      handleNext();
    }
  };

  return (
    <div>
      <NodeHoldersContainer
        showFooterText={false}
        currentStep={1}
        pageTitle={"Contract of Incorporation"}
        totalSteps={3}
      >
        <NodeHoldersTypography
          title={"Let's Sum up"}
          cardDescription={cardDescription}
        />
        {getStepContent()}
        <Grid className={classes.mT60}>
          <ButtonStyled
            btnPadding={classes}
            btnText={currentStep < 3 ? "Confirm" : "Proceed"}
            onClickHandler={goToNext}
          />
          <div className={classes.reverse} onClick={handlerevise}>
            Revise
          </div>
        </Grid>
      </NodeHoldersContainer>
    </div>
  );
}

export default withStyles(styles)(ContractIncorporation);
