import { combineReducers } from "redux";
import auth from "./auth/reducer";
import entityForm from "./entiyForm/reducer";
import userDetails from "./userDetails/reducer";
import entityPlatform from "./entityPlatform/reducer";

export default combineReducers({
  auth,
  entityForm,
  userDetails,
  entityPlatform
});
