import React from "react";
import {
  Grid,
  Typography,
  Box,
  Popover
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import classnames from "classnames";
import Moment from "../../../../services/Moment";

const styles = theme => ({
  companyName: {
    color: "#555555",
    fontSize: 18,
    textTransform: 'capitalize'
  },
  companyDescName: {
    color: "#9E9E9E",
    fontSize: 14,
    marginTop: theme.spacing(1) - 2
  },
  suspendedTxt: {
    color: "#9E9E9E",
    fontSize: 14,
    textAlign: "right",
    textTransform: "capitalize"
  },
  activeTxt: {
    color: "#00BE20",
    fontSize: 14,
    textAlign: "right",
    textTransform: "capitalize"
  },
  nodecodeTxt: {
    color: "#9E9E9E",
    fontSize: 14
  },
  cardContainer: {
    width: "100%",
    padding: theme.spacing(2)
  },
  box: {
    border: "1px solid #DADCE0",
    borderRadius: 4,
    marginBottom: theme.spacing(2)
  },
  divider: {
    borderTop: "1px solid #DADCE0",
    padding: theme.spacing(1, 2)
  },
  moreIcon: {
    textAlign: "right",
    marginTop: "3px"
  },
  optionText: {
    color: "#535353",
    fontSize: 16,
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1, 15, 1, 1),
    "&:hover": {
      backgroundColor: "#DADCE0"
    },
    "&:last-child": {
      marginBottom: theme.spacing(0)
    }
  },
  optContainer: {
    padding: theme.spacing(2, 1, 2, 2)
  }
});

function ExecutiveNodeHolderCard({ entity, classes }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = action => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className={classes.box}>
      <Grid className={classes.cardContainer}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={7}>
            <Typography className={classes.companyName}>
              {entity.NodeHolderName && entity.NodeHolderName}
            </Typography>
          </Grid>

          <Grid
            className={
              entity.status === "active"
                ? classes.activeTxt
                : classes.suspendedTxt
            }
            item
            xs={5}
          >
            {(entity.NominationStatus) ? entity.NominationStatus : 'active or suspended or pending'}
          </Grid>
        </Grid>
        <Typography className={classes.companyDescName}>
          {entity.NodeHolderRole} Node
        </Typography>
        <Typography className={classes.companyDescName}>
          {entity.InvitedDate ? <Moment text={"Appointed: "} date={entity.InvitedDate} /> : ' Appointed: mm/dd/yy'}
        </Typography>
      </Grid>
      <Grid
        container
        spacing={0}
        direction="row"
        justify="center"
        alignItems="center"
        className={classnames(classes.divider)}
      >
        <Grid item xs={10} className={classes.nodecodeTxt}>
          #{entity.Nodecode ? entity.Nodecode : 'Nodecode'}
        </Grid>
        <Grid item xs={2} className={classes.moreIcon}>
          <MoreVertIcon
            color="primary"
            aria-describedby={id}
            onClick={handleClick}
          />
          <Popover id={id} open={open} anchorEl={anchorEl}>
            <Box className={classes.optContainer}>
              <Typography
                className={classes.optionText}
                onClick={() => handleClose("suspend")}
              >
                Suspend
              </Typography>
              <Typography
                className={classes.optionText}
                onClick={() => handleClose("Dismiss")}
              >
                Dismiss
              </Typography>
            </Box>
          </Popover>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(ExecutiveNodeHolderCard);
