import { takeLatest, all } from "redux-saga/effects";
import constants from "./constants";
import {
  loginRequest,
  signupRequest,
  passwordRequest,
  idRequest
} from "./auth/action";
import {
  entityFormRequest,
  checkEntityAvailability,
  getUserEntities,
  checkNameAvailability,
  checkUserExist,
  emailVarified,
  inviteAssociatingNode,
  submitEntityFormRequest
} from "./entiyForm/action";
import {
  userProfileRequest,
  updateProfile,
  verifyEmailRequest
} from "./userDetails/action";
import { 
  getAssociatedEntities, 
  getAllNominations, 
  setSuspendedEntity, 
  getNominationHistory, 
  changeEntityName, 
  getEquityNodeHolderHistories, 
  getNodeHolderStatusRoles,
  resignRole,
  getNodeHolderStatusHistory, 
  nominationAction,
  allocateEqt,
  currentNominationCardEntityId,
  verifyPassword,
  setVisibilityStatus,
  setAppointNodeHolders,
  updatePasswordVerifiedFlag,
  updateIsAppointNodeholderFlag,
  updateRoleLimits,
  setSelectedEntity,
  updateNodeRolesAsInitial,
  updateOldEntityName} from "./entityPlatform/action";

export function* watchActions() {
  yield takeLatest(constants.LOGIN_REQUEST, loginRequest);
  yield takeLatest(constants.SIGN_UP_REQUEST, signupRequest);
  yield takeLatest(constants.REQUEST_PASSWORD, passwordRequest);
  yield takeLatest(constants.REQUEST_ID, idRequest);
  yield takeLatest(constants.SAVE_ENTITY_FORM, entityFormRequest);
  yield takeLatest(constants.PROFILE_REQUEST, userProfileRequest);
  yield takeLatest(constants.UPDATE_PROFILE, updateProfile);
  yield takeLatest(constants.VERIFY_EMAIL, verifyEmailRequest);
  yield takeLatest(constants.GET_AVAILABLE_ENTITY_TYPE, checkEntityAvailability);
  yield takeLatest(constants.GET_NAME_AVAILABLITY, checkNameAvailability);
  yield takeLatest(constants.GET_USER_ENTITIES, getUserEntities);
  yield takeLatest(constants.GET_USER_EXIST, checkUserExist);
  yield takeLatest(constants.IS_ASSOCAITE_EMAIL_VERIFIED,emailVarified);
  yield takeLatest(constants.INVITE_ASSOCAITING_NODE,inviteAssociatingNode);
  yield takeLatest(constants.CREATE_ENTITY_FORM,submitEntityFormRequest);
  yield takeLatest(constants.GET_ASSOCIATED_ENTITIES,getAssociatedEntities);
  yield takeLatest(constants.GET_ALL_NOMINATIONS,getAllNominations);
  yield takeLatest(constants.GET_NOMINATION_HISTORY,getNominationHistory);
  yield takeLatest(constants.SET_SUSPENDED_ENTITY,setSuspendedEntity);
  yield takeLatest(constants.SET_APPOINT_NODE_HOLDER, setAppointNodeHolders);
  yield takeLatest(constants.CHANGE_ENTITY_NAME,changeEntityName);
  yield takeLatest(constants.GET_EQUITY_NODE_HOLDERS_HISTORIES, getEquityNodeHolderHistories);
  yield takeLatest(constants.GET_NODE_HOLDER_STATUS_ROLES, getNodeHolderStatusRoles);
  yield takeLatest(constants.RESIGN_ROLE, resignRole);
  yield takeLatest(constants.GET_NODE_HOLDER_STATUS_HISTORY, getNodeHolderStatusHistory);
  yield takeLatest(constants.NOMINATION_ACTION, nominationAction);
  yield takeLatest(constants.ALLOCATE_EQT, allocateEqt);
  yield takeLatest(constants.CURRENT_NOMINATION_CARD_ENTITY_ID, currentNominationCardEntityId);
  yield takeLatest(constants.VERIFY_PASSWORD, verifyPassword);
  yield takeLatest(constants.SET_VISIBILITY_STATUS, setVisibilityStatus);
  yield takeLatest(constants.UPDATE_PASSWORD_VERIFIED_FLAG, updatePasswordVerifiedFlag);
  yield takeLatest(constants.UPDATE_IS_APPOINT_NODEHOLDER_FLAG, updateIsAppointNodeholderFlag);
  yield takeLatest(constants.UPDATE_ROLE_LIMITS, updateRoleLimits);
  yield takeLatest(constants.SET_SELECTED_ENTITY, setSelectedEntity);
  yield takeLatest(constants.UPDATE_NODE_ROLES_AS_INITIAL, updateNodeRolesAsInitial);
  yield takeLatest(constants.UPDATE_OLD_ENTITY_NAME, updateOldEntityName);
}

export default function* rootSaga() {
  yield all([watchActions()]);
}
