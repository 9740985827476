import React, { Component } from 'react';
import { Grid, Button } from '@material-ui/core';

export class NewName extends Component {
  render() {
    return (
      <div className="panel-container">
        <Grid item xs={12}>
          <div className="header">
            Register a name for a <br /> new digital entity.
          </div>
          <Button
            variant="contained"
            style={{
              width: '240px',
              height: '42px',
              color: '#fff',
              backgroundColor: '#111',
              textTransform: 'capitalize'
            }}
            onClick={() => {
              this.props.history.push('/entity-formation');
            }}
          >
            Reserve New Name
          </Button>
          <br />
          <Button
            style={{ textTransform: 'capitalize', marginTop: '90px' }}
            onClick={() => {
              this.props.history.push('/nodeholder-panel');
            }}
          >
            Settings
          </Button>
        </Grid>
      </div>
    );
  }
}

export default NewName;
