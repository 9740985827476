import React, { Component, createRef } from 'react';
import { Button, Grid, withStyles } from '@material-ui/core';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import CardContainer from '../../views/website/components/Entity-card';
import './styles.css';

// Import SVG Icons
import { ReactComponent as DLC } from '../../assets/icons/entity-type/dlc.svg';
import { ReactComponent as DLP } from '../../assets/icons/entity-type/dlp.svg';
import { ReactComponent as NGF } from '../../assets/icons/entity-type/ngf.svg';
import { ReactComponent as NGP } from '../../assets/icons/entity-type/ngp.svg';

const styles = theme => ({
  formationButton: {
    color: '#ffffff',
    borderRadius: 4,
    backgroundColor: '#535353',
    fontSize: 14,
    fontWeight: 400,
    fontFamily: 'inherit',
    letterSpacing: '0.5px',
    boxShadow: '0px 2px 6px #00000029',
    textTransform: 'capitalize',
    padding: '12px 30px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    }
  },
  formationButtonDisable: {
    color: '#9E9E9E',
    borderRadius: 4,
    backgroundColor: '#00000029',
    fontSize: 14,
    fontWeight: 400,
    fontFamily: 'inherit',
    letterSpacing: '0.5px',
    textTransform: 'capitalize',
    padding: '12px 30px'
  }
});
class EntityType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slide: '1',
      currentIndex: 0,
      itemsInSlide: 1
    };
  }
  carousal = createRef();

  responsive = {
    0: { items: 1 },
    767: { items: 2 }
  };

  entityTypes = {
    smart_legal_entity: [
      {
        value: 'decentralized_company',
        name: 'Decentralized',
        name2: 'Company',
        sub: 'DLC',
        subText: 'The Ultimate Legal Entity.',
        desc:
          'Liability is limited by decentralization, opportunity is unlimited with digitalization.',
        icon: <DLC height="200px" stroke="#464646" strokeWidth="5px" />,
        btnText: 'Decentralized Company',
        disable: false
      },
      {
        value: 'decentralized_partnership',
        name: 'Decentralized',
        name2: 'Partnership',
        sub: 'DLP',
        subText: 'An Immutable Bond.',
        desc:
          'Liability is limited by decentralization, opportunity is unlimited with digitalization.',
        icon: <DLP height="200px" stroke="#464646" strokeWidth="5px" />,
        btnText: 'Decentralized Partnership',
        disable: true
      }
    ],
    next_gen_entity: [
      {
        value: 'next_gen_project',
        name: 'Next-Gen',
        name2: 'Project',
        sub: 'NGP',
        subText: 'Capital, Programmable.',
        desc:
          'Digital asset management for digital assets. Governed by a smart contract.',
        icon: <NGP height="200px" stroke="#464646" strokeWidth="5px" />,
        btnText: 'New Next-Gen Project',
        disable: true
      },
      {
        value: 'next_gen_fund',
        name: 'Next-Gen',
        name2: 'Fund',
        sub: 'NGF',
        subText: 'The New Startup Entity',
        desc: 'Begin,',
        desc2: 'With Starting.',
        icon: <NGF height="200px" stroke="#464646" strokeWidth="5px" />,
        btnText: 'New Next-Gen Fund',
        disable: true
      }
    ]
  };

  responsive = {
    0: { items: 1 },
    767: { items: 2 }
  };

  stagePadding = {
    paddingLeft: 36,
    paddingRight: 36
  };

  continue = e => {
    e.preventDefault();
    this.props.handleNext();
  };

  previous = e => {
    this.props.handleBack();
  };

  componentWillMount() {
    const index = this.entityTypes[this.props.classType].findIndex(data => {
      return data.value === this.props.entityType;
    });
    this.setState({ currentIndex: index });
  }

  getItems = () => {
    let item = [];
    this.entityTypes[this.props.classType].map(data => {
      item.push(
        <CardContainer showCustomMargin={true}>
          <div className="eclass-type-carousel">
            <div className="entity-class-name">
              <span className="entity-type-fName">{data.name}</span>
              <span className="entity-type-fName entity-type-fName2">
                {data.name2}
              </span>
            </div>
            {data.icon}
            <div className="entity-class-sub mt-30">{data.subText}</div>
            <div className="entity-class-desc">
              {data.desc}
              {data.desc2 ? (
                <div>
                  {data.desc2} <br />
                </div>
              ) : (
                ''
              )}
            </div>
            <Button
              // className={this.props.classes.formationButton}
              className={
                !data.disable
                  ? this.props.classes.formationButton
                  : this.props.classes.formationButtonDisable
              }
              disabled={data.disable}
              onClick={e => {
                this.props.setEntityType(data.value);
                this.props.handleTopIcon(data.value);
                this.continue(e);
              }}
            >
              {`${data.btnText}`}
            </Button>
          </div>
        </CardContainer>
      );
    });
    return item;
  };

  handleSlide = button => {
    if (button === 'next') {
      this.carousal.current.slideNext();
    } else {
      this.carousal.current.slidePrev();
    }
  };

  handleOnSlideChange = event => {
    const { item } = event;
    this.setState({ currentIndex: item });
    this.props.setEntityType(
      this.entityTypes[this.props.classType][item].value
    );
  };

  render() {
    // const { currentIndex } = this.state;

    return (
      <div className="create-entity-form">
        <Grid container spacing={0} justify="center" alignItems="center">
          <Grid item xs={12}>
            <div className="form-header">
              <span>Select Entity Type</span>
            </div>
            {/* <div className="small-class-wrapper">
              {this.entityTypes[this.props.classType].map((data, i) => {
                return (
                  <div
                    className={`eSclass ${
                      this.props.entityType === data.value ? 'eSActive' : ''
                    }`}
                    onClick={e => {
                      this.setState({ currentIndex: i });
                      this.carousal.current.slideTo(i);
                    }}
                  >
                    {data.sub}
                  </div>
                );
              })}
            </div> */}
          </Grid>
          <Grid item xs={12}>
            <div className="carousel-container">
              {/* <button
                onClick={e => {
                  e.stopPropagation();
                  this.handleSlide('prev');
                }}
                className="prev"
              >
                <LeftArrow style={{ height: '48px' }} />
              </button> */}
              <AliceCarousel
                dotsDisabled={false}
                responsive={this.responsive}
                buttonsDisabled={true}
                ref={this.carousal}
                stagePadding={this.stagePadding}
                infinite={false}
                items={this.getItems()}
              />
              {/* <button onClick={() => this.handleSlide('next')} className="next">
                <RightArrow style={{ height: '48px' }} />
              </button> */}
            </div>
          </Grid>
          {/* <Grid item xs={12}>
            <div className="entity-class-sub">
              {
                this.entityTypes[this.props.classType][
                  this.entityTypes[this.props.classType].findIndex(
                    data => data.value === this.props.entityType
                  )
                ].subText
              }
            </div>
            <div className="entity-class-desc">
              {
                this.entityTypes[this.props.classType][
                  this.entityTypes[this.props.classType].findIndex(
                    data => data.value === this.props.entityType
                  )
                ].desc
              }
            </div>
            <div className="entity-class-btn">
              <Button
                variant="outlined"
                style={{
                  textTransform: 'capitalize',
                  fontSize: '1rem',
                  padding: '8px 0px',
                  width: '274px'
                }}
                onClick={e => {
                  this.props.setEntityType(
                    this.entityTypes[this.props.classType][
                      this.entityTypes[this.props.classType].findIndex(
                        data => data.value === this.props.entityType
                      )
                    ].value
                  );
                  this.props.handleTopIcon(
                    this.entityTypes[this.props.classType][
                      this.entityTypes[this.props.classType].findIndex(
                        data => data.value === this.props.entityType
                      )
                    ].value
                  );
                  this.continue(e);
                }}
              >
                {`New ${
                  this.entityTypes[this.props.classType][
                    this.entityTypes[this.props.classType].findIndex(
                      data => data.value === this.props.entityType
                    )
                  ].name
                }`}
              </Button>
            </div>

            <div
              className="back-btn"
              onClick={e => {
                this.previous();
              }}
            >
              Back
            </div>
          </Grid> */}
        </Grid>
      </div>
    );
  }
}
export default withStyles(styles)(EntityType);
