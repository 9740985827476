import React, { Fragment, useEffect } from "react";
import { withStyles, Grid, Typography, Link } from "@material-ui/core";
import classnames from "classnames";
import CloseIcon from "@material-ui/icons/Close";
import NoEntities from "../../Entities/AssociatedEntities/NoEntities";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../../../redux/actions";
import Moment from "../../../../../services/Moment";

const styles = theme => ({
  cardContainer: {
    border: "1px solid #DADCE0",
    borderRadius: 4,
    margin: "0 8px 12px"
  },
  companyName: {
    color: "#555555",
    fontSize: 18,
    marginBottom: 10
  },
  companyDescName: {
    color: "#9E9E9E",
    fontSize: 14,
    textTransform: 'capitalize'
  },
  manageEntityPAdding: {
    padding: "8px 10px",
    borderTop: "1px solid #DADCE0"
  },
  textRight: {
    textAlign: "right"
  },
  cardPAdding: {
    padding: "1rem"
  },
  mb24: {
    marginBottom: 20
  },
  closeIconCss: {
    color: "#9E9E9E",
    marginRight: -4
  },
  middleTxt: {
    marginTop: -44,
    marginBottom: 60,
    textAlign: "center",
    color: "#5F6368",
    fontSize: 14
  },
  linkTxt: {
    color: "#1A73E8",
    cursor: "pointer"
  },
  Alternatively: {
    color: "#9E9E9E",
    fontSize: 14
  }
});

function History({ classes }) {

  const dispatch = useDispatch();
  const histories = useSelector(state => state.entityPlatform.nodeholding_status_histories ? state.entityPlatform.nodeholding_status_histories : []);
  const email = useSelector(state => state.userDetails.data && state.userDetails.data.Email); 

  useEffect(() => {
    dispatch(actions.getNodeHolderStatusHistory())
  }, [])
  
  const footerText = (
    <Fragment>
      <Grid className={classes.Alternatively}>Alternatively</Grid>
      <Grid>
        <Link className={classes.linkTxt} href="/entity-formation">
          Form your own decentralized entity.
        </Link>
      </Grid>
    </Fragment>
  );
  const headerText = <Fragment>Your nodeholding history is empty.</Fragment>;
  const middleText = (
    <Grid className={classes.middleTxt}>
      Wait for others to appoint you as a nodeholder.
    </Grid>
  );

  const handleVisibility = (history) => {

    const params = {
      EntityId: history.EntityId,
      Email: email,
      Role: history.Role,
      type: 'nodeholder'
    }
    dispatch(actions.setVisibilityStatus(params))
  }

  return (
    <div style={{width: '100%'}}>
      {!histories.length ? (
        <NoEntities
          noCardPAdding={true}
          footerText={footerText}
          headerText={headerText}
          middleText={middleText}
        />
      ) : (
        <Grid
          className={classnames(classes.cardContainerPAdding)}
          container
          spacing={0}
          direction="row"
          justify="center"
          alignItems="center"
        >
          {histories &&
            histories.map(history => (
              history.Visible && (
                <Grid
                  className={classes.cardContainer}
                  item
                  xs={12}
                  key={history.EntityId}
                >
                  <Grid
                    container
                    direction="row"
                    className={classes.cardPAdding}
                    justify="center"
                  >
                    <Grid item xs={7}>
                      <Typography className={classes.companyName}>
                        {history.Role && history.Role}
                      </Typography>
                      <Typography
                        className={classnames(
                          classes.companyDescName,
                          classes.mb24
                        )}
                      >
                        {history.EntityName && history.EntityName}
                      </Typography>
                      <Typography className={classes.companyDescName}>
                          {history.ResignedDate && 
                            <Moment text={"Resigned: "} date={history.ResignedDate} /> }
                      </Typography>
                    </Grid>
                    <Grid className={classes.textRight} item xs={5}>
                      <CloseIcon className={classes.closeIconCss} onClick={() => handleVisibility(history)} />
                    </Grid>
                  </Grid>
                </Grid>
              )
            ))}
        </Grid>
      )}
    </div>
  );
}

export default withStyles(styles)(History);
