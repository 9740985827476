import React from 'react';
import ReactDOM from 'react-dom';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import store from './redux/store';
import App from './App';
import './index.css';

function CloseButton({ closeToast, type, ariaLabel }) {
  return (
    <button
      className="toasterButton"
      type="button"
      onClick={closeToast}
      aria-label={ariaLabel}
    >
      <i className="fa fa-times" aria-hidden="true"></i>
    </button>
  );
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
      <ToastContainer
        className="toasterContainer"
        toastClassName="innerToaster"
        progressClassName="tosterProgress"
        closeButton={<CloseButton />}
      />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
