import React from "react";
import { withStyles } from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import classnames from "classnames";

const THEME = createMuiTheme({
  palette: {
    typography: {
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(",")
    },
    primary: {
      main: "#535353"
    }
  }
});

const styles = theme => ({
  cardTitle: {
    fontSize: 26,
    fontWeight: 500,
    letterSpacing: 0,
    color: "#646464",
    fontFamily: "inherit",
    marginBottom: 16
  },
  cardDescription: {
    fontSize: 16,
    letterSpacing: 0.24,
    fontFamily: "inherit",
    color: "#9E9E9E"
    // marginBottom: 60
  },
  mB3: {
    marginBottom: 3
  }
});

function NodeHoldersTypography({ classes, title, cardDescription, showMb }) {
  return (
    <MuiThemeProvider theme={THEME}>
      <div
        className={classnames(classes.cardTitle, showMb ? classes.mB3 : "")}
        component="div"
      >
        {title}
      </div>
      <div className={classes.cardDescription} component="p">
        {cardDescription}
      </div>
    </MuiThemeProvider>
  );
}

export default withStyles(styles)(NodeHoldersTypography);
