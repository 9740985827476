import React from "react";
import { useSelector } from "react-redux";
import NodeHoldersContainer from "../website/components/NodeHoldersContainer";
import NodeHoldersTypography from "../website/components/NodeHoldersTypography";
import { withStyles, Grid, Typography } from "@material-ui/core";
import ButtonStyled from "../website/components/ButtonStyled";
import { ReactComponent as DocCopyIcon } from "../../assets/formation-entities/DocCopyIcon.svg";
import classnames from "classnames";

const styles = theme => ({
  borderB: {
    borderBottom: "1px solid #DADCE0",
    paddingBottom: 10
  },
  companyName: {
    letterSpacing: 0,
    color: "#A7A7A7",
    fontWeight: "500",
    fontSize: 16,
    textAlign: "left",
    fontFamily: "inherit",
    marginTop: 30,
    textTransform: "uppercase",
    marginBottom: 8
  },
  issuedCount: {
    fontSize: 14,
    fontWeight: "500",
    fontFamily: "inherit",
    letterSpacing: 0.21,
    color: "#777777"
  },
  textLeft: {
    textAlign: "left",
    fontFamily: "inherit"
  },
  summaryPadding: {
    padding: "0 24px 30px",
    borderBottom: "1px solid #DADCE0"
  },
  btnPadding: {
    padding: "9px 53px"
  },
  mT60: {
    marginTop: 60
  },
  textRight: {
    textAlign: "right"
  },
  mT16: {
    marginTop: 16
  }
});

// const summaryList = [
//     {
//         id: 1,
//         address: 'COMPANY NAME',
//         text: 'CompanyName'
//     },
//     {
//         id: 2,
//         address: 'ENTITY ID',
//         text: 'Cbkmczdltuofupta1gnl1uu626tqyg'
//     },
//     {
//         id: 3,
//         address: 'Entity Class',
//         text: 'Smart Legal Entity (SLE)'
//     },

// ];

function Summary({ classes, handleNext, entityName, entityType, classType }) {
  let entityTypeUpper = entityType
    .toLowerCase()
    .replace(/_/g, " ")
    .replace(/(?: |\b)(\w)/g, function(key, p1) {
      return key.toUpperCase();
    });

  let entityClassUpper = classType
    .toLowerCase()
    .replace(/_/g, " ")
    .replace(/(?: |\b)(\w)/g, function(key, p1) {
      return key.toUpperCase();
    });
  const entityFormData = useSelector(state => state.entityForm.data);
  return (
    <div>
      <NodeHoldersContainer showPadding0={true} showFooterText={false}>
        <Grid className={classes.borderB}>
          <NodeHoldersTypography title={"Summary"} />
        </Grid>

        <Grid className={classes.summaryPadding}>
          <Grid className={classes.companyName}>COMPANY NAME</Grid>
          <Grid className={classes.textLeft}>
            <div className={classes.issuedCount}>{entityName}</div>
          </Grid>
        </Grid>

        <Grid
          className={classes.summaryPadding}
          container
          direction="row"
          justify="flex-start"
        >
          <Grid className={classes.companyName}>ENTITY ID</Grid>
          <Grid className={classes.textLeft} item xs={11}>
            <div className={classes.issuedCount}>{entityFormData.EntityId}</div>
          </Grid>
          <Grid className={classes.textRight} item xs={1}>
            <DocCopyIcon />
          </Grid>
        </Grid>

        <Grid className={classes.summaryPadding}>
          <Grid className={classes.companyName}>Entity Class</Grid>
          <Grid className={classes.textLeft}>
            <div className={classes.issuedCount}>{entityClassUpper}</div>
          </Grid>

          <Grid className={classnames(classes.companyName, classes.mT16)}>
            Entity Type
          </Grid>
          <Grid className={classes.textLeft}>
            <div className={classes.issuedCount}>{entityTypeUpper}</div>
          </Grid>
        </Grid>

        <Grid className={classes.summaryPadding}>
          <Grid className={classes.companyName}>Associating Contract</Grid>
          <Grid className={classes.textLeft}>
            <div className={classes.issuedCount}>
              Contract of Incorporation (CoI)
            </div>
          </Grid>
        </Grid>

        <Grid className={classes.mT60}>
          <ButtonStyled
            btnPadding={classes}
            btnText={"Continue"}
            onClickHandler={handleNext}
          />
        </Grid>
      </NodeHoldersContainer>
    </div>
  );
}

export default withStyles(styles)(Summary);
