import React, { Component } from 'react';
import { Grid, CircularProgress, Button, IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';

// Import SVG Icons
import { ReactComponent as LeftArrow } from '../../assets/icons/navigation/chevron_left-48px.svg';
import { ReactComponent as RightArrow } from '../../assets/icons/navigation/chevron_right-48px.svg';

export default class ManageEntities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      itemPerPage: 3
    };
  }

  componentDidMount() {
    this.props.getUserEntities();
  }

  componentWillUnmount() {
    this.props.resetEntity();
  }

  entityTypes = {
    decentralized_company: 'DLC',
    decentralized_partnership: 'DLP',
    next_gen_project: 'NGP',
    next_gen_fund: 'NGF'
  };

  render() {
    return (
      <div className="panel-container">
        <div className="reserved-container">
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <div className="nh-icon"></div>
              <div className="header">Reserved Names</div>
            </Grid>
            <Grid item xs={12}>
              {this.props.entity.isLoading ? (
                <div>
                  <CircularProgress color="primary" size={24} />
                </div>
              ) : this.props.entity.isSuccess ? (
                this.props.entity.userEntities.length > 0 ? (
                  <>
                    <div className="entity-counter">
                      {`${this.props.entity.userEntities.length} names Reserved`}
                    </div>
                    {this.props.entity.userEntities
                      .slice(
                        this.state.page * this.state.itemPerPage,
                        this.state.page * this.state.itemPerPage +
                          this.state.itemPerPage
                      )
                      .map((row, i) => (
                        // <TableRow key={i + 1}>
                        //   <TableCell className="entity-name">
                        //     {row.EntityName}
                        //   </TableCell>
                        //   <TableCell className="entity-type">
                        //     {this.entityTypes[row.EntityType]}
                        //   </TableCell>
                        // </TableRow>
                        <Grid
                          container
                          justify="space-between"
                          className="entity-row"
                        >
                          <Grid item className="entity-item-container-name">
                            <div className="entityHeader">Entity Name</div>
                            <div className="entityContent">
                              {row.EntityName}
                            </div>
                          </Grid>
                          <Grid item className="entity-item-container">
                            <div className="entityHeader">Entity Type</div>
                            <div className="entityContent">
                              {this.entityTypes[row.EntityType]}
                            </div>
                          </Grid>
                        </Grid>
                      ))}
                  </>
                ) : (
                  <div>
                    <div style={{ fontSize: '1.5rem', marginTop: '60px' }}>
                      No Names Currently <br />
                      Reserved.
                    </div>
                    <Button
                      variant="contained"
                      style={{
                        marginTop: '60px',
                        padding: '8px 48px',
                        color: 'white',
                        backgroundColor: '#111',
                        textTransform: 'capitalize'
                      }}
                    >
                      <Link
                        to="/entity-formation"
                        style={{
                          textDecoration: 'none',
                          color: 'inherit'
                        }}
                      >
                        Reserve New Name
                      </Link>
                    </Button>
                    <div>
                      <Button
                        style={{
                          marginTop: '90px',
                          fontWeight: '500',
                          textTransform: ''
                        }}
                        onClick={() => {
                          this.props.history.push('/nodeholder-panel');
                        }}
                      >
                        Settings
                      </Button>
                    </div>
                  </div>
                )
              ) : null}
              {this.props.entity.userEntities.length > 0 ? (
                <div className="pageination-container">
                  <IconButton
                    aria-label="Previous"
                    disabled={this.state.page === 0}
                    onClick={e => {
                      this.setState(prevState => {
                        this.setState({ page: prevState.page - 1 });
                      });
                    }}
                  >
                    <LeftArrow style={{ height: '19px' }} />
                  </IconButton>
                  <span>
                    {this.state.page + 1} /{' '}
                    {Math.ceil(
                      this.props.entity.userEntities.length /
                        this.state.itemPerPage
                    )}
                  </span>
                  <IconButton
                    aria-label="Next"
                    disabled={
                      this.state.page + 1 ===
                      Math.ceil(
                        this.props.entity.userEntities.length /
                          this.state.itemPerPage
                      )
                    }
                    onClick={e => {
                      this.setState(prevState => {
                        this.setState({ page: prevState.page + 1 });
                      });
                    }}
                  >
                    <RightArrow style={{ height: '19px' }} />
                  </IconButton>
                </div>
              ) : null}
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
