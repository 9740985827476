import React, { Fragment } from "react";
import {
  withStyles,
  Container,
  Grid,
} from "@material-ui/core";
import classnames from "classnames";

const styles = theme => ({
  title: {
    color: "#555555",
    fontSize: 16,
    marginBottom: 60,
    textAlign: "center",
    width: "100%",
    padding: "0 15px"
  },
  subTitle: {
    color: "#535353",
    fontSize: 16,
    marginBottom: 24
  },
  textCenter: {
    textAlign: "center"
  },
  textRight: {
    textAlign: "right"
  }
});

function EntitiesContainer({ classes, children, title, subTitle }) {
  return (
    <Fragment>
      <Container style={{ padding: 0 }} maxWidth="xl">
        <Grid container spacing={0} justify="center" alignItems="center">
          {title && (
            <Grid item sm={12} className={classes.title}>
              {title}
            </Grid>
          )}
          {subTitle ? (
            <Grid
              item
              sm={12}
              className={classnames(classes.subTitle, classes.textCenter)}
            >
              {subTitle}
            </Grid>
          ) : (
            ""
          )}
          <Grid container spacing={0} justify="center" alignItems="center">
            {children}
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
}

export default withStyles(styles)(EntitiesContainer);
