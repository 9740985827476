import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Grid,
  SwipeableDrawer,
  Toolbar,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
  Collapse,
} from '@material-ui/core';
import map from 'lodash/map';
import { Link } from 'react-router-dom';
import { TopMenu } from '../menuObject';
import '../css/top-bar.css';
import classnames from 'classnames';

// Import SVG Icons
import { ReactComponent as CompanyIcon } from '../../../assets/icons/topbar-icon.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#333333',
  },
  headerBg: {
    backgroundColor: 'transparent',
    minHeight: 52,
    maxWidth: 1476,
    [theme.breakpoints.down('lg')]: {
      maxWidth: 1140,
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: 1000,
    },
    margin: '0 auto',
    width: '100%',
  },
  bgTransparent: {
    backgroundColor: 'transparent',
    boxShadow: '0px 2px 6px #0000001A',
  },
  menuButton: {
    marginRight: -12,
    color: 'inherit',
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    // paddingTop: 5,
    stroke: 'currentColor',
    fill: 'currentColor',
    display: 'flex',
    alignItems: 'center',
  },
  list: {
    width: 280,
  },
  headerMainBg: {
    backgroundColor: '#333333',
    boxShadow: '0px 2px 6px #0000001A',
  },
  fixedHeader: {
    position: 'fixed',
    width: '100%',
    zIndex: '99',
    [theme.breakpoints.down(1031)]: {
      display: 'none',
    },
  },
}));

function MenuBar({ isLoggedIn }) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    right: false,
  });

  const pagesWithBlackHeader = [
    '/smart-legal-entity',
    '/formation-of-entities',
    '/nodeholders',
  ];
  const appBarClass =
    pagesWithBlackHeader.indexOf(window.location.pathname) > -1
      ? classes.headerMainBg
      : classes.bgTransparent;
  const appBarRootClass =
    pagesWithBlackHeader.indexOf(window.location.pathname) > -1
      ? classes.root
      : window.location.pathname.includes('/decentralized-companies-act')
      ? classnames(classes.fixedHeader, classes.root)
      : null;
  const simpleTopBar = [
    '/login',
    '/password-recovery',
    '/id-recovery',
    '/register',
  ];
  const showSimpleTopBar =
    simpleTopBar.indexOf(window.location.pathname) > -1 ? true : false;

  const [dropDownstate, setDropDownState] = React.useState({});
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const mobileDevice =
    iOS ||
    (process.browser && /iPad|iPhone|iPod|Android/.test(navigator.userAgent));

  const toggleDrawer = (side, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    } else {
      setState({ ...state, [side]: open });
    }
  };

  const sideList = (side) => (
    <div
      className={classes.list}
      role='presentation'
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <List disableGutters>
        {map(TopMenu, (data, key) => {
          return (
            <Fragment key={key}>
              <ListItem
                disableGutters
                button
                onClick={
                  data.children.length
                    ? (e) => {
                        e.stopPropagation();
                        setDropDownState((prev) => {
                          return {
                            ...prev,
                            [data.val]: {
                              open:
                                prev[data.val] && prev[data.val].open
                                  ? false
                                  : true,
                            },
                          };
                        });
                      }
                    : null
                }
              >
                {data.children && data.children.length ? (
                  dropDownstate[data.val] && dropDownstate[data.val].open ? (
                    <>
                      {' '}
                      <ListItemText>
                        <span className='dropdown-header'>{data.name}</span>
                      </ListItemText>{' '}
                    </>
                  ) : (
                    <>
                      {' '}
                      <ListItemText>
                        <span className='dropdown-header'>{data.name}</span>
                      </ListItemText>{' '}
                    </>
                  )
                ) : (
                  <ListItemText primary={data.name} />
                )}
              </ListItem>
              {data.children.length ? (
                <Collapse
                  in={dropDownstate[data.val] && dropDownstate[data.val].open}
                  timeout='auto'
                  unmountOnExit
                >
                  {map(data.children, (dat, i) => {
                    return (
                      <List
                        key={i}
                        component='div'
                        style={{ paddingLeft: '8px' }}
                      >
                        <ListItem
                          disableGutters
                          button
                          component={Link}
                          to={dat.url}
                          onClick={
                            dat.children && dat.children.length
                              ? (e) => {
                                  e.stopPropagation();
                                  setDropDownState((prev) => {
                                    return {
                                      ...prev,
                                      [dat.val]: {
                                        open:
                                          prev[dat.val] && prev[dat.val].open
                                            ? false
                                            : true,
                                      },
                                    };
                                  });
                                }
                              : null
                          }
                        >
                          {dat.children && dat.children.length ? (
                            dropDownstate[dat.val] &&
                            dropDownstate[dat.val].open ? (
                              <>
                                {' '}
                                <ListItemText>
                                  <span className='dropdown-header'>
                                    {dat.name}
                                  </span>
                                </ListItemText>
                              </>
                            ) : (
                              <>
                                {' '}
                                <ListItemText>
                                  <span className='dropdown-header'>
                                    {dat.name}
                                  </span>
                                </ListItemText>
                              </>
                            )
                          ) : (
                            <ListItemText>
                              <span className='dropdown-menu-link'>
                                {dat.name}
                              </span>
                            </ListItemText>
                          )}
                        </ListItem>
                        {dat.children && dat.children.length ? (
                          <Collapse
                            in={
                              dropDownstate[dat.val] &&
                              dropDownstate[dat.val].open
                            }
                            timeout='auto'
                            unmountOnExit
                          >
                            {map(dat.children, (item, index) => {
                              return (
                                <List
                                  component='div'
                                  key={index}
                                  style={{ paddingLeft: '16px' }}
                                >
                                  <ListItem button disableGutters>
                                    <ListItemText>
                                      <span className='dropdown-menu-link'>
                                        {item.name}
                                      </span>
                                    </ListItemText>
                                  </ListItem>
                                </List>
                              );
                            })}
                          </Collapse>
                        ) : null}
                      </List>
                    );
                  })}
                </Collapse>
              ) : null}
            </Fragment>
          );
        })}
      </List>
    </div>
  );

  return (
    <div className={appBarRootClass}>
      <AppBar
        className={appBarClass}
        position='sticky'
        color='inherit'
        elevation={0}
        style={{ backgroundColor: '#FFFFFF' }}
      >
        <Toolbar className={classes.headerBg}>
          <Grid
            justify={showSimpleTopBar ? 'center' : 'space-between'}
            container
            alignItems='center'
          >
            <Grid className={classes.logo} item>
              <Link
                to={'/'}
                className={classes.logo}
                style={{
                  textDecoration: 'none',
                  color: 'inherit',
                }}
              >
                <CompanyIcon
                  height='25px'
                  width='25px'
                  strokeWidth='9px'
                  stroke='#333'
                  style={!showSimpleTopBar ? { marginRight: '12px' } : null}
                />
              </Link>
              {!showSimpleTopBar && (
                <Link
                  to='/'
                  style={{
                    textDecoration: 'none',
                    color: 'inherit',
                    fontWeight: '500',
                  }}
                >
                  
                </Link>
              )}
            </Grid>

            {!showSimpleTopBar && (
              <Grid item>
                <div>
                  <Link
                    to={isLoggedIn ? '/nodeholder-panel' : '/login'}
                    style={{
                      textDecoration: 'none',
                      color: 'inherit',
                    }}
                  >
                    <Button
                      color='inherit'
                      style={{ textTransform: 'capitalize' }}
                    >
                      {isLoggedIn ? 'Nodeholder Panel' : 'Nodeholder Access'}
                    </Button>
                  </Link>

                  {/* <IconButton
                  edge="start"
                  className={classes.menuButton}
                  aria-label="menu"
                  onClick={toggleDrawer("right", true)}
                >
                  <MenuIcon />
                </IconButton> */}
                </div>
              </Grid>
            )}
          </Grid>
        </Toolbar>
        <SwipeableDrawer
          open={state.right}
          anchor='right'
          onClose={toggleDrawer('right', false)}
          onOpen={toggleDrawer('right', true)}
          swipeAreaWidth={mobileDevice ? 20 : 0}
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
        >
          <div className='drawer-header'>
            <span className='drawer-logo'></span>
            <div className='header-text'>
              <Link
                to='/'
                style={{
                  textDecoration: 'none',
                  color: 'inherit',
                }}
              >
              </Link>
            </div>
            <Divider />
          </div>
          {sideList('right')}
        </SwipeableDrawer>
      </AppBar>
    </div>
  );
}

export default MenuBar;
