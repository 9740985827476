import React from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import "./styles.css";

export default function ComingSoon() {
  return (
    <div className="coming-soon-container">
      <h2>Coming Soon</h2>
      <p>Our product is under consFtruction. We will keep you posted!</p>
      <Link to="/">
        <Button
          style={{
            color: "#ffffff",
            borderRadius: 4,
            backgroundColor: "#535353",
            fontSize: "0.875rem",
            fontWeight: 400,
            fontFamily: "inherit",
            letterSpacing: "0.5px",
            boxShadow: "0px 2px 6px #00000029",
            textTransform: "capitalize",
            height: "43px",
            padding: "0 30px",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          Go Home
        </Button>
      </Link>
    </div>
  );
}
