import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "80%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: "5px",
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
    "&:focus": {
      outline: "none"
    }
  },
  modalTitle: {
    fontSize: "20px",
    color: "#535353",
    padding: "16px",
    fontWeight: '500',
    borderBottom: "1px solid #DADCE0"
  },
  modalDataContainer: {
    padding: "16px 21px"
  },
  modalBtn: {
    fontSize: "14px",
    color: "#555555",
    textAlign: "right",
    padding: "5px 25px 15px 5px"
  }
}));

export default function ConfirmationModal({
  open,
  handleClose,
  confirmationData
}) {
  const classes = useStyles();

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <div className={classes.paper}>
          {confirmationData && confirmationData.title && (
            <div className={classes.modalTitle}>{confirmationData.title}</div>
          )}
          {confirmationData && confirmationData.child && (
            <div className={classes.modalDataContainer}>
              {confirmationData.child}
            </div>
          )}
          {confirmationData && confirmationData.btnText && (
            <div onClick={handleClose} className={classes.modalBtn}>
              {confirmationData.btnText}
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}
