import React from 'react';
import { withStyles, div } from '@material-ui/core';
import CardContainer from '../../website/components/Entity-card';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

const THEME = createMuiTheme({
  palette: {
    div: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(',')
    },
    primary: {
      main: '#535353'
    }
  }
});

const styles = theme => ({
  entityMainContainer: {
    maxWidth: '500px',
    margin: '0 auto',
    padding: '60px 24px 70px 24px'
  },
  pagination: {
    fontSize: 18,
    letterSpacing: '0.25px',
    color: '#7A7A7A'
  },
  textBottomStyle: {
    fontSize: 14,
    letterSpacing: 0.42,
    color: '#9E9E9E'
  }
});

function NodeHoldersContainer({
  classes,
  children,
  currentStep,
  totalSteps,
  showFooterText,
  pageTitle,
  showPadding0 = false
}) {
  return (
    <div className={classes.entityMainContainer}>
      <MuiThemeProvider theme={THEME}>
        {pageTitle && <div className={classes.pagination}>{pageTitle}</div>}
        {!pageTitle && currentStep && totalSteps && (
          <div component="div" className={classes.pagination}>
            {currentStep}/{totalSteps}
          </div>
        )}

        <CardContainer showPadding0={showPadding0}>{children}</CardContainer>
        {showFooterText ? (
          <div className={classes.textBottomStyle}>
            This can be revised before completing the incorporation process.
          </div>
        ) : (
          ''
        )}
      </MuiThemeProvider>
    </div>
  );
}

export default withStyles(styles)(NodeHoldersContainer);
