import * as actions from '../actions';
import { call, put } from 'redux-saga/effects';
import { setLoggedUser } from '../../services/generic';
import fireAjax from '../../services/index';
import { toast } from 'react-toastify';

export function* loginRequest(action) {
  try {
    const response = yield call(
      fireAjax,
      'POST',
      'user/login',
      action.payload,
      {}
    );
    if (response.data.status === 1) {
      yield put(
        actions.loginSuccess({
          message: 'Logged In Successfully.',
          token: setLoggedUser(response.data.data),
        })
      );
      if (response.data.entity) {
        toast('Entity created Successfully.');
      } else {
        toast(
          `${
            response.data.temp
              ? 'Please change your password.'
              : 'Logged In successfully.'
          }`
        );
      }
    } else {
      yield put(
        actions.loginError({
          message: response.data.message || 'Error Occurs !!',
        })
      );
      toast(`${response.data.message || 'Error Occurs !!'}`);
    }
  } catch (e) {
    yield put(
      actions.loginError({
        message: e.response.data.message || 'Error Occurs !!',
      })
    );
    toast(`${e.response.data.message || 'Error Occurs !!'}`);
  }
}

export function* signupRequest(action) {
  try {
    const response = yield call(
      fireAjax,
      'POST',
      'user/addUser',
      { Email: action.payload.email, full_name: action.payload.full_name },
      {}
    );
    if (response.data.status === 1) {
      yield put(
        actions.signupSuccess({
          message: response.data.message,
        })
      );
      toast(response.data.message);
    } else {
      yield put(
        actions.signupError({
          message: response.data.message || 'Error Occurs !!',
        })
      );
      toast(`${response.data.message || 'Error Occurs !!'}`);
    }
  } catch (e) {
    yield put(
      actions.signupError({
        message: e.response.data.message,
      })
    );
    toast(`${e.response.data.message || 'Error Occurs !!'}`);
  }
}

export function* passwordRequest(action) {
  try {
    const response = yield call(
      fireAjax,
      'PUT',
      'user/forgetPassword',
      { Email: action.payload.email },
      {}
    );
    if (response.data.status === 1) {
      yield put(
        actions.passwordSuccess({
          message: response.data.message,
        })
      );
      toast(response.data.message);
    } else {
      yield put(
        actions.passwordError({
          message: response.data.message || 'Error Occurs !!',
        })
      );
      toast(`${response.data.message || 'Error Occurs !!'}`);
    }
  } catch (e) {
    yield put(
      actions.passwordError({
        message: e.response.data.message || 'Error Occurs !!',
      })
    );
    toast(`${e.response.data.message || 'Error Occurs !!'}`);
  }
}

export function* idRequest(action) {
  try {
    const response = yield call(
      fireAjax,
      'PUT',
      'user/forgetNodeHolderId',
      { Email: action.payload.email },
      {}
    );
    if (response.data.status === 1) {
      yield put(
        actions.idSuccess({
          message: response.data.message,
        })
      );
      toast(response.data.message);
    } else {
      yield put(
        actions.idError({
          message: response.data.message || 'Error Occurs !!',
        })
      );
      toast('Error Occurs !!');
    }
  } catch (e) {
    yield put(
      actions.idError({
        message: e.response.data.message || 'Error Occurs !!',
      })
    );
    toast(`${e.response.data.message || 'Error Occurs !!'}`);
  }
}
