import React, { Fragment } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import classnames from 'classnames';

function AppointRoleContainer({children, classes, titleDescription}) {
    return (
        <Fragment>
            <Grid container spacing={0} className={classes.executiveMainCont}>
                <Box style={{ width: '100%' }} className={classes.executiveTitleCont}>
                    <Grid item xs={12}>
                        <Typography className={classes.cardTitleText}>Appoint 'Role' Node</Typography>
                        {titleDescription && <Typography className={classnames(classes.cardDescriptionText, classes.RoleListDescriptionText)}>
                            {titleDescription}
                        </Typography>}
                    </Grid>
                    <Grid item xs={12}>
                        {children}
                    </Grid>
                </Box>
            </Grid>
        </Fragment>
    );
}

export default AppointRoleContainer;