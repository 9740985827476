import React, { Component } from "react";
import {
  Grid,
  Button,
  TextField,
  InputLabel,
  FormControl,
  OutlinedInput,
  CircularProgress
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as action from "../../../redux/actions";
import PrimaryButton from "../components/PrimaryButton";
import "../styles.css";

class PassRecovery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      errorText: ""
    };
  }

  componentDidMount() {
    document.body.style.backgroundColor = "#fcfcfc";
  }

  componentWillUnmount() {
    document.body.style.backgroundColor = "unset";
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.auth.isSuccess != this.props.auth.isSuccess &&
      this.props.auth.isSuccess
    ) {
      this.setState({ email: "", errorText: "" });
      this.props.history.push("/login");
    }
  }

  onSubmit = () => {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email)) {
      this.setState({ errorText: "Invalid email address" });
    } else {
      this.props.recoverPassword({ email: this.state.email });
    }
  };

  render() {
    return (
      <Grid container justify="center" alignItems="center" spacing={0}>
        <Grid item xs={12}>
          <form className="recover-form-container">
            <div className="form-header">Recover Password</div>
            <div className="form-subheader">
              We will send you instructions on how to reset your password by
              email.
            </div>
            <div className="input-box">
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="email">
                  Enter your email address
                </InputLabel>
                <OutlinedInput
                  id="email"
                  name="email"
                  type="text"
                  autoComplete="email"
                  error={this.state.errorText ? true : false}
                  value={this.state.email}
                  onChange={e => {
                    this.setState({ email: e.target.value, errorText: "" });
                  }}
                  labelWidth={168}
                />
                <small style={{ color: "red", textAlign: "left" }}>
                  {this.state.errorText}
                </small>
              </FormControl>
            </div>
            <Button
              label="Submit"
              variant="outlined"
              type="submit"
              style={{
                marginTop: "40px",
                color: "#fff",
                backgroundColor: "#333333",
                "&:hover": {
                  backgroundColor: "#333333"
                },
                width: "100%",
                padding: "14px 0px 15px 0px",
                fontSize: "0.875rem",
                letterSpacing: "0.5px"
              }}
              disabled={this.props.auth.isLoading}
              onClick={this.onSubmit}
              type="submit"
            >
              {this.props.auth.isLoading ? (
                <CircularProgress color="primary" size={24} />
              ) : (
                "Submit"
              )}
            </Button>
          </form>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};
const mapDispatchToProps = dispatch => {
  return { recoverPassword: data => dispatch(action.passwordRequest(data)) };
};

export default connect(mapStateToProps, mapDispatchToProps)(PassRecovery);
