import React, { useState, useEffect } from 'react';
import NodeHoldersContainer from '../../website/components/NodeHoldersContainer';
import { Typography, withStyles, Grid, Button } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import DialogContainer from '../../website/components/DialogContainer';
import DialogContentText from '@material-ui/core/DialogContentText';
import NodeHoldersTypography from '../../website/components/NodeHoldersTypography';
import IconTextField from '../../website/components/IconTextField';

const styles = theme => ({
  cardSubDescription: {
    fontSize: 16,
    fontWeight: 500,
    fontFamily: 'inherit',
    letterSpacing: 0.24,
    color: '#A7A7A7',
    textAlign: 'left',
    marginTop: 60
  },
  NominateDirector: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.21,
    color: '#777777',
    marginBottom: '1rem'
  },
  textLeft: {
    textAlign: 'left'
  },
  textRight: {
    textAlign: 'right'
  },

  marginInput: {
    marginBottom: 8
  },
  btnStyle: {
    backgroundColor: '#535353',
    color: '#fff',
    fontWeight: 400,
    fontFamily: 'inherit',
    letterSpacing: '0.5px',
    textTransform: 'capitalize',
    padding: '9px 26px',
    marginTop: '60px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#535353',
      color: '#fff',
      opacity: 0.8
    }
  },
  doneTxt: {
    color: '#535353',
    cursor: 'pointer !important',
    letterSpacing: 0.42,
    fontWeight: 500
  },
  modelDesc: {
    fontSize: 16,
    letterSpacing: 0.24,
    padding: 16,
    color: '#9E9E9E',
    marginBottom: 40
  },
  mt40: {
    marginTop: 40
  }
});

function AppointDirectorNodeholders({
  classes,
  appointedNodes,
  handleNext,
  onInvite,
  emailError,
  values,
  handleChange,
  showInviteSuccessDialog,
  inviteDialogCloseHandler
}) {
  const DialogActions = ({ handleClose }) => (
    <Button
      className={classes.doneTxt}
      disableRipple={true}
      onClick={handleClose}
      color="primary"
    >
      Done
    </Button>
  );
  const [errorEmail, setErrorEmail] = useState({ value: false, message: '' });
  const [errorName, setErrorName] = useState({ value: false, message: '' });

  const [nominneFullName, setnominneFullName] = useState('');
  const [nominneEmailAddress, setNominneEmailAddress] = useState('');

  const changeNomineeName = event => {
    setnominneFullName(event.target.value);
    const name = event.target.value;
    if (name) {
      setErrorName({
        value: !/^[a-zA-Z ]+$/i.test(name),
        message: 'Name only contains alphabets'
      });
    } else {
      setErrorName({ value: false, message: '' });
    }
  };
  const changeNomineeEmailAddress = event => {
    const email = event.target.value;
    setNominneEmailAddress(event.target.value);
    if (email) {
      setErrorEmail({
        value: !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email),
        message: 'Invalid email address'
      });
    } else {
      setErrorEmail({ value: false, message: '' });
    }
  };

  const inviteNodeholder = () => {
    if (appointedNodes.count === 2) {
      handleNext();
      return;
    }
    setErrorEmail({ value: false, message: '' });
    onInvite({ name: nominneFullName, email: nominneEmailAddress });
  };

  const cardDescription =
    'Appoint principle Nodeholders in your Decentralized Company.';

  useEffect(() => {
    const appointedNode =
      appointedNodes.list.length &&
      appointedNodes.list[appointedNodes.list.length - 1];
    setnominneFullName(appointedNode.name ? appointedNode.name : '');
    setNominneEmailAddress(appointedNode.email ? appointedNode.email : '');
  }, []);

  useEffect(() => {
    setErrorEmail(emailError);
    if (!emailError.value && appointedNodes.count === 1) {
      setNominneEmailAddress('');
      setnominneFullName('');
    }
  }, [emailError]);
  return (
    <div>
      <NodeHoldersContainer
        showFooterText={false}
        currentStep={2}
        totalSteps={3}
      >
        <NodeHoldersTypography
          title={'Appoint Nodeholders'}
          cardDescription={cardDescription}
        />
        <Typography className={classes.cardSubDescription} component="p">
          KEY NOMINATORS
        </Typography>
        <Grid
          className={classes.NominateDirector}
          container
          direction="row"
          justify="flex-start"
        >
          <Grid className={classes.textLeft} item xs={10}>
            Nominate Director Nodes.
          </Grid>
          <Grid className={classes.textRight} item xs={2}>
            ({appointedNodes.count}/2)
          </Grid>
        </Grid>

        <IconTextField
          InputIcon={PersonIcon}
          placeholder={'Full name'}
          onChangeHandler={changeNomineeName}
          value={nominneFullName}
          hasError={errorName}
        />

        <IconTextField
          InputIcon={MailOutlineIcon}
          placeholder={'Email Address'}
          onChangeHandler={changeNomineeEmailAddress}
          value={nominneEmailAddress}
          hasError={errorEmail}
          type={'email'}
        />
        <Button
          className={classes.btnStyle}
          disabled={
            !nominneFullName ||
            errorEmail.value ||
            !nominneEmailAddress ||
            errorName.value
          }
          variant="contained"
          onClick={inviteNodeholder}
        >
          {appointedNodes.count < 2 ? 'Invite' : 'Continue'}
        </Button>
      </NodeHoldersContainer>
      <DialogContainer
        open={showInviteSuccessDialog}
        title="Nodeholder Appointed"
        actions={
          <DialogActions handleClose={() => inviteDialogCloseHandler(false)} />
        }
      >
        <DialogContentText className={classes.modelDesc}>
          A nomination email has been sent to {nominneEmailAddress}.
        </DialogContentText>
      </DialogContainer>
    </div>
  );
}

export default withStyles(styles)(AppointDirectorNodeholders);
