import { createAction } from 'redux-actions';
import constants from './constants';

export const loginRequest = createAction(constants.LOGIN_REQUEST);
export const loginSuccess = createAction(constants.LOGIN_REQUEST_SUCCESS);
export const loginError = createAction(constants.LOGIN_REQUEST_ERROR);

export const signupRequest = createAction(constants.SIGN_UP_REQUEST);
export const signupSuccess = createAction(constants.SIGN_UP_REQUEST_SUCCESS);
export const signupError = createAction(constants.SIGN_UP_REQUEST_ERROR);

export const passwordRequest = createAction(constants.REQUEST_PASSWORD);
export const passwordSuccess = createAction(constants.REQUEST_PASSWORD_SUCCESS);
export const passwordError = createAction(constants.REQUEST_PASSWORD_ERROR);

export const idRequest = createAction(constants.REQUEST_ID);
export const idSuccess = createAction(constants.REQUEST_ID_SUCCESS);
export const idError = createAction(constants.REQUEST_ID_ERROR);

export const profileRequest = createAction(constants.PROFILE_REQUEST);
export const profileRequestSuccess = createAction(constants.PROFILE_REQUEST_SUCCESS);
export const profileRequestError = createAction(constants.PROFILE_REQUEST_ERROR);

export const updateProfileRequest = createAction(constants.UPDATE_PROFILE);
export const updateProfileRequestSuccess = createAction(constants.UPDATE_PROFILE_SUCCESS);
export const updateProfileRequestError = createAction(constants.UPDATE_PROFILE_ERROR);

export const entityFormRequest = createAction(constants.SAVE_ENTITY_FORM);
export const entityFormRequestSuccess = createAction(constants.SAVE_ENTITY_FORM_SUCCESS);
export const entityFormRequestError = createAction(constants.SAVE_ENTITY_FORM_ERROR);

export const submitEntityFormRequest = createAction(constants.CREATE_ENTITY_FORM);
export const submitEntityFormRequestError = createAction(constants.CREATE_ENTITY_FORM_ERROR);
export const submitEntityFormRequestSuccess = createAction(constants.CREATE_ENTITY_FORM_SUCCESS);


export const verifyEmailRequest = createAction(constants.VERIFY_EMAIL);
export const verifyEmailRequestSuccess = createAction(constants.VERIFY_EMAIL_SUCCESS);
export const verifyEmailRequestError = createAction(constants.VERIFY_EMAIL_ERROR);

export const getEntityTypeAvailability = createAction(constants.GET_AVAILABLE_ENTITY_TYPE);
export const getEntityTypeAvailabilitySuccess = createAction(constants.GET_AVAILABLE_ENTITY_TYPE_SUCCESS);
export const getEntityTypeAvailabilityError = createAction(constants.GET_AVAILABLE_ENTITY_TYPE_ERROR);
export const resetEntityState = createAction(constants.RESET_ENTITY);

export const getNameAvailability = createAction(constants.GET_NAME_AVAILABLITY);
export const getNameAvailabilitySuccess = createAction(constants.GET_NAME_AVAILABLITY_SUCCESS);
export const getNameAvailabilityError = createAction(constants.GET_NAME_AVAILABLITY_ERROR);
export const resetNameAvailabilityState = createAction(constants.RESET_NAME_AVAILABLITY);

export const getUserEntities = createAction(constants.GET_USER_ENTITIES);
export const getUserEntitiesSuccess = createAction(constants.GET_USER_ENTITIES_SUCCESS);
export const getUserEntitiesError = createAction(constants.GET_USER_ENTITIES_ERROR);

export const getUserExist = createAction(constants.GET_USER_EXIST)
export const getUserExistSuccess = createAction(constants.GET_USER_EXIST_SUCCESS)
export const getUserExistError = createAction(constants.GET_USER_EXIST_ERROR)
export const resetEmailVerificationState = createAction(constants.RESET_EMAIL_VERIFICATION)

export const isAssociateEmailVarified = createAction(constants.IS_ASSOCAITE_EMAIL_VERIFIED)
export const isEmailVarifiedSuccess = createAction(constants.IS_ASSOCIATE_EMAIL_VERIFIED_SUCCESS)
export const isEmailVarifiedError = createAction(constants.IS_ASSOCIATE_EMAIL_VERIFIED_ERROR)

export const inviteAssociatingNode = createAction(constants.INVITE_ASSOCAITING_NODE)
export const inviteAssociatingNodeSuccess = createAction(constants.INVITE_ASSOCAITING_NODE_SUCCESS)
export const inviteAssociatingNodeError = createAction(constants.INVITE_ASSOCAITING_NODE_ERROR)

export const logout = createAction(constants.LOG_OUT);

export const getAssociatedEntities = createAction(constants.GET_ASSOCIATED_ENTITIES)
export const getAssociatedEntitiesSuccess = createAction(constants.GET_ASSOCIATED_ENTITIES_SUCCESS)
export const getAssociatedEntitiesError = createAction(constants.GET_ASSOCIATED_ENTITIES_ERROR)

export const getAllNominations = createAction(constants.GET_ALL_NOMINATIONS)
export const getAllNominationsSuccess = createAction(constants.GET_ALL_NOMINATIONS_SUCCESS)
export const getAllNominationsError = createAction(constants.GET_ALL_NOMINATIONS_ERROR)

export const getNominationHistory = createAction(constants.GET_NOMINATION_HISTORY)
export const getNominationHistorySuccess = createAction(constants.GET_NOMINATION_HISTORY_SUCCESS)
export const getNominationHistoryError = createAction(constants.GET_NOMINATION_HISTORY_ERROR)

export const setSuspendedEntity = createAction(constants.SET_SUSPENDED_ENTITY)
export const setSuspendedEntitySuccess = createAction(constants.SET_SUSPENDED_ENTITY_SUCCESS)
export const setSuspendedEntityError = createAction(constants.SET_SUSPENDED_ENTITY_ERROR)


export const changeEntityName = createAction(constants.CHANGE_ENTITY_NAME)
export const changeEntityNameSuccess = createAction(constants.CHANGE_ENTITY_NAME_SUCCESS)
export const changeEntityNameError = createAction(constants.CHANGE_ENTITY_NAME_ERROR)


export const getEquityNodeHolderHistories = createAction(constants.GET_EQUITY_NODE_HOLDERS_HISTORIES)
export const getEquityNodeHolderHistoriesSuccess = createAction(constants.GET_EQUITY_NODE_HOLDERS_HISTORIES_SUCCESS)
export const getEquityNodeHolderHistoriesError = createAction(constants.GET_EQUITY_NODE_HOLDERS_HISTORIES_ERROR)

export const getNodeHolderStatusRoles = createAction(constants.GET_NODE_HOLDER_STATUS_ROLES)
export const getNodeHolderStatusRolesSuccess = createAction(constants.GET_NODE_HOLDER_STATUS_ROLES_SUCCESS)
export const getNodeHolderStatusRolesError = createAction(constants.GET_NODE_HOLDER_STATUS_ROLES_ERROR)

export const setAppointNodeHolder = createAction(constants.SET_APPOINT_NODE_HOLDER)
export const setAppointNodeHolderSuccess = createAction(constants.SET_APPOINT_NODE_HOLDER_SUCCESS)
export const setAppointNodeHolderError = createAction(constants.SET_APPOINT_NODE_HOLDER_ERROR)

export const resignRole = createAction(constants.RESIGN_ROLE)
export const resignRoleSuccess = createAction(constants.RESIGN_ROLE_SUCCESS)
export const resignRoleError = createAction(constants.RESIGN_ROLE_ERROR)

export const getNodeHolderStatusHistory = createAction(constants.GET_NODE_HOLDER_STATUS_HISTORY);
export const getNodeHolderStatusHistorySuccess = createAction(constants.GET_NODE_HOLDER_STATUS_HISTORY_SUCCESS);
export const getNodeHolderStatusHistoryError = createAction(constants.GET_NODE_HOLDER_STATUS_HISTORY_ERROR);

export const allocateEqt = createAction(constants.ALLOCATE_EQT);
export const allocateEqtSuccess = createAction(constants.ALLOCATE_EQT_SUCCESS);
export const allocateEqtError = createAction(constants.ALLOCATE_EQT_ERROR);

export const currentNominationCardEntityId = createAction(constants.CURRENT_NOMINATION_CARD_ENTITY_ID);
export const currentNominationCardEntityIdSuccess = createAction(constants.CURRENT_NOMINATION_CARD_ENTITY_ID_SUCCESS);
export const currentNominationCardEntityIdError = createAction(constants.CURRENT_NOMINATION_CARD_ENTITY_ID_ERROR);

export const nominationAction = createAction(constants.NOMINATION_ACTION);
export const nominationActionSuccess = createAction(constants.NOMINATION_ACTION_SUCCESS);
export const nominationActionError = createAction(constants.NOMINATION_ACTION_ERROR);

export const verifyPassword = createAction(constants.VERIFY_PASSWORD);
export const verifyPasswordSuccess = createAction(constants.VERIFY_PASSWORD_SUCCESS);
export const verifyPasswordError = createAction(constants.VERIFY_PASSWORD_ERROR);

export const setVisibilityStatus = createAction(constants.SET_VISIBILITY_STATUS);
export const setVisibilityStatusSuccess = createAction(constants.SET_VISIBILITY_STATUS_SUCCESS);
export const setVisibilityStatusError = createAction(constants.SET_VISIBILITY_STATUS_ERROR);

export const updatePasswordVerifiedFlag = createAction(constants.UPDATE_PASSWORD_VERIFIED_FLAG);
export const updatePasswordVerifiedFlagSuccess = createAction(constants.UPDATE_PASSWORD_VERIFIED_FLAG_SUCCESS);

export const updateIsAppointNodeholderFlag = createAction(constants.UPDATE_IS_APPOINT_NODEHOLDER_FLAG);
export const updateIsAppointNodeholderFlagSuccess = createAction(constants.UPDATE_IS_APPOINT_NODEHOLDER_FLAG_SUCCESS);


export const updateRoleLimits = createAction(constants.UPDATE_ROLE_LIMITS);
export const updateRoleLimitsSuccess = createAction(constants.UPDATE_ROLE_LIMITS_SUCCESS);

export const setSelectedEntity = createAction(constants.SET_SELECTED_ENTITY);
export const setSelectedEntitySuccess = createAction(constants.SET_SELECTED_ENTITY_SUCCESS);

export const updateNodeRolesAsInitial = createAction(constants.UPDATE_NODE_ROLES_AS_INITIAL);
export const updateNodeRolesAsInitialSuccess = createAction(constants.UPDATE_NODE_ROLES_AS_INITIAL_SUCCESS);

export const updateOldEntityName = createAction(constants.UPDATE_OLD_ENTITY_NAME);
export const updateOldEntityNameSuccess = createAction(constants.UPDATE_OLD_ENTITY_NAME_SUCCESS);