import React, { Fragment } from 'react';
import EntitiesContainer from '../EntitiesContainer';
import AssociatedEntityCard from './AssociatedEntityCard';
import { withStyles, Link } from '@material-ui/core';
import NoEntities from './NoEntities';
import { useSelector } from 'react-redux';

const styles = theme => ({
  linkTxt: {
    color: '#1A73E8',
    cursor: 'pointer'
  }
});

function AssociatedEntities({ classes, setActivePage }) {
  const entities = useSelector(state =>
    state.entityPlatform.associated_entities !== null
      ? state.entityPlatform.associated_entities
      : []
  );
  const footerText = (
    <Fragment>
      Proceed to the Entities Registrar to{' '}
      <Link className={classes.linkTxt} href="/entity-formation">
        form a new entity.
      </Link>
    </Fragment>
  );
  const headerText = (
    <Fragment>You currently are not associated with any entities.</Fragment>
  );

  return (
    <EntitiesContainer
      subTitle={`Associated Entities (${entities.length})`}
      title={'Manage your decentralized entities.'}
      maxWidth="xl"
    >
      {!entities.length ? (
        <NoEntities footerText={footerText} headerText={headerText} />
      ) : (
        entities.map(entity => (
          <AssociatedEntityCard
            key={entity._id}
            entity={entity}
            setActivePage={setActivePage}
          />
        ))
      )}
    </EntitiesContainer>
  );
}

export default withStyles(styles)(AssociatedEntities);
