import React, { useState, useEffect } from "react";
import { withStyles, Grid } from "@material-ui/core";
import EntitiesContainer from "../../Entities/EntitiesContainer";
import NodeholderRoles from "./NodeholderRoles";
import History from "./NodeholderHistory";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../../../redux/actions";



const pages = {
  nodeholderRoles: 1,
  history: 2
};

const styles = {
  subTitlePadding: {
    padding: "0px 24px"
  },
  historyText: {
    color: "#9E9E9E",
    fontSize: 16,
    textAlign: "right"
  },
  historyActiveText: {
    color: "#535353",
    fontSize: 16,
    textAlign: "right"
  },
  subTitle: {
    color: "#9E9E9E",
    fontSize: 16,
    marginBottom: 24
  },
  subTitleActive: {
    color: "#535353",
    fontSize: 16,
    marginBottom: 24
  }
};

function NodeholderStatus({ classes }) {
  const dispatch = useDispatch();
  const nodeholderRolesList = useSelector(state => state.entityPlatform.nodeholding_status_roles ? state.entityPlatform.nodeholding_status_roles : []);
  const currentUserEmail = useSelector(state => state.userDetails.data && state.userDetails.data.Email);

  const [activePage, setActivePage] = useState(1);

  useEffect(() => {
    dispatch(actions.getNodeHolderStatusRoles())
  }, [])

  const getActivePage = () => {
    switch (activePage) {
      case pages.nodeholderRoles:
        return <NodeholderRoles userEmail={currentUserEmail}/>;

      case pages.history:
        return <History />;

      default:
        return <NodeholderRoles />;
    }
  };

  return (
    <EntitiesContainer
      title={"Manage your nodeholder status in different entities."}
      maxWidth="xl"
    >
      <Grid container className={classes.subTitlePadding} direction="row">
        <Grid
          item
          xs={10}
          className={
            activePage === pages.nodeholderRoles
              ? classes.subTitleActive
              : classes.subTitle
          }
          onClick={() => setActivePage(pages.nodeholderRoles)}
        >
          Nodeholder Roles ({(nodeholderRolesList.length) ? nodeholderRolesList.length: 0})
        </Grid>

        <Grid
          item
          xs={2}
          className={
            activePage === pages.history
              ? classes.historyActiveText
              : classes.historyText
          }
          onClick={() => setActivePage(pages.history)}
        >
          History
        </Grid>
      </Grid>
      {getActivePage()}
    </EntitiesContainer>
  );
}

export default withStyles(styles)(NodeholderStatus);
