import React from "react";
import classnames from "classnames";
import { withStyles, Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { pages } from "./AssociatedEntitiesContainer";
import { manageEntityPages } from "../../ManageEntity/ManageEntitySteps";
import * as actions from "../../../../../redux/actions";


const styles = theme => ({
  cardContainer: {
    border: "1px solid #DADCE0",
    borderRadius: 4,
    margin: "0 8px 12px"
  },
  ManageEntity: {
    padding: "16px 10px",
    borderTop: "1px solid #DADCE0"
  },
  ManageEntityBtnTxt: {
    color: "#555555",
    fontSize: "14px",
    textTransform: "capitalize",
    lineHeight: "1"
  },
  disabledClass: {
    backgroundColor: "#e0e0e0"
  }
});

function EntitiesCardContainer({
  classes,
  children,
  btnActions,
  styleClasses,
  setActivePage,
  entity,
  setManageEntityStep,
  nodeHolders,
  entityActiveStatus = true,
  setShowDisActiveEntityDialog
}) {
  
  const dispatch = useDispatch();

  const handleCardActions = () => {
    if (entityActiveStatus) {
      switch (btnActions) {
        
        case "Manage entity":
          setActivePage(pages.manageEntitiesPage);
          dispatch(actions.setSelectedEntity(entity));
          break;
        case "Manage Name":
          setManageEntityStep(manageEntityPages.manageEntitiesNamePage);
          break;
        case "Manage Equity":
          setManageEntityStep(manageEntityPages.manageEquityPage);
          break;
        case "Manage Nodeholder":
          setManageEntityStep(manageEntityPages.manageNodeholdersPage);
          break;
        default:
          break;
      }
    }
  };
  const handleDisActivate = () => {
    setShowDisActiveEntityDialog(true)
  }

  return (
    <div>
      <Grid className={classes.cardContainer}>
        {children}
        {btnActions && (
          <Grid
            container
            spacing={0}
            className={classnames(
              styleClasses.manageEntityPAdding,
              !entityActiveStatus ? classes.disabledClass : ""
            )}
            direction="row"
            alignItems="center"
          >
            <Grid
              className={classes.ManageEntityBtnTxt}
              item
              xs={12}
              onClick={btnActions === 'Disactivate Entity' ? handleDisActivate : handleCardActions }
            >
              {btnActions}
            </Grid>
          </Grid>
        )}
        {nodeHolders && (
          <Grid
            container
            spacing={0}
            className={styleClasses.manageEntityPAdding}
            direction="row"
            alignItems="center"
          >
            <Grid
              className={classes.ManageEntityBtnTxt}
              item
              xs={12}
              onClick={handleCardActions}
            >
              {nodeHolders}
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default withStyles(styles)(EntitiesCardContainer);
