import React, { useState } from "react";
import NodeHoldersContainer from "../../website/components/NodeHoldersContainer";
import NodeHoldersTypography from "../../website/components/NodeHoldersTypography";
import { Button, withStyles, Grid } from "@material-ui/core";
import classnames from "classnames";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import IconTextField from "../../website/components/IconTextField";
import ButtonStyled from "../../website/components/ButtonStyled";
import CloseIcon from "@material-ui/icons/Close";
import BorderButton from "../../website/components/BorderButton";

const styles = theme => ({
  NominateDirector: {
    fontSize: 14,
    letterSpacing: 0.21,
    color: "#777777",
    marginBottom: 6
  },
  mb16: {
    marginBottom: "1rem"
  },
  textLeft: {
    textAlign: "left"
  },
  textRight: {
    textAlign: "right"
  },
  mT60: {
    marginTop: 60
  },
  mT52: {
    marginTop: 52
  },
  btnStyle: {
    backgroundColor: "#DDDDDD",
    color: "#A4A4A4",
    fontWeight: 400,
    fontFamily: "inherit",
    letterSpacing: "0.5px",
    textTransform: "capitalize",
    padding: "9px 26px",
    marginTop: "60px",
    boxShadow: "0px 3px 6px #00000029",
    "&:hover": {
      backgroundColor: "#535353",
      color: "#fff",
      opacity: 0.8
    }
  },
  btnBorder: {
    backgroundColor: "#ffffff",
    fontSize: 14,
    color: "#535353",
    textTransform: "capitalize",
    padding: "7px 24px",
    boxShadow: "none",
    marginRight: 30,
    border: "2px solid #535353",
    "&:hover": {
      backgroundColor: "#535353",
      color: "#fff",
      opacity: 0.8
    }
  },
  btnBorderDisable: {
    border: "2px solid #DDDDDD",
    borderRadius: 4,
    letterSpacing: 0.42,
    color: "#DDDDDD",
    marginRight: 30,
    fontSize: 14,
    textTransform: "capitalize",
    padding: "7px 24px"
  },
  mt22: {
    marginTop: 22
  },
  close: {
    fontSize: 23,
    color: "#9E9E9E"
  },
  btnFloat:{
    float: 'right'
  }

});

function InviteEquityNodeholders({
  classes,
  emails,
  onInviteSuccess,
  onAssignSuccess,
  removeEmailFromList
}) {
  const cardDescription =
    "Choose the nodeholders you wish to assign ownership rights in the company to.";
  const [inviteNodeHolderEmail, setInviteNodeHolderEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState({ value: false, message: "" });
  const changeInviteEmail = event => {
    const email = event.target.value;
    setInviteNodeHolderEmail(email);
    if (email) {
      setErrorEmail({
        value: !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email),
        message: "Invalid email address."
      });
    } else {
      setErrorEmail({ ...errorEmail, value: false });
    }
  };

  const assignEmailCheck = () => {
    const existsEmails = emails && emails.list;
    if (existsEmails.length) {
      const result = existsEmails.find(
        email => email.inviteNodeHolderEmail === inviteNodeHolderEmail
      );
      if (result) {
        setErrorEmail({
          value: true,
          message: "Email already exists.Please enter different email."
        });
      } else if (!result && !errorEmail.value && inviteNodeHolderEmail) {
        onAssignSuccess({ inviteNodeHolderEmail });
        setInviteNodeHolderEmail("");
      }
    } else if (
      !existsEmails.length &&
      !errorEmail.value &&
      inviteNodeHolderEmail
    ) {
      onAssignSuccess({ inviteNodeHolderEmail });
      setInviteNodeHolderEmail("");
    }
  };

  return (
    <div>
      <NodeHoldersContainer
        showFooterText={true}
        pageTitle={"Equity Nodeholders"}
      >
        <NodeHoldersTypography
          title={"EQT Nodeholders"}
          cardDescription={cardDescription}
        />

        <Grid
          className={classnames(
            classes.NominateDirector,
            classes.mT60,
            classes.mb16
          )}
          container
          direction="row"
          justify="flex-start"
        >
          <Grid
            className={classes.textLeft}
            item
            xs={10}
            style={{ fontWeight: "500" }}
          >
            Equity Nodeholders
          </Grid>
          <Grid
            className={classes.textRight}
            item
            xs={2}
            style={{ fontWeight: "500" }}
          >
            {emails.count}/5
          </Grid>
        </Grid>

        <IconTextField
          InputIcon={MailOutlineIcon}
          placeholder={"Email Address"}
          onChangeHandler={changeInviteEmail}
          value={inviteNodeHolderEmail}
          hasError={errorEmail}
          type={"email"}
        />

        <div className={classes.mt22}>
          {emails.list.map(({ inviteNodeHolderEmail }) => {
            return (
              <Grid
                key={inviteNodeHolderEmail}
                className={classnames(classes.NominateDirector)}
                container
                direction="row"
                justify="flex-start"
              >
                <Grid className={classes.textLeft} item xs={10}>
                  {inviteNodeHolderEmail}
                </Grid>
                <Grid className={classes.textRight} item xs={2}>
                  <CloseIcon
                    className={classes.close}
                    onClick={() =>
                      removeEmailFromList({ inviteNodeHolderEmail })
                    }
                  />
                </Grid>
              </Grid>
            );
          })}
        </div>

        <Grid className={classes.mT52}>
          {/* <Button className={classes.btnBorder}>
                        Assign
                    </Button> */}
          <BorderButton
            btnText={"Assign"}
            disabled={
              emails.count === 5 || errorEmail.value || !inviteNodeHolderEmail
            }
            onClickHandler={assignEmailCheck}
          />
          <ButtonStyled
            btnFloatClass={classes}
            btnText={"Invite"}
            disabled={emails.count === 0 || emails.count > 5}
            onClickHandler={() => onInviteSuccess("")}
          />
        </Grid>
      </NodeHoldersContainer>
    </div>
  );
}

export default withStyles(styles)(InviteEquityNodeholders);
