import React, { Fragment } from "react";
import { Grid, withStyles } from "@material-ui/core";
import BorderButton from "../../../../website/components/BorderButton";
import ButtonStyled from "../../../../website/components/ButtonStyled";
import { manageEntityPages } from "../../ManageEntity/ManageEntitySteps";
import classnames from "classnames";

const styles = theme => ({
  title: {
    fontSize: "20px",
    color: "#535353",
    // marginBottom: '12px',
    textAlign: "center",
    marginTop: theme.spacing(4)
  },
  subTitle: {
    fontSize: "14px",
    color: "#9E9E9E",
    marginBottom: theme.spacing(3),
    textAlign: "center"
  },
  mainContainer: {
    padding: "0 24px"
  },
  mT30: {
    marginTop: 30
  },
  btnFloat: {
    float: "right"
  },

  btnCenter: {
    textAlign: "center"
  },
  linkBtnStyle: {
    textDecoration: "underline",
    textTransform: "uppercase",
    color: "#9E9E9E",
    fontSize: "14px"
  }
});

function AlterationContainer({
  children,
  handleNext,
  title,
  classes,
  AcceptTerms,
  subTitle,
  btnText,
  setManageEntityStep,
  handleBack,
  containedBtnText,
  linkBtnText
}) {
  const handleButtonAction = () => {
    switch (btnText) {
      case "Abort":
        abortAlteratinOfEntityName();
        break;
      case "Back":
        handleBack();
        break;
      default:
        break;
    }
  };

  const abortAlteratinOfEntityName = () => {
    setManageEntityStep(manageEntityPages.manageEntitiesPage);
  };

  return (
    <Fragment>
      <Grid container spacing={0} className={classes.mainContainer}>
        <Grid item xs={12} className={classes.title}>
          {title}
        </Grid>
        <Grid item xs={12} className={classes.subTitle}>
          {subTitle}
        </Grid>
        {children}
        {(btnText || containedBtnText) && (
          <Grid
            className={classnames(classes.mT30, classes.btnCenter)}
            item
            xs={12}
          >
            {btnText && (
              <BorderButton
                disabled={false}
                btnText={btnText}
                onClickHandler={handleButtonAction}
                classes={classes}
              />
            )}
            {containedBtnText && (
              <ButtonStyled
                btnFloatClass={classes}
                onClickHandler={handleNext}
                disabled={!AcceptTerms}
                btnText={containedBtnText}
              />
            )}
          </Grid>
        )}
        {linkBtnText && (
          <Grid
            className={classnames(classes.mT30, classes.btnCenter)}
            item
            xs={12}
          >
            <div
              className={classes.linkBtnStyle}
              onClick={abortAlteratinOfEntityName}
            >
              {linkBtnText}
            </div>
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
}

export default withStyles(styles)(AlterationContainer);
