import React, { useState, Fragment } from 'react';
import ManageEntities from './ManageEntities';
import EntitiesAlteration from '../Entities/EntityAlteration/EntitiesAlteration';
import ManageEquityContainer from '../Entities/ManageEquity/ManageEquityContainer';
import ManageNodeholder from '../ManageNodeholder/ManageNodeholder';

export const manageEntityPages = {
    manageEntitiesPage: 1,
    manageEntitiesNamePage: 2,
    manageNodeholdersPage: 3,
    manageEquityPage: 4,
};

function ManageEntitySteps() {
    const [manageEntityStep, setManageEntityStep] = useState(1);

    const getActiveStep = () => {
        switch (manageEntityStep) {
            case manageEntityPages.manageEntitiesPage:
                return <ManageEntities setManageEntityStep={setManageEntityStep} />;

            case manageEntityPages.manageEntitiesNamePage:
                return <EntitiesAlteration setManageEntityStep={setManageEntityStep} />;

            case manageEntityPages.manageEquityPage:
                return <ManageEquityContainer setManageEntityStep={setManageEntityStep} />;

            case manageEntityPages.manageNodeholdersPage: 
                return <ManageNodeholder setManageEntityStep={setManageEntityStep} />
                
            default:
                return <ManageEntities setManageEntityStep={setManageEntityStep} />;
        }
    }

    return (
        <Fragment>
            {getActiveStep()}
        </Fragment>
    );
}

export default ManageEntitySteps;