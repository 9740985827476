import React from 'react';
import TopBar from './TopBar';
import EntityTopBar from './EntityTopBar';

const ExceptionRoutes = ['/entity-formation', '/entity-platform', '/decentralized-companies-act'];
export default function TopBarContainer({ location, isLoggedIn }) {
    

    const topBar = <TopBar isLoggedIn={isLoggedIn} />;

    const getTopBar = () => {
        let result = topBar;

        const isExceptionRoute = ExceptionRoutes.find(route => location.pathname.includes(route));
        if (isExceptionRoute) {
            result = '';
        }
        return result;
    }

    return (
        getTopBar()
    )
}