import React from "react";
import DialogContainer from "../../../../website/components/DialogContainer";
import {
  DialogContent,
  Typography,
  Grid,
  withStyles,
  createStyles,
  InputBase
} from "@material-ui/core";

const BootstrapInput = withStyles(theme =>
  createStyles({
    root: {
      marginTop: theme.spacing(1) + 2,
      // marginBottom: theme.spacing(1) + 2,
      width: "100%"
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: "#FCFCFC",
      border: "2px solid #DAE1E7",
      fontSize: 16,
      width: "100%",
      padding: theme.spacing(2),
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      color: "#9E9E9E",
      '&:focus':{
        border: "2px solid #535353",
      }
    }
  })
)(InputBase);

function EqtDialog({ equityForm, open, actionBtn, classes, onFormChange, onBackdropClick }) {
  return (
    <DialogContainer open={open} title="Equity Allocation" actions={actionBtn} onBackdropClick={onBackdropClick}>
      <DialogContent>
        <Typography className={classes.addEqtP}>
          Enter the amount of eqt you want to allocate to what nodecode.
        </Typography>
        <Grid container spacing={0} style={{ margin: "16px 0px 4px" }}>
          <Grid item xs={12}>
            <BootstrapInput
              type="text"
              name="Nodecode"
              placeholder="Enter Nodecode"
              value={equityForm.Nodecode}
              onChange={onFormChange}
              required
              error={equityForm.nodeCodeError.length > 0}
            />
            {equityForm.nodeCodeError.length>0 && <small style={{color: 'red'}}>
              {equityForm.nodeCodeError}
            </small>}
          </Grid>
          <Grid item xs={12}>
            <BootstrapInput
              type="number"
              name="EquityPercentage"
              placeholder="Amount of equity in %"
              value={equityForm.EquityPercentage}
              onChange={onFormChange}
              required
            />
          </Grid>
        </Grid>
      </DialogContent>
    </DialogContainer>
  );
}

export default EqtDialog;
