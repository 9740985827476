import { Component } from 'react';

export default class ScrollIntoView extends Component {

    componentDidMount() {
        this.scroll()
    }

    componentDidUpdate() {
        this.scroll()
    }

    scroll() {
        const { id } = this.props
        if (!id) {
            return
        }
        const element = document.getElementById(id)
        if (element) {
            element.scrollIntoView()
        }
    }

    render() {
        return this.props.children
    }
}