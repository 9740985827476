import React, { useState } from 'react';
import AssociatedEntities from './AssociatedEntities';
import ManageEntitySteps from '../../ManageEntity/ManageEntitySteps';


export const pages = {
    associatedEntitiesListPage: 1,
    manageEntitiesPage: 2
};

function AssociatedEntitiesContainer() {
    const [activePage, setActivePage] = useState(1);
    
    const getActivePage = () => {
        switch (activePage) {
            case pages.associatedEntitiesListPage:
                return <AssociatedEntities setActivePage={setActivePage} />;
            case pages.manageEntitiesPage:
                return <ManageEntitySteps setActivePage={setActivePage} />; 

            default:
                return <AssociatedEntities setActivePage={setActivePage} />;
        }
    }

    return (
        getActivePage()
    )
}

export default AssociatedEntitiesContainer;
