export default [
  {
    // 1st Section
    header: {
      name: "Decentralized Entities",
      icon: null,
      subHeader: [
        {
          name: null,
          data: [
            {
              type: "text",
              content: `A decentralized entity is a set of nodes associated 
              under a smart programmable agreement on the 
              blockchain. An immutable global agent locally 
              powered by a set of otherwise unrelated nodes to
              represent their association on the network as a 
              distinct digital person. Each associated node runs 
              the entity contract locally, together forming a 
              global, decentralized entity established under the 
              specific terms of association set by its nodeholders.`,
            },
            {
              type: "text",
              content: `Nodes create new decentralized entities to 
              represent their association. The nodes associated 
              under an entity contract are its nodeholders. 
              Principal nodeholders are nodes assigned with key 
              roles in a decentralized entity. Equity nodeholders 
              are nodes assigned with ownership rights in its
              underlying association.`,
            },
            {
              type: "text",
              content: `Ownership rights in a decentralized entity 
              are determined through possession of EQT tokens. Each 
              new entity is created with a fixed number of entity-unique 
              equity tokens corresponding to a total ownership stake of 
              up to 100% in the underlying association. Equity is held 
              and allocated by nodeholders via network transactions of 
              EQT tokens, forming a transaction mechanism of equity rights
               on the blockchain.`,
            },
            {
              type: "text",
              content: `Decentralized entities vary by their underlying smart
              contract, with different contracts created to represent a different
              nature of association.`,
            },
          ],
        },
      ],
    },
  },
  {
    // 2nd section
    header: {
      name: "Smart Legal Entities",
      icon: null,
      subHeader: [
        {
          name: null,
          data: [
            {
              type: "text",
              content: `Smart Legal Entities (SLEs) are contractpowered 
                        decentralized entities suitable for doing business 
                        in the digital, multimonetary world`,
            },
            {
              type: "text",
              content: `A decentralized company is a Smart Legal Entity 
                        representing an equity-based association of a set 
                        of nodes formed under a Contract of Incorporation`,
            },
          ],
        },
      ],
    },
  },
  {
    // 3rd section
    header: {
      name: "Next Generation Entities",
      icon: null,
      subHeader: [
        {
          name: null,
          data: [
            {
              type: "text",
              content: `Next-Generation Entities (NGEs) are contract-
              governed entities designed for doing business in a 
              next-generation environment`,
            },
          ],
        },
      ],
    },
  },
  {
    // 4th section
    header: {
      name: "Autonomous Legal Person",
      icon: null,
      subHeader: [
        {
          name: null,
          data: [
            {
              type: "text",
              content: `A digital legal vessel powered by selfgoverned 
              digital agent make for an autonomous digital agent that has 
              its own legal identity. Effectively granting legal identity
              to an AI.`,
            },
            {
              type: "text",
              content: `An autonomous legal person (ALP) is thus a digital 
              vessel containing the legal identity of self-
              governed digital agents. Embracing for AI to reach 
              a degree of capability and individuality exceeding 
              the liability of its maker`,
            },
          ],
        },
      ],
    },
  },
  {
    // 4th section
    header: {
      name: "Decentralized Company",
      icon: null,
      subHeader: [
        {
          name: null,
          data: [
            {
              type: "text",
              content: `A decentralized company is a Smart Legal Entity 
              representing an equity-based association of a set 
              of nodes formed under a Contract of Incorporation.`,
            },
          ],
        },
        {
          name: "Decentralized Liability Company",
          data: [
            {
              type: "text",
              content: `A decentralized liability company is a set of nodes associated under a Contract of Incorporation, with the liability of its nodeholders limited by decentralization`,
            },
          ],
        },
        {
          name: "Types of Decentralized Company",
          data: null,
        },
        {
          name: "Single-Node Company",
          data: [
            {
              type: "text",
              content: `A decentralized company where all nodeholding 
              rights are assigned to a single equity nodeholder.`,
            },
          ],
        },
        {
          name: "Multi-Node Company",
          data: [
            {
              type: "text",
              content: `A decentralized company where all nodeholding 
              rights are assigned to a single equity nodeholder.`,
            },
          ],
        },
      ],
    },
  },
  {
    header: {
      name: "Nodeholders",
      icon: null,
      subHeader: [
        {
          name: "Nodes and Nodeholding",
          data: [
            {
              type: "text",
              content: `Nodes are principle network units, each 
                representing an individual party in the association 
                underlying a decentralized entity.`,
            },
            {
              type: "text",
              content: `A nodeholder is a node assigned with nodeholding 
                rights in a decentralized entity.`,
            },
            {
              type: "text",
              content: `Nodeholding is the state of holding a node in a 
                decentralized entity (i.e. of being a ‘nodeholder’).`,
            },
          ],
        },
        {
          name: "Nodeholder in a Decentralized Company",
          data: null,
        },
        {
          name: "Types of Nodeholders",
          data: [
            {
              type: "text",
              content: `Executive nodeholders are assigned with principal 
                    roles in a decentralized company, while equity 
                    nodeholders hold an ownership stake in its 
                    underlying association.`,
            },
          ],
        },
        {
          name: "Equity Nodeholders",
          data: [
            {
              type: "text",
              content: `Equity nodeholders are nodes assigned with ownership rights in a decentralized company, equivalent to their ownership stake in its underlying association.`,
            },
            {
              type: "text",
              content: `Equity nodeholders control the company’s state through its associating contract as well as the status and nodeholding rights of the company’s executive nodeholders.`,
            },
          ],
        },
        {
          name: "Executive Nodeholders",
          data: [
            {
              type: "text",
              content: `Founder Node - A title role crediting nodes who 
                      founded the company on the masthead of its 
                      associating contract.`,
            },
            {
              type: "text",
              content: `Director Node - Each decentralized company must 
                have at least one director, appointed by its equity 
                nodeholders to represent the company as its 
                official contact.`,
            },
            {
              type: "text",
              content: `Chairman Node - The chairman node can be 
                appointed by the company’s equity nodeholders 
                and in turn can appoint and dismiss the CEO node.`,
            },
            {
              type: "text",
              content: `CEO Node - The CEO node is appointed by the chairman node to manage non-executive nodeholding parties.`,
            },
          ],
        },
        {
          name: "Essential Nodeholders",
          data: [
            {
              type: "text",
              content: `Essential Nodeholders are the nodeholders required for creating and operating a decentralized entity.`,
            },
            {
              type: "text",
              content: `The essential nodeholders is a decentralized company are at least one Director Node and one Equity Nodeholder, which can be the same person.
                `,
            },
          ],
        },
      ],
    },
  },
  {
    header: {
      name: "EQT: Tokenized Ownership Rights",
      icon: null,
      subHeader: [
        {
          name: "A Transaction Mechanism of Equity Rights on the Blockchain",
          data: [
            {
              type: "text",
              content: `Ownership and control of a decentralized company are determined through possession of entity-unique equity tokens.
              `,
            },
            {
              type: "text",
              content: `Nodeholders’ equity stake in a decentralized company is determined by the amount of the company’s unique equity tokens in their possession.
                `,
            },
          ],
        },
        {
          name: "Entity-Unique Equity Tokens",
          data: [
            {
              type: "text",
              content: `Each decentralized company is created with a set 
                of entity-unique EQT tokens generated and 
                allocated amongst its equity nodeholders at 
                formation.
                `,
            },
            {
              type: "text",
              content: `Possession of a company's EQT tokens grants the 
                nodeholding party an ownership stake in the 
                company and a claim on the assets of its 
                underlying association corresponding to the 
                number of tokens in possession.
                  `,
            },
          ],
        },
        {
          name: "Nodeholder Equity",
          data: [
            {
              type: "text",
              content: `Every nodeholder can be assigned with ownership 
                rights of up to 100% in a decentralized company.
                  `,
            },
            {
              type: "text",
              content: `Nodeholder equity is an equity nodeholder’s 
                ownership stake in a decentralized company.
                    `,
            },
            {
              type: "text",
              content: `A nodeholder’s ownership stake is determined and 
                equates to the amount of the company’s entity-
                unique EQT tokens in possession by that 
                nodeholder.
                    `,
            },
          ],
        },
      ],
    },
  },
  {
    header: {
      name: "Contract of Incorporation",
      icon: null,
      subHeader: [
        {
          name: "Associating Contract",
          data: [
            {
              type: "text",
              content:
                "A Contract of Incorporation is the associating contract underlying a Decentralized Company",
            },
            {
              type: "text",
              content: `An associating contract is a digitally immutable 
                programmable agreement representing an 
                association of nodeholders as a distinct digital 
                person.`,
            },
            {
              type: "text",
              content: `The Contract of Incorporation stores references of 
                the company’s official name, nodeholders and 
                terms of association`,
            },
          ],
        },
        {
          name: "Entity Keys",
          data: [
            {
              type: "text",
              content: `The entity keys are the private and public keys of 
                  the associating contract.`,
            },
          ],
        },
        {
          name: "Company Address",
          data: [
            {
              type: "text",
              content: `The Company Address is the hashed public key of the Contract of Incorporation.`,
            },
          ],
        },
        {
          name: "Company Signature",
          data: [
            {
              type: "text",
              content: `The Company Signature is the unique private key of the entity’s associating contract.`,
            },
            {
              type: "text",
              content: `The signature serves to authenticate the 
              company’s identity, e.g. when entering transactions.`,
            },
          ],
        },
        {
          name: "Entity Data",
          data: null,
        },
        {
          name: "Company Name",
          data: [
            {
              type: "text",
              content: `The decentralized company’s official name, as stored on the entities registrar.`,
            },
          ],
        },
        {
          name: "Nodeholding Parties",
          data: [
            {
              type: "text",
              content: `the nodeholders represented by the 
                decentralized company and associated by its 
                Contract of Incorporation.`,
            },
          ],
        },
        {
          name: "Terms of Association",
          data: [
            {
              type: "text",
              content: `the nodeholders represented by the 
              decentralized company and associated by its 
              Contract of Incorporation.`,
            },
          ],
        },
        {
          name: "Entity Status",
          data: [
            {
              type: "text",
              content: `The company status is the network status of its 
                state.`,
            },
          ],
        },
        {
          name: "Active",
          data: [
            {
              type: "text",
              content: `‘Active’ status enables a decentralized company to 
              ‘read’ from and/ or write (‘alter’) its state, as stored 
              on the registrar blockchain`,
            },
          ],
        },
        {
          name: "Pending",
          data: [
            {
              type: "text",
              content: `Pending’ status temporarily restricts an decentralized company from writing (‘update’ its state), on the blockchain`,
            },
          ],
        },
        {
          name: "Terminated",
          data: [
            {
              type: "text",
              content: `Terminated’ status permanently restricts a decentralized company from reading or writing on the blockchain again.`,
            },
          ],
        },
      ],
    },
  },
  {
    header: {
      name: "Decentralized Company Formation",
      icon: null,
      subHeader: [
        {
          name: "Entity Class and Type",
          data: [
            {
              type: "text",
              content: `A Decentralized Company is a ​type ​ of Smart Legal 
                          Entity (Class).`,
            },
          ],
        },
        {
          name: "Company Name",
          data: [
            {
              type: "text",
              content: `Select the official name of the new decentralized company.`,
            },
          ],
        },
        {
          name: "Appoint Nodeholders",
          data: null,
        },
        {
          name: "Associating Node",
          data: [
            {
              type: "text",
              content: `The nodeholding party that registered the new entity with the entities registrar`,
            },
            {
              type: "text",
              content: `The Associating Node can either be a nodeholding 
                party in the entity as well as a non-holding ‘third-
                party’ node, without a role in the decentralized 
                company past its formation process.`,
            },
          ],
        },
        {
          name: "Executive Nodeholders",
          data: [
            {
              type: "text",
              content: `The nodeholding parties in charge of operating a 
              decentralized company's ongoing activity are its 
              Founder, Director, Chairman and CEO Nodes.`,
            },
            {
              type: "text",
              content: `Executive nodeholders are initially appointed by 
              the associating node during the formation process.`,
            },
            {
              type: "text",
              content: `A decentralized company must have at least one Director Node. Other nominations are optional.`,
            },
          ],
        },
        {
          name: "Equity Nodeholders",
          data: [
            {
              type: "text",
              content: `At least one equity nodeholder must be appointed to hold the company’s equity.`,
            },
          ],
        },
        {
          name: "Generate The Associating Contract",
          data: [
            {
              type: "text",
              content: `The final step in forming a new decentralized company is generating its Contract of Incorporation.`,
            },
            {
              type: "text",
              content: `Pending nodeholder confirmation, the associating 
              contract underlying the new company will be auto-
              generated from the registrar interface, containing 
              the new company's unique keys and data`,
            },
          ],
        },
        {
          name: "Nodeholder Confirmation",
          data: [
            {
              type: "text",
              content: `The new decentralized company is formed once all 
                its essential nodeholders have confirmed their 
                nomination as assigned to them by the Associating 
                Node.`,
            },
          ],
        },
      ],
    },
  },
  {
    header: {
      name: "Decentralized Company Management",
      icon: null,
      subHeader: [
        {
          name: null,
          data: [
            {
              type: "text",
              content: `Decentralized companies can be managed at the 
                          entity level from a designated interface on the 
                          Companies House platform, where referenced and 
                          state-altering changes could be made to the 
                          company and its associating contract.`,
            },
          ],
        },
        {
          name: "Alteration of Company Name",
          data: [
            {
              type: "text",
              content: `Changing the company’s official name can be done from the companies house interface by authorized nodeholding parties and assuming the new name is available and valid.`,
            },
          ],
        },
        {
          name: "Alter Entity Status",
          data: null,
        },
        {
          name: "Suspend Entity",
          data: [
            {
              type: "text",
              content: `Temporarily unpublish the company’s Contract of 
              Incorporation.`,
            },
          ],
        },
        {
          name: "Dissolve Entity",
          data: [
            {
              type: "text",
              content: `Terminate the Contract of Incorporation and dissolve its underlying association.`,
            },
          ],
        },
        {
          name: "Manage Nodeholders",
          data: null,
        },
        {
          name: "Appoint Nodeholders",
          data: [
            {
              type: "text",
              content: `Invite others to become nodeholder in the 
              decentralized company and official members in 
              it’s underlying association.`,
            },
          ],
        },
        {
          name: "Suspend or Dismiss Nodeholders",
          data: [
            {
              type: "text",
              content: `suspend or altogether revoke nodeholding 
              rights from current nodeholders.`,
            },
          ],
        },
      ],
    },
  },
  {
    header: {
      name: "The Register Of Digital Entities",
      icon: null,
      subHeader: [
        {
          name: "The Entities Registrar",
          data: [
            {
              type: "text",
              content: `The registrar is an internet platform for creating 
              decentralized entities: immutable digital agents, 
              representing official associations between 
              nodeholding parties`,
            },
            {
              type: "text",
              content: `The registrar aims to enable anyone, anywhere in 
              the world to register their own legal entity, as the 
              first step towards accessing a myriad of instant and 
              affordable tools and services for doing business in 
              and beyond the digital age`,
            },
          ],
        },
        {
          name: "Registrar Platform",
          data: [
            {
              type: "text",
              content: `A streamlined online interface for creating smart 
              legal and next-generation digital entities for free.`,
            },
          ],
        },
        {
          name: "Registrar Protocol",
          data: [
            {
              type: "text",
              content: `A protocol layer designed for auto-generating the 
              associating contracts underlying new decentralized 
              entities.`,
            },
            {
              type: "text",
              content: `An associating contract is automatically 
              programmed with its terms of association at  
              formation. Then published and stored on the  
              registrar blockchain through the entirety of the  
              association period.`,
            },
          ],
        },
        {
          name: "Registrar Blockchain",
          data: [
            {
              type: "text",
              content: `Powered and maintained by the Decentralized 
              Companies House, the registrar blockchain is the 
              mainnet storing the states of the associating 
              contracts underlying decentralized entities.`,
            },
          ],
        },
      ],
    },
  },
  {
    header: {
      name: "Decentralized Companies House",
      icon: null,
      subHeader: [
        {
          name: "About the Companies House",
          data: [
            {
              type: "text",
              content: `The Decentralized Companies House operates 
              the registrar of decentralized entities.`,
            },
          ],
        },
        {
          name: "Key Objectives",
          data: [
            {
              type: "text",
              content: `Expedite an inclusive transition of doing business into the digital age and beyond.`,
            },
            {
              type: "text",
              content: `Provide entrepreneurs and organizations 
              worldwide with instant and affordable access to 
              business capabilities.`,
            },
            {
              type: "text",
              content: `Remove barriers on the formation, ownership, and 
              operating of organizations - their assets, legal 
              status, financial, human and capital resources, 
              through digitization and automation.`,
            },
          ],
        },
        {
          name: "companieshouse.io",
          data: [
            {
              type: "text",
              content: `companieshouse.io is the official domain for the 
              Decentralized Companies House and the Registrar 
              of Digital Entities, from where decentralized 
              entities can be formed and managed.`,
            },
            {
              type: "link",
              content: "www.companieshouse.io/",
            },
          ],
        },
        {
          name: "entity.foundation",
          data: [
            {
              type: "text",
              content: `The Entity Foundation is a nonprofit organization 
              committed to removing barriers on doing business 
              through the innovation of legal entities.`,
            },
            {
              type: "link",
              content: "www.entity.foundation/",
            },
          ],
        },
      ],
    },
  },
];
