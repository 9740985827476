import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import NodeHoldersContainer from '../../website/components/NodeHoldersContainer';
import NodeHoldersTypography from '../../website/components/NodeHoldersTypography';
import { withStyles, Grid } from '@material-ui/core';
import ButtonStyled from '../../website/components/ButtonStyled';
import classnames from 'classnames';
import { ReactComponent as DocCopyIcon } from '../../../assets/formation-entities/DocCopyIcon.svg';

const styles = theme => ({
  textRight: {
    textAlign: 'right'
  },
  textLeft: {
    textAlign: 'left'
  },
  mT60: {
    marginTop: 60
  },
  entityKeys: {
    textAlign: 'center',
    fontWeight: '500',
    letterSpacing: 0.24,
    color: '#777777',
    fontSize: 16,
    marginTop: 60,
    marginBottom: 8
  },
  textBottomStyle: {
    fontSize: 14,
    letterSpacing: 0.42,
    fontWeight: '400',
    color: '#9E9E9E'
  },
  companyName: {
    letterSpacing: 0,
    fontWeight: '500',
    color: '#A7A7A7',
    fontSize: 16,
    textAlign: 'left',
    marginTop: 30,
    textTransform: 'uppercase',
    marginBottom: 8
  },
  issuedCount: {
    fontSize: 14,
    fontWeight: '500',
    letterSpacing: 0.21,
    color: '#777777'
  },
  btnPadding: {
    padding: '9px 57px'
  },
  mB16: {
    marginBottom: 16
  },
  negativeMargin: {
    marginTop: -70,
    padding: '0 30px 60px'
  }
});

let incorporatedList = [
  {
    id: 1,
    address: 'COMPANY Signature (Private)',
    text: 'Cbkmczdltuofupta1gnl1uu626tqyg'
  },
  {
    id: 2,
    address: 'Company Address (Public)',
    text: 'Cbkmczdltuofupta1gnl1uu626tqyg'
  }
];

function Incorporated({ classes, handleNext }) {
  const cardDescription = 'Entity contract was generated successfully.';
  const entityFormData = useSelector(state => state.entityForm.data);
  useEffect(() => {
    incorporatedList[0].text = entityFormData.CompanySignature;
    incorporatedList[1].text = entityFormData.CompanyAddress;
  });

  return (
    <div>
      <NodeHoldersContainer showFooterText={false}>
        <NodeHoldersTypography
          title={'Incorporated.'}
          cardDescription={cardDescription}
        />

        <Grid className={classes.entityKeys}>ENTITY KEYS</Grid>

        <Grid className={classes.textBottomStyle}>
          Entity keys are the private and public keys of the smart contract
          underlying your new decentralized company.
        </Grid>

        {incorporatedList.map((incorporate, i) => {
          return (
            <Fragment key={incorporate.id}>
              <Grid className={classes.companyName}>{incorporate.address}</Grid>
              <Grid container direction="row" justify="flex-start">
                <Grid className={classes.textLeft} item xs={11}>
                  <div className={classes.issuedCount}>{incorporate.text}</div>
                </Grid>
                <Grid className={classes.textRight} item xs={1}>
                  <DocCopyIcon />
                </Grid>
              </Grid>
            </Fragment>
          );
        })}

        <Grid className={classes.mT60}>
          <ButtonStyled
            btnPadding={classes}
            btnText={'Proceed'}
            onClickHandler={handleNext}
          />
        </Grid>
      </NodeHoldersContainer>
      <div
        className={classnames(classes.textBottomStyle, classes.negativeMargin)}
      >
        The decentralized company's ID and Keys are avaiblable on the Entity
        Panel in the DCH website.
      </div>
    </div>
  );
}

export default withStyles(styles)(Incorporated);
