import React, { useState, Fragment, useEffect } from 'react';
import {
  withStyles,
  Grid,
  Typography,
  Button,
  DialogContent,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';

import EntitiesCardContainer from '../../Entities/AssociatedEntities/EntitiesCardContainer';
import DialogContainer from '../../../../website/components/DialogContainer';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
// import classnames from "classnames";
import Moment from '../../../../../services/Moment';
import { useDispatch, connect } from 'react-redux';
import * as actions from '../../../../../redux/actions';
import moment from 'moment';

// Import SVG Icons
import { ReactComponent as SlEntityIcon } from '../../../../../assets/icons/entity-class/sle.svg';

const styles = theme => ({
  cardContainerPAdding: {
    padding: '16px'
  },
  sleIcon: {
    border: '1px solid #DADCE0',
    borderRadius: 4,
    backgroundColor: '#F8F8F8',
    width: '84%'
  },
  companyName: {
    color: '#555555',
    fontSize: 18,
    marginBottom: 10,
    textTransform: 'capitalize'
  },
  companyDescName: {
    color: '#9E9E9E',
    fontSize: 14
  },
  activeTxt: {
    color: '#0FE45D',
    fontSize: 12,
    textAlign: 'right'
  },
  ManageEntityBtnTxt: {
    color: '#555555',
    fontSize: '14px',
    textTransform: 'capitalize'
  },
  pendingManageEntityBtnTxt: {
    color: '#9E9E9E',
    fontSize: '14px',
    textTransform: 'capitalize'
  },
  manageEntityPAdding: {
    padding: '8px 10px',
    borderTop: '1px solid #DADCE0'
  },
  doneTxt: {
    color: '#535353',
    cursor: 'pointer !important',
    letterSpacing: 0.42,
    textTransform: 'capitalize'
  },
  resignationP: {
    color: '#757575',
    fontSize: 16,
    marginBottom: 25
  },
  borderTopStyle: {
    borderTop: '1px solid #DADCE0'
  },
  checkboxTxt: {
    color: '#707070',
    fontSize: 16
  },
  btnDisableColor: {
    color: '#9E9E9E'
  },
  suspendedTxt: {
    color: '#9E9E9E',
    fontSize: 12,
    textAlign: 'right'
  }
});

function NodeholderCard({ classes, entity, userEmail }) {
  const dispatch = useDispatch();

  const [showInviteSuccessDialog, setShowInviteSuccessDialog] = useState(false);
  const [ShowResignSuccessDialog, setShowResignSuccessDialog] = useState(false);
  const [resignCheckbox, setResignCheckbox] = useState(false);
  const [timer, setTimer] = useState('Counting..');
  const [resignedStatus, setResignedStatus] = useState('Pending Resignation ');
  let timerInterval = null;
  const resignNodeholder = () => {
    setShowInviteSuccessDialog(true);
  };

  const clearIntervalForTimer = () => {
    if (timerInterval) {
      clearInterval(timerInterval);
    }
  };

  useEffect(() => {
    countdown();
    return () => {
      clearIntervalForTimer();
    };
  }, [entity.ResignedDate]);

  const countdown = () => {
    if (entity.ResignedDate) {
      const stopTimer = moment(entity.ResignedDate).add(900, 'seconds');
      clearIntervalForTimer();
      timerInterval = setInterval(() => {
        const different = stopTimer.diff(moment());
        if (different <= 0 || timer === 'Resigned') {
          clearIntervalForTimer();
          setResignedStatus('');
          return setTimer('Resigned');
        } else return setTimer(`(${moment.utc(different).format('mm:ss')})`);
      }, 1000);
    }
  };

  const resignNodeholderDone = () => {
    setShowInviteSuccessDialog(false);
    if (userEmail) {
      const params = {
        EntityId: entity.EntityId,
        Email: userEmail,
        Role: entity.Role
      };
      dispatch(actions.resignRole(params));
    }

    setShowResignSuccessDialog(true);
  };

  const onDialogClose = () => {
    setShowInviteSuccessDialog(false);
    setShowResignSuccessDialog(false);
  };

  const NodeholderActions = () => (
    <Button
      className={
        !entity.Resigned
          ? classes.ManageEntityBtnTxt
          : classes.pendingManageEntityBtnTxt
      }
      disableRipple={true}
      disabled={entity.Resigned}
      onClick={resignNodeholder}
    >
      {entity.Resigned ? `${resignedStatus}${timer}` : 'Resign'}
    </Button>
  );

  const ResignDialogActions = ({ handleClose }) => (
    <Button
      className={classes.doneTxt}
      disableRipple={true}
      onClick={handleClose}
      color="primary"
    >
      Okay
    </Button>
  );

  const DialogActions = ({ handleClose }) => (
    <Fragment>
      <Button
        className={classes.doneTxt}
        disableRipple={true}
        onClick={handleClose}
        color="primary"
      >
        Cancel
      </Button>

      <Button
        className={classes.doneTxt}
        disableRipple={true}
        disabled={!resignCheckbox}
        onClick={resignNodeholderDone}
        color="primary"
      >
        Resign
      </Button>
    </Fragment>
  );

  return (
    <div>
      <EntitiesCardContainer
        styleClasses={classes}
        btnActions={<NodeholderActions />}
      >
        <Grid
          className={classes.cardContainerPAdding}
          container
          spacing={0}
          direction="row"
          justify="center"
        >
          <Grid item xs={4}>
            <SlEntityIcon className={classes.sleIcon} />
          </Grid>
          <Grid item xs={8}>
            <Grid container direction="row" justify="center">
              <Grid item xs={7}>
                <Typography className={classes.companyName}>
                  {entity.Role}
                </Typography>
              </Grid>

              <Grid
                className={
                  entity.NominationStatus &&
                  entity.NominationStatus.toLowerCase() === 'active'
                    ? classes.activeTxt
                    : classes.suspendedTxt
                }
                item
                xs={5}
              >
                {entity.NominationStatus ? entity.NominationStatus : 'InActive'}
              </Grid>
            </Grid>
            <Typography className={classes.companyDescName}>
              {entity.EntityName.toUpperCase()}
            </Typography>
            <Typography className={classes.companyDescName}>
              {entity.InvitedDate ? (
                <Moment text={'Since: '} date={entity.InvitedDate} />
              ) : (
                'Since: mm/dd/yy'
              )}
            </Typography>
          </Grid>
        </Grid>
      </EntitiesCardContainer>
      <DialogContainer
        open={showInviteSuccessDialog}
        footerBorderTop={classes}
        title="Nodeholder Resignation"
        actions={<DialogActions handleClose={onDialogClose} />}
        onBackdropClick={() => setShowInviteSuccessDialog(false)}
      >
        <DialogContent style={{ padding: '8px 7px' }}>
          <Typography className={classes.resignationP}>
            You are about to resign from your role as "nh_role" in
            "entity_name+entity_type".
          </Typography>
          <Typography className={classes.resignationP}>
            Resignation cannot be undone. To assume this role again, you will
            have to be reappointed by the entity's nodeholders.
          </Typography>
          <Typography className={classes.resignationP}>
            All your nodeholder's rights and duties will be suspended and your
            access to the entity panel limited.
          </Typography>
          <Typography className={classes.resignationP}>
            Upon confirmation, all nodeholding parties will be notified of your
            resignation.
          </Typography>
          <FormControlLabel
            style={{ marginRight: 0, alignItems: 'flex-start' }}
            control={
              <Checkbox
                style={{ paddingTop: 4 }}
                color="default"
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                value="checkedI"
                onChange={event => setResignCheckbox(event.target.checked)}
              />
            }
            label={
              <Typography variant="h6" className={classes.checkboxTxt}>
                I accept the terms of resignation and would like to proceed.
              </Typography>
            }
          />
        </DialogContent>
      </DialogContainer>

      <DialogContainer
        open={ShowResignSuccessDialog}
        title="You have resigned."
        actions={<ResignDialogActions handleClose={onDialogClose} />}
        onBackdropClick={() => setShowResignSuccessDialog(false)}
      >
        <DialogContent style={{ padding: '8px 7px' }}>
          <Typography className={classes.resignationP}>
            You are now dismissed from all nodeholding duties associated with
            your role in the underlying entity.
          </Typography>
          <Typography
            style={{ marginBottom: 10 }}
            className={classes.resignationP}
          >
            Unless specified otherwise in the nodeholding agreement, your
            resignation is effective immediately.
          </Typography>
        </DialogContent>
      </DialogContainer>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    entityPlatform: state.entityPlatform
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(NodeholderCard));
