import constants from "../constants";

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  isCheckingLoading: false,
  isCheckingSuccess: false,
  isCheckingError: false,
  message: "",
  data: {},
  avilableTypes: [],
  userEntities: [],
  nameAvailability: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: {}
  },
  isFormSubmitSuccessfully: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: {}
  },
  userExist: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    existEmail: null
  },
  isAssociateEmailVarified: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: {}
  },
  inviteAssociatingNode: {
    needsToBeInvited: false,
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: {},
    isAssociatingNodeInviteSuccessfully: false
  }
};

const entityForm = (state = initialState, action) => {
  switch (action.type) {
    case constants.SAVE_ENTITY_FORM:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isError: false,
        message: "",
        data: {}
      };
    case constants.SAVE_ENTITY_FORM_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        message: action.payload,
        data: {}
      };
    case constants.SAVE_ENTITY_FORM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        isCheckingLoading: false,
        isCheckingSuccess: false,
        isCheckingError: false,
        data: action.payload,
        avilableTypes: [],
        message: "Entity created successfully.",
        nameAvailability: {
          isLoading: false,
          isSuccess: false,
          isError: false,
          data: {}
        }
      };

    //create entity form start
    case constants.CREATE_ENTITY_FORM:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isError: false,
        message: "",
        data: {}
      };
    case constants.CREATE_ENTITY_FORM_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        message: action.payload,
        data: {}
      };
    case constants.CREATE_ENTITY_FORM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        data: action.payload,
        message: "Entity submitted successfully.",
        isFormSubmitSuccessfully: {
          isLoading: false,
          isSuccess: true,
          isError: false,
          data: {}
        }
      };
    //create enity form end
    case constants.GET_AVAILABLE_ENTITY_TYPE:
      return {
        ...state,
        isCheckingLoading: true,
        isCheckingSuccess: false,
        isCheckingError: false,
        message: "",
        avilableTypes: []
      };
    case constants.GET_AVAILABLE_ENTITY_TYPE_ERROR:
      return {
        ...state,
        isCheckingLoading: false,
        isCheckingError: true,
        isCheckingSuccess: false,
        message: action.payload,
        avilableTypes: []
      };
    case constants.GET_AVAILABLE_ENTITY_TYPE_SUCCESS:
      return {
        ...state,
        isCheckingLoading: false,
        isCheckingError: false,
        isCheckingSuccess: true,
        avilableTypes: action.payload.data,
        message: "Get availablity successfully."
      };
    case constants.GET_USER_ENTITIES:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isError: false,
        message: "",
        userEntities: []
      };
    case constants.GET_USER_ENTITIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isError: false,
        message: "",
        userEntities: action.payload
      };
    case constants.GET_USER_ENTITIES_ERROR:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        message: "",
        userEntities: []
      };
    case constants.GET_NAME_AVAILABLITY:
      return {
        ...state,
        nameAvailability: {
          isLoading: true,
          isSuccess: false,
          isError: false,
          data: {}
        }
      };
    case constants.GET_NAME_AVAILABLITY_SUCCESS:
      return {
        ...state,
        nameAvailability: {
          isLoading: false,
          isSuccess: true,
          isError: false,
          data: action.payload
        }
      };
    case constants.GET_NAME_AVAILABLITY_ERROR:
      return {
        ...state,
        nameAvailability: {
          isLoading: false,
          isSuccess: false,
          isError: true,
          data: {}
        }
      };
    case constants.RESET_NAME_AVAILABLITY:
      return {
        ...state,
        nameAvailability: {
          isLoading: false,
          isSuccess: false,
          isError: false,
          data: {}
        }
      };
    case constants.RESET_ENTITY:
      return {
        ...state,
        isCheckingLoading: false,
        isCheckingError: false,
        isCheckingSuccess: false,
        isLoading: false,
        isSuccess: false,
        isError: false,
        data: {},
        avilableTypes: [],
        userEntities: [],
        message: "",
        nameAvailability: {
          isLoading: false,
          isSuccess: false,
          isError: false,
          data: {}
        }
      };
    case constants.GET_USER_EXIST:
      return {
        ...state,
        userExist: {
          isLoading: true,
          isSuccess: false,
          isError: false,
          data: null
        }
      };
    case constants.GET_USER_EXIST_SUCCESS:
      const data = action.payload.data;
      const existEmail = data.available ? action.payload.email : null;
      return {
        ...state,
        userExist: {
          isLoading: false,
          isSuccess: true,
          isError: false,
          data,
          existEmail
        },
        inviteAssociatingNode: {
          ...state.inviteAssociatingNode,
          needsToBeInvited: !data.available
        }
      };
    case constants.GET_USER_EXIST_ERROR:
      return {
        ...state,
        userExist: {
          isLoading: false,
          isSuccess: false,
          isError: true,
          data: null
        }
      };

    case constants.GET_USER_NOT_EXIST:
      return {
        ...state,
        userIsNotExist: {
          isLoading: true,
          isSuccess: false,
          isError: false,
          data: {}
        }
      };
    case constants.GET_USER_NOT_EXIST_SUCCESS:
      return {
        ...state,
        userIsNotExist: {
          isLoading: false,
          isSuccess: true,
          isError: false,
          data: action.payload
        }
      };
    case constants.GET_USER_NOT_EXIST_ERROR:
      return {
        ...state,
        userIsNotExist: {
          isLoading: false,
          isSuccess: false,
          isError: true,
          data: {}
        }
      };
    case constants.RESET_EMAIL_VERIFICATION:
      return {
        ...state,
        userExist: {
          isLoading: false,
          isSuccess: false,
          isError: false,
          data: null
        }
      }
    case constants.IS_ASSOCAITE_EMAIL_VERIFIED:
      return {
        ...state,
        isAssociateEmailVarified: {
          isLoading: true,
          isSuccess: false,
          isError: false,
          data: {}
        }
      };

    case constants.IS_ASSOCIATE_EMAIL_VERIFIED_SUCCESS:
      return {
        ...state,
        isAssociateEmailVarified: {
          isLoading: false,
          isSuccess: true,
          isError: false,
          data: action.payload
        }
      }
    case constants.IS_ASSOCIATE_EMAIL_VERIFIED_ERROR:
      return {
        ...state,
        isAssociateEmailVarified: {
          isLoading: false,
          isSuccess: false,
          isError: true,
          data: {}
        }
      };
    case constants.INVITE_ASSOCAITING_NODE:
      return {
        ...state,
        inviteAssociatingNode: {
          isLoading: true,
          isSuccess: false,
          isError: false,
          data: {},
          isAssociatingNodeInviteSuccessfully: false
        }
      };

    case constants.INVITE_ASSOCAITING_NODE_SUCCESS:
      return {
        ...state,
        inviteAssociatingNode: {
          needsToBeInvited: false,
          isLoading: false,
          isSuccess: true,
          isError: false,
          data: action.payload,
          isAssociatingNodeInviteSuccessfully: true
        }
      }
    case constants.INVITE_ASSOCAITING_NODE_ERROR:
      return {
        ...state,
        inviteAssociatingNode: {
          needsToBeInvited: false,
          invited: false,
          isLoading: false,
          isSuccess: false,
          isError: true,
          data: {},
          isAssociatingNodeInviteSuccessfully: false
        }
      };
    default:
      return state;
  }
};

export default entityForm;
