import React, { useEffect } from "react";
import { withStyles, Grid } from "@material-ui/core";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import EntitiesContainer from "../Entities/EntitiesContainer";
import NewNomination from "./Nominations/NewNomination";
import NominationHistory from "./Nominations/NominationHistory";
import * as actions from "../../../../redux/actions";

const pages = {
  newNominations: 1,
  history: 2,
};

const styles = theme => ({
  linkTxt: {
    color: "#1A73E8",
    cursor: "pointer"
  },
  subTitlePadding: {
    padding: "0px 24px"
  },
  subTitle: {
    color: "#9E9E9E",
    fontSize: 16,
    marginBottom: 24
  },
  subTitleActive: {
    color: "#535353",
    fontSize: 16,
    marginBottom: 24
  },
  historyText: {
    color: "#9E9E9E",
    fontSize: 16,
    textAlign: "right"
  },
  historyActiveText: {
    color: "#535353",
    fontSize: 16,
    textAlign: "right"
  }
});

function Nominations({ classes, handleNextTerms }) {

  const dispatch = useDispatch();
  let nominations = useSelector(state => state.entityPlatform.nodeholding_nominations ? state.entityPlatform.nodeholding_nominations : []);

  useEffect(() => {
    dispatch(actions.getAllNominations())
  }, []);

  const [activePage, setActivePage] = useState(1);
  const handleNext = () => {
    handleNextTerms();
  };


  const getActivePage = () => {
    switch (activePage) {
      case pages.newNominations:
        return <NewNomination handleNext={handleNext}/>;

      case pages.history:
        return <NominationHistory />;

      default:
        return <NewNomination handleNext={handleNext}/>;
    }
  };

  
  return (
    <div>
      <EntitiesContainer
        title={"Accept or decline nodeholder roles in decentralized entities."}
        maxWidth="xl"
      >
        <Grid container className={classes.subTitlePadding} direction="row">
          <Grid
            item
            xs={10}
            className={
              activePage === pages.newNominations
                ? classes.subTitleActive
                : classes.subTitle
            }
            onClick={() => setActivePage(pages.newNominations)}
          >
            New Nominations ({nominations.length ? nominations.length : 0})
          </Grid>

          <Grid
            item
            xs={2}
            className={
              activePage === pages.history
                ? classes.historyActiveText
                : classes.historyText
            }
            onClick={() => setActivePage(pages.history)}
          >
            History
          </Grid>
        </Grid>
        {getActivePage()}
      </EntitiesContainer>
    </div>
  );
}

export default withStyles(styles)(Nominations);
