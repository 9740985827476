import React, { useState } from "react";
import TermsOfAlteration from "./TermsOfAlteration";
import ReasonsForAlteration from "./ReasonsForAlteration";
import AlterationOfEntityName from "./AlterationOfEntityName";
import ConfirmAlteration from "./ConfirmAlteration";
import CompleteAlteration from "./CompleteAlteration";
import * as actions from "../../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";

export default function EntitiesAlteration({ setManageEntityStep }) {

  const dispatch = useDispatch();
  const selectedEntity = useSelector(
    state => state.entityPlatform.selectedEntity
  );
  const [activeStep, setActiveStep] = useState(0);
  const [newEntityName, setNewEntityName] = useState("");
  const [error, setError] = useState({ value: false, message: "" });
  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  function handleEntityName(value) {
    setNewEntityName(value);
  }

  const onNameCheck = () => {
    if (newEntityName && newEntityName.length > 3) {
      let val = selectedEntity.EntityName;
      let regex = /\b[a-z]/g;
      val = val.toLowerCase().replace(regex, function(letter) {
        return letter.toUpperCase();
      });
      if (newEntityName === val) {
        setError({
          value: true,
          message: "Entity name is same as existing name."
        });
        return;
      } else {
        dispatch(
          actions.getNameAvailability({
            EntityClassName: selectedEntity.EntityClassName,
            EntityName: newEntityName.toLowerCase(),
            EntityType: selectedEntity.EntityType
          })
        );
        setError({ value: false, message: "" });
        }
    }
  };

  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return (
          <TermsOfAlteration
            handleNext={handleNext}
            setManageEntityStep={setManageEntityStep}
          />
        );

      case 1:
        return (
          <ReasonsForAlteration
            handleNext={handleNext}
            setManageEntityStep={setManageEntityStep}
          />
        );

      case 2:
        return (
          <AlterationOfEntityName
            handleNext={handleNext}
            handleBack={handleBack}
            newEntityName={newEntityName}
            onNameCheck={onNameCheck}
            selectedEntity = {selectedEntity}
            setNewEntityName={value => handleEntityName(value)}
            error={error}
          />
        );

      case 3:
        return (
          <ConfirmAlteration
            handleNext={handleNext}
            setManageEntityStep={setManageEntityStep}
            newEntityName={newEntityName}
            selectedEntity={selectedEntity}
          />
        );

      case 4:
        return (
          <CompleteAlteration
            setManageEntityStep={setManageEntityStep}
            newEntityName={newEntityName}
            selectedEntity={selectedEntity}

          />
        );

      default:
        return <div />;
    }
  };

  return <div>{getStepContent(activeStep)}</div>;
}
