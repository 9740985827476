import React from 'react';
import { useSelector } from 'react-redux';
import NodeHoldersContainer from '../../website/components/NodeHoldersContainer';
import NodeHoldersTypography from '../../website/components/NodeHoldersTypography';
import { ReactComponent as GenerateEntityIcon } from '../../../assets/formation-entities/corporate-Entity.svg';
import { withStyles, Grid, Tooltip } from '@material-ui/core';
import ButtonStyledWithTooltip from '../../website/components/ButtonStyledWithTooltip';

const styles = theme => ({
  textRight: {
    textAlign: 'right'
  },
  mT40: {
    marginTop: 40
  },
  negativeMargin: {
    marginTop: -70
  },
  textBottomStyle: {
    fontSize: '14px',
    letterSpacing: 0.42,
    color: '#9E9E9E',
    padding: '0 30px 60px'
  },
  unVerifiedHolderText: {
    maxWidth: '339px',
    letterSpacing: '0.42px',
    color: '#535353',
    width: '100 %',
    marginLeft: '15px'
  },
  linkBtn: {
    textAlign: 'center',
    cursor: 'pointer',
    fontSize: 14,
    letterSpacing: 0.42,
    color: '#535353',
    marginTop: '1rem',
    marginBottom: '1rem'
  }
});
const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: '#535353',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    display: 'inline-block'
  }
}))(Tooltip);

function GenerateEntity({
  classes,
  onSubmit,
  isAssociateEmailVarified,
  associateEmail,
  isEmailVarifiedOrNot,
  handleNext
}) {
  const entityForm = useSelector(state => state.entityForm);

  if (Object.keys(entityForm.data).length !== 0) {
    handleNext();
  }
  const isEmailVarified = isAssociateEmailVarified.data.data;
  const cardDescription =
    "Complete the formation process by generating the company's Contract of Incorporation, the unique smart contract underlying your new decentralized entity.";
  return (
    <div>
      <NodeHoldersContainer
        showFooterText={false}
        currentStep={1}
        pageTitle={'Contract of Incorporation'}
        totalSteps={3}
      >
        <Grid className={classes.textRight}>
          <GenerateEntityIcon style={{ margin: '0 auto' }} />
        </Grid>
        <NodeHoldersTypography cardDescription={cardDescription} />

        <Grid className={classes.mT40}>
          {}
          {!isEmailVarified ? (
            <LightTooltip title="Email is not verified" arrow>
              <div
                className={classes.linkBtn}
                onClick={() => isEmailVarifiedOrNot(associateEmail)}
              >
                Check Verification Status
              </div>
            </LightTooltip>
          ) : (
            <div className={classes.linkBtn}>Account is verified</div>
          )}
          <ButtonStyledWithTooltip
            disabled={isEmailVarified ? false : true}
            email={'<' + associateEmail + '>'}
            btnText={'Generate Entity'}
            onClickHandler={onSubmit}
          />
        </Grid>
      </NodeHoldersContainer>
    </div>
  );
}

export default withStyles(styles)(GenerateEntity);
