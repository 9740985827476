import React from 'react';
import ScrollIntoView from './ScrollIntoView'
import { Route } from 'react-router-dom'

const MatchWithParams = ({ component: Component, ...props }) => (
    <Route {...props} render={(props) => (        
        <ScrollIntoView id={props.match.params.subId ? props.match.params.subId : props.match.params.id}>
           <Component {...props} />
        </ScrollIntoView>
    )} />
)

export default MatchWithParams