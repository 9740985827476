import constants from "../constants";

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: "",
  data: {}
};

const userDetails = (state = initialState, action) => {
  switch (action.type) {
    case constants.PROFILE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isError: false,
        message: "",
        data: {}
      };
    case constants.PROFILE_REQUEST_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        message: action.payload,
        data: {}
      };
    case constants.PROFILE_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        data: action.payload,
        message: "Fetched successfully."
      };
    case constants.LOG_OUT:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: false,
        message: "",
        data: {}
      };
    default:
      return state;
  }
};

export default userDetails;
