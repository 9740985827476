import React, { Component } from "react";
import {
  Grid,
  InputLabel,
  FormControl,
  OutlinedInput
} from "@material-ui/core";
import { Link } from "react-router-dom";
import PasswordInput from "../components/PasswordInput";
import PrimaryButton from "../components/PrimaryButton";
import isEmpty from "lodash/isEmpty";
import * as action from "../../../redux/actions";
import { getLoggedUser } from "../../../services/generic";
import { connect } from "react-redux";

import "../styles.css";

export class LoginPage extends Component {
  constructor(props) {
    super(props);
    if (getLoggedUser()) {
      this.props.history.push("/nodeholder-panel");
    }
    this.state = {
      email: "",
      password: "",
      emailErrorText: "",
      passwordErrorText: "",
      showPassword: false
    };
    this.classes = props.classes;
  }

  componentDidMount() {
    document.body.style.backgroundColor = "#fcfcfc";
  }

  componentWillUnmount() {
    document.body.style.backgroundColor = "unset";
  }

  componentDidUpdate(prevProps) {
    const redirectTo =
      this.props.location.state && this.props.location.state["to"]
        ? this.props.location.state
        : {};

    if (
      prevProps.auth.isSuccess !== this.props.auth.isSuccess &&
      this.props.auth.isSuccess &&
      (isEmpty(this.props.location.state) || !isEmpty(redirectTo))
    ) {

      this.props.history.push({
        pathname: "/nodeholder-panel",
        state: redirectTo
      });
    } 
    else if (
      prevProps.auth.isSuccess !== this.props.auth.isSuccess &&
      this.props.auth.isSuccess &&
      isEmpty(redirectTo)
    ) {

      this.props.history.push({
        pathname: "/entity-formation",
        state: {
          screen: 5,
          EntityName: this.props.location.state.formData.entityName,
          EntityType: this.props.location.state.formData.entityType,
          BaseEQT: this.props.location.state.formData.eqt,
          IssueEQT: this.props.location.state.formData.generateEQT,
          successfulReserve: true
        }
      });
    }
  }

  // Handle fields change
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  checkEmail = email => {
    if (!email) {
      this.setState({ emailErrorText: "Please fill the email field" });
      return false;
    } else {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        this.setState({ emailErrorText: "Invalid email address" });
        return false;
      } else {
        this.setState({ emailErrorText: "" });
        return true;
      }
    }
  };

  checkPassword = pass => {
    if (!pass) {
      this.setState({ passwordErrorText: "Please fill the password field" });
      return false;
    } else {
      if (pass.length < 5) {
        this.setState({ passwordErrorText: "Password length must be 5" });
        return false;
      } else {
        this.setState({ passwordErrorText: "" });
        return true;
      }
    }
  };

  onSubmit = () => {
    const emailValid = this.checkEmail(this.state.email);
    const passwordValid = this.checkPassword(this.state.password);

    if (emailValid && passwordValid) {
      this.setState({ passwordErrorText: "", emailErrorText: "" });

      if (
        !isEmpty(this.props.location.state) &&
        !this.props.location.state["to"]
      ) {
        this.props.loginRequest({
          Email: this.state.email,
          Password: this.state.password,
          EntityClassName: this.props.location.state.EntityClassName,
          EntityName: this.props.location.state.formData.entityName.toLowerCase(),
          EntityType: this.props.location.state.formData.entityType
        });
      } else {
        this.props.loginRequest({
          Email: this.state.email,
          Password: this.state.password
        });
      }
    }
  };

  onKeyDown = (event) => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.onSubmit();
    }
  }

  render() {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <div className="login-form-container">
            <div className="header-eyebrow">decentralized companies House</div>
            <div className="form-header">entity platform</div>
            <div className="input-boxes textLeft">
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="email">Email or Nodeholder ID</InputLabel>
                <OutlinedInput
                  id="email"
                  name="email"
                  type="text"
                  error={this.state.emailErrorText ? true : false}
                  value={this.state.email}
                  onChange={this.handleChange}
                  labelWidth={168}
                />
              </FormControl>
              {this.state.emailErrorText && (
                <small className="error-text">
                  {this.state.emailErrorText}
                </small>
              )}

              <div className="password-box">
                <PasswordInput
                  handleChange={this.handleChange}
                  formData={this.state}
                  onKeyDown={this.onKeyDown}
                />
                {this.state.passwordErrorText && (
                  <small className="error-text">
                    {this.state.passwordErrorText}
                  </small>
                )}
              </div>
            </div>
            <div className="forgot-password">
              <Link
                to="/password-recovery"
                style={{ textDecoration: "none", color: "#535353" }}
              >
                Forgot your password?
              </Link>
            </div>
            <div className="signin-btn">
              <PrimaryButton label="sign in" onSubmit={this.onSubmit} />
            </div>
            <div className="signup-link">
              Not a nodeholder? <br />
              <span>
                <Link
                  to="/register"
                  style={{ textDecoration: "none", color: "#333" }}
                >
                  Become a nodeholder now
                </Link>
              </span>
            </div>
          </div>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};
const mapDispatchToProps = dispatch => {
  return { loginRequest: data => dispatch(action.loginRequest(data)) };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
