import React, { useState, Fragment, useEffect } from 'react';
import NodeHoldersContainer from '../../website/components/NodeHoldersContainer';
import NodeHoldersTypography from '../../website/components/NodeHoldersTypography';
import {
  Typography,
  withStyles,
  Fab,
  Grid,
  DialogContentText,
  Button,
  DialogContent
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import classnames from 'classnames';
import ButtonStyled from '../../website/components/ButtonStyled';
import DialogContainer from '../../website/components/DialogContainer';
import IconTextField from '../../website/components/IconTextField';
import PersonIcon from '@material-ui/icons/Person';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  },
  palette: {
    primary: {
      main: '#535353'
    }
  }
});

const styles = theme => ({
  entityMainContainer: {
    margin: '0 auto',
    padding: '60px 24px 111px 24px'
  },
  pagination: {
    fontSize: 18,
    letterSpacing: 0,
    color: '#7A7A7A'
  },
  cardSubDescription: {
    fontSize: 16,
    fontFamily: 'inherit',
    fontWeight: 500,
    textTransform: 'capitalize',
    letterSpacing: 0.24,
    color: '#A7A7A7',
    textAlign: 'left',
    marginTop: 60
  },
  NominateDirector: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.21,
    color: '#777777',
    fontFamily: 'inherit',
    marginTop: '4px',
    marginBottom: '1rem'
  },
  textLeft: {
    textAlign: 'left'
  },
  textRight: {
    textAlign: 'right'
  },
  addIconStyle: {
    backgroundColor: '#535353',
    boxShadow: '0px 2px 6px #00000021',
    borderRadius: 600,
    color: '#fff',
    width: 36,
    height: 36
  },
  paddingR5: {
    paddingRight: 5
  },
  mB40: {
    marginBottom: 40
  },
  mT60: {
    marginTop: 60
  },
  DialogContentP: {
    padding: '8px 1rem !important'
  },
  padding0: {
    padding: '23px 0 26px'
  },
  inputBg: {
    backgroundColor: '#ffffff'
  },
  doneTxt: {
    color: '#535353',
    letterSpacing: 0.42,
    textTransform: 'capitalize'
  },
  disableAddNode: {
    backgroundColor: '#DDDDDD',
    boxShadow: '0px 2px 6px #00000021',
    borderRadius: '600px',
    color: '#9E9E9E',
    width: 36,
    height: 36
  }
});

const actionTypes = {
  cancel: 1,
  appoint: 2
};

function AppointOtherNodeholders({
  classes,
  handleNext,
  nodesList,
  emailError,
  nodesListChange = () => null,
  toggleAppointDialog = () => null,
  appointNodeHolderDialogValue
}) {
  const cardDescription =
    'Appoint principle Nodeholders in your Decentralized Company.';
  const defaultAppointNodeHolderState = {
    showDialog: false,
    selectedNodeIndex: null
  };
  const [errorEmail, setErrorEmail] = useState({ value: false, message: '' });
  const [errorName, setErrorName] = useState({ value: false, message: '' });

  const [nominneFullName, setnominneFullName] = useState('');
  const [nominneEmailAddress, setNominneEmailAddress] = useState('');
  const [
    appointNodeHolderBtnDisabled,
    setAppointNodeHolderBtnDisabled
  ] = useState(true);
  const changeNomineeName = event => {
    setnominneFullName(event.target.value);
    const name = event.target.value;
    if (name) {
      setErrorName({
        value: !/^[a-zA-Z ]+$/i.test(name),
        message: 'Name only contains alphabets'
      });
    } else {
      setErrorName({ value: false, message: '' });
    }
  };
  const changeNomineeEmailAddress = event => {
    const email = event.target.value;
    setNominneEmailAddress(event.target.value);
    if (email) {
      setErrorEmail({
        value: !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email),
        message: 'Invalid email address'
      });
    } else {
      setErrorEmail({ value: false, message: '' });
    }
  };

  const DialogActions = ({ handleClose }) => (
    <Fragment>
      <Button
        className={classes.doneTxt}
        onClick={() => handleClose(actionTypes.cancel)}
        color="primary"
      >
        {' '}
        Cancel{' '}
      </Button>
      <Button
        className={classes.doneTxt}
        onClick={() => handleClose(actionTypes.appoint)}
        disabled={
          !nominneFullName ||
          errorEmail.value ||
          !nominneEmailAddress ||
          errorName.value
        }
        color="primary"
      >
        {' '}
        Appoint{' '}
      </Button>
    </Fragment>
  );

  const showAppointDialog = selectedNodeIndex => {
    const appointDialog = {
      showDialog: true,
      selectedNodeIndex
    };
    toggleAppointDialog(appointDialog);
    setNominneEmailAddress('');
    setnominneFullName('');
  };

  const onDialogClose = actionType => {
    if (actionType === actionTypes.appoint) {
      nodesListChange(nominneFullName, nominneEmailAddress);
    } else {
      toggleAppointDialog(defaultAppointNodeHolderState);
    }
  };

  useEffect(() => {
    setErrorEmail(emailError);
  }, [emailError]);

  useEffect(() => {
    nodesList &&
      nodesList.some(node => {
        if (node.users && node.users.length) {
          setAppointNodeHolderBtnDisabled(false);
        }
      });
  }, [nodesList]);

  return (
    <MuiThemeProvider theme={theme}>
      <NodeHoldersContainer
        showFooterText={true}
        currentStep={3}
        totalSteps={3}
      >
        <NodeHoldersTypography
          title={'Appoint Nodeholders'}
          cardDescription={cardDescription}
        />
        <div className={classnames(classes.textLeft, classes.mB40)}>
          <div className={classes.cardSubDescription}>
            ADDITIONAL NOMINATIONS
          </div>
          <div className={classes.NominateDirector}>
            Nominate Different Types of Nodes.
          </div>
        </div>

        {nodesList.map((node, index) => {
          return (
            <Grid
              key={node.id}
              className={classes.NominateDirector}
              container
              direction="row"
              alignItems="center"
              justify="flex-start"
            >
              <Grid className={classes.textLeft} item xs={8}>
                {node.name}
              </Grid>
              <Grid
                className={classnames(classes.textRight, classes.paddingR5)}
                item
                xs={2}
              >
                {node.completedNode}/{node.totalNode}
              </Grid>

              <Grid className={classes.textRight} item xs={2}>
                <Fab
                  className={
                    node.completedNode === node.totalNode
                      ? classes.disableAddNode
                      : classes.addIconStyle
                  }
                  onClick={() => showAppointDialog(index)}
                  disabled={node.completedNode === node.totalNode}
                >
                  <AddIcon />
                </Fab>
              </Grid>
            </Grid>
          );
        })}

        <Typography className={classes.mT60}>
          <ButtonStyled
            disabled={appointNodeHolderBtnDisabled}
            btnText={'Appoint Nodeholders'}
            onClickHandler={handleNext}
          />
        </Typography>

        <DialogContainer
          // className={classes.DialogContentP}
          open={appointNodeHolderDialogValue.showDialog}
          classesContent={classes}
          title={
            'Appoint ' +
            ((nodesList[appointNodeHolderDialogValue.selectedNodeIndex] &&
              nodesList[appointNodeHolderDialogValue.selectedNodeIndex].name) ||
              '')
          }
          actions={<DialogActions handleClose={onDialogClose} />}
        >
          <DialogContentText className={classes.modelDesc}>
            Please enter the details of the nodeholder you wish to appoint.
          </DialogContentText>

          <DialogContent className={classes.padding0}>
            <IconTextField
              inputBgClass={classes}
              InputIcon={PersonIcon}
              placeholder={'Full name'}
              onChangeHandler={changeNomineeName}
              value={nominneFullName}
              hasError={errorName}
            />
            <IconTextField
              inputBgClass={classes}
              InputIcon={MailOutlineIcon}
              placeholder={'Email Address'}
              hasError={errorEmail}
              type={'email'}
              onChangeHandler={changeNomineeEmailAddress}
              value={nominneEmailAddress}
            />
          </DialogContent>
        </DialogContainer>
      </NodeHoldersContainer>
    </MuiThemeProvider>
  );
}
export default withStyles(styles)(AppointOtherNodeholders);
