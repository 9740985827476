import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import { connect } from 'react-redux';
import * as action from '../../../redux/actions';
import { getLoggedUser } from '../../../services/generic';

class RegisterForm extends Component {
  constructor(props) {
    super(props);
    if (getLoggedUser()) {
      this.props.history.push('/nodeholder-panel');
    }
    this.state = {
      step: 1,
      email: '',
      password: '',
      full_name: '',
      confirmPassword: '',
      show: true,
      stepDir: '',
      errorText: '',
      fullNameErrorText: '',
    };
  }

  componentDidMount() {
    document.body.style.backgroundColor = '#fcfcfc';
  }

  componentWillUnmount() {
    document.body.style.backgroundColor = 'unset';
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.auth.isSuccess !== this.props.auth.isSuccess &&
      this.props.auth.isSuccess
    ) {
      this.props.history.push('/login');
      // this.setState(prevState => ({
      //   stepDir: 'next',
      //   show: false,
      //   errorText: ''
      // }));
    }
  }

  onDone = () => {
    this.props.history.push('/');
  };

  nextStep = (form) => {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email)) {
      this.setState({ errorText: 'Invalid email address' });
    } else {
      this.setState((prevState) => ({
        stepDir: 'next',
        show: false,
        errorText: '',
      }));
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (this.state.full_name === '') {
      this.setState({ fullNameErrorText: 'Invalid full_name address' });
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email)) {
      this.setState({ errorText: 'Invalid email address' });
    }
    if (
      this.state.full_name !== '' &&
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email)
    ) {
      this.setState({ errorText: '', fullNameErrorText: '' });
      this.props.registrationRequest({
        email: this.state.email,
        full_name: this.state.full_name,
      });
    }
  };

  prevStep = () => {
    this.setState((prevState) => ({ stepDir: 'prev', show: false }));
  };

  onExited = () => {
    this.setState((state) => ({
      ...state,
      show: true,
      step:
        state.step +
        1 * (state.stepDir === '' ? 0 : state.stepDir === 'next' ? 1 : -1),
      stepDir: '',
    }));
  };

  // Handle fields change
  handleChange = (input) => (e) => {
    let val = e.target.value;
    if (input === 'full_name') {
      let regex = /\b[a-z]/g;
      val = val.toLowerCase().replace(regex, function (letter) {
        return letter.toUpperCase();
      });
    }
    this.setState({ [input]: val, errorText: '', fullNameErrorText: '' });
  };

  renderNextComponent() {
    const {
      step,
      email,
      password,
      confirmPassword,
      errorText,
      fullNameErrorText,
      full_name,
    } = this.state;
    const values = {
      email,
      password,
      confirmPassword,
      errorText,
      fullNameErrorText,
      full_name,
    };

    switch (step) {
      case 1:
        return (
          <FirstStep
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
            isLoading={this.props.auth.isLoading}
            submit={this.onSubmit}
          />
        );
      case 2:
        return (
          <SecondStep
            onDone={this.onDone}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            values={values}
            isLoading={this.props.auth.isLoading}
          />
        );
      default:
        return null;
    }
  }
  toggleTransition = () => {
    this.setState((prevState) => ({ start: !prevState.start }));
  };

  render() {
    const { show } = this.state;
    return (
      <CSSTransition
        in={show}
        timeout={300}
        classNames='fade'
        onEntered={this.onEntered}
        onExited={this.onExited}
      >
        <div>{this.renderNextComponent()}</div>
      </CSSTransition>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    registrationRequest: (data) => dispatch(action.signupRequest(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
