import React from "react";
import { withStyles, Grid } from "@material-ui/core";
import EntitiesCardContainer from "../AssociatedEntities/EntitiesCardContainer";

const styles = theme => ({
  cardContainerPAdding: {
    padding: "16px"
  },
  companyName: {
    color: "#555555",
    fontSize: 20,
    marginBottom: 10,
    textTransform: "uppercase"
  },
  status: {
    color: "#9E9E9E",
    fontSize: 12,
    textAlign: "right"
  },
  companyDesc: {
    color: "#9E9E9E",
    fontSize: 14
  }
});

function ReservedNames({ entity, classes }) {
  return (
    <Grid>
      <EntitiesCardContainer btnActions={"Form entity"}>
        <Grid
          className={classes.cardContainerPAdding}
          container
          spacing={0}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid className={classes.companyName} item xs={10}>
            {entity.name}
          </Grid>
          <Grid className={classes.status} item xs={2}>
            {entity.status}
          </Grid>

          <Grid className={classes.companyDesc} item xs={12}>
            {entity.companyDesc}
          </Grid>
          <Grid className={classes.companyDesc} item xs={12}>
            {entity.Expires}
          </Grid>
        </Grid>
      </EntitiesCardContainer>
    </Grid>
  );
}

export default withStyles(styles)(ReservedNames);
