const constants = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_REQUEST_SUCCESS: "LOGIN_REQUEST_SUCCESS",
  LOGIN_REQUEST_ERROR: "LOGIN_REQUEST_ERROR",

  SIGN_UP_REQUEST: "SIGN_UP_REQUEST",
  SIGN_UP_REQUEST_SUCCESS: "SIGN_UP_REQUEST_SUCCESS",
  SIGN_UP_REQUEST_ERROR: "SIGN_UP_REQUEST_ERROR",

  REQUEST_PASSWORD: "REQUEST_PASSWORD",
  REQUEST_PASSWORD_SUCCESS: "REQUEST_PASSWORD_SUCCESS",
  REQUEST_PASSWORD_ERROR: "REQUEST_PASSWORD_ERROR",

  REQUEST_ID: "REQUEST_ID",
  REQUEST_ID_SUCCESS: "REQUEST_ID_SUCCESS",
  REQUEST_ID_ERROR: "REQUEST_ID_ERROR",

  PROFILE_REQUEST: "PROFILE_REQUEST",
  PROFILE_REQUEST_SUCCESS: "PROFILE_REQUEST_SUCCESS",
  PROFILE_REQUEST_ERROR: "PROFILE_REQUEST_ERROR",

  UPDATE_PROFILE: "UPDATE_PROFILE",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_ERROR: "UPDATE_PROFILE_ERROR",

  VERIFY_EMAIL: "VERIFY_EMAIL",
  VERIFY_EMAIL_SUCCESS: "VERIFY_EMAIL_SUCCESS",
  VERIFY_EMAIL_ERROR: "VERIFY_EMAIL_ERROR",

  SAVE_ENTITY_FORM: "SAVE_ENTITY_FORM",
  SAVE_ENTITY_FORM_SUCCESS: "SAVE_ENTITY_FORM_SUCCESS",
  SAVE_ENTITY_FORM_ERROR: "SAVE_ENTITY_FORM_ERROR",

  CREATE_ENTITY_FORM: "CREATE_ENTITY_FORM",
  CREATE_ENTITY_FORM_ERROR: "CREATE_ENTITY_FORM_ERROR",
  CREATE_ENTITY_FORM_SUCCESS: "CREATE_ENTITY_FORM_SUCCESS",

  GET_AVAILABLE_ENTITY_TYPE: "GET_AVAILABLE_ENTITY_TYPE",
  GET_AVAILABLE_ENTITY_TYPE_SUCCESS: "GET_AVAILABLE_ENTITY_TYPE_SUCCESS",
  GET_AVAILABLE_ENTITY_TYPE_ERROR: "GET_AVAILABLE_ENTITY_TYPE_ERROR",
  RESET_ENTITY: "RESET_ENTITY",

  GET_NAME_AVAILABLITY: "GET_NAME_AVAILABLITY",
  GET_NAME_AVAILABLITY_SUCCESS: "GET_NAME_AVAILABLITY_SUCCESS",
  GET_NAME_AVAILABLITY_ERROR: "GET_NAME_AVAILABLITY_ERROR",
  RESET_NAME_AVAILABLITY: "RESET_NAME_AVAILABLITY",

  GET_USER_ENTITIES: "GET_USER_ENTITIES",
  GET_USER_ENTITIES_SUCCESS: "GET_USER_ENTITIES_SUCCESS",
  GET_USER_ENTITIES_ERROR: "GET_USER_ENTITIES_ERROR",

  GET_USER_EXIST: "GET_USER_EXIST",
  GET_USER_EXIST_SUCCESS: "GET_USER_EXIST_SUCCESS",
  GET_USER_EXIST_ERROR: "GET_USER_EXIST_ERROR",
  GET_USER_NOT_EXIST: "GET_USER_NOT_EXIST",
  GET_USER_NOT_EXIST_SUCCESS: "GET_USER_NOT_EXIST_SUCCESS",
  GET_USER_NOT_EXIST_ERROR: "GET_USER_NOT_EXIST_ERROR",

  RESET_EMAIL_VERIFICATION: "RESET_EMAIL_VERIFICATION",
  IS_ASSOCAITE_EMAIL_VERIFIED: "IS_ASSOCAITE_EMAIL_VERIFIED",
  IS_ASSOCIATE_EMAIL_VERIFIED_SUCCESS: "IS_ASSOCIATE_EMAIL_VERIFIED_SUCCESS",
  IS_ASSOCIATE_EMAIL_VERIFIED_ERROR: "IS_ASSOCIATE_EMAIL_VERIFIED_ERROR",
  INVITE_ASSOCAITING_NODE: "INVITE_ASSOCAITING_NODE",
  INVITE_ASSOCAITING_NODE_SUCCESS: "INVITE_ASSOCAITING_NODE_SUCCESS",
  INVITE_ASSOCAITING_NODE_ERROR: "INVITE_ASSOCAITING_NODE_ERROR",
  LOG_OUT: "LOG_OUT",

  GET_ASSOCIATED_ENTITIES: "GET_ASSOCIATED_ENTITIES",
  GET_ASSOCIATED_ENTITIES_SUCCESS: "GET_ASSOCIATED_ENTITIES_SUCCESS",
  GET_ASSOCIATED_ENTITIES_ERROR: "GET_ASSOCIATED_ENTITIES_ERROR",

  GET_ALL_NOMINATIONS: "GET_ALL_NOMINATIONS",
  GET_ALL_NOMINATIONS_SUCCESS: "GET_ALL_NOMINATIONS_SUCCESS",
  GET_ALL_NOMINATIONS_ERROR: "GET_ALL_NOMINATIONS_ERROR",
  
  GET_NOMINATION_HISTORY: "GET_NOMINATION_HISTORY",
  GET_NOMINATION_HISTORY_SUCCESS: "GET_NOMINATION_HISTORY_SUCCESS",
  GET_NOMINATION_HISTORY_ERROR: "GET_NOMINATION_HISTORY_ERROR",
  
  SET_SUSPENDED_ENTITY: "SET_SUSPENDED_ENTITY",
  SET_SUSPENDED_ENTITY_SUCCESS: "SET_SUSPENDED_ENTITY_SUCCESS",
  SET_SUSPENDED_ENTITY_ERROR: "SET_SUSPENDED_ENTITY_ERROR",
  
  CHANGE_ENTITY_NAME: "CHANGE_ENTITY_NAME",
  CHANGE_ENTITY_NAME_SUCCESS: "CHANGE_ENTITY_NAME_SUCCESS",
  CHANGE_ENTITY_NAME_ERROR: "CHANGE_ENTITY_NAME_ERROR",

  GET_EQUITY_NODE_HOLDERS_HISTORIES: "GET_EQUITY_NODE_HOLDERS_HISTORIES",
  GET_EQUITY_NODE_HOLDERS_HISTORIES_SUCCESS: "GET_EQUITY_NODE_HOLDERS_HISTORIES_SUCCESS",
  GET_EQUITY_NODE_HOLDERS_HISTORIES_ERROR: "GET_EQUITY_NODE_HOLDERS_HISTORIES_ERROR",

  GET_NODE_HOLDER_STATUS_ROLES: "GET_NODE_HOLDER_STATUS_ROLES",
  GET_NODE_HOLDER_STATUS_ROLES_SUCCESS: "GET_NODE_HOLDER_STATUS_ROLES_SUCCESS",
  GET_NODE_HOLDER_STATUS_ROLES_ERROR: "GET_NODE_HOLDER_STATUS_ROLES_ERROR",

  RESIGN_ROLE: "RESIGN_ROLE",
  RESIGN_ROLE_SUCCESS: "RESIGN_ROLE_SUCCESS",
  RESIGN_ROLE_ERROR: "RESIGN_ROLE_ERROR",

  GET_NODE_HOLDER_STATUS_HISTORY: "GET_NODE_HOLDER_STATUS_HISTORY",
  GET_NODE_HOLDER_STATUS_HISTORY_SUCCESS: "GET_NODE_HOLDER_STATUS_HISTORY_SUCCESS",
  GET_NODE_HOLDER_STATUS_HISTORY_ERROR: "GET_NODE_HOLDER_STATUS_HISTORY_ERROR",

  ALLOCATE_EQT: "ALLOCATE_EQT",
  ALLOCATE_EQT_SUCCESS: "ALLOCATE_EQT_SUCCESS",
  ALLOCATE_EQT_ERROR: "ALLOCATE_EQT_ERROR",

  CURRENT_NOMINATION_CARD_ENTITY_ID: "CURRENT_NOMINATION_CARD_ENTITY_ID",
  CURRENT_NOMINATION_CARD_ENTITY_ID_SUCCESS: "CURRENT_NOMINATION_CARD_ENTITY_ID_SUCCESS",
  CURRENT_NOMINATION_CARD_ENTITY_ID_ERROR: "CURRENT_NOMINATION_CARD_ENTITY_ID_ERROR",

  NOMINATION_ACTION: "NOMINATION_ACTION",
  NOMINATION_ACTION_SUCCESS: "NOMINATION_ACTION_SUCCESS",
  NOMINATION_ACTION_ERROR: "NOMINATION_ACTION_ERROR",

  VERIFY_PASSWORD: "VERIFY_PASSWORD",
  VERIFY_PASSWORD_SUCCESS: "VERIFY_PASSWORD_SUCCESS",
  VERIFY_PASSWORD_ERROR: "VERIFY_PASSWORD_ERROR",
   
  SET_VISIBILITY_STATUS: "SET_VISIBILITY_STATUS",
  SET_VISIBILITY_STATUS_SUCCESS: "SET_VISIBILITY_STATUS_SUCCESS",
  SET_VISIBILITY_STATUS_ERROR: "SET_VISIBILITY_STATUS_ERROR",

  SET_APPOINT_NODE_HOLDER: "SET_APPOINT_NODE_HOLDER",
  SET_APPOINT_NODE_HOLDER_SUCCESS: "SET_APPOINT_NODE_HOLDER_SUCCESS",
  SET_APPOINT_NODE_HOLDER_ERROR: "SET_APPOINT_NODE_HOLDER_ERROR",

  UPDATE_PASSWORD_VERIFIED_FLAG: "UPDATE_PASSWORD_VERIFIED_FLAG",
  UPDATE_PASSWORD_VERIFIED_FLAG_SUCCESS: "UPDATE_PASSWORD_VERIFIED_FLAG_SUCCESS",

  UPDATE_IS_APPOINT_NODEHOLDER_FLAG: "UPDATE_IS_APPOINT_NODEHOLDER_FLAG",
  UPDATE_IS_APPOINT_NODEHOLDER_FLAG_SUCCESS: "UPDATE_IS_APPOINT_NODEHOLDER_FLAG_SUCCESS",

  UPDATE_ROLE_LIMITS: "UPDATE_ROLE_LIMITS",
  UPDATE_ROLE_LIMITS_SUCCESS: "UPDATE_ROLE_LIMITS_SUCCESS",

  SET_SELECTED_ENTITY: "SET_SELECTED_ENTITY",
  SET_SELECTED_ENTITY_SUCCESS: "SET_SELECTED_ENTITY_SUCCESS",

  UPDATE_NODE_ROLES_AS_INITIAL: "UPDATE_NODE_ROLES_AS_INITIAL",
  UPDATE_NODE_ROLES_AS_INITIAL_SUCCESS:"UPDATE_NODE_ROLES_AS_INITIAL_SUCCESS",

  UPDATE_OLD_ENTITY_NAME: "UPDATE_OLD_ENTITY_NAME",
  UPDATE_OLD_ENTITY_NAME_SUCCESS: "UPDATE_OLD_ENTITY_NAME_SUCCESS"
};

export default constants;
