import React, { useState, Fragment } from 'react';
import {
  withStyles,
  Grid,
  Typography,
  DialogContent,
  Button
} from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import classnames from 'classnames';
import EntitiesCardContainer from '../Entities/AssociatedEntities/EntitiesCardContainer';
import DialogContainer from '../../../website/components/DialogContainer';
import VerificationDialogue from '../VerificationDialogue';
import Moment from '../../../../services/Moment';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../redux/actions';

// Import SVG Icons
import { ReactComponent as RightArrow } from '../../../../assets/icons/navigation/chevron_right-24px.svg';
import { ReactComponent as DLC } from '../../../../assets/icons/entity-type/dlc-name.svg';

const styles = theme => ({
  cardContainer: {
    border: '1px solid #DADCE0',
    borderRadius: 4,
    margin: '0 8px 12px'
  },
  cardContainerPAdding: {
    padding: '16px'
  },
  dlcIcon: {
    width: '80px',
    height: '95px'
  },
  companyName: {
    color: '#555555',
    fontSize: 20,
    marginBottom: 10,
    marginTop: 7,
    lineHeight: 1.2,
    textTransform: 'capitalize'
  },
  companyDescName: {
    color: '#9E9E9E',
    fontSize: 14,
    // width: '64%',
    marginTop: 5
  },
  copyTextContainer: {
    border: '1px solid #DADCE0',
    borderRadius: '2px',
    lineHeight: 1.3,
    marginBottom: 20,
    maxWidth: '250px'
  },
  copyText: {
    fontSize: '12px',
    color: '#777777',
    paddingLeft: '8px'
  },
  copyBg: {
    backgroundColor: '#DADCE0',
    color: '#777777',
    textAlign: 'center',
    height: '22px'
  },
  copyIcon: {
    fontSize: '14px',
    verticalAlign: 'middle',
    width: '14px'
  },
  manageEntityPAdding: {
    padding: '16px 10px',
    borderTop: '1px solid #DADCE0'
  },
  status: {
    color: '#25D164',
    fontSize: 14,
    textAlign: 'right'
  },
  InActiveStatus: {
    color: '#ff0606',
    fontSize: 14,
    textAlign: 'right'
  },
  companyStatusText: {
    color: '#9E9E9E',
    fontSize: 14,
    display: 'inline-block',
    paddingRight: '2px'
  },
  companyEntityName: {
    color: '#555555',
    fontSize: '18px',
    textTransform: 'capitalize',
    width: '100%'
  },
  companyStatusEntities: {
    marginTop: 20
  },
  companyEntitiesValue: {
    padding: '15px 0px 15px 0px',
    borderBottom: '1px solid #DADCE0',
    '&:last-child': {
      borderBottom: 'none',
      paddingBottom: '0px'
    }
  },
  companyEntityMargin: {
    paddingTop: '15px'
  },
  suspendP: {
    marginBottom: theme.spacing(3),
    fontSize: '16px',
    color: '#757575',
    lineHeight: '1.5',
    '&:last-child': {
      marginBottom: theme.spacing(0)
    }
  },
  borderTopStyle: {
    borderTop: '1px solid #DADCE0'
  },
  suspendbtn: {
    color: '#9E9E9E',
    fontSize: '14px',
    textTransform: 'capitalize'
  },
  suspendCancel: {
    color: '#535353'
  },
  terminationActions: {
    width: '100%'
  },
  terminationBtn: {
    color: '#9E9E9E',
    fontSize: '15px',
    textAlign: 'center',
    marginTop: theme.spacing(1)
  },
  cancelTerminationBtn: {
    border: '1px solid #DADCE0',
    padding: '2px 26px',
    borderRadius: '0px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
});

function CreateManageEntity({ classes, setManageEntityStep }) {
  const dispatch = useDispatch();
  const selectedEntity = useSelector(
    state => state.entityPlatform.selectedEntity
  );

  const entityActiveStatus =
    selectedEntity.EntityStatus === 'Active' ? true : false;
  const [showSuspendDialog, setShowSuspendDialog] = useState(false);
  const [showSuspendVerification, setShowSuspendVerification] = useState(false);
  const [showSuspendConfirmation, setShowSuspendConfirmation] = useState(false);

  // const [showTerminationDialog, setShowTerminationDialog] = useState(false);
  // const [
  //   showTerminationVerification,
  //   setShowTerminationVerification
  // ] = useState(false);
  // const [
  //   showTerminationConfirmation,
  //   setShowTerminationConfirmation
  // ] = useState(false);

  const [showDisActivateEntityDialog, setShowDisActiveEntityDialog] = useState(
    false
  );
  const [disActivateVerification, setDisActivateVerification] = useState(false);
  const [
    showDisActivateConfirmation,
    setShowDisActivateConfirmation
  ] = useState(false);

  const onDialogClose = () => {
    setShowDisActiveEntityDialog(false);
    setShowSuspendDialog(false);
    // setShowTerminationDialog(false);
  };

  const onSuspend = () => {
    setShowSuspendDialog(false);
    setShowSuspendVerification(true);
  };

  // const onTerminate = () => {
  //   setShowTerminationDialog(false);
  //   setShowTerminationVerification(true);
  // };

  const onDisActivate = () => {
    setShowDisActiveEntityDialog(false);
    setDisActivateVerification(true);
  };

  const onSuspendVerify = password => {
    if (password) {
      dispatch(actions.setSuspendedEntity(selectedEntity.EntityId));
      setShowSuspendVerification(false);
      setShowSuspendConfirmation(true);
    }
  };

  // const onTerminationVerify = () => {
  //   setShowTerminationVerification(false);
  //   setShowTerminationConfirmation(true);
  // };

  const onDisActivateVerify = password => {
    if (password) {
      setDisActivateVerification(false);
      setShowSuspendConfirmation(false);
      setShowDisActivateConfirmation(true);
      dispatch(actions.setSuspendedEntity(selectedEntity.EntityId));
    }
  };

  const DialogActions = ({ handleClose }) => (
    <Fragment>
      <Button
        disableRipple={true}
        onClick={handleClose}
        className={classnames(classes.suspendCancel, classes.suspendbtn)}
      >
        Cancel
      </Button>

      <Button
        disableRipple={true}
        className={classes.suspendbtn}
        onClick={onSuspend}
      >
        Suspend
      </Button>
    </Fragment>
  );

  // const TerminationActions = ({ handleClose }) => (
  //   <Grid container spacing={0} className={classes.terminationActions}>
  //     <Grid
  //       item
  //       xs={12}
  //       className={classes.terminationBtn}
  //       onClick={onTerminate}
  //     >
  //       Issue Notice of termination
  //     </Grid>
  //     <Grid item xs={12} style={{ textAlign: "center" }}>
  //       <Button
  //         disableRipple={true}
  //         onClick={handleClose}
  //         className={classnames(
  //           classes.suspendCancel,
  //           classes.suspendbtn,
  //           classes.cancelTerminationBtn
  //         )}
  //       >
  //         Cancel
  //       </Button>
  //     </Grid>
  //   </Grid>
  // );
  const DisActivateActions = ({ handleClose }) => (
    <Grid container spacing={0} className={classes.terminationActions}>
      <Grid
        item
        xs={12}
        className={classes.terminationBtn}
        onClick={onDisActivate}
      >
        DISACTIVATE
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Button
          disableRipple={true}
          onClick={handleClose}
          className={classnames(
            classes.suspendCancel,
            classes.suspendbtn,
            classes.cancelTerminationBtn
          )}
        >
          Cancel
        </Button>
      </Grid>
    </Grid>
  );

  const SuspendConfirmActions = () => (
    <Fragment>
      <Button
        disableRipple={true}
        onClick={() => {
          setShowSuspendConfirmation(false);
          setShowDisActivateConfirmation(false);
        }}
        className={classnames(classes.suspendCancel, classes.suspendbtn)}
      >
        Done
      </Button>
    </Fragment>
  );

  // const TerminationConfirmActions = () => (
  //   <Fragment>
  //     <Button
  //       disableRipple={true}
  //       onClick={() => setShowTerminationConfirmation(false)}
  //       className={classnames(classes.suspendCancel, classes.suspendbtn)}
  //     >
  //       Done
  //     </Button>
  //   </Fragment>
  // );

  return (
    <Grid>
      <EntitiesCardContainer
        styleClasses={classes}
        btnActions={''}
        entityActiveStatus={entityActiveStatus}
      >
        <Grid
          container
          className={classes.cardContainerPAdding}
          spacing={0}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid container justify="center">
            <DLC className={classes.dlcIcon} />
          </Grid>
          <Grid
            container
            spacing={0}
            direction="row"
            justify="center"
            alignItems="center"
            align="center"
          >
            <Grid item xs={12}>
              <Typography className={classes.companyName}>
                {selectedEntity.EntityName}
              </Typography>
            </Grid>
            <Grid
              className={classes.copyTextContainer}
              container
              spacing={0}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid className={classes.copyBg} item xs={1}>
                <FileCopyOutlinedIcon className={classes.copyIcon} />
              </Grid>
              <Grid className={classes.copyText} item xs={11}>
                {selectedEntity.EntityId}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={6}>
              <Typography className={classes.companyDescName}>
                <Moment text={'Associated: '} date={selectedEntity.createdAt} />
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <div
                className={
                  entityActiveStatus ? classes.status : classes.InActiveStatus
                }
              >
                <Typography className={classes.companyStatusText}>
                  Status:
                </Typography>
                {selectedEntity.EntityStatus}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </EntitiesCardContainer>

      <Grid>
        <EntitiesCardContainer
          styleClasses={classes}
          btnActions={'Manage Name'}
          setManageEntityStep={setManageEntityStep}
          entityActiveStatus={entityActiveStatus}
        >
          <Grid
            container
            className={classes.cardContainerPAdding}
            spacing={0}
            direction="row"
            alignItems="center"
          >
            <Typography className={classes.companyEntityName}>
              {'Entity Name'}
            </Typography>
            <Typography className={classes.companyDescName}>
              {'Manage the selectedEntity official display name'}
            </Typography>
          </Grid>
        </EntitiesCardContainer>
      </Grid>

      <Grid>
        <EntitiesCardContainer
          styleClasses={classes}
          btnActions={'Disactivate Entity'}
          entityActiveStatus={entityActiveStatus}
          setShowDisActiveEntityDialog={setShowDisActiveEntityDialog}
        >
          <Grid
            container
            className={classes.cardContainerPAdding}
            spacing={0}
            direction="row"
            alignItems="center"
          >
            <Typography className={classes.companyEntityName}>
              {'Entity Status'}
            </Typography>
            <Typography className={classes.companyDescName}>
              {
                'Adjust the selectedEntity status to match its status of activity'
              }
            </Typography>
            <Grid
              container
              className={classes.companyStatusEntities}
              spacing={0}
              direction="row"
              alignItems="center"
            >
              <Grid
                className={classes.companyEntitiesValue}
                container
                direction="row"
                alignItems="center"
              >
                <Grid item xs={11}>
                  <Typography className={classes.companyEntityName}>
                    {'Suspend Entity'}
                  </Typography>
                  <Typography className={classes.companyDescName}>
                    {'Temporary disactivate the associating contact'}
                  </Typography>
                </Grid>
                <Grid item xs={1} align="right">
                  <RightArrow
                    style={{
                      height: '20px',
                      fill: entityActiveStatus ? '#696969' : '#e0e0e0'
                    }}
                    onClick={() =>
                      entityActiveStatus ? setShowSuspendDialog(true) : null
                    }
                  />
                </Grid>
              </Grid>
              {/* <Grid
                className={classes.companyEntitiesValue}
                container
                direction="row"
                alignItems="center"
              >
                <Grid item xs={11}>
                  <Typography className={classes.companyEntityName}>
                    {"Dissolve Entity"}
                  </Typography>
                  <Typography className={classes.companyDescName}>
                    {"Terminate the underlying association"}
                  </Typography>
                </Grid>
                <Grid item xs={1} align="right">
                  <RightArrow
                    style={{
                      height: "20px",
                      fill: entityActiveStatus ? "#696969" : "#e0e0e0"
                    }}
                    onClick={() =>
                      entityActiveStatus
                        ? setShowTerminationDialog(true)
                        : null
                    }
                  />
                </Grid>
              </Grid> */}
            </Grid>
          </Grid>
        </EntitiesCardContainer>
      </Grid>
      <Grid>
        <EntitiesCardContainer
          styleClasses={classes}
          btnActions={'Manage Nodeholder'}
          setManageEntityStep={setManageEntityStep}
          entityActiveStatus={entityActiveStatus}
        >
          <Grid
            container
            className={classes.cardContainerPAdding}
            spacing={0}
            alignItems="center"
          >
            <Typography className={classes.companyEntityName}>
              {'Nodeholders'}
            </Typography>
            <Typography className={classes.companyDescName}>
              {'Manage the selectedEntity nodeholders, Lorem ipsum some text'}
            </Typography>
          </Grid>
        </EntitiesCardContainer>
      </Grid>
      <Grid>
        <EntitiesCardContainer
          styleClasses={classes}
          btnActions={'Manage Equity'}
          setManageEntityStep={setManageEntityStep}
          entityActiveStatus={entityActiveStatus}
        >
          <Grid
            container
            className={classes.cardContainerPAdding}
            spacing={0}
            alignItems="center"
          >
            <Typography className={classes.companyEntityName}>
              {'Equity'}
            </Typography>
            <Typography className={classes.companyDescName}>
              {'Manage the EQT stake in the selectedEntity'}
            </Typography>
          </Grid>
        </EntitiesCardContainer>
      </Grid>

      <DialogContainer
        open={showSuspendDialog}
        footerBorderTop={classes}
        title="Suspend Entity"
        actions={<DialogActions handleClose={onDialogClose} />}
        onBackdropClick={() => setShowSuspendDialog(false)}
      >
        <DialogContent>
          <Typography className={classes.suspendP}>
            You are about to suspend the selectedEntity by deactivating is
            associating contract.
          </Typography>
          <Typography className={classes.suspendP}>
            Suspension is immediate and cannot be undone.
          </Typography>
          <Typography className={classes.suspendP}>
            While suspended, the selectedEntity will not be accessible to its
            nodeholders and will not be able to sign or receive transactions.
          </Typography>
          <Typography className={classes.suspendP}>
            The selectedEntity contract will automatically reactivate in 48
            hours.
          </Typography>
        </DialogContent>
      </DialogContainer>
      {showSuspendVerification && (
        <VerificationDialogue
          open={showSuspendVerification}
          onDialogClose={onSuspendVerify}
          verificationText="Please enter your password in order to suspend the selectedEntity."
          onBackdropClick={() => setShowSuspendVerification(false)}
        />
      )}

      <DialogContainer
        open={showSuspendConfirmation}
        titleBorder="true"
        title="Suspend Entity"
        actions={<SuspendConfirmActions />}
        onBackdropClick={() => setShowSuspendConfirmation(false)}
      >
        <DialogContent>
          <Typography className={classes.suspendP}>
            Entity has been suspended. the selectedEntity contract will be
            reactivated in 48 hours.
          </Typography>
        </DialogContent>
      </DialogContainer>

      {/* <DialogContainer
        open={showTerminationDialog}
        title="Notice of Termination"
        actions={<TerminationActions handleClose={onDialogClose} />}
      >
        <DialogContent>
          <Typography className={classes.suspendP}>
            You are about to TERMINATE the underlying association.
          </Typography>
          <Typography className={classes.suspendP}>
            Terminating the underlying association is permeant and requires
            approval from all equity nodeholders.
          </Typography>
          <Typography className={classes.suspendP}>
            To start the process, issue a Notice of Termination to the
            nodeholders.
          </Typography>
          <Typography className={classes.suspendP}>
            Once all nodeholding parties have approved, you will be able to
            proceed with the termination process.
          </Typography>
        </DialogContent>
      </DialogContainer>

      <VerificationDialogue
        open={showTerminationVerification}
        onDialogClose={onTerminationVerify}
        verificationText="Please enter your password in order to dissolve the selectedEntity."
      />

      <DialogContainer
        open={showTerminationConfirmation}
        title="Notice of Termination"
        titleBorder="true"
        actions={<TerminationConfirmActions />}
      >
        <DialogContent>
          <Typography className={classes.suspendP}>
            Termination Issued. You will be notified once all nodeholding
            parties have responded.
          </Typography>
        </DialogContent>
      </DialogContainer> */}

      <DialogContainer
        open={showDisActivateEntityDialog}
        title="Disactivate Entity"
        actions={
          <DisActivateActions
            handleClose={onDialogClose}
            showSuspendConfirmation
          />
        }
        onBackdropClick={() => setShowDisActiveEntityDialog(false)}
      >
        <DialogContent>
          <Typography className={classes.suspendP}>
            You are about to SUSPEND the underlying association.
          </Typography>
          <Typography className={classes.suspendP}>
            Disactivate the underlying association is permanent .
          </Typography>
          <Typography className={classes.suspendP}>
            To continue the process, click on DISACTIVATE
          </Typography>
        </DialogContent>
      </DialogContainer>
      {disActivateVerification && (
        <VerificationDialogue
          open={disActivateVerification}
          onDialogClose={onDisActivateVerify}
          verificationText="Please enter your password in order to disactivate the selectedEntity."
          onBackdropClick={() => setDisActivateVerification(false)}
        />
      )}

      <DialogContainer
        open={showDisActivateConfirmation}
        titleBorder="true"
        title="Disactivate Entity"
        actions={<SuspendConfirmActions />}
        onBackdropClick={() => setShowDisActivateConfirmation(false)}
      >
        <DialogContent>
          <Typography className={classes.suspendP}>
            Entity has been disactivated successfully.
          </Typography>
        </DialogContent>
      </DialogContainer>
    </Grid>
  );
}

export default withStyles(styles)(CreateManageEntity);
