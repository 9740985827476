import React, { Fragment } from "react";
import { withStyles, Container, Grid, Typography } from "@material-ui/core";
import classnames from "classnames";
import { useDispatch } from "react-redux";
import * as actions from "../../../../redux/actions";

const styles = theme => ({
  title: {
    color: "#5F6368",
    fontSize: 16,
    marginBottom: theme.spacing(3),
    textAlign: "center",
    width: "80%"
  },
  subTitle: {
    color: "#535353",
    fontSize: 16,
    marginBottom: 24
  },
  textCenter: {
    textAlign: "center"
  },
  textRight: {
    textAlign: "right"
  },
  bottomText: {
    color: "#535353",
    fontSize: 14,
    marginTop: 24
  }
});

function ManageEntitiesContainer({
  classes,
  children,
  title,
  subTitle,
  bottomText,
  setManageEntityStep,
  manageEntityPages
}) {
  const dispatch = useDispatch();

  const handleBottomLink = () => {
    if (setManageEntityStep) {
      setManageEntityStep(manageEntityPages.manageEntitiesPage);
      dispatch(actions.updateNodeRolesAsInitial());  //dispatch action of update nodeRoles as initial(updateNodeRolesAsInitial)
    }
  };
  return (
    <Fragment>
      <Container style={{ padding: 0 }} maxWidth="xl">
        <Grid container spacing={0} justify="center" alignItems="center">
          <Grid item sm={12} className={classes.title}>
            {title}
          </Grid>
          {subTitle ? (
            <Grid
              item
              sm={12}
              className={classnames(classes.subTitle, classes.textCenter)}
            >
              {subTitle}
            </Grid>
          ) : (
            ""
          )}
          <Grid container spacing={0} justify="center" alignItems="center">
            {children}
          </Grid>
          <Grid container spacing={0} justify="center" alignItems="center">
            <Typography
              className={classes.bottomText}
              onClick={() => handleBottomLink()}
            >
              {" "}
              {bottomText}{" "}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
}

export default withStyles(styles)(ManageEntitiesContainer);
