import React, { Component } from 'react';
import '../css/header.css';

// Import SVG Icons
import { ReactComponent as Logo } from '../../../assets/icons/dch-icon.svg';

export class Header extends Component {
  render() {
    return (
      <div className="header-container">
        <Logo
          height="90px"
          width="90px"
          stroke="#333"
          strokeWidth="4"
          style={{ marginTop: '90px' }}
        />
        <div className="header">
          Decentralized <br />
          Companies House
        </div>
        <div className="sub-header">The Registrar of Digital Entities</div>
      </div>
    );
  }
}

export default Header;
