import React, { useState, useEffect } from "react";
import DialogContainer from "../../website/components/DialogContainer";
import {
  Button,
  DialogContent,
  Typography,
  Grid,
  withStyles,
  createStyles,
  InputBase
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../redux/actions";
import { toast } from "react-toastify";


const BootstrapInput = withStyles(theme =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3)
      }
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: "#FCFCFC",
      border: "2px solid #ced4da",
      fontSize: 16,
      width: "100%",
      padding: "15px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      color: "#9E9E9E",
      '&:focus': {
        border: "2px solid #535353",
      }
    }
  })
)(InputBase);

const styles = theme => ({
  doneTxt: {
    color: "#555555",
    fontSize: 14,
    textTransform: "capitalize"
  },
  verifyP: {
    color: "#757575",
    fontSize: 16,
    marginBottom: theme.spacing(3),
    lineHeight: "1.5"
  }
});

function VerificationDialogue({
  classes,
  open,
  onDialogClose,
  verificationText,
  onBackdropClick
}) {

  const dispatch = useDispatch();
  const email = useSelector(state => state.userDetails.data && state.userDetails.data.Email);
  const passwordVerified = useSelector(state => state.entityPlatform.passwordVerified);
  const isPassVerified = useSelector(state => state.entityPlatform.isPassVerified);
  const [password, setPassword] = useState('');
  const [disableVerify, setDisableVerify] = useState(true);


  useEffect(() => {
    if (passwordVerified) {
      setPassword("");
      setDisableVerify(true);
      onDialogClose(passwordVerified);
      dispatch(actions.updatePasswordVerifiedFlag())
    } else if (isPassVerified && password!=='') {
      toast('Please Enter Correct Password !!')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordVerified]);

  useEffect(() => {
    if (password.length >= 5) {
      setDisableVerify(false);
    } else {
      setDisableVerify(true);
    }
  }, [password])
  

  useEffect(() => {
    return(() => {
      setPassword('')
    })
  },[]);

  // const onPasswordChange = e => {
  //   setPassword(e.target.value);
  //   if (password.length >= 5) {
  //     setDisableVerify(false);
  //   } else {
  //     setDisableVerify(true);
  //   }
  //   console.log('pass change length ', password.length);
  // };

  const onVerify = () => {
    const params = {
      email,
      password
    }
    dispatch(actions.verifyPassword(params))

    if (passwordVerified) {
      setPassword("");
      setDisableVerify(true);
      onDialogClose(passwordVerified);
    }
  }

  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      onVerify();
    }
  }

  const onBackdrop = () => {
    setPassword("");
    dispatch(actions.updatePasswordVerifiedFlag());
    onBackdropClick();
  }
  const VerifyDialogActions = () => (
    <Button
      className={classes.doneTxt}
      disableRipple={true}
      onClick={onVerify}
      color="primary"
      disabled={disableVerify}
    >
      Verify
    </Button>
  );

  return (
    <div>
      <DialogContainer
        open={open}
        title="Verification"
        actions={
          <VerifyDialogActions />
        }
        onBackdropClick={onBackdrop}
      >
        <DialogContent>
          <Typography className={classes.verifyP}>
            {verificationText}
          </Typography>
          <Grid item xs={12}>
            <form>
              <BootstrapInput
                style={{ width: "100%" }}
                type="password"
                placeholder="Enter your password"
                name="password"
                required
                value={password}
                onChange={e => setPassword(e.target.value)}
                onPaste={e => setPassword(e.target.value)}
                onKeyDown={onKeyDown}
              />
            </form>
          </Grid>
        </DialogContent>
      </DialogContainer>
    </div>
  );
}

export default withStyles(styles)(VerificationDialogue);
