import React from "react";
import {
  withStyles,
  styled,
  makeStyles
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

const MyButton = styled(Button)({
  backgroundColor: "#535353",
  border: 0,
  borderRadius: 4,
  color: "#ffffff",
  letterSpacing: 0.42,
  padding: "9px 24px",
  fontSize: 14,
  fontFamily: "inherit",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#535353",
    color: "#fff",
    opacity: 0.8
  }
});
const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "#535353",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    display: "inline-block"
  }
}))(Tooltip);
const useStyles = makeStyles(theme => ({
  disabled: {
    backgroundColor: "#DDDDDD",
    color: "#A4A4A4 !important"
  },
  enabled: {
    backgroundColor: "#535353",
    color: "#ffffff !important"
  }
}));

export default function ButtonStyledWithTooltip({
  disabled,
  btnPadding,
  onClickHandler,
  btnText,
  email
}) {
  const classes = useStyles();
  if (disabled === true) {
    return (
      <LightTooltip
        title={
          <React.Fragment>
            <em>{"Your Email :"}</em> <b>{email}</b>
            {
              "is not verified yet. Please verify your email and click on Check Verification Status to able to generate Entity."
            }
          </React.Fragment>
        }
        arrow
      >
        <span>
          <MyButton
            classes={{ root: (btnPadding && btnPadding.btnPadding) || null }}
            disabled={disabled}
            onClick={onClickHandler}
            className={disabled ? classes.disabled : classes.enabled}
          >
            {btnText}
          </MyButton>
        </span>
      </LightTooltip>
    );
  } else {
    return (
      <MyButton
        classes={{ root: (btnPadding && btnPadding.btnPadding) || null }}
        disabled={disabled}
        onClick={onClickHandler}
        className={disabled ? classes.disabled : classes.enabled}
      >
        {btnText}
      </MyButton>
    );
  }
}
