import React from "react";
import { Card, withStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";

const styles = theme => ({
  root: {
    borderRadius: 4,
    background: "#FFF",
    fontFamily: "inherit",
    boxShadow: "0px 0px 8px #5353531A",
    margin: "1rem 10px 54px",
    padding: "40px 24px 80px",
    [theme.breakpoints.down("sm")]: {
      margin: "1rem auto 1rem"
    }
  }
});

const CardStyled = withStyles(styles)(Card);
function CardContainer({
  children,
  showCustomMargin = false,
  showPadding0 = false
}) {
  const useStyles = makeStyles(theme => ({
    customMargin: {
      marginBottom: "60px"
    },
    summaryPadding: {
      padding: "40px 0 80px"
    }
  }));
  const classes = useStyles();
  return (
    <CardStyled
      className={classnames(
        showCustomMargin ? classes.customMargin : "",
        showPadding0 ? classes.summaryPadding : ""
      )}
    >
      {children}
    </CardStyled>
  );
}

export default CardContainer;
