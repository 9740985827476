import React, { useEffect } from 'react';
import AppointRoleContainer from './AppointRoleContainer';
import { Grid, Typography } from '@material-ui/core';
import classnames from 'classnames';
import BorderButtonStyle from '../../../website/components/BorderButton';
import { nodeholderSteps } from './ManageNodeholder';
import * as actions from "../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";


function AppointRoleConfirmation({ classes, handleNext, activeStep, selectedRole, formData }) {
    
    const dispatch = useDispatch();

    const selectedEntity = useSelector(
    state => state.entityPlatform.selectedEntity
    );
    const { name, email } = formData;
    const { EntityId } = selectedEntity;   
    const isAppointNodeholderSuccess = useSelector(state => state.entityPlatform.isAppointNodeholderSuccess)

    useEffect(() => {
        if (isAppointNodeholderSuccess) {
            handleNext(nodeholderSteps.appointRoleCompletion)
            dispatch(actions.updateIsAppointNodeholderFlag())
        }
    }, [isAppointNodeholderSuccess])

    const onAppoint = () => {
        if (name && selectedRole && email && EntityId) {
           const params = {
               EntityId,
               Email: email,
               Role: selectedRole,
               FullName: name
            };
            dispatch(actions.setAppointNodeHolder(params))
        }
        
    }
    return (
        <AppointRoleContainer classes={classes}>
            <Grid item xs={12} className={classes.my4}>
                <Typography className={classes.roleConfirmationTitle}>You are about to issue a new nomination.</Typography>
                <Typography style={{margin: '0 auto'}} className={classnames(classes.cardDescriptionText, classes.textCenter)}>
                    You will be notified once the designated nodeholder has responded to your request.
                </Typography>                
            </Grid>
            <Grid item xs={12} className={classes.appointBtnCont}>
                {activeStep === nodeholderSteps.appointRoleConfirmation && 
                    <BorderButtonStyle btnStyle={classes.btnStyle} onClickHandler={onAppoint} btnText={'Appoint'}/>
                }
                {activeStep === nodeholderSteps.appointRoleCompletion && 
                    <Typography className={classnames(classes.cardDescriptionText, classes.textCenter, classes.mb4)}>Nomination Sent.</Typography>
                    // <BorderButtonStyle btnStyle={classes.btnStyleNoBorder} btnText={'Nomination Sent.'} />
                }
             </Grid>
        </AppointRoleContainer>
    );
}

export default AppointRoleConfirmation;