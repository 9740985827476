import React, { Fragment } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import classnames from 'classnames';
import { nodeholderSteps } from './ManageNodeholder';
import { useSelector } from "react-redux";


function RoleList({ classes, handleNext }) {
    const roleLimits = useSelector(state => state.entityPlatform.remainingRoleLimits);
    
    return (
        <Fragment>
            <Grid container spacing={0} className={classes.executiveMainCont}>
                <Box style={{ width: '100%' }} className={classes.executiveTitleCont}>
                    <Grid item xs={12}>
                        <Typography className={classes.cardTitleText}>Available Roles</Typography>
                        <Typography className={classnames(classes.cardDescriptionText, classes.RoleListDescriptionText)}>Select the offered role in the entity.</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.roleListCont}>

                        {roleLimits && roleLimits.length > 0 && roleLimits.map((role) => 
                            (role.limit > 0 && <Box className={classnames(classes.roleListBox, classes.executiveMainCont, classes.executiveTitleCont)} key={role.role}>
                                <Typography className={classes.roleTitleText}>{role.role} ({role.limit})</Typography>
                                <Typography className={classes.roleSelectText} onClick={() => handleNext(nodeholderSteps.appointRole, role.role)}>Select</Typography>
                                </Box>
                            )
                        )}
                       
                    </Grid>
                </Box>
            </Grid>
        </Fragment>
    );
}

export default RoleList;