import React, { useState, useEffect, useRef, Fragment } from "react";
import { withRouter } from "react-router-dom";
import {
  // Button,
  // Typography,
  LinearProgress,
  // makeStyles,
  withStyles,
} from "@material-ui/core";
import EntityClass from "./EntityClass";
import EntityType from "./EntityType";
import EntityName from "./EntityName";
// import VerifyMail from "./VerifyMail";
// import NameVerified from "./NameVerified";
import AppointDirectorNodeholders from "./appoint-nodeholders/AppointDirectorNodeholders";
import LandingForm from "./LandingFormPage";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../redux/actions";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
// import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
// import Loader from "../../components/genric/Loader";
// import InitialScreen from "./InitialScreen";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { getLoggedUser } from "../../services/generic";
import "./styles.css";
import "./entityForm.css";
import AppointOtherNodeholders from "./appoint-nodeholders/AppointOtherNodeholders";
import IssueEQT from "./IssueEQT";
import GenerateEQT from "./GenerateEQT";
import EntityTopBar from "../website/components/EntityTopBar";
import AllocateEquity from "./allocate-equity/AllocateEquity";
import InviteEquityNodeholders from "./allocate-equity/InviteEquityNodeholders";
import NodeHolderEQT from "./NodeHolderEQT";
import ContractIncorporation from "./contract-incorporation/ContractIncorporation";
import GenerateEntity from "./contract-incorporation/GenerateEntity";
import Incorporated from "./contract-incorporation/Incorporated";
import Summary from "./Summary";
import IncorporationComplete from "./IncorporationComplete";
import ComingSoon from "./ComingSoon";
import AssociatingNodeHolders from "./appoint-nodeholders/AssociatingNodeHolders";
// import { stat } from "fs";

const THEME = createMuiTheme({
  palette: {
    typography: {
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
    primary: {
      main: "#535353",
    },
  },
});

const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: "#EAEAEA",
  },
  barColorPrimary: {
    backgroundColor: "#707070",
  },
})(LinearProgress);

const nodesList = [
  {
    id: 1,
    name: "Chairman Node",
    completedNode: 0,
    totalNode: 1,
    users: [],
  },
  {
    id: 2,
    name: "Ceo Node",
    completedNode: 0,
    totalNode: 2,
    users: [],
  },
  {
    id: 3,
    name: "Founder Node",
    completedNode: 0,
    totalNode: 5,
    users: [],
  },
];

function BottomStepper(props) {
  const [activeStep, setActiveStep] = useState(0); // React Hooks for using state without class
  const [classType, setClassType] = useState("smart_legal_entity");
  const [nodes, setNodes] = useState(nodesList);
  const [entityType, setEntityType] = useState("decentralized_company");
  const [entityName, setEntityName] = useState("");
  const [eqt, setEqt] = useState(1000000);
  const [generateEQT, setGenerateEQT] = useState(1000);
  const [entityFormEmail, setEmail] = useState("");
  const entityForm = useSelector((state) => state.entityForm);
  const userDetails = useSelector((state) => state.userDetails.data);
  const [
    associatingNodeHolderDetails,
    setAssociatingNodeHolderDetails,
  ] = useState({ name: "", email: "", verify: false });
  const [errorEmail, setErrorEmail] = useState({ value: false, message: "" });
  const [errorEntityName, setErrorEntityName] = useState("");
  const [icons, handleTopIcon] = useState(["formicon"]);
  const [showInviteSuccessDialog, setShowInviteSuccessDialog] = useState(false);
  const [appointNodeHolderDialog, setAppointNodeHolderDialog] = useState({
    showDialog: false,
    selectedNodeIndex: null,
  });
  const [loading, setLoading] = useState(true);
  // const [entityUpperCase, setEntityUpperCase] = useState("");
  const dispatch = useDispatch();

  const nameAvailability = useSelector(
    (state) => state.entityForm.nameAvailability
  );

  // const userExist = useSelector(state => state.entityForm.userExist);
  const isAssociateEmailVarified = useSelector(
    (state) => state.entityForm.isAssociateEmailVarified
  );
  // const isFormSubmitSuccessfully = useSelector(
  //   state => state.entityForm.isFormSubmitSuccessfully
  // );
  const timer = useRef();

  const [appointDirectorNodes, setAppointDirectorNodes] = useState({
    count: 0,
    list: [],
  });

  const [invitedEmails, setInvitedEmails] = useState({
    count: 0,
    list: [],
    value: 0,
  });

  useEffect(() => {
    if (isEmpty(userDetails) && getLoggedUser()) {
      dispatch(actions.profileRequest());
    }
    if (
      !isEmpty(props.location.state) &&
      props.location.state.successfulReserve
    ) {
      setActiveStep(props.location.state.screen);
      setEntityType(props.location.state.EntityType);
      setEntityName(props.location.state.EntityName);
      setEqt(props.location.state.IssueEQT);
      props.history.replace({
        pathname: props.location.pathname,
        state: {},
      });
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(userDetails) && getLoggedUser()) {
      setAssociatingNodeHolderDetails({
        name: userDetails.FirstName,
        email: userDetails.Email,
      });
    }
  }, [userDetails]);

  useEffect(() => {
    if (entityForm.inviteAssociatingNode.needsToBeInvited) {
      dispatch(
        actions.inviteAssociatingNode({
          full_name: associatingNodeHolderDetails.name,
          email: associatingNodeHolderDetails.email,
        })
      );
    }
  }, [entityForm.inviteAssociatingNode.needsToBeInvited]);

  const handleChange = (input) => (e) => {
    if (input === "entName") {
      setEntityName(e.target.value);
      setErrorEntityName("");
      dispatch(actions.resetNameAvailabilityState());
    } else if (input === "email") {
      setEmail(e.target.value);
      setErrorEmail({ value: false, message: "" });
      dispatch(actions.resetEmailVerificationState());
    }
  };
  const onKeyPress = (e) => {
    let val = e;
    let regex = /\b[a-z]/g;
    val = val.toLowerCase().replace(regex, function (letter) {
      return letter.toUpperCase();
    });
    setEntityName(val);
  };

  const getNodeHoldersByRoles = () => {
    const allNodes = [];

    if (
      appointDirectorNodes &&
      appointDirectorNodes.list &&
      appointDirectorNodes.list.length > 0
    ) {
      appointDirectorNodes.list.map((node) => {
        const newNode = {};
        newNode.NodeHolderName = node.name;
        newNode.NodeHolderEmail = node.email;
        newNode.NodeHolderRole = "Director";
        return allNodes.push(newNode);
      });
    }

    nodes.map((node) => {
      if (node.users && node.users.length > 0) {
        node.users.map((user) => {
          const newUser = {};
          newUser.NodeHolderName = user.name;
          newUser.NodeHolderEmail = user.email;
          newUser.NodeHolderRole = node.name.split(" ")[0];
          allNodes.push(newUser);
        });
      }
    });

    return allNodes;
  };

  const getEquityAllocation = () => {
    const EQTlist = invitedEmails.list.slice();
    const modifiedEQTlist = [];

    if (EQTlist.length > 0) {
      EQTlist.map((eqtNode) => {
        const newEqtNode = {};
        newEqtNode.NodeHolderEmail = eqtNode.inviteNodeHolderEmail;
        newEqtNode.EquityPercentage = eqtNode.nodeEqtValue;
        modifiedEQTlist.push(newEqtNode);
      });
    }

    return modifiedEQTlist;
  };

  const nameFormate = (name) => {
    const formatedName = name
      .toLowerCase()
      .replace(/_/g, " ")
      .replace(/(?: |\b)(\w)/g, function (key) {
        return key.toUpperCase();
      });
    return formatedName;
  };
  const onSubmit = () => {
    const NodeHolderRoles = getNodeHoldersByRoles();
    const EquityAllocation = getEquityAllocation();

    const EntityClassName = nameFormate(classType);

    const EntityType = nameFormate(entityType);

    const finalData = {
      EntityClassName,
      EntityType,
      EntityName: entityName.toLowerCase(),
      AssociatingNodeName: associatingNodeHolderDetails.name,
      AssociatingNodeEmail: associatingNodeHolderDetails.email,
      // Email: entityFormEmail,
      NodeHolderRoles,
      EquityBase: eqt,
      EquityIssued: generateEQT,
      EquityAllocation,
      // user_id:
    };
    dispatch(actions.submitEntityFormRequest(finalData));
    if (!entityName) {
      setErrorEntityName("Please fill the field");
      // old flow code
      // }
      // else if (!entityFormEmail) {
      //   console.log('form submitted');
      //   setErrorEmail({ value: true, message: "Please fill the field" });
      // } else if (
      //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(entityFormEmail)
      // ) {
      //   setErrorEmail({ value: true, message: "Invalid Email." });
      // } else {
      //   dispatch(
      //     actions.submitEntityFormRequest(finalData)
      //   );
    }
    // if (getLoggedUser() && entityName) {
    //   dispatch(
    //     actions.submitEntityFormRequest({
    //       EntityClassName: classType,
    //       EntityName: entityName.toLowerCase(),
    //       EntityType: entityType,
    //       Email: userDetails.Email
    //     })
    //   );
    // }
    // handleNext();
  };

  const onNameCheck = () => {
    if (entityName && entityName.length > 3) {
      const EntityClassName = nameFormate(classType);
      const EntityType = nameFormate(entityType);

      dispatch(
        actions.getNameAvailability({
          EntityClassName,
          EntityName: entityName.toLowerCase(),
          EntityType,
        })
      );
    }
  };

  // const userExistCheck = () => {
  //   if (entityFormEmail && entityFormEmail.length > 3) {
  //     dispatch(
  //       actions.getUserExist({
  //         email: entityFormEmail
  //       })
  //     );
  //   }
  // };

  const handleIcons = (name) => {
    handleTopIcon((old) => [...old, name]);
  };

  // const removeIcons = index => {
  //   setActiveStep(index - 1);
  //   let icon = cloneDeep(icons);
  //   icon.splice(index, 9e9);
  //   handleTopIcon(icon);
  // };

  useEffect(() => {
    document.body.classList.add("entity-formation-bg");
    if (entityForm.isSuccess) {
      // setActiveStep(prevActiveStep => prevActiveStep + 1);
      // if (getLoggedUser()) {
      //   setActiveStep(5);
      // }
      handleTopIcon(["formicon"]);
    } else {
      handleReset();
    }
  }, [entityForm.isSuccess]);

  useEffect(() => {
    // const [classType, setClassType] = useState("smart_legal_entity");
    // const [nodes, setNodes] = useState(nodesList);
    // const [entityType, setEntityType] = useState("decentralized_company");
    // const [entityName, setEntityName] = useState("");
    // const [eqt, setEqt] = useState(1000000);
    // const [generateEQT, setGenerateEQT] = useState(1000);

    if (
      !isEmpty(props.location.state) &&
      props.location.state.successfulReserve
    ) {
      setActiveStep(props.location.state.screen);
      setEntityType(props.location.state.EntityType);
      setEntityName(props.location.state.EntityName);
      setEqt(props.location.state.BaseEQT);
      setGenerateEQT(props.location.state.IssueEQT);
      props.history.replace({
        pathname: props.location.pathname,
        state: {},
      });
    } else {
      timer.current = window.setTimeout(removeLoading, 3000);
      return () => {
        window.clearTimeout(timer.current);
        document.body.classList.remove("entity-formation-bg");
        dispatch(actions.resetEntityState());
      };
    }
  }, []);

  const removeLoading = () => {
    setLoading(false);
  };

  // const steps = getSteps();

  // Next Step
  function handleNext() {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  function goToAllocateEqtPage() {
    setActiveStep(9);
  }
  function handlerevise() {
    setActiveStep(3);
  }
  // Previous Step
  function handleBack() {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }
  // Reset to 1st Step
  //finish button click start
  function handleFinish() {
    // props.history.push("/");
    if (getLoggedUser()) {
      props.history.push("/entity-platform");
    } else {
      props.history.push("/login", { to: "/entity-platform" });
    }
  }
  //finish button click end
  //entity panel click start
  function handleEntityPanel() {
    if (getLoggedUser()) {
      props.history.push("/entity-platform");
    } else {
      props.history.push("/login", { to: "/entity-platform" });
    }
  }
  //entity panel click end
  function handleReset() {
    dispatch(actions.resetEntityState());
    setActiveStep(0);
    setClassType("smart_legal_entity");
    setEntityType("decentralized_liability_company");
    setEntityName("");
    setEmail("");
    handleTopIcon(["formicon"]);
  }

  const associatingNodeHolderValue = (user) => {
    setAssociatingNodeHolderDetails({
      name: user.nomineeFullName,
      email: user.nomineeEmailAddress,
      verify: true,
    });
  };
  // const altLogin = () => {
  //   props.history.push({
  //     pathname: "/login",
  //     state: {
  //       EntityClassName: classType,
  //       EntityName: entityName.toLowerCase(),
  //       EntityType: entityType
  //     }
  //   });
  // };

  const onDirectorAppointment = (directorNode) => {
    const appointDirectorNodesResult =
      appointDirectorNodes.list.length &&
      appointDirectorNodes.list.find(
        (list) => list.email === directorNode.email
      );
    if (appointDirectorNodesResult) {
      setErrorEmail({
        value: true,
        message: "Email already exist.Please enter different email.",
      });
      return;
    } else {
      const { list } = appointDirectorNodes;
      const listClone = list.slice();
      listClone.push(directorNode);
      setAppointDirectorNodes({
        count: listClone.length,
        list: listClone,
      });
      toggleInviteSuccessDialogPopup(true);
      return;
    }
  };

  const toggleInviteSuccessDialogPopup = (value) => {
    setShowInviteSuccessDialog(value);
    if (!value) {
      setErrorEmail({ value: false, message: "" });
    }
  };

  const addInvite = (email) => {
    // const { list } = appointDirectorNodes;
    // const listClone = list.slice();
    // listClone.push(email);
    // setAppointDirectorNodes({
    //   count: listClone.length,
    //   list: listClone
    // });
    handleBack();
  };

  const addAssign = (email) => {
    const { list } = invitedEmails;
    const listClone = list.slice();
    //add node eqt value property to object
    email["nodeEqtValue"] = 0;
    listClone.push(email);
    setInvitedEmails({
      count: listClone.length,
      list: listClone,
    });
  };

  const removeEmailFromList = (emailRemove) => {
    let { list } = invitedEmails;
    let listClone = list.filter(
      (s) => s.inviteNodeHolderEmail !== emailRemove.inviteNodeHolderEmail
    );
    setInvitedEmails({
      count: listClone.length,
      list: listClone,
    });
  };
  const nodesListChange = (name, email) => {
    const nodesClone = nodes.slice();
    const selectedNodeIndex = appointNodeHolderDialog.selectedNodeIndex;
    const nodeUser = nodes[selectedNodeIndex].users.slice();
    const nodeUserResult = nodesClone.some((node) => {
      return node.users.find((user) => user.email === email);
    });
    const appointDirectorNodesResult =
      appointDirectorNodes.list &&
      appointDirectorNodes.list.find((list) => list.email === email);
    const associatingNodeHolderResult =
      associatingNodeHolderDetails.email === email;
    if (
      associatingNodeHolderResult ||
      nodeUserResult ||
      appointDirectorNodesResult
    ) {
      setErrorEmail({
        value: true,
        message: "Email already exist.Please enter different email.",
      });
      return;
    } else {
      nodeUser.push({
        name,
        email,
      });
      nodesClone[selectedNodeIndex] = {
        ...nodesClone[selectedNodeIndex],
        completedNode: nodesClone[selectedNodeIndex].users.length + 1,
        users: nodeUser,
      };
      if (nodesClone && nodesClone !== nodes) {
        setNodes(nodesClone);
      }
      toggleAppointDialog({ showDialog: false, selectedNodeIndex: null });
    }
  };

  const toggleAppointDialog = (value) => {
    setErrorEmail({ value: false, message: "" });
    setAppointNodeHolderDialog(value);
  };

  const associateUserCheck = (mail) => {
    dispatch(
      actions.getUserExist({
        email: mail,
      })
    );

    handleNext();
  };

  const isEmailVarifiedOrNot = (mail) => {
    dispatch(
      actions.isAssociateEmailVarified({
        email: mail,
      })
    );
  };

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <LandingForm handleNext={handleNext} handleTopIcon={handleIcons} />
        );
      case 1:
        return (
          <EntityClass
            handleNext={handleNext}
            handleBack={handleBack}
            step="1"
            setClassType={setClassType}
            classType={classType}
            setEntityType={setEntityType}
            handleTopIcon={handleIcons}
          />
        );
      case 2:
        return (
          <EntityType
            handleNext={handleNext}
            handleBack={handleBack}
            step="2"
            entityType={entityType}
            classType={classType}
            setEntityType={setEntityType}
            handleTopIcon={handleIcons}
          />
        );
      case 3:
        return (
          <EntityName
            handleNext={handleNext}
            handleBack={handleBack}
            step="3"
            values={{
              entityName,
              entityFormEmail,
              errorEmail,
              errorEntityName,
            }}
            entityType={entityType}
            classType={classType}
            handleChange={handleChange}
            onNameCheck={onNameCheck}
            nameAvailability={nameAvailability}
            isLoggedIn={getLoggedUser()}
            onSubmit={onSubmit}
            isLoading={entityForm.isLoading}
            isSuccess={entityForm.isSuccess}
            onKeyPress={onKeyPress}
          />
        );
      case 4:
        return (
          <Fragment>
            <AssociatingNodeHolders
              handleNext={handleNext}
              associatingNodeHolder={associatingNodeHolderValue}
              associatingNodeHolderDetails={associatingNodeHolderDetails}
              isLoggedIn={getLoggedUser()}
            />
          </Fragment>
        );
      case 5:
        return (
          <AppointDirectorNodeholders
            appointedNodes={appointDirectorNodes}
            onInvite={onDirectorAppointment}
            handleNext={handleNext}
            emailError={errorEmail}
            showInviteSuccessDialog={showInviteSuccessDialog}
            inviteDialogCloseHandler={toggleInviteSuccessDialogPopup}
          />
        );
      case 6:
        return (
          <AppointOtherNodeholders
            handleNext={handleNext}
            nodesList={nodes}
            nodesListChange={nodesListChange}
            emailError={errorEmail}
            appointNodeHolderDialogValue={appointNodeHolderDialog}
            toggleAppointDialog={toggleAppointDialog}
          />
          // <NameVerified
          //   entityType={entityType}
          //   classType={classType}
          //   entityName={entityName}
          //   handleReset={handleReset}
          //   history={props.history}
          // />
        );
      case 7:
        return (
          <IssueEQT
            handleNext={handleNext}
            classType={classType}
            values={eqt}
          />
        );
      case 8:
        return (
          <GenerateEQT
            handleNext={handleNext}
            generateEQT={generateEQT}
            setGenerateEQT={setGenerateEQT}
          />
        );
      case 9:
        return (
          <AllocateEquity
            emails={invitedEmails.list}
            onAdd={handleNext}
            handleNext={() => setActiveStep(activeStep + 2)}
          />
          // <NameVerified
          //   entityType={entityType}
          //   classType={classType}
          //   entityName={entityName}
          //   handleReset={handleReset}
          //   history={props.history}
          // />
        );
      case 10:
        return (
          // <Fragment>
          <InviteEquityNodeholders
            emails={invitedEmails}
            emailError={errorEmail}
            onInviteSuccess={addInvite}
            onAssignSuccess={addAssign}
            removeEmailFromList={removeEmailFromList}
          />
          // <NameVerified
          //   entityType={entityType}
          //   classType={classType}
          //   entityName={entityName}
          //   handleReset={handleReset}
          //   history={props.history}
          // />
          // </Fragment>
        );

      case 11:
        return (
          <NodeHolderEQT handleNext={handleNext} emails={invitedEmails.list} />
        );

      case 12:
        return (
          <ContractIncorporation
            handleNext={handleNext}
            entityName={entityName}
            entityType={entityType}
            nodes={nodes}
            nodeHolderName={invitedEmails.list}
            goToAllocateEqtPage={goToAllocateEqtPage}
            handlerevise={handlerevise}
            isEmailVarifiedOrNot={isEmailVarifiedOrNot}
            AssociatingNodeEmail={associatingNodeHolderDetails.email}
          />
        );

      case 13:
        return (
          // <GenerateEntity
          //   onSubmit={onSubmit}
          //   isEmailVarifiedOrNot={isEmailVarifiedOrNot}
          //   associateUserCheck={associateUserCheck}
          //   isAssociateEmailVarified={isAssociateEmailVarified}
          //   associateEmail={associatingNodeHolderDetails.email}
          //   handleNext={handleNext}
          // />
          <ComingSoon />
        );

      // case 14:
      //   return <Incorporated handleNext={handleNext} />;

      // case 15:
      //   return (
      //     <Summary
      //       handleNext={handleNext}
      //       entityName={entityName}
      //       entityType={entityType}
      //       classType={classType}
      //     />
      //   );

      // case 16:
      //   return (
      //     <IncorporationComplete
      //       handleFinish={handleFinish}
      //       handleEntityPanel={handleEntityPanel}
      //       entityName={entityName}
      //       entityType={entityType}
      //     />
      //   );

      default:
        return "Uknown stepIndex";
    }
  }
  return (
    <TransitionGroup>
      <CSSTransition
        // key={this.state.selected.id}
        timeout={1000}
        classNames="move"
      >
        <MuiThemeProvider theme={THEME}>
          <EntityTopBar
            activeStep={activeStep}
            handleNext={handleNext}
            handleBack={handleBack}
            appointedNodes={appointDirectorNodes.count}
            associatingNodeHolderDetails={associatingNodeHolderDetails}
            invitedEmails={invitedEmails.count}
          />
          <ColorLinearProgress
            variant="determinate"
            value={activeStep * 6.25}
          />
          <div className="entity-formation-container">
            {/* {loading ? (
              <InitialScreen />
            ) : (
              
            )} */}

            <div className="top-icon-container">
              {/* {icons.map((data, i) => {
                  return (
                    <div
                      className={`icon-class ${data}`}
                      onClick={
                        i != 0
                          ? e => {
                              removeIcons(i);
                            }
                          : null
                      }
                    ></div>
                  );
                })} */}
              {/* {<Typography>{getStepContent(activeStep)}</Typography> } */}
              {getStepContent(activeStep)}
            </div>
          </div>
        </MuiThemeProvider>
      </CSSTransition>
    </TransitionGroup>
  );
}

export default withRouter(BottomStepper);

// <VerifyMail
//   handleNext={handleNext}
//   handleBack={handleBack}
//   step="4"
//   values={{
//     entityName,
//     entityFormEmail,
//     errorEmail
//   }}
//   entityType={entityType}
//   classType={classType}
//   onSubmit={onSubmit}
//   handleChange={handleChange}
//   userExistCheck={userExistCheck}
//   userExist={userExist}
//   isLoading={entityForm.isLoading}
//   isSuccess={entityForm.isSuccess}
//   altLogin={altLogin}
// />
