import React, { Fragment, useState, useEffect } from 'react';
import ManageEntitiesContainer from '../../ManageEntity/ManageEntitiesContainer';
import EntitiesCardContainer from '../AssociatedEntities/EntitiesCardContainer';
import { Grid, Typography, Button } from '@material-ui/core';
import ButtonStyled from '../../../../website/components/ButtonStyled';
import { manageEntityPages } from '../../ManageEntity/ManageEntitySteps';
import AddBoxIcon from '@material-ui/icons/AddBox';
import classnames from 'classnames';
import TuneIcon from '@material-ui/icons/Tune';
import VerificationDialogue from '../../VerificationDialogue';
// import { equityPages } from './ManageEquityContainer';
import EqtDialog from './EqtDialog';
// import { equities } from './ManageEquityContainer';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from "../../../../../redux/actions";



const initialEquityForm = {
    Nodecode: '',
    EquityPercentage: '',
    id: '',
    nodeCodeError: ''
};

let amountValid = false, nodecodeValid = false;
let enableSubmitBtn = false;

export default function AllocateEquity({ classes, setManageEntityStep}) {
    const dispatch = useDispatch();
    const selectedEntity = useSelector(
    state => state.entityPlatform.selectedEntity
    );
    // const error = useSelector(state => state.entityPlatform.isAllocatedError);
    const [showAddEquity, setShowAddEquity] = useState(false);
    const [showEditEquity, setShowEditEquity] = useState(false);
    const [showVerification, setShowVerification] = useState(false);
    const [equityForm, setEquityForm] = useState(initialEquityForm);
    const [enableSubmit, setEnableSubmit] = useState(false);
    const [nodeholderList, setNodeholderList] = useState([]);
    const [unAllocated, setUnAllocated] = useState(100);
    const [allocated, setAllocated] = useState(0);
   
    useEffect(() => {
        enableSubmitBtn = nodecodeValid && amountValid
        setEnableSubmit(enableSubmitBtn);
    }, [equityForm]);

    useEffect(() => {
        let total = nodeholderList.reduce((prev, node) => parseFloat(prev) + parseFloat(node.EquityPercentage), 0);
        setAllocated(total);
    }, [nodeholderList, equityForm]);

    useEffect(() => {
        setUnAllocated(parseFloat(100) - allocated);
    }, [allocated])


    const onFormChange = (e, action) => {
        if (action === 'add') {
            const id = Math.floor(Math.random() * 50) + 1;
            setEquityForm({
                ...equityForm,
                [e.target.name]: e.target.value,
                id
            });
        } else if (action === 'edit') {
            setEquityForm({
                ...equityForm,
                [e.target.name]: e.target.value
            });
        }

        if (e.target.name === 'Nodecode') {
            if (e.target.value.length === 6 && e.target.value !== '') nodecodeValid = true;
            else nodecodeValid = false;
        } else if (e.target.name === 'EquityPercentage') {
            if (e.target.value.length && e.target.value !== '' && e.target.value <= 100 && e.target.value > 0) amountValid = true;
            else amountValid = false;
        }

    };

    const checkNodecode = (nodecode) => {
        let sameNode=[];
        if(nodeholderList.length>0){
           sameNode = nodeholderList.filter(node => node.Nodecode === nodecode);
        }
        if(sameNode.length>0){
            return true;
        }else {
            return false;
        }
    }

    const submitForm = () => {
        const nodecodeExistInList = checkNodecode(equityForm.Nodecode);

        if(!nodecodeExistInList) {
            setShowAddEquity(false);
            let nodeholderListClone = nodeholderList.slice();
            nodeholderListClone.push(equityForm);
            setNodeholderList(nodeholderListClone);
            // clearForm
            setEquityForm(initialEquityForm);
            setEnableSubmit(false);
            amountValid = false; nodecodeValid = false;
        } else {
            setEquityForm({
                ...equityForm,
                nodeCodeError: `user with #${equityForm.Nodecode} is already exist!!`
            })
        }
    }


    const onNodeChange = (action, id) => {

        setShowEditEquity(false);
        let nodeholderListClone = nodeholderList.slice();
        const node = nodeholderListClone.find((node) => node.id === id);
        const index = nodeholderListClone.indexOf(node);

        if(action === 'remove') {
            nodeholderListClone.splice(index, 1);
        } else if(action === 'update') {
            nodeholderListClone[index].Nodecode = equityForm.Nodecode;
            nodeholderListClone[index].EquityPercentage = equityForm.EquityPercentage;
        }
        setNodeholderList(nodeholderListClone);
        setEquityForm(initialEquityForm);

    }


    const changeNodeEqt = (node) => {
        setEquityForm(node);
        amountValid = true; nodecodeValid = true;
        setShowEditEquity(true);
    }


    const AddEquityBtn = () =>
        (
            <Fragment>
                <Button
                    disabled={!enableSubmit}
                    onClick={() => submitForm()}
                    style={{ textTransform: 'capitalize' }}
                >
                    Add
                </Button>
            </Fragment>
        );

    const EditEquityBtn = ({id}) =>
        (
            <Fragment>
                <Button
                    onClick={() => onNodeChange('remove', id)}
                    style={{ textTransform: 'capitalize' }}
                >
                    Remove
                </Button>
                <Button
                    disabled={!enableSubmit}
                    onClick={() => onNodeChange('update', id)}
                    style={{ textTransform: 'capitalize' }}
                >
                    Update
                </Button>
               
            </Fragment>
        );

    const onVerify = (password) => {
        
        if(password) {
            setShowVerification(false);
            const Allocations = [];
            if(nodeholderList.length>0){
                nodeholderList.map((node) => {
                    const obj = {
                        Nodecode: node.Nodecode,
                        EquityPercentage: node.EquityPercentage
                    }
                    Allocations.push(obj);
                })
            }
            if (Allocations.length > 0) {
                const params = {
                    EntityId: selectedEntity.EntityId,
                    Allocations
                }
                dispatch(actions.allocateEqt(params))
            }
            
        }
    }

    const closeEqt = () => {
        setEquityForm(initialEquityForm); 
        setEnableSubmit(false);
    }

    return (
        <ManageEntitiesContainer
            bottomText={'Continue to the entity\'s panel'}
            title={'Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum'}
            setManageEntityStep={setManageEntityStep}
            manageEntityPages={manageEntityPages}
        >
            <EntitiesCardContainer styleClasses={classes} >
                <Grid className={classes.cardContainerPAdding} container spacing={0} direction="row" alignItems="center">
                    <Typography className={classes.companyName}>Nodeholder Equity</Typography>
                    <Grid item xs={10} className={classes.companyDescName}>Your current equity stake in the entity.</Grid>
                </Grid>
                <Grid container spacing={0} direction="row" alignItems="center" style={{ padding: '10px 0px' }}>
                    <Grid item xs={4} className={classes.textCenter}>
                        <Typography className={classes.companyDescName}>Total Equity</Typography>
                        <Typography className={classes.companyName}>{selectedEntity && selectedEntity.EquityIssued} EQT</Typography>
                    </Grid>
                    <Grid item xs={4} className={classes.textCenter}>
                        <Typography className={classes.companyDescName}>Unallocated</Typography>
                        <Typography className={classes.companyName}>{unAllocated}%</Typography>
                    </Grid>
                    <Grid item xs={4} className={classes.textCenter}    >
                        <Typography className={classes.companyDescName}>Allocated</Typography>
                        <Typography className={classes.companyName}>{allocated}%</Typography>
                    </Grid>
                </Grid>
            </EntitiesCardContainer>
            <EntitiesCardContainer styleClasses={classes} >
                <Grid className={classes.cardContainerPAdding} container spacing={0} direction="row" alignItems="center">
                    <Typography className={classes.companyName}>Allocate Equity</Typography>
                    <Grid item xs={10} className={classes.companyDescName}>Add nodeholders and the amount of equity you wish to assign to them.</Grid>
                </Grid>

                {nodeholderList.length > 0 && <Grid className={classes.cardContainerPAdding} container spacing={0}>

                    {nodeholderList.map((node) => (
                        <Grid container spacing={0} direction="row" alignItems="center" className={classes.equityNodeList} key={node.Nodecode}>
                            <Grid item xs={10} className={classnames(classes.equityNodeListLeft, classes.borderRight1)}>
                                <Grid container spacing={0} direction="row" alignItems="center">
                                    <Grid item xs={6}>
                                        <Typography className={classes.equityNodeText}>#{node.Nodecode}</Typography>
                                    </Grid>
                                    <Grid item xs={6} className={classes.textRight}>
                                        <Typography className={classes.equityNodeText}>{node.EquityPercentage}% EQT</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={2} className={classnames(classes.equityNodeListRight, classes.displayCenter)}>
                                <TuneIcon color="primary"
                                    onClick={() => changeNodeEqt(node)}
                                />
                            </Grid>
                        </Grid>
                    ))}

                </Grid>}

                <Grid className={classes.cardContainerPAdding} container spacing={0} direction="row" alignItems="center" justify="center">
                    <Grid item xs={12} className={classes.textCenter}>
                        <div className={classnames(classes.displayCenter)}>
                            <span className={classes.addNodeholderText}>Add Nodeholder</span>
                            <AddBoxIcon
                                disabled={allocated > 100}
                                className={classes.plusIcon}
                                color="primary"
                                onClick={() => setShowAddEquity(true)} />
                        </div>
                    </Grid>
                    <ButtonStyled
                        disabled={(nodeholderList.length === 0 || unAllocated<0)} // (!isModified ||)
                        btnPadding={classes}
                        onClickHandler={() => setShowVerification(true)}
                        btnText={'Allocate'} />
                </Grid>
                <VerificationDialogue
                    open={showVerification}
                    onDialogClose={onVerify}
                    verificationText="Please enter your password in order to allocate the eqt."
                    onBackdropClick={() => setShowVerification(false)}
                />
                <EqtDialog
                    equityForm={equityForm}
                    open={showAddEquity}
                    actionBtn={<AddEquityBtn />}
                    classes={classes}
                    onFormChange={(e) => onFormChange(e, 'add')}
                    onBackdropClick={() => 
                        {
                            setShowAddEquity(false);
                            closeEqt();
                        }
                    }
                />
                <EqtDialog
                    equityForm={equityForm}
                    open={showEditEquity}
                    actionBtn={<EditEquityBtn id={equityForm.id} />}
                    classes={classes}
                    onFormChange={(e) => onFormChange(e, 'edit')}
                    onBackdropClick={() => 
                        {
                            setShowEditEquity(false);
                            closeEqt();
                        }
                    }
                />

            </EntitiesCardContainer>
        </ManageEntitiesContainer>
    );
}