import React from 'react';
import { useSelector } from 'react-redux';
import NodeHoldersContainer from '../website/components/NodeHoldersContainer';
import NodeHoldersTypography from '../website/components/NodeHoldersTypography';
import { withStyles, Grid, Link } from '@material-ui/core';
import ButtonStyled from '../website/components/ButtonStyled';
import classnames from 'classnames';

// Import SVG Icons
import { ReactComponent as DLC } from '../../assets/icons/entity-type/dlc.svg';

const styles = theme => ({
  entityStatus: {
    letterSpacing: 0.24,
    color: '#9E9E9E',
    fontWeight: '500',
    fontSize: 16,
    textAlign: 'left',
    marginTop: 60,
    marginBottom: 12
  },
  associated: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 1px 4px #00000029',
    padding: '15px 0',
    letterSpacing: 0,
    fontWeight: '500',
    color: '#25D164',
    fontSize: 20,
    borderRadius: 4
  },
  notAssociated: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 1px 4px #00000029',
    padding: '15px 0',
    fontWeight: '500',
    letterSpacing: 0,
    color: '#ff2702',
    fontSize: 20,
    borderRadius: 4
  },
  associatedDesc: {
    letterSpacing: 0.42,
    color: '#9E9E9E',
    fontSize: 14,
    marginTop: 16,
    marginBottom: 40
  },
  timesTamp: {
    letterSpacing: 0.21,
    color: '#CCCCCC',
    fontSize: 14,
    marginBottom: 16,
    textAlign: 'left'
  },
  mT60: {
    marginTop: 60,
    marginBottom: 30
  },
  btnPadding: {
    padding: '9px 53px'
  },
  mB0: {
    marginBottom: 0
  },
  imgWidth: {
    height: 100,
    strokeWidth: 4,
    stroke: '#535353'
  }
});

function IncorporationComplete({
  classes,
  handleFinish,
  handleEntityPanel,
  entityName,
  entityType
}) {
  const entityFormData = useSelector(state => state.entityForm.data);
  let entityTypeUpper = entityType
    .toLowerCase()
    .replace(/_/g, ' ')
    .replace(/(?: |\b)(\w)/g, function(key, p1) {
      return key.toUpperCase();
    });
  // const timeStamp=() =>{
  let timestamp = new Date();
  var dd = String(timestamp.getDate()).padStart(2, '0');
  var mm = String(timestamp.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yy = timestamp
    .getFullYear()
    .toString()
    .substr(-2);
  timestamp = mm + '/' + dd + '/' + yy;
  // }
  return (
    <div>
      <NodeHoldersContainer
        pageTitle={'Incorporation Complete'}
        showFooterText={false}
      >
        <DLC className={classes.imgWidth} marginBottom="8px" />
        <NodeHoldersTypography
          showMb={true}
          title={entityName}
          cardDescription={entityTypeUpper}
        />
        <div className={classes.entityStatus}>Entity Status</div>
        {entityFormData.AssociatedContract && (
          <Grid className={classes.associated}>Associated</Grid>
        )}
        {!entityFormData.AssociatedContract && (
          <Grid className={classes.notAssociated}>Not Associated.</Grid>
        )}
        <div className={classes.associatedDesc}>
          Under a Contract of Incorporation
        </div>
        <div className={classes.timesTamp}>Timestamp: {timestamp}</div>
        <div className={classes.timesTamp}>Association Stamp: 99gj5933k2</div>

        <Grid className={classes.mT60}>
          <ButtonStyled
            btnPadding={classes}
            btnText={'Finish'}
            onClickHandler={handleFinish}
          />
        </Grid>
        <Grid className={classnames(classes.associatedDesc, classes.mB0)}>
          <div>
            Continue to the{' '}
            <Link onClick={handleEntityPanel} color="inherit">
              {' Entity Panel'}
            </Link>{' '}
            to manage your new decentralized company.
          </div>
        </Grid>
      </NodeHoldersContainer>
    </div>
  );
}

export default withStyles(styles)(IncorporationComplete);
