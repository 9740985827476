import React, { Fragment, useState } from "react";
import AlterationContainer from "./AlterationContainer";
import {
  Grid,
  withStyles,
  FormControlLabel,
  Checkbox,
  Typography
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const styles = theme => ({
  TnCBlock: {
    width: "100%",
    marginBottom: "20px",
    border: "1px solid #DADCE0",
    padding: "10px 0 0 15px",
    borderRadius: "5px",
    margin: "0 0 20px 0 !important"
  },
  checkboxTxt: {
    color: "#707070",
    fontSize: 14
  },
  checkboxPera: {
    color: "#707070",
    fontSize: 14,
    paddingLeft: 26,
    lineHeight: "23px",
    marginTop: 2,
    paddingBottom: 12,
    borderBottom: "1px solid #DADCE0",
    marginBottom: 16,
    "&:last-child": {
      border: 0,
      marginBottom: 7
    }
  },
  formControlCss: {
    paddingTop: 1,
    color: "#9E9E9E",
    paddingBottom: 0
  }
});

function ReasonsForAlteration({ classes, handleNext, setManageEntityStep }) {
  const [acceptAlteration, setAcceptAlteration] = useState(false);
  const [alteration, setAlteration] = useState([
    {
      id: 1,
      label: "Technical",
      labelDesc:
        "Choose a name better aligned with the entity's underlying activity."
    },
    {
      id: 1,
      label: "Stractural",
      labelDesc: "Due to changes in the operation's entity infrastructure."
    },
    {
      id: 1,
      label: "Accuisitional",
      labelDesc: "Modify the entity name due to change of ownership."
    },
    {
      id: 1,
      label: "Other",
      labelDesc: "Reason for change not listed above."
    }
  ]);

  const proceedBtnEnable = (e, index) => {
    setAlteration([...alteration], alteration[index].checked = e.target.checked)
    const alterationCheckedResults = alteration.filter(({ checked }) => checked === true)
    setAcceptAlteration(alterationCheckedResults.length ? true : false)

  }

  return (
    <Fragment>
      <AlterationContainer
        handleNext={handleNext}
        btnText={"Abort"}
        containedBtnText={"Proceed"}
        setManageEntityStep={setManageEntityStep}
        AcceptTerms={acceptAlteration}
        subTitle={"Select the reason for changing the entity name."}
        title={"Reasons for Alteration"}
      >
        <Grid className={classes.TnCBlock} cols={1}>
          <Grid>
            {alteration.map((alteration, i) => (
              <Fragment key={i}>
                <FormControlLabel
                  style={{
                    marginRight: 0,
                    marginBottom: 0,
                    alignItems: "flex-start"
                  }}
                  control={
                    <Checkbox
                      // style={{ paddingTop: 1, color: '#9E9E9E' }}
                      className={classes.formControlCss}
                      color="default"
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      value="checkedI"
                      onChange={(event) => proceedBtnEnable(event, i)}
                    />
                  }
                  label={
                    <Typography variant="h6" className={classes.checkboxTxt}>
                      {alteration.label}
                    </Typography>
                  }
                />
                <Grid className={classes.checkboxPera}>
                  {alteration.labelDesc}
                </Grid>
              </Fragment>
            ))}
          </Grid>
        </Grid>
      </AlterationContainer>
    </Fragment>
  );
}
export default withStyles(styles)(ReasonsForAlteration);
