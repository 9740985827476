import React, { useState, useEffect } from "react";
import AlterationContainer from "./AlterationContainer";
import { Grid, withStyles, createStyles } from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import ButtonStyled from "../../../../website/components/ButtonStyled";
import VerificationDialogue from "../../VerificationDialogue";
import * as actions from "../../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";

const BootstrapInput = withStyles(theme =>
  createStyles({
    root: {
      marginTop: theme.spacing(1) + 2,
      marginBottom: theme.spacing(1) + 2,
      width: "100%"
    },
    input: {
      textAlign: "center",
      borderRadius: 4,
      position: "relative",
      backgroundColor: "#FCFCFC",
      border: "2px solid #DAE1E7",
      fontSize: 20,
      width: "100%",
      padding: "15px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      color: "#757575",
      '&:focus': {
        border: "2px solid #535353",
      }
    }
  })
)(InputBase);

const styles = themes => ({
  mainContainer: {
    border: "1px solid #DADCE0",
    borderRadius: "5px",
    padding: themes.spacing(3, 2)
  },
  infoText: {
    fontSize: 16,
    color: "#757575",
    textAlign: "center"
  },
  confirmationText: {
    lineHeight: "1.5",
    fontSize: 14,
    color: "#9E9E9E",
    textAlign: "center"
  },
  confirmBtnContainer: {
    marginTop: themes.spacing(10),
    marginBottom: themes.spacing(3),
    textAlign: "center"
  }
});

function ConfirmAlteration({
  handleNext,
  setManageEntityStep,
  newEntityName,
  classes
}) {
  const [showVerifyDialogue, setShowVerifyDialogue] = useState(false);
  const dispatch = useDispatch();

  const selectedEntity = useSelector(
    state => state.entityPlatform.selectedEntity
  );
  const oldEntityName = useSelector(
    state => state.entityPlatform.oldEntityName
  );

  useEffect(() => {
      if (oldEntityName !== '') {
        console.log('oldEntityName', oldEntityName);
        handleNext();
      }
  }, [selectedEntity, oldEntityName]);   //selectedEntity is only change after CHANGE_ENTITY_NAME_SUCCESS 

  const openVerification = () => {
    setShowVerifyDialogue(true);
  };

  function onVerify(password) {

    if (password) {
      const data = {
        EntityName: newEntityName,
        selectedEntity,
      }
      dispatch(actions.changeEntityName(data));
      setShowVerifyDialogue(false);
      // handleNext();
      // console.log('oldEntityName', oldEntityName);

      // if (oldEntityName !== '') {
      //   handleNext();
      // }
    }
  };

  return (
    <div>
      <AlterationContainer
        linkBtnText={"Abort name change"}
        handleNext={handleNext}
        setManageEntityStep={setManageEntityStep}
        AcceptTerms={newEntityName}
        title={"Confirm Alteration"}
      >
        <Grid container spacing={0} className={classes.mainContainer}>
          <Grid item xs={12}>
            <div className={classes.infoText}>You have selected</div>
          </Grid>
          <Grid item xs={12}>
            <BootstrapInput value={newEntityName} disabled />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.infoText}>As the new entity name.</div>
          </Grid>
          <Grid item xs={12} className={classes.confirmBtnContainer}>
            <ButtonStyled
              onClickHandler={openVerification}
              disabled={false}
              btnText={"Confirm & Proceed"}
            />
          </Grid>
          <Grid item xs={12} className={classes.confirmationText}>
            The alteration request could still be retracted and canceled up to
            30 minutes from confirmation.
          </Grid>
        </Grid>
      </AlterationContainer>
      <VerificationDialogue
        open={showVerifyDialogue}
        onDialogClose={onVerify}
        verificationText="Please enter your password in order to change the entity's name."
        onBackdropClick={() => setShowVerifyDialogue(false)}
      />
    </div>
  );
}

export default withStyles(styles)(ConfirmAlteration);
