import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles, createMuiTheme, ThemeProvider } from "@material-ui/core";

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(",")
  }
});

const styles = theme => ({
  modelTitle: {
    fontSize: 20,
    fontFamily: "inherit",
    letterSpacing: 0,
    color: "#535353",
    whiteSpace: "nowrap"
  },
  dialogBorder: {
    borderBottom: "1px solid #DADCE0",
    padding: "16px 16px"
  },
  noDialogBorder: {
    borderBottom: "none"
  },
  DialogPr: {
    // paddingRight: '16px'
  }
});

function DialogContainer({
  open,
  handleClose,
  classes,
  classesContent,
  title,
  actions,
  children,
  footerBorderTop,
  titleBorder,
  onBackdropClick
}) {
  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onBackdropClick={onBackdropClick}
      >
        <DialogTitle
          className={
            titleBorder ? classes.noDialogBorder : classes.dialogBorder
          }
        >
          <span className={classes.modelTitle}>{title}</span>
        </DialogTitle>
        <div className={classesContent && classesContent.DialogContentP}>
          {children}
        </div>
        <DialogActions
          className={footerBorderTop ? footerBorderTop.borderTopStyle : ""}
        >
          {actions}
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}
export default withStyles(styles)(DialogContainer);
