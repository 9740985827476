import React, { Component } from 'react';
import {
  Dialog,
  DialogContentText,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  CircularProgress
} from '@material-ui/core';

export default class GenricDialog extends Component {
  render() {
    const {
      open,
      onClose,
      title,
      label,
      sub,
      onChange,
      inputType,
      error,
      onSubmit,
      inputName,
      inputValue
    } = this.props;
    return (
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth={true}
        maxWidth="xs"
        aria-labelledby="form-dialog-title"
        style={{ margin: '-24px' }}
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{sub}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label={label}
            name={inputName}
            type={inputType}
            fullWidth
            error={error ? true : false}
            onChange={onChange(inputName)}
            value={inputValue}
          />
          <small style={{ color: 'red' }}>{error}</small>
        </DialogContent>
        <DialogActions className="dialog-action">
          <Button onClick={() => onClose(inputName)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => onSubmit(inputName)}
            color="primary"
            variant="outlined"
            disabled={this.props.isLoading}
          >
            {this.props.isLoading ? (
              <CircularProgress color="primary" size={24} />
            ) : (
              'Submit'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
