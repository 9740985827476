import React, { Component } from "react";
import { Grid } from "@material-ui/core";
// import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import HeaderSection from "./components/Header";
import PrimaryButton from "./components/PrimaryButton";
import SecondaryButton from "./components/SecondaryButton";
import ActPDF from "../../assets/dc_act.pdf";
// import Footer from './components/Footer';
import "./index.css";

export class index extends Component {
  render() {
    return (
      <div className="main-container">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <HeaderSection />
          </Grid>
          <Grid container spacing={0} style={{ textAlign: "center" }}>
            <Grid item xs={12}>
              <div style={{ marginTop: "40px" }}>
                {/* <Link to='/entity-formation' style={{ textDecoration: 'none' }}> */}
                <Link to="/entity-formation">
                  <PrimaryButton
                    btnText="Form a Decentralized Entity"
                    style={{ margin: "0 auto" }}
                  />
                </Link>
              </div>

              {/* </Link> */}
            </Grid>
            <Grid item xs={12}>
              <div style={{ marginTop: "12px" }}>
                <Link to="/register" style={{ textDecoration: "none" }}>
                  <SecondaryButton btnText="Become a Nodeholder" />
                </Link>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div style={{ marginTop: "40px" }}>
                <a
                  // to="/decentralized-companies-act"
                  download
                  href={ActPDF}
                  style={{ color: "#535353", fontWeight: "500" }}
                >
                  Decentralized Companies Act {">>"}
                </a>
              </div>
            </Grid>
          </Grid>
        </Grid>
        {/*
          {/* Info Blocks Section 
          <Grid container spacing={0} wrap="wrap" justify="center">
            <Grid item xs={12} md={6} lg={5}>
              <NodeholderBlock />
            </Grid>
            <Grid item xs={12} md={6} lg={5}>
              <EntitiesBlock />
            </Grid>
            <Grid item xs={12} md={6} lg={5}>
              <RegistrarBlock />
            </Grid>
            <Grid item xs={12} md={6} lg={5}>
              <DCHBlock />
            </Grid>
            <Grid item xs={12} md={6} lg={5}>
              <EcosystemBlock />
            </Grid>
            <Grid item xs={12} md={6} lg={5}>
              <AutonomousBlock />
            </Grid>
            {/* Footer Section 
            
          <Grid item xs={12}>
            <Footer />
          </Grid>
            */}
      </div>
    );
  }
}

export default index;
