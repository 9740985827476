import constants from "../constants";
import { getLoggedUser } from "../../services/generic";

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  isLogedOut: false,
  isLoggedIn: getLoggedUser(),
  message: ""
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLogedOut: false,
        isSuccess: false,
        isLoggedIn: false,
        isError: false,
        message: ""
      };
    case constants.LOGIN_REQUEST_ERROR:
      return {
        ...state,
        isLoading: false,
        isLogedOut: false,
        isLoggedIn: false,
        isSuccess: false,
        isError: true,
        message: ""
      };
    case constants.LOGIN_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isError: false,
        isLoggedIn: true,
        isLogedOut: false,
        message: action.payload.message
      };
    case constants.SIGN_UP_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isError: false,
        message: ""
      };
    case constants.SIGN_UP_REQUEST_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isLogedOut: false,
        isLoggedIn: false,
        isSuccess: false,
        message: ""
      };
    case constants.SIGN_UP_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isError: false,
        isLoggedIn: false,
        isLogedOut: false,
        message: action.payload.message
      };
    case constants.REQUEST_PASSWORD:
      return {
        ...state,
        isLoading: true,
        isLogedOut: false,
        isSuccess: false,
        isError: false,
        message: ""
      };
    case constants.REQUEST_PASSWORD_ERROR:
      return {
        ...state,
        isLoading: false,
        isLogedOut: false,
        isSuccess: false,
        isError: true,
        message: ""
      };
    case constants.REQUEST_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isError: false,
        isLogedOut: false,
        message: action.payload.message
      };
    case constants.REQUEST_ID:
      return {
        ...state,
        isLoading: true,
        isLogedOut: false,
        isSuccess: false,
        isError: false,
        message: ""
      };
    case constants.REQUEST_ID_ERROR:
      return {
        ...state,
        isLoading: false,
        isLogedOut: false,
        isSuccess: false,
        isError: true,
        message: ""
      };
    case constants.REQUEST_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isError: false,
        isLogedOut: false,
        message: action.payload.message
      };
    case constants.LOG_OUT:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isLoggedIn: false,
        isError: false,
        isLogedOut: true,
        message: ""
      };
    default:
      return state;
  }
};

export default auth;
