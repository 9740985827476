import React, { useState, useEffect } from 'react';
import NodeHoldersContainer from '../../website/components/NodeHoldersContainer';
import { makeStyles, Button } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import NodeHoldersTypography from '../../website/components/NodeHoldersTypography';
import IconTextField from '../../website/components/IconTextField';
import DialogContainer from '../../website/components/DialogContainer';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../redux/actions';

function AssociatingNodeHolders({
  handleNext,
  associatingNodeHolder,
  associatingNodeHolderDetails,
  isLoggedIn
}) {
  const [errorEmail, setErrorEmail] = useState({ value: false, message: '' });
  const [errorName, setErrorName] = useState({ value: false, message: '' });
  const [nomineeFullName, setNomineeFullName] = useState('');
  const [nomineeEmailAddress, setNomineeEmailAddress] = useState('');
  const [showInviteSuccessDialog, setShowInviteSuccessDialog] = useState(false);
  const existEmail = useSelector(
    state => state.entityForm.userExist.existEmail
  );
  const isAssociatingNodeInviteSuccessfully = useSelector(
    state =>
      state.entityForm.inviteAssociatingNode.isAssociatingNodeInviteSuccessfully
  );
  const entityForm = useSelector(state => state.entityForm);
  const dispatch = useDispatch();
  const useStyles = makeStyles(theme => ({
    btnStyle: {
      backgroundColor: '#535353',
      color: '#fff',
      fontWeight: 400,
      fontFamily: 'inherit',
      letterSpacing: '0.5px',
      textTransform: 'capitalize',
      padding: '9px 26px',
      marginTop: '60px',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: '#535353',
        color: '#fff',
        opacity: 0.8
      }
    },
    modelDesc: {
      fontSize: 16,
      letterSpacing: 0.24,
      color: '#9E9E9E',
      marginBottom: 40,
      padding: '16px 16px 0px 16px'
    },
    doneTxt: {
      color: '#535353',
      cursor: 'pointer !important',
      letterSpacing: 0.42,
      fontWeight: 500
    },
    mT32: {
      marginTop: 32
    }
  }));

  useEffect(() => {
    if (
      entityForm.userExist &&
      entityForm.userExist.data &&
      entityForm.userExist.data.available
    ) {
      console.log('existEmail', existEmail);
      checkEmailValidation(nomineeEmailAddress);
    }
  }, [entityForm.userExist]);

  useEffect(() => {
    if (isAssociatingNodeInviteSuccessfully) {
      setShowInviteSuccessDialog(true);
    }
  }, [isAssociatingNodeInviteSuccessfully]);

  const associateUserCheck = mail => {
    dispatch(
      actions.getUserExist({
        email: mail
      })
    );
  };

  const classes = useStyles();
  const cardDescription =
    'The associating node is the person who initiated the entity formation process.';
  const DialogActions = ({ handleClose }) => (
    <Button
      className={classes.doneTxt}
      disableRipple={true}
      onClick={handleClose}
      color="primary"
    >
      Done
    </Button>
  );

  const checkEmailValidation = mail => {
    if (mail === existEmail) {
      setErrorEmail({
        value: true,
        message: `Email is already in use. Please enter a different email or login to continue`
      });
    } else if (mail) {
      setErrorEmail({
        value: !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(mail),
        message: 'Invalid email address'
      });
    } else {
      setErrorEmail({ value: false, message: '' });
    }
  };

  const changeNomineeName = event => {
    const name = event.target.value;
    setNomineeFullName(event.target.value);
    if (name) {
      setErrorName({
        value: !/^[a-zA-Z ]+$/i.test(name),
        message: 'Name should contain only alphabets'
      });
    } else {
      setErrorName({ value: false, message: '' });
    }
  };

  const changeNomineeEmailAddress = event => {
    setNomineeEmailAddress(event.target.value);
    checkEmailValidation(event.target.value);
  };

  const onDialogClose = () => {
    setShowInviteSuccessDialog(false);
    handleNext();
  };

  const inviteNodeholder = () => {
    associateUserCheck(nomineeEmailAddress);
    associatingNodeHolder({ nomineeFullName, nomineeEmailAddress });
  };

  useEffect(() => {
    setNomineeEmailAddress(associatingNodeHolderDetails.email);
    setNomineeFullName(associatingNodeHolderDetails.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <NodeHoldersContainer
        showFooterText={false}
        currentStep={1}
        totalSteps={3}
      >
        <NodeHoldersTypography
          title={'Associating Node'}
          cardDescription={cardDescription}
        />
        <form className={classes.mT32} noValidate autoComplete="off">
          <IconTextField
            InputIcon={PersonIcon}
            disabled={isLoggedIn ? true : false}
            placeholder={'Full name'}
            onChangeHandler={changeNomineeName}
            value={nomineeFullName}
            hasError={errorName}
          />

          <IconTextField
            InputIcon={MailOutlineIcon}
            disabled={isLoggedIn ? true : false}
            type="email"
            placeholder={'Email Address'}
            onChangeHandler={changeNomineeEmailAddress}
            value={nomineeEmailAddress}
            hasError={errorEmail}
          />
        </form>

        <Button
          className={classes.btnStyle}
          disabled={
            !nomineeFullName ||
            errorName.value ||
            !nomineeEmailAddress ||
            errorEmail.value ||
            isLoggedIn
              ? true
              : false
          }
          variant="contained"
          onClick={inviteNodeholder}
        >
          Invite
        </Button>
        {/* <Button className={classes.btnStyle} disabled={ !nomineeFullName || !nomineeEmailAddress || errorEmail.value } variant="contained" onClick={inviteNodeholder}>Invite</Button> */}
      </NodeHoldersContainer>
      <DialogContainer
        open={showInviteSuccessDialog}
        title="Invitation has been sent."
        actions={<DialogActions handleClose={onDialogClose} />}
      >
        <DialogContentText className={classes.modelDesc}>
          Please click on the link that has just been sent to your email account
          to verify yourself in order to fully complete the formation process.
        </DialogContentText>
      </DialogContainer>
    </div>
  );
}

export default AssociatingNodeHolders;
