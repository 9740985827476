import React, { Fragment, useEffect } from "react";
import { withStyles, Grid, Typography } from "@material-ui/core";
import NoEntities from "../../Entities/AssociatedEntities/NoEntities";
import classnames from "classnames";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../../../redux/actions";



const styles = theme => ({
  cardContainer: {
    border: "1px solid #DADCE0",
    borderRadius: 4,
    margin: "0 8px 12px"
  },
  companyName: {
    color: "#555555",
    fontSize: 18,
    marginBottom: 10,
    textTransform: 'capitalize'
  },
  companyDescName: {
    color: "#9E9E9E",
    fontSize: 14,
  },
  manageEntityPAdding: {
    padding: "8px 10px",
    borderTop: "1px solid #DADCE0"
  },
  textRight: {
    textAlign: "right"
  },
  cardPAdding: {
    padding: "1rem"
  },
  mb24: {
    marginBottom: 20
  },
  closeIconCss: {
    color: "#9E9E9E",
    marginRight: -4
  },
  middleTxt: {
    marginTop: -44,
    marginBottom: 60,
    textAlign: "center",
    color: "#5F6368",
    fontSize: 14
  },
  linkTxt: {
    color: "#1A73E8",
    cursor: "pointer"
  },
  Alternatively: {
    color: "#9E9E9E",
    fontSize: 14
  },
  accepted: {
    color: "#00BE20"
  },
  decline: {
    color: "#FF0012"
  },
  NoEntitiesMb: {
    marginBottom: 20,
    width: 120,
    height: 120,
    backgroundColor: "#F8F8F8",
    border: "1px solid #DADCE0",
    borderRadius: 4,
    marginTop: 35
  }
});

function NominationHistory({ classes }) {
  const dispatch = useDispatch();
  const nominationsHistories = useSelector(state => state.entityPlatform.nodeholding_nominationsHistories ? state.entityPlatform.nodeholding_nominationsHistories : []);
  const email = useSelector(state => state.userDetails.data && state.userDetails.data.Email);

  useEffect(() => {
    dispatch(actions.getNominationHistory())
  }, []);

  const headerText = (
    <Fragment>You currently have no previous nomination.</Fragment>
  );

  const handleVisibility = (history) => {
   
    const params = {
      EntityId: history.EntityId,
      Email: email,
      Role: history.Role,
      type: 'nomination'
    };
    dispatch(actions.setVisibilityStatus(params))
  }

  return (
    <div style={{width: '100%'}}>
      {!nominationsHistories.length ? (
        <NoEntities
          entityCardMargin={classes}
          noCardPAdding={true}
          headerText={headerText}
        />
      ) : (
        <Grid
          className={classnames(classes.cardContainerPAdding)}
          container
          spacing={0}
          direction="row"
          justify="center"
          alignItems="center"
        >
          {nominationsHistories &&
            nominationsHistories.map(history => (
              history.Visible && (
                <Grid
                  className={classes.cardContainer}
                  item
                  xs={12}
                  key={history.EntityId}
                >
                  <Grid
                    container
                    direction="row"
                    className={classes.cardPAdding}
                    justify="center"
                  >
                    <Grid item xs={7}>
                      <Typography className={classes.companyName}>
                        {history.Role ? history.Role : 'CEO'}
                      </Typography>
                      <Typography
                        className={classnames(
                          classes.companyDescName,
                          classes.mb24
                        )}
                      >
                        {history.EntityName ? (history.EntityName).toUpperCase() : 'MR NEVILLE JOHNSON & MRS'}
                      </Typography>
                     
                      <Typography
                        className={classnames(
                          classes.companyDescName,
                          history.InvitationStatus === "Accepted"
                            ? classes.accepted
                            : classes.decline
                        )}
                      >
                        {history.InvitationStatus ? history.InvitationStatus : 'Declined or Accepted'}
                      </Typography>
                    </Grid>
                    <Grid className={classes.textRight} item xs={5}>
                      <CloseIcon className={classes.closeIconCss} onClick={() => handleVisibility(history)}/>
                    </Grid>
                  </Grid>
                </Grid>
              )
            ))}
        </Grid>
      )}
    </div>
  );
}
export default withStyles(styles)(NominationHistory);
