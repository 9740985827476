import React from "react";
import { withStyles, Grid } from "@material-ui/core";

const styles = theme => ({
  headerTxt: {
    color: "#5F6368",
    fontSize: 14,
    letterSpacing: "0.3px",
    textAlign: "center"
  },
  noEntity: {
    width: 120,
    height: 120,
    backgroundColor: "#F8F8F8",
    border: "1px solid #DADCE0",
    borderRadius: 4,
    marginTop: 35,
    marginBottom: 60
  },
  cardContainer: {
    margin: "0 36px",
    border: "1px solid #DADCE0",
    borderRadius: 4,
    padding: "30px 40px 40px"
  },
  noCardPAdding: {
    margin: "0 36px",
    border: "1px solid #DADCE0",
    borderRadius: 4,
    padding: "30px 24px 40px"
  }
});

function NoEntities({
  footerText,
  headerText,
  classes,
  noCardPAdding,
  middleText,
  entityCardMargin
}) {

  return (
    <div
      className={noCardPAdding ? classes.noCardPAdding : classes.cardContainer}
    >
      <Grid container spacing={0} justify="center" alignItems="center">
        <Grid item sm={12} className={classes.headerTxt}>
          {headerText}
        </Grid>

        <Grid
          item
          sm={12}
          className={
            entityCardMargin ? entityCardMargin.NoEntitiesMb : classes.noEntity
          }
        ></Grid>
        {middleText && (
          <Grid item sm={12} className={classes.middleText}>
            {middleText}
          </Grid>
        )}
        <Grid item sm={12} className={classes.headerTxt}>
          {footerText}
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(NoEntities);
