import React, { useState, useEffect } from 'react';
import {
  Container,
  withStyles,
  Button,
  Menu,
  MenuItem,
  Grid,
  AppBar,
  Tabs,
  Tab,
  Typography
} from '@material-ui/core';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import NotificationsRoundedIcon from '@material-ui/icons/NotificationsRounded';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import green from '@material-ui/core/colors/green';
import ReservedEntities from './Entities/ReservedNamesEntities/ReservedEntities';
import NodeholderStatus from './Nodeholding/NodeholderStatus/NodeholderStatus';
import NominationsContainer from './Nodeholding/Nominations/NominationsContainer';
import AssociatedEntitiesContainer from './Entities/AssociatedEntities/AssociatedEntitiesContainer';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// import { logout } from "../../../redux/actions";
import * as actions from '../../../redux/actions';

// Import SVG Icons
import { ReactComponent as CompanyIcon } from '../../../assets/icons/topbar-icon.svg';

const THEME = createMuiTheme({
  palette: {
    primary: {
      main: '#555555',
      height: '3px'
    },
    secondary: green
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  }
});

const styles = theme => ({
  appBar: {
    color: '#555555',
    backgroundColor: '#f8f8f8'
  },
  containerPadding: {
    padding: '0px 0 16px 0'
  },
  topBarPadding: {
    padding: '16px 24px',
    backgroundColor: '#f8f8f8'
  },
  mr8: {
    marginRight: 8
  },
  entities: {
    color: '#555',
    fontSize: '14px'
  },
  mlAuto: {
    marginLeft: 'auto',
    marginRight: -4
  },
  notificationColor: {
    color: '#555555',
    fontSize: '26px'
  },
  downArrow: {
    fontSize: 19
  },
  menuPadding: {
    padding: '0 8px'
  },
  textColorPrimary: {
    '&$selected': {
      color: '#555555',
      fontSize: '14px',
      textTransform: 'capitalize'
    }
  },
  selected: {
    color: '#555555'
  },
  tabText: {
    color: '#555555',
    fontSize: '14px',
    textTransform: 'capitalize',
    // marginRight: 30,
    width: '50%'
  },
  mt13: {
    marginTop: '13px',
    borderBottom: '1px solid #DADCE0',
    padding: theme.spacing(0, 2)
  },
  logo: {
    height: '25px',
    width: '25px',
    stroke: '#333',
    strokeWidth: '9px',
    fill: '#333',
    textDecoration: 'none',
    color: 'inherit',
    textAlign: 'center'
  },
  indicator: {
    height: 3
  },
  dummy: {
    padding: 0
  }
});

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: '24px 0px', width: '100%' }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

const menuList = {
  Entity: [
    {
      id: 0,
      label: 'Associated Entities',
      child: <AssociatedEntitiesContainer />
    },
    {
      id: 1,
      label: 'Reserved Names',
      child: <ReservedEntities />
    }
  ],
  Nodeholding: [
    {
      id: 0,
      label: 'Nodeholder Status',
      child: <NodeholderStatus />
    },
    {
      id: 1,
      label: 'Nominations',
      child: <NominationsContainer />
    }
  ],
  Logout: [
    {
      id: 0,
      label: 'logout options',
      child: <div />
    }
  ]
};

function EntityPlatformTopBar({ classes }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activeMenu, setActiveMenu] = React.useState(menuList.Entity);
  const [activeTab, setActiveTab] = React.useState(menuList.Entity[0]);
  const [menuTitle, setMenuTitle] = React.useState('Entity');
  const defaultChild = menuList.Entity[0].child;
  const [child, setChild] = useState(defaultChild);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(actions.profileRequest());
  }, []);

  function handleTabChange(event, newValue) {
    setActiveTab(newValue);
    const children = newValue.child;
    setChild(children);
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleMenuChange(menuTabs, menuTitle) {
    if (menuTabs.length > 0) {
      if (menuTitle === 'Logout') {
        localStorage.removeItem('nodeHolderToken');
        dispatch(actions.logout());
        history.push('/');
      }
      setActiveTab(menuTabs[0]);
      setMenuTitle(menuTitle);
      setActiveMenu(menuTabs);
      const children = menuTabs[0].child;
      setChild(children);
    }
    handleClose();
  }

  return (
    <TransitionGroup>
      <CSSTransition timeout={500}>
        <MuiThemeProvider theme={THEME}>
          <div>
            <Container className={classes.containerPadding} maxWidth="xl">
              <Grid
                className={classes.topBarPadding}
                container
                spacing={0}
                alignItems="center"
              >
                <Link to="/" className={classes.mr8}>
                  <CompanyIcon className={classes.logo} />
                </Link>
                <Button
                  aria-controls="simple-menu"
                  className={classes.menuPadding}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  {menuTitle}{' '}
                  <ExpandMoreRoundedIcon className={classes.downArrow} />
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {Object.keys(menuList).map((key, i) => {
                    const childList = Object.values(menuList)[i];
                    return (
                      <MenuItem
                        key={i}
                        onClick={() => handleMenuChange(childList, key)}
                      >
                        {key}
                      </MenuItem>
                    );
                  })}
                </Menu>
                <NotificationsRoundedIcon
                  className={classnames(
                    classes.mlAuto,
                    classes.notificationColor
                  )}
                />
              </Grid>

              <Grid container spacing={0} alignItems="center">
                <AppBar
                  className={classes.appBar}
                  elevation={0}
                  position="static"
                >
                  <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    scrollButtons="auto"
                    classes={{ indicator: classes.indicator }}
                  >
                    {activeMenu.length &&
                      activeMenu.map((tab, i) => {
                        return (
                          <Tab
                            key={i}
                            className={classes.tabText}
                            classes={{
                              textColorPrimary: classes.textColorPrimary,
                              selected: classes.selected
                            }}
                            label={tab.label}
                            value={tab}
                          ></Tab>
                        );
                      })}
                  </Tabs>
                </AppBar>
                <TabContainer className={classes.dummy}>{child}</TabContainer>
              </Grid>
            </Container>
          </div>
        </MuiThemeProvider>
      </CSSTransition>
    </TransitionGroup>
  );
}

export default withStyles(styles)(EntityPlatformTopBar);
