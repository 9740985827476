import React, { useState, Fragment } from "react";
import NodeHoldersContainer from "../website/components/NodeHoldersContainer";
import {
  withStyles,
  Grid,
  Button,
  DialogContentText,
  DialogContent,
  Slider,
  Tooltip,
  InputBase
} from "@material-ui/core";
import ButtonStyled from "../website/components/ButtonStyled";
import RectangleButton from "../website/components/RectangleButton";
import DialogContainer from "../website/components/DialogContainer";
import { createMuiTheme, MuiThemeProvider, createStyles } from "@material-ui/core/styles";
import { bool } from "prop-types";

const THEME = createMuiTheme({
  palette: {
    typography: {
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(",")
    },
    primary: {
      main: "#535353"
    }
  }
});

const styles = theme => ({
  textLeft: {
    textAlign: "left"
  },
  textcenter: {
    textAlign: "center"
  },
  holderName: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
    // marginTop: 15
  },

  textfieldtop: {
    maxWidth: 86
  },
  textRight: {
    textAlign: "right"
  },
  issued: {
    fontSize: "16px/19px",
    letterSpacing: "0.24px",
    color: "#535353",
    fontWeight: "500"
  },
  issuedName: {
    fontSize: 16,
    fontWeight: "500",
    textAlign: "left",
    letterSpacing: 0,
    color: "#777777",
    wordBreak: "break-all"
  },
  issuedCount: {
    fontSize: 14,
    fontWeight: "500",
    letterSpacing: 0.21,
    color: "#A4A4A4"
  },
  issuedContainer: {
    borderBottom: "1px solid #DADCE0",
    padding: "1rem 1.3rem",
    marginBottom: 30,
    paddingTop: 0,
    paddingBottom: 36
  },
  mb40: {
    marginBottom: 40
  },
  doneTxt: {
    color: "#535353",
    letterSpacing: 0.42,
    textTransform: "capitalize",
    paddingRight: 0
  },
  addEquity: {
    textAlign: "center",
    fontSize: 14,
    letterSpacing: 0.42,
    color: "#535353"
  },

  mT60: {
    marginTop: 60
  },
  eqtText: {
    color: "#9E9E9E",
    display: "inline-block",
    width: "40px",
    marginTop: "2px",
    float: "left"
  },
  nodeSliderEQT: {
    width: "calc(100% - 76px)",
    float: "left",
    paddingRight: 8
  },
  eqtMaxValue: {
    color: "#9E9E9E",
    float: "left",
    width: "30px"
  },
  modelDesc: {
    fontSize: 16,
    letterSpacing: 0.24,
    color: "#9E9E9E",
    marginBottom: 20,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  padding0: {
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
    padding: theme.spacing(1, 2)
  },
  mt50: {
    marginTop: 40
  }
});

const NodeholderEQTSlider = withStyles({
  root: {
    color: "#707070",
    paddingTop: 30
  }
})(Slider);

const actionTypes = {
  cancel: 1,
  appoint: 2
};

// const marks = [
//   {
//     value: 100,
//     label: "100"
//   }
// ];

const BootstrapInput = withStyles(theme =>
  createStyles({
    root: {
      marginTop: theme.spacing(1) + 2,
      marginBottom: theme.spacing(1) + 2,
      width: "100%"
    },
    input: {
      textAlign: "center",
      borderRadius: 4,
      position: "relative",
      backgroundColor: "#FFF",
      border: "1px solid #DAE1E7",
      fontSize: 'inherit',
      width: "100%",
      padding: "12px 5px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      color: "#757575",
     
    }
  })
)(InputBase);

function NodeHolderEQT({ classes, handleNext, emails }) {
  let nodeEQTSum = 0;
  emails.forEach(element => {
    nodeEQTSum += element.nodeEqtValue;
  });
  let gridIssueStatus = bool;
  let [allocateValue, setAllocateValue] = useState();
  let [remainingValue, setRemainingValue] = useState();
  if (nodeEQTSum > 0) {
    gridIssueStatus = true;
    allocateValue = nodeEQTSum * 10;
    //Number((1000-999.9).toFixed(2))
    remainingValue = Number(1000 - allocateValue).toFixed(2);
  } else {
    gridIssueStatus = false;
  }
  const defaultNodeHolderState = {
    showDialog: false,
    selectedNodeIndex: null,
    data: { name: "", email: "" }
  };
  const [nodeHolder, setNodeHolder] = useState(defaultNodeHolderState);
  const [showIssueGrid, setIssueGrid] = useState(gridIssueStatus);
  const [showNodeHolderName, setShowNodeHolderName] = useState();
  const [nodeIndex, setNodeIndex] = useState();
  const [sliderOldValue, setSliderOldValue] = useState();
  let [sliderValue, setSlderValue] = useState();
  let [remainSliderValue, setRemainSlderValue] = useState(nodeEQTSum);
  let [eqtSum, setEqtSum] = useState();
  const [emailList, setEmailList] = useState();
  const DialogActions = ({ handleClose }) => (
    <Fragment>
      <Button
        className={classes.doneTxt}
        onClick={() => handleClose(actionTypes.cancel)}
        color="primary"
      >
        {" "}
        Cancel{" "}
      </Button>
      <Button
        className={classes.doneTxt}
        onClick={() =>
          appointNode(
            sliderValue,
            handleClose,
            emailList,
            allocateValue,
            remainSliderValue
          )
        }
        color="primary"
      >
        {" "}
        Allocate{" "}
      </Button>
    </Fragment>
  );

  const ValueLabelComponent = props => {
    const { children, open, value } = props;
    setSlderValue(props.value);

    return (
      <Tooltip open={open} placement="top" title={value}>
        {children}
      </Tooltip>
    );
  };
  const onDialogClose = () => {
    setNodeHolder(defaultNodeHolderState);
  };
  const showNodeholderEQTDialog = (selectedNodeIndex, node, nodeHolderList) => {
    let nodeSliderValue = nodeHolderList.find(
      x => x.inviteNodeHolderEmail === node.inviteNodeHolderEmail
    ).nodeEqtValue;
    setNodeHolder({
      ...defaultNodeHolderState,
      showDialog: true,
      selectedNodeIndex
    });
    setEmailList(node);
    setNodeIndex(selectedNodeIndex);
    setShowNodeHolderName(node.inviteNodeHolderEmail);
    setSlderValue(nodeSliderValue * 10);
    setSliderOldValue(node.nodeEqtValue);
    setIssueGrid({
      gridIssueStatus: true
    });
  };
  const appointNode = (
    sliderCurrentValue,
    handleClose,
    nodes,
    nodeAllocateValue,
    remainSliderValue
  ) => {
    let nodeSliderValue =
      emails.find(x => x.inviteNodeHolderEmail === nodes.inviteNodeHolderEmail)
        .nodeEqtValue * 10;
    let totalEQT = 1000;
    nodes.nodeEqtValue = (100 * sliderCurrentValue) / totalEQT;
    let nodesValues = nodes.nodeEqtValue * 10;
    let nodeEQTSum = 0;
    let difference = 0;
    emails.forEach(element => {
      nodeEQTSum += element.nodeEqtValue;
    });
    setEqtSum(nodeEQTSum * 10);
    // if(nodeEQTSum>100){
    //     toast("Sorry you don't have much amount of equity")
    //     nodes.nodeEqtValue=(nodeSliderValue)/10;
    // }
    // else
    // {
    if (nodeAllocateValue === undefined) {
      nodeAllocateValue = 0;
    }
    if (nodeSliderValue !== 0) {
      if (nodeSliderValue < nodesValues) {
        difference = nodesValues - nodeSliderValue;
        setAllocateValue(nodeAllocateValue + difference);
        setRemainingValue(totalEQT - (nodeAllocateValue + difference));
      }
      if (nodeSliderValue > nodesValues) {
        difference = nodeSliderValue - nodesValues;
        setAllocateValue(nodeAllocateValue - difference);
        setRemainingValue(totalEQT - (nodeAllocateValue - difference));
      }
    } else {
      setAllocateValue(
        nodeAllocateValue + (totalEQT * nodes.nodeEqtValue) / 100
      );
      setRemainingValue(
        totalEQT - (nodeAllocateValue + (totalEQT * nodes.nodeEqtValue) / 100)
      );
    }
    setRemainSlderValue(nodeEQTSum);
    handleClose(actionTypes.appoint);

    //}
  };
  const divideEQT = () => {
    const allocated = 100;
    const divided = emails.length;
    setAllocateValue(1000);
    setRemainingValue(0);
    emails.forEach(email => {
      //email.nodeEqtValue = Math.floor(allocated / divided)
      email.nodeEqtValue = Number((allocated / divided).toFixed(2));
      setSlderValue(email.nodeEqtValue);
    });
    setRemainSlderValue(100);

    setIssueGrid({
      gridIssueStatus: true
    });
    setEqtSum(1000);
  };
  const handleChange = (event, val) => {
    let nodeEQTSum = 0;
    let nodeSliderValue = [];
    if (emailList) {
      nodeSliderValue = emails.filter(
        x => x.inviteNodeHolderEmail !== emailList.inviteNodeHolderEmail
      );
      nodeSliderValue.forEach(element => {
        nodeEQTSum += element.nodeEqtValue;
      });

      if (nodeEQTSum + val / 10 > 100) {
        let nodeSliderValueOld = emails.filter(
          x => x.inviteNodeHolderEmail === emailList.inviteNodeHolderEmail
        )[0].nodeEqtValue;
        setSlderValue(nodeSliderValueOld * 10);
      } else {
        setSlderValue(val);
      }
    }
  };
  return (
    <MuiThemeProvider theme={THEME}>
      <div className="Nodeholder_EQT">
        <NodeHoldersContainer
          showFooterText={true}
          pageTitle={"Nodeholder EQT"}
        >
          {!showIssueGrid ? (
            <Grid
              className={classes.issuedContainer}
              container
              direction="row"
              justify="flex-start"
            >
              <Grid className={classes.textcenter} item xs={6}>
                <div className={classes.issued}>Issued</div>
                <div className={classes.issuedCount}>1000</div>
              </Grid>
              <Grid className={classes.textcenter} item xs={6}>
                <div className={classes.issued}>Allocated</div>
                <div className={classes.issuedCount}>0 / 1000</div>
              </Grid>
            </Grid>
          ) : null}
          {showIssueGrid ? (
            <Grid
              className={classes.issuedContainer}
              container
              direction="row"
              justify="flex-start"
            >
              <Grid className={classes.textcenter} item xs={6}>
                <div className={classes.issued}>Allocated</div>
                <div className={classes.issuedCount}>{allocateValue}</div>
              </Grid>
              <Grid className={classes.textcenter} item xs={6}>
                <div className={classes.issued}>Remaining</div>
                <div className={classes.issuedCount}>{remainingValue}</div>
              </Grid>
            </Grid>
          ) : null}
          <div>
            <RectangleButton
              btnText={"Divide Equally"}
              onClickHandler={divideEQT}
            />
          </div>

          {emails.map((email, i) => {
            return (
              <Grid className={classes.mt50} container spacing={2} key={i} onClick={() => showNodeholderEQTDialog(i, email, emails)}>
                <Grid
                  className={classes.holderName}
                  item
                  xs={9}
                  
                >
                  <div className={classes.issuedName}>
                    {email.inviteNodeHolderEmail}
                  </div>
                </Grid>
                <Grid key={i} item xs={3} className={classes.textfieldtop}>
                  {/* <TextField
                    style={{ width: "100%" }}
                    variant="outlined"
                    className="border-input_text"
                    value={email.nodeEqtValue + "%"}
                    disabled={true}
                  /> */}
                  <BootstrapInput value={email.nodeEqtValue + "%"} disabled />
                </Grid>
              </Grid>
            );
          })}

          <div className={classes.mT60}>
            <ButtonStyled
              btnText={"Done"}
              disabled={
                remainSliderValue === 100 || remainSliderValue === 99.99
                  ? false
                  : true
              }
              onClickHandler={handleNext}
            />
          </div>

          <DialogContainer
            open={nodeHolder.showDialog}
            classesContent={classes}
            title={"Allocate EQT"}
            actions={<DialogActions handleClose={onDialogClose} />}
          >
            <DialogContentText className={classes.modelDesc}>
              How much EQT would you like to allocate to "{showNodeHolderName}"
            </DialogContentText>

            <DialogContent className={classes.padding0}>
              <div className={classes.margin} />
              <Grid className={classes.eqtText}>EQT</Grid>
              <Grid className={classes.nodeSliderEQT}>
                <NodeholderEQTSlider
                  ValueLabelComponent={ValueLabelComponent}
                  aria-label="custom thumb label"
                  //defaultValue={sliderValue ? sliderValue : 0}
                  value={sliderValue}
                  step={100}
                  min={0}
                  max={1000}
                  valueLabelDisplay="auto"
                  onChange={handleChange}
                />
              </Grid>
              <Grid className={classes.eqtMaxValue}>1000</Grid>
            </DialogContent>
          </DialogContainer>
        </NodeHoldersContainer>
      </div>
    </MuiThemeProvider>
  );
}

export default withStyles(styles)(NodeHolderEQT);
