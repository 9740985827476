import React from 'react';
import AlterationContainer from './AlterationContainer';
import { withStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import BorderButton from '../../../../website/components/BorderButton';
import { manageEntityPages } from '../../ManageEntity/ManageEntitySteps';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../../../redux/actions';

// Import SVG Icons
import { ReactComponent as DLC } from '../../../../../assets/icons/entity-type/dlc-name.svg';

const styles = themes => ({
  mainContainer: {
    border: '1px solid #DADCE0',
    borderRadius: '5px',
    padding: themes.spacing(4, 2)
  },
  newNameText: {
    textAlign: 'center',
    fontSize: 20,
    color: '#535353'
  },
  dlcIcon: {
    width: '79px',
    height: '99px',
    margin: '0 auto'
  },
  confirmationText: {
    lineHeight: '1.5',
    fontSize: 14,
    color: '#9E9E9E',
    textAlign: 'center',
    marginTop: themes.spacing(1)
  },
  confirmBtnContainer: {
    textAlign: 'center',
    margin: themes.spacing(4, 0, 1)
  }
});

function CompleteAlteration({ classes, setManageEntityStep, newEntityName }) {
  const dispatch = useDispatch();
  const oldEntityName = useSelector(
    state => state.entityPlatform.oldEntityName
  );

  const goManageEntityPage = () => {
    setManageEntityStep(manageEntityPages.manageEntitiesPage);
    //update oldEntityName to empty string
    dispatch(actions.updateOldEntityName());
  };

  return (
    <div>
      <AlterationContainer title={'Alteration Complete'}>
        <Grid container spacing={0} className={classes.mainContainer}>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <DLC className={classes.dlcIcon} />
          </Grid>
          {newEntityName && (
            <Grid item xs={12}>
              <div className={classes.newNameText}>{newEntityName}</div>
            </Grid>
          )}
          <Grid item xs={12} className={classes.confirmationText}>
            your entity name has changed from "{oldEntityName}" to "
            {newEntityName}"
          </Grid>
          <Grid item xs={12} className={classes.confirmBtnContainer}>
            <BorderButton
              onClickHandler={goManageEntityPage}
              disabled={false}
              btnText={'Return to Entity Page'}
            />
          </Grid>
        </Grid>
      </AlterationContainer>
    </div>
  );
}

export default withStyles(styles)(CompleteAlteration);
