import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Grid,
  CircularProgress,
  Button,
  InputLabel,
  FormControl,
  OutlinedInput
} from "@material-ui/core";
import "../styles.css";

class FirstStep extends Component {
  continue = e => {
    e.preventDefault();
    this.props.submit();
  };
  previous = e => {
    e.preventDefault();
    this.props.prevStep();
  };
  render() {
    const { values, handleChange } = this.props;
    return (
      <div>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <form className="signup-form-container">
              <div className="header-eyebrow">
                decentralized companies House
              </div>
              <div className="form-header">entity platform</div>
              <div className="form-subheader">
                Become a nodeholder to create your own <br /> decentralized
                entities.
              </div>
              <div className="input-boxes textLeft">
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="email">
                    Enter your email address
                  </InputLabel>
                  <OutlinedInput
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    error={values.errorText ? true : false}
                    value={values.email}
                    required={true}
                    onChange={handleChange("email")}
                    labelWidth={168}
                  />
                  {values.errorText && (
                    <small style={{ color: "red" }}>{values.errorText}</small>
                  )}
                </FormControl>
              </div>
              <Button
                label="Next"
                variant="outlined"
                onClick={this.props.submit}
                style={{
                  marginTop: "40px",
                  color: "#fff",
                  backgroundColor: "#333333",
                  "&:hover": {
                    backgroundColor: "#333333"
                  },
                  width: "100%",
                  padding: "14px 0px 15px 0px",
                  fontSize: "0.875rem",
                  letterSpacing: "0.5px"
                }}
                type="submit"
                disabled={this.props.isLoading || values.email === ""}
              >
                {this.props.isLoading ? (
                  <CircularProgress color="primary" size={24} />
                ) : (
                  "Sign Up"
                )}
              </Button>
              <div className="signup-link">
                Already a nodeholder?
                <br />
                <span>
                  <Link
                    to="/login"
                    style={{ textDecoration: "none", color: "#333" }}
                  >
                    LOGIN NOW
                  </Link>
                </span>
              </div>
            </form>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default FirstStep;
