import React, { useState } from "react";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  InputAdornment,
  Box
} from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { manageEntityPages } from "../ManageEntity/ManageEntitySteps";
import ExecutiveNodeHolders from "./ExecutiveNodeHolders";
import ManageEntitiesContainer from "../ManageEntity/ManageEntitiesContainer";
import RoleList from "./RoleList";
import AppointRoleNodeForm from "./AppointRoleNodeForm";
import AppointRoleConfirmation from "./AppointRoleConfirmation";
import { useSelector } from "react-redux";


const BootstrapInput = withStyles(theme =>
  createStyles({
    root: {
      marginTop: theme.spacing(1) - 2,
      width: "100%",
      backgroundColor: "#FCFCFC",
      border: "2px solid #DAE1E7",
      borderRadius: 4
    },
    input: {
      fontSize: 16,
      color: "#9E9E9E",
      position: "relative",
      width: "100%",
      padding: "15px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      '&:focus': {
        border: "2px solid #535353",
      }
    }
  })
)(InputBase);

const styles = theme => ({
  mainContainer: {
    width: "95%",
    padding: 0
  },
  cardTitleText: {
    color: "#555555",
    fontSize: 18,
    marginBottom: theme.spacing(1)
  },
  cardDescriptionText: {
    color: "#9E9E9E",
    fontSize: 14,
    marginBottom: theme.spacing(2),
    lineHeight: "1.5",
    width: "80%"
  },
  textCenter: {
    textAlign: "center"
  },
  textRight: {
    textAlign: "right"
  },
  appointNodeholderCont: {
    border: "1px solid #DADCE0",
    borderRadius: "5px",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  executiveMainCont: {
    border: "1px solid #DADCE0",
    borderRadius: "5px"
  },
  roleListBox: {
    width: "100%",
    color: "#555555",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    "&:last-child": {
      marginBottom: theme.spacing(0)
    },
    "&:hover": {
      backgroundColor: "#555555",
      color: "#fff"
    }
  },
  executiveTitleCont: {
    padding: theme.spacing(2)
  },
  executiveDescriptionCont: {
    padding: theme.spacing(0)
  },
  adornmentPositionEnd: {
    marginRight: theme.spacing(1) + 2
  },
  linkTextContainer: {
    textAlign: "center",
    margin: theme.spacing(2, 0)
  },
  linkText: {
    color: "#535353",
    fontSize: 14
  },
  borderBtm: {
    borderBottom: "1px solid #DADCE0",
    width: "100%"
  },
  executiveCardDescriptionText: {
    marginBottom: theme.spacing(0),
    width: "67%"
  },
  RoleListDescriptionText: {
    width: "100%"
  },
  subTitleActive: {
    color: "#535353",
    fontSize: 16
  },
  historyText: {
    color: "#9E9E9E",
    fontSize: 16,
    textAlign: "right"
  },
  py: {
    padding: theme.spacing(0, 2)
  },
  plusIcon: {
    "&:hover": {
      // backgroundColor: 'red',
      color: "#535353"
    }
  },
  roleListCont: {
    padding: theme.spacing(1, 0, 3)
  },
  roleTitleText: {
    fontSize: 18,
    textTransform: 'capitalize'
  },
  roleSelectText: {
    fontSize: 14,
    cursor: "pointer"
  },
  btnPadding: {
    padding: theme.spacing(1, 4),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2)
  },
  disabled: {
    backgroundColor: "#DADCE0"
  },
  enabled: {
    backgroundColor: "#535353",
    color: "#fff"
  },
  roleConfirmationTitle: {
    fontSize: 18,
    color: "#555555",
    textAlign: "center",
    margin: "0 auto 16px",
    width: "80%",
    lineHeight: 1.5
  },
  my4: {
    margin: theme.spacing(4, 0)
  },
  appointBtnCont: {
    display: "flex",
    justifyContent: "center"
  },
  btnStyle: {
    borderRadius: "0px",
    border: "1px solid #DADCE0",
    margin: "0 auto 32px"
  },
  // btnStyleNoBorder: {
  //     border: '0px',
  //     borderRadius: '0px',
  //     margin: '0 auto 32px'
  // },
  mb4: {
    margin: "0 auto 32px"
  },
  pointerNone: {
    pointerEvents: 'none'
  }
});

export const nodeholderSteps = {
  executiveNodeholder: 1,
  availableRole: 2,
  appointRole: 3,
  appointRoleConfirmation: 4,
  appointRoleCompletion: 5
};

const initialFormData = {
  name: "",
  email: "",
  emailError:""
};

function ManageNodeholder({ classes, setManageEntityStep}) {

  const selectedEntity = useSelector(
    state => state.entityPlatform.selectedEntity
  );

  const [activeStep, setActiveStep] = useState(1);
  const [selectedRole, setSelectedRole] = useState("");
  const [formData, setFormData] = useState(initialFormData);

  // const associated_entities = useSelector(state => state.entityPlatform.associated_entities);
  
  const handleNext = (step, role) => {
    setActiveStep(step);
    if(role){
      setSelectedRole(role)
    }
  };

  const getCurrentStep = () => {
    switch (activeStep) {
      case nodeholderSteps.executiveNodeholder:
        return <ExecutiveNodeHolders classes={classes} selectedEntity={selectedEntity}/>;

      case nodeholderSteps.availableRole:
        return <RoleList classes={classes} handleNext={handleNext} />;

      case nodeholderSteps.appointRole:
        return (
          <AppointRoleNodeForm 
            classes={classes} 
            handleNext={handleNext}
            formData={formData}
            setFormData={setFormData} 
            nodeholderRoles={selectedEntity.NodeHolderRoles} />
        );

      case nodeholderSteps.appointRoleConfirmation:
        return (
          <AppointRoleConfirmation
            classes={classes}
            handleNext={handleNext}
            selectedRole={selectedRole}
            activeStep={activeStep}
            selectedEntity={selectedEntity}
            formData={formData}
          />
        );

      case nodeholderSteps.appointRoleCompletion:
        return (
          <AppointRoleConfirmation
            classes={classes}
            handleNext={handleNext}
            selectedRole={selectedRole}
            activeStep={activeStep}
            selectedEntity={selectedEntity}
            formData={formData}
          />
        );

      default:
        break;
    }
  };

  const currentStep = getCurrentStep();

  return (
    <ManageEntitiesContainer
      title={"Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum"}
      bottomText={"Continue to the entity's panel"}
      setManageEntityStep={setManageEntityStep}
      manageEntityPages={manageEntityPages}
    >
      <Box className={classes.mainContainer}>
        <Grid item xs={12} className={classes.appointNodeholderCont}>
          <Typography className={classes.cardTitleText}>
            Appoint Nodeholders
          </Typography>
          <Typography className={classes.cardDescriptionText}>
            Invite new nodeholders to assume an executive role in the underlying
            entity.
          </Typography>
          <BootstrapInput
            value="New nodeholder"
            disabled
            endAdornment={
              <InputAdornment
                position="end"
                classes={{ positionEnd: classes.adornmentPositionEnd }}
              >
                <AddBoxIcon
                  onClick={() => handleNext(nodeholderSteps.availableRole)}
                  className={activeStep === 1 ? classes.plusIcon : classes.pointerNone}
                />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12}>
          {currentStep}
        </Grid>
      </Box>
    </ManageEntitiesContainer>
  );
}

export default withStyles(styles)(ManageNodeholder);
