import React from "react";
import NodeHoldersContainer from "../../website/components/NodeHoldersContainer";
import NodeHoldersTypography from "../../website/components/NodeHoldersTypography";
import { withStyles, Grid } from "@material-ui/core";
import ButtonStyled from "../../website/components/ButtonStyled";
import Link from "@material-ui/core/Link";
import classnames from "classnames";

const styles = theme => ({
  textLeft: {
    textAlign: "left"
  },
  textRight: {
    textAlign: "right"
  },
  issued: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0,
    color: "#A7A7A7"
  },
  issuedCount: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.21,
    color: "#777777"
  },
  issuedContainer: {
    borderTop: "1px solid #DADCE0",
    borderBottom: "1px solid #DADCE0",
    padding: "1rem 1.3rem",
    marginTop: 40,
    marginBottom: 30
  },
  mb40: {
    marginBottom: 40
  },
  addEquity: {
    textAlign: "center",
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.42,
    color: "#535353",
    cursor: "pointer"
  },
  mT60: {
    marginTop: 60
  },
  emailContainer: {
    marginTop: 16,
    padding: "0.8rem 0px 0.5rem"
  },
  font14: {
    fontSize: 14,
    marginBottom: 5
  }
});

function AllocateEquity({ classes, onAdd, emails, handleNext }) {
  //
  const cardDescription =
    "Assign ownership rights in your decentralized company via entity-unique EQT tokens.";

  return (
    <div>
      <NodeHoldersContainer
        showFooterText={true}
        currentStep={3}
        totalSteps={3}
      >
        <NodeHoldersTypography
          title={"Allocate EQT"}
          cardDescription={cardDescription}
        />
        <Grid
          className={classes.issuedContainer}
          container
          direction="row"
          justify="flex-start"
        >
          <Grid className={classes.textLeft} item xs={6}>
            <div className={classes.issued}>Issued</div>
            <div className={classes.issuedCount}>1000</div>
          </Grid>
          <Grid className={classes.textRight} item xs={6}>
            <div className={classes.issued}>Allocated</div>
            <div className={classes.issuedCount}>0 / 1000</div>
          </Grid>
        </Grid>
        <Link
          to={"/eqt-nodeholders"}
          onClick={onAdd}
          className={classes.addEquity}
        >
          Add Equity Nodeholders
        </Link>

        {emails && emails.length > 0 && (
          <Grid
            className={classnames(
              classes.issuedContainer,
              classes.emailContainer
            )}
            container
            direction="row"
            justify="flex-start"
          >
            {emails.map((email, i) => {
              return (
                <Grid key={i} className={classes.textLeft} item xs={12}>
                  <div className={classnames(classes.issued, classes.font14)}>
                    {email.inviteNodeHolderEmail}
                  </div>
                </Grid>
              );
            })}
          </Grid>
        )}

        <Grid className={classes.mT60}>
          <ButtonStyled
            btnText={"Allocate Equity"}
            disabled={emails.length === 0 ? true : false}
            onClickHandler={handleNext}
          />
        </Grid>
      </NodeHoldersContainer>
    </div>
  );
}

export default withStyles(styles)(AllocateEquity);
