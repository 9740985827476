export const TopMenu = [
  {
    name: 'Nodeholders',
    val: 'NH',
    children: [
      {
        name: 'Nodeholders in a Decentralized Entity',
        url: '/change1'
      },
      {
        name: 'Become a Nodeholder',
        url: '/register'
      }
    ]
  },
  {
    name: 'Decentralized Entities',
    val: 'DE',
    children: [
      {
        name: 'Smart Legal Entities',
        val: 'SLE',
        children: [
          {
            name: 'Decentralized Company',
            url: '/change2'
          },
          {
            name: 'Decentralized Trust',
            url: '/change3'
          },
          {
            name: 'Decentralized Holding Company',
            url: '/change4'
          }
        ]
      },
      {
        name: 'Next-Generation Entities',
        val: 'NGE',
        children: [
          {
            name: 'Next-Generation Fund',
            url: '/change5'
          },
          {
            name: 'Next-Generation Project',
            url: '/change6'
          },
          {
            name: 'Decentralized Guild',
            url: '/change7'
          },
          {
            name: 'Decentralized Holdings Group',
            url: '/change8'
          }
        ]
      },
      {
        name: 'Decentralized Companies Act',
        url: '/change9',
        val: 'DCA'
      }
    ]
  },
  {
    name: 'The Registrar of Digital Entities',
    val: 'RDE',
    children: [
      {
        name: 'Registrar DApp',
        val: 'RDA',
        children: [
          {
            name: 'Decentralized Entities Protocol',
            url: '/change10'
          },
          {
            name: 'ENTT Token',
            url: '/change11'
          }
        ]
      },
      {
        name: 'Decentralized Entity Formation',
        val: 'DEF',
        children: [
          {
            name: 'Become a nodeholder'
          },
          {
            name: 'Allocate EQT'
          },
          {
            name: 'Generate Entity Contract'
          }
        ]
      },
      {
        name: 'New Decentralized Entity (app)',
        val: 'NDEA'
      }
    ]
  },
  {
    name: 'Decentralized Companies House',
    val: 'DCH',
    children: [
      {
        name: 'Doing Business in the Digital Age'
      },
      {
        name: 'About the DCH'
      }
    ]
  },
  {
    name: 'Ecosystem',
    val: 'ECO',
    children: [
      {
        name: 'Doing Business in a Next-Generation Environment'
      },
      {
        name: 'Entity-Signed Digital Ecosystem'
      }
    ]
  },
  {
    name: 'Initial Node Offering',
    val: 'INO',
    children: [
      {
        name: 'INO: An Equity-Based Crowdsale Mechanism',
        val: 'INOCM',
        children: [
          {
            name: 'Decentralized Public Companies'
          },
          {
            name: 'Decentralized Node Exchange'
          }
        ]
      },
      {
        name: 'Node Offering',
        val: 'NOF',
        children: [
          {
            name: 'A Crowdsale Mechanism for Decentralized Entities'
          },
          {
            name: 'NO Crowdsale Models'
          },
          {
            name: 'NO Protocol'
          }
        ]
      }
    ]
  },
  {
    name: 'Autonomous Legal Person',
    val: 'ALP',
    children: [
      {
        name: 'Machine Identity'
      },
      {
        name: 'Autonomous Legal Entity'
      }
    ]
  }
];
