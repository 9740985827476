import React, { Component } from 'react';
import { Grid, Button, withStyles } from '@material-ui/core';
import './styles.css';

// Import SVG Icons
import { ReactComponent as DecentralizedEntity } from '../../assets/icons/de.svg';

const styles = theme => ({
  entityDescription: {
    color: '#9E9E9E',
    letterSpacing: 0.21,
    fontSize: 14,
    lineHeight: '25px',
    marginTop: 16
  },
  PrimaryBtn: {
    color: theme.palette.getContrastText('#535353'),
    backgroundColor: '#535353',
    '&:hover': {
      backgroundColor: '#535353'
    },
    width: '100%',
    padding: '12px 0px',
    fontSize: '0.875rem',
    letterSpacing: '0.5px',
    fontFamily: 'inherit',
    fontWeight: '400',
    textTransform: 'Capitalize'
  }
});

class LandingForm extends Component {
  continue = e => {
    e.preventDefault();
    this.props.handleNext();
    this.props.handleTopIcon('newDecentalized');
  };

  render() {
    const { classes } = this.props;
    return (
      <div className="landing-page-container">
        <div className="landing-page-card">
          <Grid container spacing={0} justify="center" alignItems="center">
            <Grid item xs={12}>
              <DecentralizedEntity
                strokeWidth="3.5"
                stroke="#464646"
                style={{ height: '140px', width: '140px' }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                className={classes.PrimaryBtn}
                variant="contained"
                onClick={e => {
                  this.continue(e);
                }}
                style={{ marginTop: '40px' }}
              >
                New Decentralized Entity
              </Button>
              {/* <button
                className="landing-form-btn"
                onClick={e => {
                  this.continue(e);
                }}
              >
                New Decentralized Entity
              </button>*/}

              <div className={classes.entityDescription}>
                Form a New Decentralized Entity Powered <br /> by a Smart
                Contract.
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(LandingForm);
