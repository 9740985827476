import * as actions from '../actions';
import { call, put } from 'redux-saga/effects';
import { getLoggedUser } from '../../services/generic';
import fireAjax from '../../services/index';
import { toast } from 'react-toastify';

export function* userProfileRequest(action) {
  try {
    const response = yield call(
      fireAjax,
      'GET',
      'user/userInfo',
      {},
      { Authorization: getLoggedUser() }
    );
    if (response.data.status === 1) {
      yield put(actions.profileRequestSuccess(response.data.data));
    } else {
      yield put(
        actions.profileRequestError({
          message: response.data.msg || 'Error when trying to profile details'
        })
      );
      // toast('Error when trying to profile details');
    }
  } catch (e) {
    yield put(
      actions.profileRequestError({
        message: 'Error when trying to profile details'
      })
    );
    // toast('Error when trying to profile details');
  }
}

export function* updateProfile(action) {
  try {
    const response = yield call(
      fireAjax,
      'PUT',
      'user/updateProfile',
      action.payload,
      { Authorization: getLoggedUser() }
    );
    if (response.data.status === 1) {
      yield put(
        actions.updateProfileRequestSuccess({
          message: 'Updated successfully.'
        })
      );
      yield put(actions.profileRequest({}));
      toast('Updated successfully.');
    } else {
      yield put(
        actions.updateProfileRequestError({
          message: response.data.msg || 'Error While Updating !!'
        })
      );
      // toast('Error While Updating !!');
    }
  } catch (e) {
    yield put(
      actions.updateProfileRequestError({
        message: 'Error While Updating !!'
      })
    );
    // toast('Error While Updating !!');
  }
}

export function* verifyEmailRequest(action) {
  try {
    const response = yield call(
      fireAjax,
      'GET',
      'user/resendVerification',
      {},
      { Authorization: getLoggedUser() }
    );
    if (response.data.status === 1) {
      yield put(actions.verifyEmailRequestSuccess(response.data.message));
      toast(response.data.message);
    } else {
      yield put(
        actions.verifyEmailRequestError({
          message: response.data.message || 'Error Occurs !!'
        })
      );
      // toast('Error Occurs !!');
    }
  } catch (e) {
    yield put(
      actions.verifyEmailRequestError({
        message: e.response.data.message || 'Error Occurs !!'
      })
    );
    // toast(e.response.data.message || 'Error Occurs !!');
  }
}
