import React, { Fragment, useState } from "react";
import {
  Grid,
  withStyles,
  Typography,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import AlterationContainer from "./AlterationContainer";

const styles = theme => ({
  title: {
    fontSize: "20px",
    color: "#5F6368",
    marginBottom: "15px",
    textAlign: "center"
  },
  TnCBlock: {
    width: "100%",
    // height: '325px',
    marginBottom: "20px",
    border: "1px solid #DADCE0",
    padding: "10px 15px 0",
    borderRadius: "5px",
    margin: "0 0 20px 0 !important"
  },

  TnCBlockLi: {
    height: "max-content !important",
    marginBottom: "25px"
  },
  dataTitle: {
    fontSize: "14px",
    color: "#535353",
    marginBottom: "10px"
  },
  dataDesc: {
    fontSize: "14px",
    color: "#535353",
    lineHeight: "1.5"
  },
  mainContainer: {
    padding: "0 24px"
  },
  checkboxTxt: {
    color: "#707070",
    fontSize: 14,
    paddingLeft: 0
  },
  mT30: {
    marginTop: 30
  },
  btnFloat: {
    float: "right"
  },
  doneTxt: {
    color: "#535353",
    cursor: "pointer !important",
    letterSpacing: 0.42,
    textTransform: "capitalize"
  },
  resignationP: {
    color: "#757575",
    fontSize: 16,
    marginBottom: 25
  },
  borderInput: {
    border: "1px solid #DAE1E7",
    backgroundColor: "#FCFCFC",
    borderRadius: 4,
    color: "#9E9E9E !important",
    fontSize: 16,
    letterSpacing: 0.24
  }
});

const tileData = [
  {
    description:
      "You are about to change the official name of your decentralized entity."
  },
  {
    description:
      "By the end of this process your entity will be officially renamed and its current name will be made available for use by other entities."
  },
  {
    description:
      "Upon confirmation of the new name, the entity name could be changed again within 12 calendar days."
  }
];

function TermsOfAlteration({ classes, handleNext, setManageEntityStep }) {
  // const [terms, setTerms] = useState({
  //   agree: false,
  //   confirm: false
  // });
  const [AcceptTerms, setAcceptTerms] = useState(false);

  // const handleCheckBox = name => event => {
  //   setTerms({ ...terms, [name]: event.target.checked });
  // };

  return (
    <Fragment>
      <AlterationContainer
        btnText={"Abort"}
        containedBtnText={"Proceed"}
        AcceptTerms={AcceptTerms}
        handleNext={handleNext}
        title={"Terms Of Alteration"}
        setManageEntityStep={setManageEntityStep}
      >
        <Grid className={classes.TnCBlock} cols={1}>
          {tileData.map((tile, i) => (
            <Grid className={classes.TnCBlockLi} key={i}>
              {tile.title && (
                <div className={classes.dataTitle}>{tile.title}</div>
              )}
              {tile.description && (
                <div className={classes.dataDesc}>{tile.description}</div>
              )}
            </Grid>
          ))}
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            style={{ marginRight: 0, marginBottom: 0, alignItems: "center" }}
            control={
              <Checkbox
                color="default"
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                value="checkedI"
                onChange={event => setAcceptTerms(event.target.checked)}
              />
            }
            label={
              <Typography variant="h6" className={classes.checkboxTxt}>
                I agree to the terms of alteration.
              </Typography>
            }
          />
        </Grid>
      </AlterationContainer>
    </Fragment>
  );
}

export default withStyles(styles)(TermsOfAlteration);
