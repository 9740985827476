import React, { Component, createRef } from "react";
import {
  TextField,
  Button,
  Divider,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import CardContainer from "../../views/website/components/Entity-card";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import isEmpty from "lodash/isEmpty";
import "./styles.css";

// Import SVG Icons
import { ReactComponent as DLC } from "../../assets/icons/entity-type/dlc.svg";
import { ReactComponent as DLP } from "../../assets/icons/entity-type/dlp.svg";
import { ReactComponent as NGF } from "../../assets/icons/entity-type/ngf.svg";
import { ReactComponent as NGP } from "../../assets/icons/entity-type/ngp.svg";

const THEME = createMuiTheme({
  palette: {
    typography: {
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
    primary: {
      main: "#535353",
    },
  },
});

class EntityName extends Component {
  entityIcons = {
    decentralized_company: {
      name: "Decentralized Company",
      icon: <DLC height="128px" stroke="#464646" strokeWidth="4px" />,
    },
    decentralized_partnership: {
      name: "Decentralized Partnership",
      icon: <DLP />,
    },
    next_gen_project: {
      name: "Next Generation Project",
      icon: <NGP />,
    },
    next_gen_fund: {
      name: "Next Generation Fund",
      icon: <NGF />,
    },
  };
  keyTimer = createRef();

  continue = (e) => {
    e.preventDefault();
    this.props.handleNext();
  };

  previous = (e) => {
    this.props.handleBack();
  };

  //on keyup, start the countdown
  onKeyup = (e) => {
    window.clearTimeout(this.keyTimer.current);
    this.keyTimer.current = setTimeout(this.doneTyping, 500);
  };

  //on keydown, clear the countdown
  onKeyDown = () => {
    window.clearTimeout(this.keyTimer.current);
  };

  //user is "finished typing," make the api call
  doneTyping = () => {
    this.props.onNameCheck();
  };

  render() {
    return (
      <MuiThemeProvider theme={THEME}>
        <div className="entity-name-container">
          <div className="form-header">Select Entity Name</div>
          <CardContainer showCustomMargin={true}>
            <Grid container spacing={0} justify="center" alignItems="center">
              <Grid item xs={12}>
                <div className="header">Entity Name</div>
                <div className="entity-class-desc">
                  <div>Enter a name for your new</div>
                  {this.props.entityType.replace(/_/g, " ")}.
                </div>

                <div className="text-field-top">
                  <TextField
                    style={{
                      width: "100%",
                    }}
                    label="Official Name"
                    variant="outlined"
                    // required={true}
                    value={this.props.values.entityName}
                    onChange={this.props.handleChange("entName")}
                    onKeyUp={this.onKeyup}
                    onKeyDown={this.onKeyDown}
                    onKeyPress={this.props.onKeyPress(
                      this.props.values.entityName
                    )}
                    error={this.props.values.errorEntityName ? true : false}
                  />
                  {this.props.values.errorEntityName && (
                    <small style={{ color: "red" }}>
                      {this.props.values.errorEntityName}
                    </small>
                  )}
                </div>

                <div className="entity-icon-container">
                  {this.entityIcons[this.props.entityType].icon}
                  <div className="entity-name-header">Entity Name is</div>
                </div>
                {!isEmpty(this.props.nameAvailability.data) ? (
                  <div
                    className={`availability-container ${
                      this.props.nameAvailability.data.available
                        ? "available"
                        : "not-available"
                    }`}
                  >
                    <span
                      className={
                        this.props.nameAvailability.data.available
                          ? "available"
                          : "not-available"
                      }
                    >
                      {this.props.nameAvailability.data.available
                        ? "Available"
                        : "Unavailable"}
                    </span>
                  </div>
                ) : null}

                <div className="reserve-btn-container">
                  {/* {!isEmpty(this.props.nameAvailability.data) &&
                !this.props.nameAvailability.data.available ? (
                  <a href="#">Name available for other entities</a>
                ) : null} */}

                  <Button
                    variant="contained"
                    style={{
                      textTransform: "capitalize",
                      fontFamily: "inherit",
                      fontWeight: "400",
                      letterSpacing: "0.5px",
                    }}
                    className={
                      this.props.nameAvailability.data &&
                      this.props.nameAvailability.data.available
                        ? "entity-name-btn"
                        : "entity-name-d-btn"
                    }
                    onClick={(e) => {
                      // this.props.setEntityType(data.value);
                      // if (this.props.isLoggedIn) {
                      //   this.props.onSubmit();
                      // } else {
                      this.continue(e);
                      // }
                    }}
                    disabled={
                      this.props.nameAvailability.data &&
                      this.props.nameAvailability.data.available
                        ? false
                        : true
                    }
                  >
                    {this.props.isLoading ? (
                      <CircularProgress color="primary" size={24} />
                    ) : (
                      "Reserve Entity Name"
                    )}
                  </Button>
                </div>
                {/* <div
              className="back-btn"
              onClick={e => {
                this.previous();
              }}
            >
              Back
            </div> */}

                {/* <div className="register-container">
              <div className="header">
                Register as a nodeholder and reserve your Entity Name.
              </div>
              <div className="sub-header">
                Reserve a name for your ‘entity_type’. Once the digital entities
                registrar attains <br /> full functionality, you’d be notified
                via email to login with your nodeholder <br />
                details and complete your ‘entity_type’ formation process.
              </div>
              <div className="text-field">
                <TextField
                  style={{ width: "100%" }}
                  variant="filled"
                  label="Enter Email Address"
                  type="email"
                  value={this.props.values.entityFormEmail}
                  onChange={this.props.handleChange("email")}
                  error={this.props.values.errorEmail ? true : false}
                />
                {this.props.values.errorEmail && (
                  <small style={{ color: "red" }}>
                    {this.props.values.errorEmail}
                  </small>
                )}
              </div>
              <div className="bottom-helper-text">
                You will receive an email with your nodeholder access
                information.
              </div>
              <div className="button">
                <Button
                  label="reserve-entity-name"
                  variant="contained"
                  color="inherit"
                  style={{
                    padding: "15px 30px",
                    textTransform: "capitalize"
                  }}
                  onClick={this.props.onSubmit}
                  disabled={this.props.isLoading}
                >
                  {this.props.isLoading ? (
                    <CircularProgress color="primary" size={24} />
                  ) : (
                    "Reserve Entity Name"
                  )}
                </Button>
              </div>
            </div> */}
              </Grid>
            </Grid>
          </CardContainer>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default EntityName;
