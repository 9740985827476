import React, { Fragment, useState } from "react";
import {
  Grid,
  withStyles,
  GridList,
  GridListTile,
  Typography,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import BorderButton from "../../../../website/components/BorderButton";
import ButtonStyled from "../../../../website/components/ButtonStyled";
import VerificationDialogue from "../../VerificationDialogue";
import classnames from "classnames";
import { nominationSteps } from "./NominationsContainer";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../../../redux/actions";

const styles = theme => ({
  title: {
    fontSize: "20px",
    color: "#5F6368",
    marginBottom: "15px",
    textAlign: "center"
  },
  TnCBlock: {
    width: "100%",
    height: "325px",
    marginBottom: "20px",
    border: "1px solid #DADCE0",
    padding: "10px 15px 0",
    borderRadius: "5px",
    margin: "0 0 20px 0 !important"
  },
  TnCBlockLi: {
    height: "auto !important",
    marginBottom: "20px"
  },
  dataTitle: {
    fontSize: "14px",
    color: "#535353",
    marginBottom: "10px"
  },
  dataDesc: {
    fontSize: "14px",
    color: "#535353",
    lineHeight: "1.5"
  },
  mainContainer: {
    padding: "0 24px"
  },
  checkboxTxt: {
    color: "#707070",
    fontSize: 14
  },
  mT40: {
    marginTop: 40
  },
  btnContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  btnFloat: {
    float: "right"
  },
  doneTxt: {
    color: "#535353",
    cursor: "pointer !important",
    letterSpacing: 0.42,
    textTransform: "capitalize"
  },
  resignationP: {
    color: "#757575",
    fontSize: 16,
    marginBottom: 25
  },
  borderInput: {
    border: "1px solid #DAE1E7",
    backgroundColor: "#FCFCFC",
    borderRadius: 4,
    color: "#9E9E9E !important",
    fontSize: 16,
    letterSpacing: 0.24
  }
});

const tileData = [
  {
    title: "1. Terms of Nodeholding",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  },
  {
    title: "2. Nodeholder Rights and Duties",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  }
];

function TermsAndConditions({ classes, setActiveStep }) {
  const dispatch = useDispatch();
  const email = useSelector(state => state.userDetails.data && state.userDetails.data.Email);
  const token = useSelector(state => state.entityPlatform.currentNominationCardId);
  const passwordVerified = useSelector(state => state.entityPlatform.passwordVerified);


  const [AcceptTerms, setAcceptTerms] = useState(false);
  const [ConfirmTerms, setConfirmTerms] = useState(false);
  const [ShowAcceptSuccessDialog, setShowAcceptSuccessDialog] = useState(false);

  const onDialogClose = (password) => {
    if (password) {
      setShowAcceptSuccessDialog(false);
      setActiveStep(nominationSteps.default);
      onNominationAction('Accepted');
    }
  };

  const onNominationAction = (action) => {
    const params = {
      token,
      email,
      action
    };
    if (token && passwordVerified) dispatch(actions.nominationAction(params))
  }

  const termsAccept = () => {
    setShowAcceptSuccessDialog(true);
  };

  return (
    <Fragment>
      <Grid container spacing={0} className={classes.mainContainer}>
        <Grid item xs={12} className={classes.title}>
          Nodeholding Agreement
        </Grid>

        <GridList className={classes.TnCBlock} cols={1}>
          {tileData.map((tile, i) => (
            <GridListTile className={classes.TnCBlockLi} key={i}>
              {tile.title && (
                <div className={classes.dataTitle}>{tile.title}</div>
              )}
              {tile.description && (
                <div className={classes.dataDesc}>{tile.description}</div>
              )}
            </GridListTile>
          ))}
        </GridList>

        <Grid item xs={12}>
          <FormControlLabel
            style={{
              marginRight: 0,
              marginBottom: 16,
              alignItems: "flex-start"
            }}
            control={
              <Checkbox
                style={{ paddingTop: 4 }}
                color="default"
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                value="checkedI"
                onChange={event => setAcceptTerms(event.target.checked)}
              />
            }
            label={
              <Typography variant="h6" className={classes.checkboxTxt}>
                I have read and agreed to the nodeholding terms and conditions.
              </Typography>
            }
          />

          <FormControlLabel
            style={{ marginRight: 0, alignItems: "flex-start" }}
            control={
              <Checkbox
                style={{ paddingTop: 4 }}
                color="default"
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                value="checkedI"
                onChange={event => setConfirmTerms(event.target.checked)}
              />
            }
            label={
              <Typography variant="h6" className={classes.checkboxTxt}>
                I confirm my association with the underlying entity.
              </Typography>
            }
          />
        </Grid>

        <Grid
          className={classnames(classes.mT40, classes.btnContainer)}
          item
          xs={12}
        >
          <BorderButton disabled btnText={"Decline"} />
          <ButtonStyled
            btnFloatClass={classes}
            onClickHandler={termsAccept}
            disabled={!AcceptTerms || !ConfirmTerms}
            btnText={"Accept"}
          />
        </Grid>
      </Grid>

      <VerificationDialogue
        open={ShowAcceptSuccessDialog}
        onDialogClose={onDialogClose}
        verificationText="Please enter your password in order to accept the nomination."
        onBackdropClick={() => setShowAcceptSuccessDialog(false)}
      />
    </Fragment>
  );
}

export default withStyles(styles)(TermsAndConditions);
