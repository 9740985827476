import React, { Fragment, useState, useEffect } from 'react';
import { withStyles, Grid, Typography, Button } from '@material-ui/core';
import EntitiesCardContainer from '../../Entities/AssociatedEntities/EntitiesCardContainer';
import ConfirmationModal from './ConfirmationModal';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../../../redux/actions';
import classnames from 'classnames';
import moment from 'moment';

// Import SVG Icons
import { ReactComponent as SlEntityIcon } from '../../../../../assets/icons/entity-class/sle.svg';

const styles = theme => ({
  cardContainerPAdding: {
    padding: '16px'
  },
  sleIcon: {
    border: '1px solid #DADCE0',
    borderRadius: 4,
    backgroundColor: '#F8F8F8',
    width: '84%'
  },
  companyName: {
    color: '#555555',
    fontSize: 18,
    marginBottom: 10
  },
  companyDescName: {
    color: '#9E9E9E',
    fontSize: 14
  },
  activeTxt: {
    color: '#0FE45D',
    fontSize: 12,
    textAlign: 'right'
  },
  suspendText: {
    color: '#9E9E9E',
    fontSize: 12,
    textAlign: 'right'
  },
  ManageEntityBtnTxt: {
    color: '#555555',
    fontSize: '14px',
    textTransform: 'capitalize'
  },
  textDim: {
    color: '#9E9E9E'
  },
  manageEntityPAdding: {
    padding: '8px 10px',
    // borderTop: '1px solid #DADCE0'
    textAlign: 'right'
  },
  confirmationText: {
    fontSize: '16px',
    color: '#757575',
    lineHeight: '1.5',
    marginBottom: '18px',
    '&:last-child': {
      marginBottom: '0px'
    }
  }
});

function NominationsCard({ classes, nomination, activeText, handleNext }) {
  const dispatch = useDispatch();
  const email = useSelector(
    state => state.userDetails.data && state.userDetails.data.Email
  );
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [timer, setTimer] = useState('Counting..');
  let timerInterval = null;

  useEffect(() => {
    countdown();
    return () => {
      clearIntervalForTimer();
    };
  }, [nomination.InvitedDate]);

  const clearIntervalForTimer = () => {
    if (timerInterval) {
      clearInterval(timerInterval);
    }
  };

  const countdown = () => {
    if (nomination.InvitedDate) {
      const InvitationExpiredDate = moment(nomination.InvitationExpiredDate);
      // var resultHours = InvitationExpiredDate.diff(moment(new Date()), 'hours'); // https://jsfiddle.net/aacostamx/nogksp7o/
      // var diff = moment(InvitationExpiredDate, 'HH:mm').diff(moment(new Date(), 'HH:mm')); // https://forum.ionicframework.com/t/how-can-i-calculate-the-difference-between-two-times-that-are-in-24-hour-format/133701/13

      clearIntervalForTimer();
      timerInterval = setInterval(() => {
        // console.log('minutes', moment.utc(moment(InvitationExpiredDate, "HH:mm:ss").diff(moment(new Date(), "HH:mm:ss"))).format("mm"));
        // console.log('hours', InvitationExpiredDate.diff(moment(new Date()), 'hours'));
        const different = moment(InvitationExpiredDate, 'HH:mm').diff(
          moment(new Date(), 'HH:mm')
        );
        if (different <= 0) {
          clearIntervalForTimer();
          return setTimer('');
        } else
          return setTimer(
            `(${InvitationExpiredDate.diff(
              moment(new Date()),
              'hours'
            )}:${moment
              .utc(
                moment(InvitationExpiredDate, 'HH:mm:ss').diff(
                  moment(new Date(), 'HH:mm:ss')
                )
              )
              .format('mm')})`
          );
      }, 60000);
    }
  };

  const NomineeChild = () => {
    return (
      <Fragment>
        <div className={classes.confirmationText}>
          Accepting the nomination will associate your node with the underlying
          nomination.
        </div>
        <div className={classes.confirmationText}>
          Your confirmation indicates you are aware and agree to the nodeholding
          terms set by the nominating party.
        </div>
      </Fragment>
    );
  };
  const nominationsData = {
    title: 'Nodeholder Nomination',
    child: <NomineeChild />,
    btnText: 'Okay'
  };

  const openModal = data => {
    setOpen(true);
    setModalData(data);
  };

  const closeModal = () => {
    handleNext();
    setOpen(false);
    dispatch(actions.currentNominationCardEntityId(nomination.EntityId));
  };

  const onNominationAction = action => {
    const params = {
      token: nomination.EntityId,
      email,
      action
    };
    dispatch(actions.nominationAction(params));
  };

  const NominationsActions = () => (
    <Fragment>
      <Button
        className={classnames(classes.ManageEntityBtnTxt, classes.textDim)}
        disableRipple={true}
        disabled={timer === '' ? true : false}
        onClick={() => onNominationAction('Declined')}
      >
        Decline
      </Button>
      <Button
        className={classes.ManageEntityBtnTxt}
        disableRipple={true}
        disabled={timer === '' ? true : false}
        onClick={() => openModal(nominationsData)}
      >
        Accept Nominations
      </Button>
    </Fragment>
  );
  return (
    <div>
      <EntitiesCardContainer
        styleClasses={classes}
        btnActions={<NominationsActions />}
      >
        {/* manageEntityText={'Resign'} */}
        <Grid
          className={classes.cardContainerPAdding}
          container
          spacing={0}
          direction="row"
          justify="center"
        >
          <Grid item xs={4}>
            <SlEntityIcon className={classes.sleIcon} />
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.companyName}>
              {nomination.Role}
            </Typography>
            <Typography className={classes.companyDescName}>
              {nomination.EntityName.toUpperCase()}
            </Typography>
            <Typography className={classes.companyDescName}>
              By: {nomination.associated ? nomination.associated : '#Nodecode'}
            </Typography>
          </Grid>
          <Grid
            className={timer !== '' ? classes.activeTxt : classes.suspendText}
            item
            xs={2}
          >
            {timer !== '' ? timer : 'Suspended'}
          </Grid>
        </Grid>
      </EntitiesCardContainer>
      <ConfirmationModal
        open={open}
        handleClose={closeModal}
        confirmationData={modalData}
      />
    </div>
  );
}

export default withStyles(styles)(NominationsCard);
