import React, { Fragment, useEffect } from 'react';
import { Grid, Link, withStyles } from '@material-ui/core';
import NoEntities from '../../Entities/AssociatedEntities/NoEntities';
import NodeholderCard from './NodeholderCard';
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../../../redux/actions";

const styles = theme => ({
    linkTxt: {
        color: '#1A73E8',
        cursor: 'pointer'
    },
    middleTxt: {
        marginTop: -44,
        marginBottom: 60,
        textAlign: 'center',
        color: '#5F6368',
        fontSize: 14
    },
    Alternatively: {
        color: '#9E9E9E',
        fontSize: 14
    }
})

function NodeholderRoles({ classes, userEmail }) {

    const dispatch = useDispatch();

    const nodeholderRolesList = useSelector(state => state.entityPlatform.nodeholding_status_roles ? state.entityPlatform.nodeholding_status_roles : []);

    useEffect(() => {
        dispatch(actions.getNodeHolderStatusRoles())
    }, []);

    const footerText = (<Fragment><Grid className={classes.Alternatively}>Alternatively</Grid><Grid><Link className={classes.linkTxt} href="/entity-formation">Form your own decentralized entity.</Link></Grid></Fragment>);
    const headerText = (<Fragment>You currently don't have an active role in any decentralized entity.</Fragment>);
    const middleText = (<Grid className={classes.middleTxt}>Wait for others to appoint you as a nodeholder.</Grid>);

    return (
        <Fragment>
            {
                nodeholderRolesList.length > 0 ?
                    nodeholderRolesList.map((entity, index) =>
                        <NodeholderCard
                            key={index}
                            entity={entity}
                            userEmail={userEmail}
                        />) :
                    (<NoEntities
                        noCardPAdding={true}
                        footerText={footerText}
                        headerText={headerText}
                        middleText={middleText}
                    />)

            }
        </Fragment>
    )
}

export default withStyles(styles)(NodeholderRoles);
