import React from "react";
import { styled, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import classnames from "classnames";

const MyButton = styled(Button)({
  backgroundColor: "#333",
  border: 0,
  borderRadius: 4,
  color: "#ffffff",
  letterSpacing: 0.5,
  width: 279,
  height: 52,
  fontSize: 14,
  fontWeight: 400,
  fontFamily: "inherit",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#333",
    color: "#fff",
    opacity: 0.9
  }
});

const useStyles = makeStyles(theme => ({
  disabled: {
    backgroundColor: "#DDDDDD",
    color: "#A4A4A4"
  },
  enabled: {
    backgroundColor: "#353535",
    color: "#ffffff"
  }
}));

export default function ButtonStyled({
  disabled,
  btnPadding,
  onClickHandler,
  btnText,
  btnFloatClass
}) {
  const classes = useStyles();
  return (
    <MyButton
      classes={{ root: (btnPadding && btnPadding.btnPadding) || null }}
      disabled={disabled}
      onClick={onClickHandler}
      className={classnames(
        disabled ? classes.disabled : classes.enabled,
        btnText === "Proceed" ? btnFloatClass.btnFloat : ""
      )}
    >
      {btnText}
    </MyButton>
  );
}
