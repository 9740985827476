import * as actions from "../actions";
import { call, put } from "redux-saga/effects";
import { getLoggedUser } from "../../services/generic";
import fireAjax from "../../services/index";
import { toast } from "react-toastify";

export function* entityFormRequest(action) {
  try {
    const response = yield call(
      fireAjax,
      "POST",
      "entity/addEntity",
      action.payload,
      {}
    );
    if (response.data.status === 1) {
      yield put(actions.entityFormRequestSuccess(response.data.entity));
      toast("Entity created successfully");
    } else {
      yield put(actions.entityFormRequestError(response.data.message));
      toast(response.data.message);
    }
  } catch (e) {
    yield put(actions.entityFormRequestError("Error Occurs !!"));
    toast("Error Occurs !!");
  }
}

//submit data on click of Generate button start
export function* submitEntityFormRequest(action) {
  try {

    const response = yield call(
      fireAjax,
      "POST",
      "entity/create-entity",
      action.payload,
      {}
    );
    if (response.data.status === 1) {
      yield put(actions.submitEntityFormRequestSuccess(response.data.data));
      toast("Entity submitted successfully");
    } else {
      yield put(actions.submitEntityFormRequestError(response.data.message));
      toast(response.data.message);
    }
  } catch (e) {
    yield put(actions.submitEntityFormRequestError("Error Occurs !!"));
    toast("Error Occurs !!");
  }
}
//submit data on click of Generate button end


export function* checkEntityAvailability(action) {
  try {
    const response = yield call(
      fireAjax,
      "POST",
      "entity/getAvailability",
      action.payload,
      { Authorization: getLoggedUser() }
    );
    if (response.data.status === 1) {
      yield put(actions.getEntityTypeAvailabilitySuccess(response.data));
    } else {
      yield put(actions.getEntityTypeAvailabilityError(response.data.message));
    }
  } catch (e) {
    yield put(actions.getEntityTypeAvailabilityError("Error Occurs !!"));
    toast("Error Occurs !!");
  }
}

export function* checkNameAvailability(action) {

  try {
    const response = yield call(
      fireAjax,
      "POST",
      "entity/checkAvailability",
      action.payload,
    );
    if (response.data.status === 1) {
      yield put(actions.getNameAvailabilitySuccess(response.data.data));
    } else {
      yield put(actions.getNameAvailabilityError(response.data.data));
    }
  } catch (e) {
    yield put(actions.getNameAvailabilityError("Error Occurs !!"));
    toast("Error Occurs !!");
  }
}

export function* getUserEntities(action) {
  try {
    const response = yield call(
      fireAjax,
      "GET",
      "entity/getAllEntities",
      {},
      { Authorization: getLoggedUser() }
    );
    if (response.data.status === 1) {
      yield put(actions.getUserEntitiesSuccess(response.data.data));
    } else {
      yield put(actions.getUserEntitiesError(response.data.message));
    }
  } catch (e) {
    yield put(actions.getUserEntitiesError("Error Occurs !!"));
    toast("Error Occurs In Getting Entities !!");
  }
}

export function* checkUserExist(action) {
  try {
    const response = yield call(
      fireAjax,
      "POST",
      "user/checkUserExist",
      action.payload,
    );
    if (response.data.status === 1) {
      yield put(actions.getUserExistSuccess({data: response.data.data, email: action.payload.email}));
    } else {
      yield put(actions.getUserExistError(response.data.data));
    }
  } catch (e) {
    yield put(actions.getUserExistError("Error Occurs !!"));
    toast("Error Occurs !!");
  }
}

export function* checkUserIsNotExist(action) {
  // try {
  //   const response = yield call(
  //     fireAjax,
  //     "POST",
  //     "user/checkUserExist",
  //     action.payload,
  //   );
  //   console.log("response", response)
  //   if (response.data.status == 1) {
  //     yield put(actions.getUserExistSuccess(response.data.data));
  //   } else {
  //     yield put(actions.getUserExistError(response.data.data));
  //   }
  // } catch (e) {
  //   yield put(actions.getUserExistError("Error Occurs !!"));
  //   toast("Error Occurs !!");
  // }
}
export function* emailVarified(action) {

  try {

    const response = yield call(
      fireAjax,
      "POST",
      "entity/email-verified",
      action.payload,
      {}
    );
    if (!response.data.error) {

      yield put(actions.isEmailVarifiedSuccess(response.data));

    } else {

      yield put(actions.isEmailVarifiedError(response.data.error));
    }
  } catch (e) {

    toast("Error Occurs !!");
  }
}

export function* inviteAssociatingNode(action) {

  try {

    const response = yield call(
      fireAjax,
      "POST",
      "entity/invite-associate",
      action.payload,
      {}
    );
    if (!response.data.error) {

      yield put(actions.inviteAssociatingNodeSuccess(response.data));

    } else {

      yield put(actions.inviteAssociatingNodeError(response.data.error));
    }
  } catch (e) {

    toast("Error Occurs !!");
  }
}