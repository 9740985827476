import React from 'react'
import ManageEntitiesContainer from './ManageEntitiesContainer'
// import { useState } from 'react'
import CreateManageEntity from './CreateManageEntity';
import { withStyles } from "@material-ui/core";
// import NoEntities from '../Entities/AssociatedEntities/NoEntities';

const styles = theme => ({
    linkTxt: {
        color: '#1A73E8',
        cursor: 'pointer'
    }
})

function ManageEntities({ setManageEntityStep }) {
    
    return (
        <div>
            <ManageEntitiesContainer subTitle={''} title={'Manage the entity underlying the decentralized company.'} maxWidth="xl">
                <CreateManageEntity setManageEntityStep={setManageEntityStep} />
            </ManageEntitiesContainer>
        </div>
    )
}

export default withStyles(styles)(ManageEntities)
