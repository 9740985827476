import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions';
import { Route } from 'react-router-dom';
import Setting from './Settings';
import Reserved from './Reserved';
import NewName from './NewName';

// import {
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Button,
//   TextField,
//   CircularProgress
// } from '@material-ui/core';
// import isEmpty from 'lodash/isEmpty';

class Panel extends Component {
  constructor(props) {
    super(props);
    this.props.getProfile();
    this.state = {
      password: '',
      error: ''
    };
  }

  handleChange = input => e => {
    this.setState({ [input]: e.target.value, errorText: '' });
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.auth.isLogedOut !== this.props.auth.isLogedOut &&
      this.props.auth.isLogedOut
    ) {
      this.props.history.push('/login');
    }
  }

  render() {
    return (
      <div className="panel">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <div className="screen-container">
              <Route
                exact
                path="/nodeholder-panel"
                render={props => <Setting {...props} {...this.props} />}
              />
              <Route
                path="/nodeholder-panel/entities"
                render={props => <Reserved {...props} {...this.props} />}
              />
              <Route
                path="/nodeholder-panel/reserve-entity"
                render={props => <NewName {...props} {...this.props} />}
              />
            </div>
          </Grid>
          {/* {!isEmpty(this.props.userDetail.data) ? (
            <Dialog
              open={this.props.userDetail.data.TempPassword}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">
                Please Change the Password
              </DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  label={'Password'}
                  name={'password'}
                  type={'password'}
                  fullWidth
                  error={this.state.error ? true : false}
                  onChange={this.handleChange('password')}
                  value={this.state.password}
                />
                <small style={{ color: 'red' }}>{this.state.error}</small>
              </DialogContent>
              <DialogActions className="dialog-action">
                <Button
                  onClick={() => {
                    if (!this.state.password) {
                      this.setState({ error: 'Please fill the field' });
                    } else {
                      this.props.updateProfile({
                        Password: this.state.password
                      });
                    }
                  }}
                  color="primary"
                  variant="outlined"
                  disabled={this.props.userDetail.isLoading}
                >
                  {this.props.userDetail.isLoading ? (
                    <CircularProgress color="primary" size={24} />
                  ) : (
                    'Submit'
                  )}
                </Button>
              </DialogActions>
            </Dialog>
          ) : null} */}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userDetail: state.userDetails,
    auth: state.auth,
    entity: state.entityForm
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getProfile: () => dispatch(actions.profileRequest()),
    updateProfile: data => dispatch(actions.updateProfileRequest(data)),
    logOut: () => dispatch(actions.logout()),
    checkAvailableEntityTypes: data =>
      dispatch(actions.getEntityTypeAvailability(data)),
    emailVerification: () => dispatch(actions.verifyEmailRequest()),
    reserveEntity: data => dispatch(actions.entityFormRequest(data)),
    getUserEntities: () => dispatch(actions.getUserEntities()),
    resetEntity: () => dispatch(actions.resetEntityState())
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Panel);
