import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Grid, Toolbar } from '@material-ui/core';
import { Link } from 'react-router-dom';
import '../css/top-bar.css';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getLoggedUser } from '../../../services/generic';

// Import SVG Icons
import { ReactComponent as CompanyIcon } from '../../../assets/icons/topbar-icon.svg';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#333333',
    fontFamily: 'inherit',
    boxShadow: '0 4px 6px #0000001A'
  },
  headerBg: {
    backgroundColor: 'transparent',
    minHeight: 52,
    maxWidth: 1476,
    [theme.breakpoints.down('lg')]: {
      maxWidth: 1140,
      boxShadow: 'none'
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: 1000,
      boxShadow: 'none'
    },
    margin: '0 auto',
    width: '100%',
    padding: '0 24px 0 24px'
  },
  bgTransparent: {
    backgroundColor: 'transparent'
  },
  menuButton: {
    marginRight: -12,
    color: 'inherit'
  },
  title: {
    flexGrow: 1
  },
  logo: {
    paddingTop: 2,
    stroke: 'currentColor',
    fill: 'currentColor',
    textDecoration: 'none',
    color: 'inherit',
    textAlign: 'center'
  },
  list: {
    width: 280
  },
  headerMainBg: {
    backgroundColor: '#333333',
    boxShadow: '0px 4px 6px #0000001A'
  },
  iconMargin: {
    marginTop: 4
  },
  nextTxt: {
    fontSize: 16,
    letterSpacing: 0.24,
    color: '#111111'
  },
  textRight: {
    textAlign: 'right'
  },
  arrowMl: {
    marginLeft: -3,
    cursor: 'pointer'
  },
  pointer: {
    cursor: 'pointer'
  }
}));

const stepTypes = {
  associateNode: 4,
  appointDirectors: 5,
  appointOtherUsers: 6,
  inviteNodeHolder: 10
};

function EntityTopBar({
  handleNext,
  handleBack,
  activeStep,
  appointedNodes,
  associatingNodeHolderDetails,
  invitedEmails
}) {
  const classes = useStyles();
  const showBackButton = activeStep > 0;
  const showNextOrSkip =
    (activeStep === stepTypes.appointDirectors && appointedNodes === 1) ||
    (activeStep === stepTypes.inviteNodeHolder && invitedEmails === 5) ||
    (activeStep === stepTypes.associateNode &&
      (associatingNodeHolderDetails.verify === true || getLoggedUser())) ||
    activeStep === stepTypes.appointOtherUsers;

  const goToPrevPage = () => {
    if (showBackButton) {
      handleBack();
    }
  };

  const goToNextPage = () => {
    if (showNextOrSkip) {
      handleNext();
    }
  };

  return (
    <div>
      <AppBar
        className={classes.bgTransparent}
        position="sticky"
        color="inherit"
        elevation={0}
      >
        <Toolbar className={classes.headerBg}>
          <Grid
            justify="space-between"
            direction="row"
            alignItems="center"
            container
          >
            <Grid className={classes.iconMargin} onClick={goToPrevPage} item xs>
              {showBackButton && <ArrowBackIcon className={classes.arrowMl} />}
            </Grid>
            <Grid className={classes.logo} item xs>
              <Link to={'/'} className={classes.logo}>
                <CompanyIcon
                  height="25px"
                  width="25px"
                  strokeWidth="9px"
                  stroke="#333"
                />
              </Link>
            </Grid>

            <Grid className={classes.textRight} onClick={goToNextPage} item xs>
              {showNextOrSkip ? (
                <div className={classes.pointer}>
                  {activeStep === stepTypes.appointDirectors ||
                  activeStep === stepTypes.associateNode ||
                  activeStep === stepTypes.inviteNodeHolder
                    ? 'Next'
                    : 'Skip'}
                </div>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default EntityTopBar;
