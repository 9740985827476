import React from 'react';
import { withStyles, Grid, Typography } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

// import classnames from "classnames";
import EntitiesCardContainer from './EntitiesCardContainer';
import Moment from '../../../../../services/Moment';

// Import SVG Icons
import { ReactComponent as SlEntityIcon } from '../../../../../assets/icons/entity-class/sle.svg';

const styles = theme => ({
  cardContainer: {
    border: '1px solid #DADCE0',
    borderRadius: 4,
    margin: '0 8px 12px'
  },
  cardContainerPAdding: {
    padding: '16px'
  },
  sleIcon: {
    border: '1px solid #DADCE0',
    borderRadius: 4,
    backgroundColor: '#F8F8F8',
    width: '84%'
  },
  companyName: {
    color: '#555555',
    fontSize: 18,
    textTransform: 'capitalize',
    marginBottom: 10
  },
  companyDescName: {
    color: '#9E9E9E',
    fontSize: 14
  },
  copyTextContainer: {
    border: '1px solid #DADCE0',
    borderRadius: '2px',
    lineHeight: 1.3,
    marginTop: 10
  },
  copyText: {
    fontSize: '12px',
    color: '#777777',
    paddingLeft: '8px'
  },
  copyBg: {
    backgroundColor: '#DADCE0',
    color: '#777777',
    textAlign: 'center',
    height: '22px'
  },
  copyIcon: {
    fontSize: '14px',
    verticalAlign: 'middle',
    width: '14px'
  },
  manageEntityPAdding: {
    padding: '16px 10px',
    borderTop: '1px solid #DADCE0'
  }
});

function AssociatedEntityCard({
  entity,
  classes,
  setActivePage
  // setSelectedEntity
}) {
  return (
    <Grid>
      <EntitiesCardContainer
        styleClasses={classes}
        btnActions={'Manage entity'}
        setActivePage={setActivePage}
        entity={entity}
      >
        <Grid
          className={classes.cardContainerPAdding}
          container
          spacing={0}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={4}>
            <SlEntityIcon className={classes.sleIcon} />
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.companyName}>
              {entity.EntityName}
            </Typography>
            <Typography className={classes.companyDescName}>
              {entity.EntityType}
            </Typography>
            <Typography className={classes.companyDescName}>
              <Moment text={'Associated: '} date={entity.createdAt} />
            </Typography>
          </Grid>
          <Grid
            className={classes.copyTextContainer}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid className={classes.copyBg} item xs={1}>
              <FileCopyOutlinedIcon className={classes.copyIcon} />
            </Grid>
            <Grid className={classes.copyText} item xs={11}>
              {entity.EntityId}
            </Grid>
          </Grid>
        </Grid>
      </EntitiesCardContainer>
    </Grid>
  );
}

export default withStyles(styles)(AssociatedEntityCard);
