import React from "react";
import {
  withStyles,
  FormControl,
  InputBase,
  FormHelperText
} from "@material-ui/core";
import { createStyles } from "@material-ui/core/styles";
import classnames from "classnames";

const BootstrapInput = withStyles(theme =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3)
      },
      fontFamily: "inherit"
    },
    input: {
      borderRadius: 4,
      // color: '#9E9E9E',
      position: "relative",
      border: "1px solid #DAE1E7",
      fontSize: 16,
      fontFamily: "inherit",
      width: "100%",
      padding: "16px 12px 16px 60px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden"
    }
  })
)(InputBase);

const styles = theme => ({
  inputIcon: {
    position: "absolute",
    zIndex: 1,
    top: 12,
    left: 15,
    color: "#9E9E9E",
    fontSize: 26
  },
  fullWidth: {
    width: "100%"
  },
  emailIcon: {
    fontSize: 25,
    top: 14,
    position: "absolute",
    zIndex: 1,
    left: 15,
    color: "#9E9E9E"
  },
  marginInput: {
    marginBottom: 8
  },
  inputText: {
    fontSize: 16,
    letterSpacing: 0.24,
    color: "#9E9E9E",
    paddingLeft: 0
  },
  disableInput: {
    backgroundColor: "#f3f3f3"
  },
  inputBg: {
    backgroundColor: "#FCFCFC"
  }
});

function IconTextField({
  classes,
  InputIcon,
  disabled = false,
  value,
  placeholder,
  inputBgClass,
  onChangeHandler,
  hasError = { value: false, message: "" },
  type = "text"
}) {
  return (
    <div>
      <FormControl className={classes.fullWidth}>
        <InputIcon className={classes.inputIcon} />
        <BootstrapInput
          classes={{ input: (inputBgClass && inputBgClass.inputBg) || null }}
          className={classnames(
            classes.marginInput,
            disabled ? classes.disableInput : classes.inputBg
          )}
          placeholder={placeholder}
          type={type}
          value={value}
          disabled={disabled}
          onChange={onChangeHandler}
        />
        {hasError.value ? (
          <FormHelperText error={hasError.value}>
            {hasError.message}
          </FormHelperText>
        ) : (
          ""
        )}
      </FormControl>
    </div>
  );
}

export default withStyles(styles)(IconTextField);
