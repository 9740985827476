import React, { Fragment, useState } from 'react';
import { Typography, Grid, Button } from '@material-ui/core';
import ManageEntitiesContainer from '../../ManageEntity/ManageEntitiesContainer';
import EntitiesCardContainer from '../AssociatedEntities/EntitiesCardContainer';
import { equityPages } from './ManageEquityContainer';
import { manageEntityPages } from '../../ManageEntity/ManageEntitySteps';
import classnames from 'classnames';
import Moment from '../../../../../services/Moment';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from "../../../../../redux/actions";
import NoEntities from '../AssociatedEntities/NoEntities';



function ManageEquity({ classes, handleNext, setManageEntityStep, showHistory, setShowHistory, equityNodeHolders }) {
    const dispatch = useDispatch();
    const selectedEntity = useSelector(
    state => state.entityPlatform.selectedEntity
    );
    const nodeholderHistoryList = useSelector(state => state.entityPlatform.equity_nodeholders_histories !== null ? state.entityPlatform.equity_nodeholders_histories : []);
    const [equityHolders] = useState(equityNodeHolders.length > 0 ? equityNodeHolders.filter(({ InvitationStatus }) => InvitationStatus === 'Accepted') : []);
    const [allocateBtn] = useState(equityNodeHolders.map(({ EquityPercentage }) => Number(EquityPercentage)).reduce((a, b) => { return a + b }))

    const headerText = showHistory ? 
        (<Fragment>You currently have no history with any nodeholder.</Fragment>) : 
        (<Fragment>You currently have no equity nodeholder.</Fragment>)

    const EquityActions = () =>
        (
            <Button
                className={classes.pendingManageEntityBtnTxt}
                disableRipple={true}
                disabled={allocateBtn === 100}
                onClick={() => handleNext(equityPages.allocateEquityPage)}
            >
                Allocate
            </Button>
        );

    const nodeHolders = (
        <Grid container direction={"row"}>
            <Grid className={classes.mb20} container direction={"row"}>
                <Grid item xs={6} onClick={() => setShowHistory(false)} className={classnames(!showHistory && classes.activeText, classes.equityNodeholdersTab)}>
                    Nodeholders ({equityHolders.length})
            </Grid>
                <Grid item xs={6}
                    className={classnames(classes.textRight, classes.equityNodeholdersTab, showHistory && classes.activeText)}
                    onClick={() => {
                        setShowHistory(true);
                        dispatch(actions.getEquityNodeHolderHistories(selectedEntity.EntityId))
                    }} >
                    History
            </Grid>
            </Grid>

           
            {!showHistory && (
                !equityHolders.length ?
                    (<NoEntities
                            headerText={headerText}
                        />) :
                    (equityHolders.map((nodeHolder) => {
                            return (
                                <Fragment key={nodeHolder._id}>
                                    <Grid className={classes.cardContainer} container direction={"row"} alignItems="center" >
                                        <Grid item xs={9}>
                                            <Grid className={classes.companyName} item xs={12}>
                                                {nodeHolder.FullName ? nodeHolder.FullName : 'N/A'}
                                            </Grid>
                                            <Grid className={classes.companyDescName} item xs={12}>
                                                {nodeHolder.InvitedDate ? <Moment text={"Since: "} date={nodeHolder.InvitedDate} /> : 'Since: mm/dd/yy'}
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={3} className={classes.companyValue}>
                                            <Grid item xs={12}>
                                                {nodeHolder.EquityPercentage}%
                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Fragment>
                            )
                    }))
            )}

            {showHistory && (
                !nodeholderHistoryList.length ?
                    (<NoEntities
                        headerText={headerText}
                    />) :
                    (nodeholderHistoryList.map((nodeHolder) => {
                        return (
                            <Fragment key={nodeHolder._id}>
                                <Grid className={classes.cardContainer} container direction={"row"} alignItems="center" >
                                    <Grid item xs={9}>
                                        <Grid className={classes.companyName} item xs={12}>
                                            {nodeHolder.FullName ? nodeHolder.FullName : 'N/A'}
                                        </Grid>
                                        <Grid className={classes.companyDescName} item xs={12}>
                                            {nodeHolder.InvitedDate ? <Moment text={"Since: "} date={nodeHolder.InvitedDate} /> : 'Since: mm/dd/yy'}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Fragment>
                        )
                    }))
            )}
        </Grid>
    );



    return (
        <div>
            <ManageEntitiesContainer
                bottomText={'Continue to the entity\'s panel'}
                title={'Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum'}
                setManageEntityStep={setManageEntityStep}
                manageEntityPages={manageEntityPages}
            >
                <EntitiesCardContainer styleClasses={classes} btnActions={<EquityActions />} >
                    <Grid className={classes.cardContainerPAdding} container spacing={0} direction="row" alignItems="center">
                        <Typography className={classes.companyName}>Allocate Equity</Typography>
                        <Grid item xs={8} className={classes.companyDescName}>Manage your ownership stake in the company.</Grid>
                    </Grid>
                </EntitiesCardContainer>
                <EntitiesCardContainer styleClasses={classes} nodeHolders={nodeHolders} >
                    <Grid className={classes.cardContainerPAdding} container spacing={0} direction="row" alignItems="center">
                        <Typography className={classes.companyName}>Equity Nodeholders</Typography>
                        <Grid item xs={10} className={classes.companyDescName}>The nodeholders currently in control of the company and their ownership stake in its underlying association.</Grid>
                    </Grid>
                </EntitiesCardContainer>
            </ManageEntitiesContainer>
        </div>
    )
}

export default ManageEquity;