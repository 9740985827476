import React from "react";
import {
  withStyles,
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const ColorButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText("#333333"),
    backgroundColor: "#333333",
    "&:hover": {
      backgroundColor: "#333333"
    },
    width: "100%",
    padding: "14px 0px 15px 0px",
    fontSize: "0.875rem",
    letterSpacing: "0.5px"
  }
}))(Button);

export default function CustomizedButtons({ onSubmit, label }) {
  return (
    <div>
      <ColorButton variant="contained" color="primary" onClick={onSubmit}>
        {label}
      </ColorButton>
    </div>
  );
}
