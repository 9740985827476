import React, { Component } from 'react';
import { Grid, Button } from '@material-ui/core';
import GenricDialog from '../../components/genric/GenricDialog';
import * as actions from '../../redux/actions';
import isEmpty from 'lodash/isEmpty';
import './panel.css';

export class NodeholderPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      password: '',
      Name: this.props.userDetail.data.Name,
      Email: this.props.userDetail.data.Email,
      MobileNo: `${
        this.props.userDetail.data.MobileNo
          ? this.props.userDetail.data.MobileNo
          : ''
      }`,
      errorText: '',
      dialogTitle: '',
      dialogLabel: '',
      dialogSub: '',
      dialogInputType: '',
      dialogInputName: '',
      emailVerified: this.props.userDetail.data.AccountVerified,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.userDetail.isSuccess !== this.props.userDetail.isSuccess &&
      !isEmpty(this.props.userDetail.data)
    ) {
      this.setState({
        MobileNo: `${
          this.props.userDetail.data.MobileNo
            ? this.props.userDetail.data.MobileNo
            : ''
        }`,
        Email: this.props.userDetail.data.Email,
        emailVerified: this.props.userDetail.data.AccountVerified,
        open: false,
      });
    } else if (this.props.auth.isLogedOut) {
      this.props.history.push('/login');
    }
  }

  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value, errorText: '' });
  };

  onClose = (name) => {
    if (name === 'Email' || name === 'MobileNo') {
      this.setState({
        [name]: this.props.userDetail.data[name],
        open: false,
        errorText: '',
      });
    } else {
      this.setState({ [name]: '', open: false, errorText: '' });
    }
  };

  onSubmit = (name) => {
    if (!this.state[name]) {
      this.setState({ errorText: 'Please fill the field' });
    } else {
      if (name === 'Email') {
        if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.Email)
        ) {
          this.setState({ errorText: 'Invalid email address' });
        } else {
          this.setState({ errorText: '' });
          this.props.updateProfile({ Email: this.state.Email });
        }
      } else if (name === 'MobileNo') {
        if (this.state.MobileNo.length < 10) {
          this.setState({ errorText: 'Invalid Mobile No' });
        } else {
          this.setState({ errorText: '' });
          this.props.updateProfile({ MobileNo: this.state.MobileNo });
        }
      } else {
        this.setState({ errorText: '' });
        this.props.updateProfile({ Password: this.state.password });
      }
    }
  };

  render() {
    const {
      open,
      errorText,
      dialogTitle,
      dialogLabel,
      dialogSub,
      dialogInputName,
      dialogInputType,
    } = this.state;
    // const { data } = this.props.userDetail;

    return (
      <div className='panel-container'>
        <Grid container spacing={0} justify='center'>
          <Grid item xs={12}>
            <div className='panel-tag'>Panel</div>
          </Grid>
          <Grid item xs={12}>
            <div className='panel-header'>Nodeholder ID</div>
          </Grid>
          <Grid item xs={12}>
            <div className='nodeholder-id'>
              {this.props.userDetail.data.NodeHolderAdd}
            </div>
          </Grid>
          <Grid
            container
            spacing={0}
            justify='center'
            className='panel-details'
          >
            <Grid item xs={6} className='detail-section'>
              <div className='detail-header'>Name</div>
              <div className='nodeholder-name'>
                {this.props.userDetail.data.FullName}
              </div>
            </Grid>
            <Grid item xs={6} className='detail-section'>
              <div className='detail-header'>Password</div>
              <Button
                className='detail-handler edit'
                onClick={() => {
                  this.setState({
                    dialogLabel: 'Password',
                    dialogTitle: 'Change Password',
                    dialogSub:
                      'In order to keep your account safe, please enter a new password.',
                    dialogInputType: 'password',
                    dialogInputName: 'password',
                    open: true,
                  });
                }}
              >
                Edit
              </Button>
            </Grid>
            <Grid item xs={6} className='detail-section'>
              <div className='detail-header'>Email</div>
              <div className='detail-handler verify'>
                {this.state.emailVerified ? 'Verified' : 'Unverified'}
              </div>
            </Grid>
            <Grid item xs={6} className='detail-section'>
              <div className='detail-header'>Email</div>
              <Button
                className='detail-handler edit'
                onClick={() => {
                  this.setState({
                    dialogLabel: 'Email',
                    dialogTitle: 'Change Email',
                    dialogSub:
                      'In order to change your email address, a verification link will be sent to your current email address',
                    dialogInputType: 'Email',
                    dialogInputName: 'Email',
                    open: true,
                  });
                }}
              >
                Edit
              </Button>
            </Grid>
            <Grid item xs={6} className='detail-section'>
              <div className='detail-header'>Mobile</div>
              <div className='detail-handler verify'>Verify</div>
            </Grid>
            <Grid item xs={6} className='detail-section'>
              <div className='detail-header'>Mobile</div>
              <Button
                className='detail-handler edit'
                onClick={() => {
                  this.setState({
                    dialogLabel: 'Mobile',
                    dialogTitle: 'Change Mobile Number',
                    // dialogSub:
                    //   "In order to change your email address, a verification link will be sent to your current email address",
                    dialogInputType: 'number',
                    dialogInputName: 'MobileNo',
                    open: true,
                  });
                }}
              >
                Edit
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button
              className='sign-out'
              onClick={() => {
                localStorage.removeItem('nodeHolderToken');
                this.props.logOut();
                this.props.history.push('/');
              }}
            >
              Sign out
            </Button>
          </Grid>
          <Button
            variant='outlined'
            disabled
            className='entities-btn'
            // onClick={() => {
            //   this.props.history.push('/entity-platform');
            // }}
          >
            My Entities
          </Button>
          <Button
            variant='outlined'
            disabled
            className='reserve-btn'
            // onClick={() => {
            //   this.props.history.push('/entity-formation');
            // }}
          >
            New Entity
          </Button>
        </Grid>
        <GenricDialog
          open={open}
          onClose={this.onClose}
          title={dialogTitle}
          label={dialogLabel}
          sub={dialogSub}
          onChange={this.handleChange}
          inputType={dialogInputType}
          error={errorText}
          onSubmit={this.onSubmit}
          inputName={dialogInputName}
          inputValue={this.state[dialogInputName]}
          isLoading={this.props.userDetail.isLoading}
        />
      </div>
    );
  }
}

// const mapDispatchToProps = dispatch => {
//   return {
//     getProfile: () => dispatch(actions.profileRequest()),
//     updateProfile: data => dispatch(actions.updateProfileRequest(data)),
//     logOut: () => dispatch(actions.logout()),
//     checkAvailableEntityTypes: data =>
//       dispatch(actions.getEntityTypeAvailability(data)),
//     emailVerification: () => dispatch(actions.verifyEmailRequest()),
//     reserveEntity: data => dispatch(actions.entityFormRequest(data)),
//     getUserEntities: () => dispatch(actions.getUserEntities()),
//     resetEntity: () => dispatch(actions.resetEntityState())
//   };
// };

export default NodeholderPanel;
