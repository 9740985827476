import React from "react";
import { connect } from "react-redux";
import Div100vh from "react-div-100vh";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import Website from "./views/website/index";
import NewAct from "./views/website/act/NewAct";
import RegisterForm from "./views/user-form/register/RegisterForm";
import LoginForm from "./views/user-form/login/LoginPage";
import IdRecovery from "./views/user-form/recovery/IdRecovery";
import PassRecovery from "./views/user-form/recovery/PassRecovery";
import MasterForm from "./views/create-entity-form/MasterForm";
import { getLoggedUser } from "./services/generic";
import Panel from "./views/nodeholder-panel/Panel";
import MatchWithHash from "./components/routing/MatchWithHash";
import { compose } from "redux";
import TopBarContainer from "./views/website/components/TopBarContainer";
import EntityPlatformContainer from "./views/entity-platform/EntityPlatformContainer";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import MatchWithParams from "./components/routing/MatchWithParams";

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  palette: {
    primary: {
      main: "#535353",
    },
  },
});

function App(props) {
  const PrivateRoute = ({ path, component }) => {
    return (
      <>
        {getLoggedUser() ? (
          props.location.state && props.location.state.to ? (
            <Redirect to={props.location.state.to} />
          ) : (
            <Route path={path} component={component} />
          )
        ) : (
          <Redirect to="/" />
        )}
      </>
    );
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Div100vh>
        <div className="App">
          <TopBarContainer
            location={props.location}
            isLoggedIn={props.auth.isLoggedIn}
          />
          <Switch>
            <Route exact path="/" component={Website} />
            {/* <Route path="/decentralized-companies-act" component={Act} /> */}
            {/* <Route
              exact
              path="/decentralized-companies-act/:id?/:subId?"
              component={NewAct}
            /> */}
            {/* <Route path="/formation-of-entities" component={Formation} />
            <Route path="/nodeholders" component={Nodeholders} />
            <Route path="/smart-legal-entity" component={SLE} /> */}
            <Route path="/entity-formation" component={MasterForm} />
            <Route path="/login" component={LoginForm} />
            {/* <Route path='/id-recovery' component={IdRecovery} /> */}
            <Route path="/password-recovery" component={PassRecovery} />
            <Route path="/register" component={RegisterForm} />
            {/* <PrivateRoute
              path="/entity-platform"
              component={EntityPlatformContainer}
            /> */}
            <PrivateRoute path="/nodeholder-panel" component={Panel} />
            {/* <MatchWithHash
              path="/formation-of-entities"
              component={Formation}
            /> */}
          </Switch>
        </div>
      </Div100vh>
    </MuiThemeProvider>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default compose(withRouter, connect(mapStateToProps, {}))(App);
