import React, { useEffect, Fragment } from "react";
import { Grid, Divider, useMediaQuery } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { withRouter, generatePath, Link } from "react-router-dom";
import TopBar from "../components/TopBar";
import Scrollspy from "react-scrollspy";
import { connect } from "react-redux";
import { compose } from "redux";
import actData from "./data";
import "./act.css";

// name = new link, newName will be new_link
const urlHashFormatter = (name) => {
  var newName = name.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "");
  return newName.split(" ").join("_").toLowerCase();
};

// name = DECENTRALIZED_ENTITIES, newName = DECENTRALIZED ENTITIES
const labelFormatter = (name) => {
  var newName = name.replace(
    /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
    "_"
  );
  return newName.split("_").join(" ");
};

function GroupedSelect({ history, match, auth }) {
  const [selectedValue, setSelectedValue] = React.useState(
    actData[0].header.name
  );
  const [defaultParams, setDefaultParams] = React.useState(null);
  let maxWithTopicList = [];
  let itemsOfScrollSpy = [];
  let itemsOfScrollSpyForSmallScreen = [];
  const smallScreenMatches = useMediaQuery("(max-width:1030px)");
  const bigScreenMatches = useMediaQuery("(min-width:1031px)");

  actData.map(({ header }, headerIndex) => {
    if (header && header.subHeader && header.subHeader.length > 0) {
      if (header.name) {
        header.identity = "header";
        maxWithTopicList.push(header);
        itemsOfScrollSpy.push(urlHashFormatter(header.name.toLowerCase()));
        itemsOfScrollSpyForSmallScreen.push(
          urlHashFormatter(header.name.toLowerCase())
        );
      }
      header.subHeader.map((subheader) => {
        subheader.identity = "subHeader";
        if (subheader.name) {
          maxWithTopicList.push(subheader);
          itemsOfScrollSpy.push(urlHashFormatter(subheader.name));
        }
      });
    }
  });

  useEffect(() => {
    document.body.style.overflowX = "unset";
    return () => {
      document.body.style.overflowX = "hidden";
    };
  }, []);

  const onOptionChange = (e) => {
    const id = e.target.value
    setSelectedValue(id);
    console.log(id);
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView()
    }
    history.push({
      pathname: generatePath(match.path, { id: id }),
    });
  };

  
  const onSmallScreenUpdate = (e) => {
  
    if (e && e.id) {
      history.replace({
        pathname: generatePath(match.path, { id: e.id.toLowerCase() }),
      });
      setSelectedValue(e.id.toLowerCase());
      //   setSelectedValue(e.id.toLowerCase()); 
      // setSelectedValue(e.id.toLowerCase());
    }
  };

  const onBigScreenUpdate = (e) => {
    let id = null;
    let subId = null;
    if (e && e.id) {
      const currentElement = document.getElementById(e.id);
      if (currentElement.className.split(" ")[0] === "section-subheader") {
        subId = e.id.toLowerCase();
        id = currentElement.parentNode.childNodes[0].id.toLowerCase();
      } else {
        id = e.id.toLowerCase();
      }

      if (defaultParams !== null) {
        setSelectedValue(e.id.toLowerCase());
        history.replace({
          pathname: generatePath(match.path, { id, subId }),
        });
      } else {
        setDefaultParams(id.toLowerCase());
      }
    }
  };

  const loadDetailsData = (header) => (
    <Fragment>
      <div className="header" id={urlHashFormatter(header.name).toLowerCase()}>
        {header.name}
        {/* <button
          onClick={() =>
            console.log(
              "selected topic: " + selectedValue,
              "params: " + defaultParams,
              +"items for scrollspy: " +
                JSON.stringify(itemsOfScrollSpyForSmallScreen)
            )
          }
        >
          check me
        </button> */}
      </div>

      {header.data?.map(({ content }, contentIndex) => (
        <p
          className={` ${contentIndex === 0 ? "mt16" : ""}`}
          key={contentIndex}
        >
          {content}
        </p>
      ))}

      {header?.subHeader?.map(({ name, data, subHeader }, subHeaderIndex) => (
        <Fragment key={subHeaderIndex}>
          {name && (
            <div
              className={`sub-header ${subHeaderIndex === 0 ? "mt16" : ""}`}
              id={urlHashFormatter(name)}
            >
              {name}
            </div>
          )}
          {data?.map(({ content }, index) => (
            <Fragment key={index}>
              {content && <p className="section-info">{content}</p>}
            </Fragment>
          ))}
          {subHeader?.map(({ name, data }, index) => (
            <Fragment key={index}>
              {name && (
                <p className="section-point" key={index}>
                  {name}
                </p>
              )}
              {data && (
                <p className="section-info">
                  {data.map(({ content }, index) => (
                    <p className="section-info" key={index}>
                      {content}
                    </p>
                  ))}
                </p>
              )}
            </Fragment>
          ))}
        </Fragment>
      ))}
    </Fragment>
  );

  return (
    <Fragment>
      <TopBar isLoggedIn={auth.isLoggedIn} />

      <div>
        {bigScreenMatches && (
          <div className="sidebar">
            <Scrollspy
              className="scrollspy"
              items={itemsOfScrollSpy}
              currentClassName="isCurrent"
              componentTag={"div"}
              onUpdate={onBigScreenUpdate}
            >
              {maxWithTopicList &&
                maxWithTopicList.length > 0 &&
                maxWithTopicList.map(({ name, identity }, index) => (
                  <div
                    key={index}
                    className={
                      identity === "header"
                        ? "section-header"
                        : "section-subheader"
                    }
                  >
                    <a href={`#${urlHashFormatter(name)}`}>{name}</a>
                  </div>
                ))}
            </Scrollspy>
          </div>
        )}
        {bigScreenMatches && (
          <div className="signIn-container">
            {auth.isLoggedIn ? (
              <Link to="/nodeholder-panel" className="doc-links">
                Nodeholder Panel
              </Link>
            ) : (
                <Link to="/login" className="doc-links">
                  Sign In →
                </Link>
              )}
          </div>
        )}
        <div className="act-container">
          {smallScreenMatches && (
            <Grid
              container
              spacing={0}
              display="flex"
              alignItems="center"
              className="topbar-container"
            >
              <Grid item sm={10} xs={9}>
                <Scrollspy
                  className="scrollspy_mobile"
                  items={itemsOfScrollSpyForSmallScreen}
                  currentClassName=""
                  offset={0}
                  componentTag={"div"}
                  onUpdate={onSmallScreenUpdate}
                >
                  <div className="mobileNav">
                    <label
                      className="mobileNav-label"
                      htmlFor="grouped-native-select"
                    >
                      {labelFormatter(selectedValue)}
                      <KeyboardArrowDownIcon />
                    </label>
                    <select
                      className="mobileNav-select"
                      defaultValue=""
                      onChange={(e) => onOptionChange(e)}
                    >
                      <optgroup label="Topics">
                        {actData.map(({ header }, headerIndex) => (
                          <option
                            key={headerIndex}
                            value={urlHashFormatter(header.name).toLowerCase()}
                          >
                            {header.name}
                          </option>
                        ))}
                      </optgroup>
                    </select>
                  </div>
                </Scrollspy>
              </Grid>
              <Grid item sm={2} xs={3} className="link-container">
                <div>
                  {auth.isLoggedIn ? (
                    <Link to="/nodeholder-panel" className="doc-links">
                      Panel
                    </Link>
                  ) : (
                      <Link to="/login" className="doc-links">
                        Sign In →
                      </Link>
                    )}
                </div>
              </Grid>
            </Grid>
          )}

          <div
            style={{
              height: "52px",
              width: "100vw",
              backgroundColor: "#fff",
            }}
          ></div>

          <Grid container spacing={0}>
            {actData.map(({ header }, headerIndex) => (
              <Fragment key={headerIndex}>
                {smallScreenMatches && (
                  <Grid
                    item
                    xs={12}
                    className="section-container"
                    id={urlHashFormatter(header.name)}
                  >
                    {loadDetailsData(header)}
                  </Grid>
                )}
                {bigScreenMatches && (
                  <Grid item xs={12} className="section-container">
                    {loadDetailsData(header)}
                  </Grid>
                )}

                <Divider style={{ width: "100%", margin: "0px 0" }} />
              </Fragment>
            ))}
          </Grid>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default compose(withRouter, connect(mapStateToProps, {}))(GroupedSelect);
