import React, { useState, createRef, useEffect } from "react";
import AlterationContainer from "./AlterationContainer";
import { Grid, withStyles } from "@material-ui/core";
import classnames from "classnames";
import InputBase from "@material-ui/core/InputBase";
import { createStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

const BootstrapInput = withStyles(theme =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3)
      }
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: "#FCFCFC",
      border: "2px solid #DAE1E7",
      fontSize: 16,
      width: "100%",
      padding: "15px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      color: "#9E9E9E",
      '&:focus': {
        border: "2px solid #535353",
      }
    }
  })
)(InputBase);

const styles = theme => ({
  TnCBlock: {
    width: "100%",
    backgroundColor: "#FCFCFC",
    border: "1px solid #DADCE0",
    padding: "16px 15px",
    borderRadius: "5px"
  },
  currentName: {
    fontSize: 18,
    color: "#555555",
    marginBottom: 8
  },
  currentDesc: {
    fontSize: 14,
    color: "#9E9E9E"
  },
  middleText: {
    marginTop: 42,
    marginBottom: 16,
    textAlign: "center",
    lineHeight: "20px"
  },
  font16: {
    fontSize: 16
  },
  entityAvailability: {
    fontSize: 14,
    textAlign: "center",
    marginTop: 40,
    marginBottom: 4
  },
  unavailable: {
    color: "#FF0012"
  },
  available: {
    color: "#00BE20"
  },
  differentName: {
    fontSize: 14,
    color: "#9E9E9E",
    textAlign: "center",
    marginBottom: 30
  }
});

function AlterationOfEntityName({
  classes,
  handleNext,
  handleBack,
  setNewEntityName,
  newEntityName,
  onNameCheck,
  error
}) {

  const nameAvailability = useSelector(
    state => state.entityForm.nameAvailability && state.entityForm.nameAvailability.data.available
  );
  const selectedEntity = useSelector(
    state => state.entityPlatform.selectedEntity
  );
  
  const [oldEntityName, setOldEntityName] = useState(selectedEntity.EntityName)
  const keyTimer = createRef();
  //on keyup, start the countdown
  const onKeyup = e => {
    window.clearTimeout(keyTimer.current);
    keyTimer.current = setTimeout(doneTyping, 500);
  };

  //on keydown, clear the countdown
  const onKeyDown = () => {
    window.clearTimeout(keyTimer.current);
  };

  //user is "finished typing," make the api call
  const doneTyping = () => {
    onNameCheck();
  };

  const onKeyPress = e => {
    let val = e;
    let regex = /\b[a-z]/g;
    val = val.toLowerCase().replace(regex, function(letter) {
      return letter.toUpperCase();
    });
    setNewEntityName(val);
  };

  useEffect(() => {
    let val = oldEntityName;
    let regex = /\b[a-z]/g;
    val = val.toLowerCase().replace(regex, function(letter) {
      return letter.toUpperCase();
    });
    setOldEntityName(val)
  }, [selectedEntity.EntityName])

  return (
    <div>
      <AlterationContainer
        btnText={"Back"}
        handleNext={handleNext}
        containedBtnText={"Proceed"}
        handleBack={handleBack}
        AcceptTerms={newEntityName.length > 3 && !error.value && nameAvailability}
        title={"Select New Entity Name"}
      >
        <Grid className={classes.TnCBlock} cols={1}>
          <Grid className={classes.currentName}>{oldEntityName}</Grid>
          <Grid className={classes.currentDesc}>{(selectedEntity.AssociatingNodeName).toUpperCase()}</Grid>
        </Grid>
        <Grid className={classnames(classes.currentDesc, classes.middleText)}>
          Type down the entity's new official name to verify availability.
        </Grid>

        <Grid item xs={12}>
          <BootstrapInput
            style={{ width: "100%" }}
            value={newEntityName}
            onChange={e => setNewEntityName(e.target.value)}
            onKeyUp={onKeyup}
            onKeyDown={onKeyDown}
            onKeyPress={onKeyPress(newEntityName)}
            placeholder="New name.."
            id="bootstrap-input"
          />
        </Grid>
        <Grid
          item
          xs={12}
          className={classnames(
            classes.entityAvailability,
            newEntityName.length > 3
              ? !error.value && nameAvailability
                ? classes.available
                : classes.unavailable
              : ""
          )}
        >
          {newEntityName.length > 3
            ? error.value ? error.message : (nameAvailability
              ? "Name Available"
              : "Name Unavailable")
            : ""}
        </Grid>
        <Grid item xs={12} className={classes.differentName}>
          {newEntityName.length > 3
            ? error.value || (!nameAvailability)
              ? "Please choose a different name."
              : ""
            : ""}
        </Grid>
      </AlterationContainer>
    </div>
  );
}

export default withStyles(styles)(AlterationOfEntityName);
