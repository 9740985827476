import React, { Fragment, useEffect } from 'react';
import { withStyles, Box } from '@material-ui/core';
import NoEntities from '../../Entities/AssociatedEntities/NoEntities';
import NominationsCard from './NominationsCard';
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../../../redux/actions";


const styles = theme => ({
    NoEntitiesMb: {
        marginBottom: 35,
        width: 120,
        height: 120,
        backgroundColor: '#F8F8F8',
        border: '1px solid #DADCE0',
        borderRadius: 4,
        marginTop: 35,
    }
});

function NewNomination({ classes, handleNext }) {
    const dispatch = useDispatch();
    let nominations = useSelector(state => state.entityPlatform.nodeholding_nominations ? state.entityPlatform.nodeholding_nominations : []);

    useEffect(() => {
        dispatch(actions.getAllNominations())
    }, []);

    const NominationModalChild = () => {
        return (
            <Fragment>
                <Box className={classes.confirmationText}>Accepting the nomination will associate your node with the underlying entity.</Box>
                <Box className={classes.confirmationText}>Your confirmation indicates you are aware and agree to the nodeholding terms set by
                the nominating party.</Box>
            </Fragment>
        );
    }

    const headerText = (<Fragment>You currently have no pending nodeholding nominations.</Fragment>);
    const buttons = {
        button1: 'Decline',
        button2: 'Accept Nominations'
    };
    const modalData = {
        title: 'Nodeholder Nomination',
        child: <NominationModalChild />,
    };

    return (
        <Fragment>
            {
                !nominations.length ?
                    (<NoEntities
                        entityCardMargin={classes}
                        noCardPAdding={true}
                        headerText={headerText}
                    />) :
                    nominations.map(nomination => (
                        <NominationsCard
                            key={nomination.EntityId}
                            nomination={nomination}
                            activeText={'hh:mm'}
                            cardButtonRight={buttons}
                            modalData={modalData}
                            handleNext={handleNext}
                           />)
                    )
            }
        </Fragment>
    )
}

export default withStyles(styles)(NewNomination)