import constants from "../constants";

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  isResign: false,
  message: "",
  associated_entities: [],
  nodeholding_nominations: [],
  nodeholding_nominationsHistories: [],
  equity_nodeholders_histories: [],
  nodeholding_status_roles: [],
  nodeholding_status_histories: [],
  currentNominationCardId: null,
  passwordVerified: null,
  isPassVerified: false,
  isAppointNodeholderSuccess: false,
  entityEquityNodeholders: [],
  isAllocatedError: false,
  nodeRoles: [{ limit: 2, role: 'Director' }, { limit: 1, role: 'Chairman' }, { limit: 2, role: 'Ceo' }, { limit: 5, role: 'Founder' }],
  remainingRoleLimits: [{ limit: 2, role: 'Director' }, { limit: 1, role: 'Chairman' }, { limit: 2, role: 'Ceo' }, { limit: 5, role: 'Founder' }],
  selectedEntity: {},
  oldEntityName: ''
};

export default function (state = initialState, action) {
  let associated_entities;
  let entityIndex;


  const removeEntity = (list, id) => {
    const entityIndex =
      list &&
      list.findIndex(
        history => history.EntityId === id
      );
    if (entityIndex > -1) list.splice(entityIndex, 1);
    return list;
  }

  switch (action.type) {
    case constants.GET_ASSOCIATED_ENTITIES:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: ""
      };
    case constants.GET_ASSOCIATED_ENTITIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        message: action.payload.statusText,
        associated_entities: action.payload.data
      };
    case constants.GET_ASSOCIATED_ENTITIES_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: ""
      };
    case constants.GET_ALL_NOMINATIONS:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: ""
      };
    case constants.GET_ALL_NOMINATIONS_SUCCESS:
      const nodeholding_nominations = action.payload;
      return {
        ...state,
        isLoading: false,
        isError: false,
        nodeholding_nominations
      };
    case constants.GET_ALL_NOMINATIONS_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: ""
      };
    case constants.GET_NOMINATION_HISTORY:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: ""
      };
    case constants.GET_NOMINATION_HISTORY_SUCCESS:
      const nodeholding_nominationsHistories = action.payload;
      return {
        ...state,
        isLoading: false,
        isError: false,
        nodeholding_nominationsHistories
      };
    case constants.GET_NOMINATION_HISTORY_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: ""
      };

    case constants.GET_EQUITY_NODE_HOLDERS_HISTORIES:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: ""
      };
    case constants.GET_EQUITY_NODE_HOLDERS_HISTORIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        equity_nodeholders_histories: action.payload
      };
    case constants.GET_EQUITY_NODE_HOLDERS_HISTORIES_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: ""
      };
    case constants.SET_SUSPENDED_ENTITY:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: ""
      };
    case constants.SET_SUSPENDED_ENTITY_SUCCESS:
      associated_entities = state.associated_entities;
      entityIndex =
        associated_entities &&
        associated_entities.findIndex(
          entity => entity.EntityId === action.payload.EntityId
        );
      if (entityIndex > -1) {
        associated_entities[entityIndex] = action.payload;
      }
      return {
        ...state,
        isLoading: false,
        isError: false,
        isPassVerified: false,
        passwordVerified: false,
        associated_entities,
        selectedEntity: action.payload
      };
    case constants.SET_SUSPENDED_ENTITY_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: ""
      };
    case constants.CHANGE_ENTITY_NAME:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: "",
        passwordVerified: state.passwordVerified
      };
    case constants.CHANGE_ENTITY_NAME_SUCCESS:
      associated_entities = state.associated_entities;
      const oldEntityName = state.selectedEntity.EntityName;
      const updatedAssociatedEntity = action.payload;
      entityIndex =
        associated_entities &&
        associated_entities.findIndex(
          entity => entity.EntityId === updatedAssociatedEntity.EntityId
        );
      if (entityIndex > -1) {
        associated_entities[entityIndex] = updatedAssociatedEntity;
      }
      return {
        ...state,
        isLoading: false,
        isError: false,
        associated_entities,
        oldEntityName,
        // isPassVerified: false,
        // passwordVerified: false,
        selectedEntity: updatedAssociatedEntity
      };
    case constants.CHANGE_ENTITY_NAME_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: ""
      };

    case constants.GET_NODE_HOLDER_STATUS_ROLES:
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
        message: ""
      };
    case constants.GET_NODE_HOLDER_STATUS_ROLES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        nodeholding_status_roles: action.payload
      };
    case constants.GET_NODE_HOLDER_STATUS_ROLES_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        message: ""
      };

    case constants.RESIGN_ROLE:
      return {
        ...state,
        isLoading: true,
        isError: false,
        isResign: false,
        isSuccess: false,
        message: ""
      };
    case constants.RESIGN_ROLE_SUCCESS:
      const { resignData, response } = action.payload;
      const nodeholding_status_roles = [...state.nodeholding_status_roles];
      const { Resigned, ResignedDate, NominationStatus } = response.NodeHolderRoles.find((node) => node.NodeHolderEmail === resignData.Email);

      entityIndex =
        nodeholding_status_roles &&
        nodeholding_status_roles.findIndex(
          nodeholders_role => nodeholders_role.EntityId === resignData.EntityId
        );

      if (entityIndex > -1) {
        nodeholding_status_roles[entityIndex].Resigned = Resigned;
        nodeholding_status_roles[entityIndex].ResignedDate = ResignedDate;
        nodeholding_status_roles[entityIndex].NominationStatus = NominationStatus;
      }
      return {
        ...state,
        isLoading: false,
        isError: false,
        isResign: true,
        isSuccess: true,
        nodeholding_status_roles
      };
    case constants.RESIGN_ROLE_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isResign: false,
        isSuccess: false,
        message: ""
      };

    case constants.GET_NODE_HOLDER_STATUS_HISTORY:
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
        message: ""
      };
    case constants.GET_NODE_HOLDER_STATUS_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        nodeholding_status_histories: action.payload
      };
    case constants.GET_NODE_HOLDER_STATUS_HISTORY_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        message: ""
      };

    case constants.NOMINATION_ACTION:
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
        message: ""
      };

    case constants.NOMINATION_ACTION_SUCCESS:
      const list = [...state.nodeholding_nominations];
      const id = action.payload;
      const updatedList = removeEntity(list, id);

      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        nodeholding_nominations: updatedList
      };

    case constants.NOMINATION_ACTION_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        message: ""
      };

    case constants.CURRENT_NOMINATION_CARD_ENTITY_ID:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: ""
      };

    case constants.CURRENT_NOMINATION_CARD_ENTITY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        currentNominationCardId: action.payload
      };

    case constants.CURRENT_NOMINATION_CARD_ENTITY_ID_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: ""
      };

    case constants.VERIFY_PASSWORD:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: "",
        isPassVerified: false,
        passwordVerified: null
      };

    case constants.VERIFY_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isPassVerified: true,
        passwordVerified: action.payload
      };

    case constants.VERIFY_PASSWORD_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: "",
        isPassVerified: false,
        passwordVerified: null
      };

    case constants.SET_VISIBILITY_STATUS:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: ""
      };

    case constants.SET_VISIBILITY_STATUS_SUCCESS:

      const data = action.payload;
      const { EntityId, type } = data;

      if (type === 'nomination') {
        const list = [...state.nodeholding_nominationsHistories];
        const updatedList = removeEntity(list, EntityId);
        return {
          ...state,
          isLoading: false,
          isError: false,
          nodeholding_nominationsHistories: updatedList
        };
      } else {
        const list = [...state.nodeholding_status_histories];
        const updatedList = removeEntity(list, EntityId);
        return {
          ...state,
          isLoading: false,
          isError: false,
          nodeholding_status_histories: updatedList
        };
      }

    case constants.SET_VISIBILITY_STATUS_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: ""
      };

    case constants.SET_APPOINT_NODE_HOLDER:
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
        message: "",
        isAppointNodeholderSuccess: false
      };

    case constants.SET_APPOINT_NODE_HOLDER_SUCCESS:
      const appointNodeholderSuccessData = action.payload;
      const responseEntityId = appointNodeholderSuccessData.EntityId;
      const associated_entitiesClone = [...state.associated_entities];
      entityIndex =
        associated_entitiesClone &&
        associated_entitiesClone.findIndex(
          entity => entity.EntityId === responseEntityId
        );
      if (entityIndex > -1) {
        associated_entitiesClone.splice(entityIndex, 1);
        associated_entitiesClone.push(appointNodeholderSuccessData);
      }
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        isAppointNodeholderSuccess: appointNodeholderSuccessData ? true : false,
        associated_entities: associated_entitiesClone,
        selectedEntity: action.payload
      };

    case constants.SET_APPOINT_NODE_HOLDER_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        isAppointNodeholderSuccess: false,
        message: ""
      };

    case constants.UPDATE_PASSWORD_VERIFIED_FLAG:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: ""
      };

    case constants.UPDATE_PASSWORD_VERIFIED_FLAG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isPassVerified: false,
        passwordVerified: false
      };

    case constants.ALLOCATE_EQT:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: "",
        isAllocatedError: false
      };

    case constants.ALLOCATE_EQT_SUCCESS:
      const entities = [...state.associated_entities];
      // const equityNodeholders = entities;
      if (action.payload) {
        const Id = action.payload.EntityId;
        //redux state management is pending due to no api available
        entityIndex =
          entities &&
          entities.findIndex(
            entity => entity.EntityId === Id
          );
        if (entityIndex > -1) {
          entities[entityIndex] = action.payload
        }
      }

      return {
        ...state,
        isLoading: false,
        isError: false,
        associated_entities: entities,
        isAllocatedError: false,
        selectedEntity: action.payload
      };

    case constants.ALLOCATE_EQT_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        message: action.payload,
        isAllocatedError: true
      };
    //isAppointNodeholderSuccess
    case constants.UPDATE_IS_APPOINT_NODEHOLDER_FLAG:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: ""
      };

    case constants.UPDATE_IS_APPOINT_NODEHOLDER_FLAG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isAppointNodeholderSuccess: false
      };

    case constants.UPDATE_ROLE_LIMITS:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: ""
      };

    case constants.UPDATE_ROLE_LIMITS_SUCCESS:
      const selectedEntity = state.selectedEntity;
      const roleListClone = state.remainingRoleLimits.slice();
      if (selectedEntity.NodeHolderRoles && selectedEntity.NodeHolderRoles.length > 0) {
        roleListClone.map(role => {
          selectedEntity.NodeHolderRoles.map(node => {
            if ((role.role).toLowerCase() === (node.NodeHolderRole).toLowerCase()) {
              if (role.limit > 0) --role.limit;
            }
          });
        });
      }

      return {
        ...state,
        isLoading: false,
        isError: false,
        remainingRoleLimits: roleListClone
      };

    case constants.SET_SELECTED_ENTITY:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: ""
      };

    case constants.SET_SELECTED_ENTITY_SUCCESS:

      return {
        ...state,
        isLoading: false,
        isError: false,
        selectedEntity: action.payload
      };

    case constants.UPDATE_NODE_ROLES_AS_INITIAL:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: ""
      };

    case constants.UPDATE_NODE_ROLES_AS_INITIAL_SUCCESS:

      return {
        ...state,
        isLoading: false,
        isError: false,
        nodeRoles: state.nodeRoles,
        remainingRoleLimits: state.nodeRoles
      };

    case constants.UPDATE_OLD_ENTITY_NAME:
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: ""
      };

    case constants.UPDATE_OLD_ENTITY_NAME_SUCCESS:

      return {
        ...state,
        isLoading: false,
        isError: false,
        oldEntityName: ''
      };

    default:
      return state;
  }
}


