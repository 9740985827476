import React from "react";
import { styled, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import classnames from "classnames";

const MyButton = styled(Button)({
  backgroundColor: "#535353",
  border: 0,
  borderRadius: 4,
  color: "#ffffff",
  letterSpacing: 0.42,
  padding: "9px 24px",
  fontSize: 14,
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"'
  ].join(","),
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#535353",
    color: "#fff",
    opacity: 0.8
  }
});

const useStyles = makeStyles(theme => ({
  disabled: {
    backgroundColor: "#DDDDDD",
    color: "#A4A4A4"
  },
  enabled: {
    backgroundColor: "#535353",
    color: "#ffffff"
  }
}));

export default function ButtonStyled({
  disabled,
  btnPadding,
  onClickHandler,
  btnText,
  btnFloatClass
}) {
  const classes = useStyles();
  return (
    <MyButton
      classes={{ root: btnPadding ? btnPadding.btnPadding : null }}
      disabled={disabled}
      onClick={onClickHandler}
      className={classnames(
        disabled ? classes.disabled : classes.enabled,
        (btnText === "Proceed" || btnText === "Invite") ? btnFloatClass && btnFloatClass.btnFloat : ""
      )}
    >
      {btnText}
    </MyButton>
  );
}
