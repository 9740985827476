import React, { Component, createRef } from 'react';
import { Grid, Button, withStyles } from '@material-ui/core';
import CardContainer from '../../views/website/components/Entity-card';
import AliceCarousel from 'react-alice-carousel';
import './styles.css';
import './entityForm.css';

// Import SVG Icons
import { ReactComponent as SlEntityIcon } from '../../assets/icons/entity-class/sle.svg';
import { ReactComponent as NGEntityIcon } from '../../assets/icons/entity-class/nge.svg';

const styles = theme => ({
  formationButton: {
    color: '#ffffff',
    borderRadius: 4,
    backgroundColor: '#535353',
    fontSize: 14,
    fontWeight: 400,
    fontFamily: 'inherit',
    letterSpacing: '0.5px',
    boxShadow: '0px 2px 6px #00000029',
    textTransform: 'capitalize',
    padding: '12px 30px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    }
  },
  formationButtonDisable: {
    color: '#9E9E9E',
    borderRadius: 4,
    backgroundColor: '#00000029',
    fontSize: 14,
    fontWeight: 400,
    fontFamily: 'inherit',
    letterSpacing: '0.5px',
    textTransform: 'capitalize',
    padding: '12px 30px'
  }
});

class EntityClass extends Component {
  constructor(props) {
    super(props);
    this.state = { currentIndex: 0 };
  }
  continue = e => {
    e.preventDefault();
    this.props.handleNext();
  };

  previous = e => {
    this.props.handleBack();
  };

  carousalClass = createRef();

  entityClass = [
    {
      value: 'smart_legal_entity',
      name: 'Smart Legal Entity',
      buttonText: 'Smart Entity',
      subText: 'Vehicle of Venture',
      desc:
        'Decentralized legal vessels, suitable for doing business in the digital age.',
      icon: <SlEntityIcon />,
      sub: 'SLE',
      initialEntityType: 'decentralized_company'
    },
    {
      value: 'next_gen_entity',
      name: 'Next-Gen Entity',
      buttonText: 'Next-Gen Entity',
      subText: 'Vessel of Enterprise',
      desc:
        'Smart digital vehicles, for doing business in a next-generation environment.',
      icon: <NGEntityIcon />,
      sub: 'NGE',
      initialEntityType: 'next_gen_project',
      disable: true
    }
  ];

  responsive = {
    0: { items: 1 },
    767: { items: 2 }
  };

  stagePadding = {
    paddingLeft: 36,
    paddingRight: 36
  };

  // handleSlide = button => {
  //   if (button === "next") {
  //     this.carousalClass.current.slideNext();
  //   } else {
  //     this.carousalClass.current.slidePrev();
  //   }
  // };

  componentWillMount() {
    const index = this.entityClass.findIndex(data => {
      return data.value === this.props.classType;
    });
    this.setState({ currentIndex: index });
  }

  handleOnSlideChange = event => {
    const { item } = event;
    this.setState({ currentIndex: item });
    this.props.setClassType(this.entityClass[item].value);
  };

  getItems = () => {
    let item = [];
    this.entityClass.map(data => {
      item.push(
        <CardContainer showCustomMargin={true}>
          <div className="eclass-carousel">
            <div className="entity-class-name">{data.name}</div>
            <div className="sle-svg">{data.icon}</div>
            <div className="entity-class-sub">{data.subText}</div>
            <div className="entity-class-desc">{data.desc}</div>
            <Button
            disabled={data.disable}
              className={data.disable ? this.props.classes.formationButtonDisable : this.props.classes.formationButton}
              onClick={e => {
                this.props.setClassType(data.value);
                this.props.setEntityType(data.initialEntityType);
                this.props.handleTopIcon(data.value);
                this.continue(e);
              }}
            >
              {`New ${data.buttonText}`}
            </Button>
          </div>
        </CardContainer>
      );
    });
    return item;
  };

  render() {
    // const { currentIndex } = this.state;

    return (
      <div className="create-entity-form">
        <Grid container spacing={0} justify="center" alignItems="center">
          <Grid item xs={12}>
            <div className="form-header">Select Entity Class</div>

            {/* <div className="small-class-wrapper">
              {this.entityClass.map((data, i) => {
                return (
                  <div
                    className={`eSclass ${
                      this.props.classType === data.value ? "eSActive" : ""
                    }`}
                    onClick={e => {
                      this.setState({ currentIndex: i });
                      this.carousalClass.current.slideTo(i);
                    }}
                  >
                    {data.sub}
                  </div>
                );
              })}
              <div
                className={`eSclass ${
                  this.props.classType === "smart_legal_entity"
                    ? "eSActive"
                    : ""
                }`}
              >
                SLE
              </div>
              <div
                className={`eSclass ${
                  this.props.classType === "next_gen_entity" ? "eSActive" : ""
                }`}
              >
                NGE
              </div>
            </div> */}
          </Grid>
          <Grid item xs={12}>
            {/* <CardContainer> */}
            <div className="carousel-container">
              {/* <button
                onClick={e => {
                  e.stopPropagation();
                  this.handleSlide("prev");
                }}
                className="prev"
              >
                <LeftArrow style={{ height: "48px" }} />
              </button> */}
              <AliceCarousel
                className="testClass"
                dotsDisabled={false}
                buttonsDisabled={true}
                responsive={this.responsive}
                stagePadding={this.stagePadding}
                ref={this.carousalClass}
                infinite={false}
                items={this.getItems()}
                // startIndex={currentIndex}
                // onSlideChanged={this.handleOnSlideChange}
              />
              {/* <button onClick={() => this.handleSlide("next")} className="next">
                <RightArrow style={{ height: "48px" }} />
              </button> */}
            </div>
            {/* </CardContainer> */}
          </Grid>
          {/* <Grid item xs={12}>
            <div>
              <div className="entity-class-sub">
                {
                  this.entityClass[
                    this.entityClass.findIndex(data => {
                      return data.value === this.props.classType;
                    })
                  ].subText
                }
              </div>
              <div className="entity-class-desc">
                {
                  this.entityClass[
                    this.entityClass.findIndex(data => {
                      return data.value === this.props.classType;
                    })
                  ].desc
                }
              </div>
              <div className="entity-class-btn">
                <Button
                  style={{
                    textTransform: "capitalize",
                    fontSize: "1rem",
                    padding: "8px 0px",
                    width: "274px"
                  }}
                  variant="outlined"
                  onClick={e => {
                    this.props.setClassType(
                      this.entityClass[
                        this.entityClass.findIndex(data => {
                          return data.value === this.props.classType;
                        })
                      ].value
                    );
                    this.props.setEntityType(
                      this.entityClass[
                        this.entityClass.findIndex(data => {
                          return data.value === this.props.classType;
                        })
                      ].initialEntityType
                    );
                    this.props.handleTopIcon(
                      this.entityClass[
                        this.entityClass.findIndex(data => {
                          return data.value === this.props.classType;
                        })
                      ].value
                    );
                    this.continue(e);
                  }}
                >
                  {`New ${
                    this.entityClass[
                      this.entityClass.findIndex(data => {
                        return data.value === this.props.classType;
                      })
                    ].name
                  }`}
                </Button>
              </div>
              <div
                className="back-btn"
                onClick={e => {
                  this.previous();
                }}
              >
                Back
              </div>
            </div>
          </Grid> */}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(EntityClass);
