import React from "react";
import NodeHoldersContainer from "../website/components/NodeHoldersContainer";
import NodeHoldersTypography from "../website/components/NodeHoldersTypography";
import { Typography, withStyles, Grid } from "@material-ui/core";
import classnames from "classnames";
import { TextField } from "@material-ui/core";
import ButtonStyled from "../website/components/ButtonStyled";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import "./styles.css";

const THEME = createMuiTheme({
  palette: {
    typography: {
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(",")
    },
    primary: {
      main: "#535353"
    }
  }
});

const styles = theme => ({
  cardSubDescription: {
    fontSize: 16,
    fontFamily: "inherit",
    fontWeight: 500,
    letterSpacing: 0.24,
    color: "#A7A7A7",
    textAlign: "left"
  },
  NominateDirector: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: "inherit",
    letterSpacing: 0.21,
    color: "#777777",
    marginTop: "4px",
    marginBottom: "1rem"
  },
  textLeft: {
    textAlign: "left"
  },
  textRight: {
    textAlign: "right"
  },
  mB30: {
    marginBottom: 30
  },
  mT60: {
    marginTop: 60
  },
  btnPadding: {
    padding: "9px 44px"
  }
});

function IssueEQT({ classes, handleNext, values }) {
  const cardDescription = "Create the companys entity-unique EQT tokenbase.";
  return (
    <MuiThemeProvider theme={THEME}>
      <NodeHoldersContainer
        showFooterText={true}
        currentStep={1}
        totalSteps={3}
      >
        <NodeHoldersTypography
          title={"Issue EQT"}
          cardDescription={cardDescription}
        />
        <div
          className={classnames(classes.textLeft, classes.mB30, classes.mT60)}
        >
          <div className={classes.cardSubDescription}>EQT BASE</div>
          <div className={classes.NominateDirector}>
            Set the total number of EQT tokens created for your decentralized
            company.
          </div>
        </div>
        <div className="text-field-top eqt_field">
          <TextField
            style={{ width: "100%" }}
            variant="outlined"
            className="border-input"
            type="number"
            value={values}
            disabled={values ? true : false}
          />
          <div className="eqt_btn">EQT </div>
        </div>
        <Grid className={classes.mT60} item xs={12}>
          {/* <button
                className="eqt_btn_style" onClick={handleNext}>
                Issue Base EQT
              </button> */}

          <ButtonStyled
            btnPadding={classes}
            btnText={"Issue Base EQT"}
            onClickHandler={handleNext}
          />
        </Grid>
      </NodeHoldersContainer>
    </MuiThemeProvider>
  );
}

export default withStyles(styles)(IssueEQT);
