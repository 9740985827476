import React, { Fragment } from 'react';
import { useState } from 'react';
import EntitiesContainer from '../EntitiesContainer';
import ReservedNames from './ReservedNames';
import NoEntities from '../AssociatedEntities/NoEntities';
import { withStyles, Link } from '@material-ui/core';

const styles = theme => ({
  linkTxt: {
    color: '#1A73E8',
    cursor: 'pointer'
  }
});

function ReservedEntities({ classes }) {
  const [entities, setEntities] = useState([
    // {
    //     id: 1,
    //     name: 'Bennet Company',
    //     companyDesc: 'Decentralized Liability Company',
    //     Expires: 'Expires: (n)h:m:s',
    //     status: 'Dismiss'
    // },
    // {
    //     id: 2,
    //     name: 'MR NEVILLE JOHNSON & MRS',
    //     companyDesc: 'Decentralized Liability Company',
    //     Expires: 'Expires: (n)h:m:s',
    //     status: 'Dismiss'
    // }
  ]);

  const footerText = (
    <Fragment>
      Proceed to the Entities Registrar to{' '}
      <Link className={classes.linkTxt} href="/entity-formation">
        reserve entity name.
      </Link>
    </Fragment>
  );
  const headerText = <Fragment>You currently have no reserved names.</Fragment>;

  return (
    <EntitiesContainer
      subTitle={`Reserved Names (${entities.length})`}
      title={'Your reserved entity names.'}
      entities={entities}
      maxWidth="xl"
    >
      {entities.map(entity => (
        <ReservedNames key={entity.id} entity={entity} />
      ))}
      {!entities.length && (
        <NoEntities footerText={footerText} headerText={headerText} />
      )}
    </EntitiesContainer>
  );
}

export default withStyles(styles)(ReservedEntities);
